import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { ArticleResultsService } from "../../press-room/ArticleResultsService";
export class LanguageNav extends Component {
	subNavToggle: JQuery;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.subNavToggle = $(".lang-accord-toggle");

		if (!this.subNavToggle) {
			console.warn("No subnav toggle found to apply toggle effect.");
		}

		this.applyToggleEffect();
		this.applyCloseEffect();
		this.applyLangClickEvt();
	}

	applyToggleEffect(): void {
		this.subNavToggle.on(
			"click keypress",
			function (e: JQuery.TriggeredEvent): void {
				e.preventDefault();

				const $target = $(e.target);

				const $accordContent = $target.next(".accord-toggle-content");

				if (e.key === "Enter" || e.type === "click") {
					if ($target.hasClass("expanded")) {
						$accordContent.stop().slideUp("fast", () => {
							$target.removeClass("expanded");
							$target.attr("aria-expanded", "false");
						});
					} else {
						$accordContent.stop().slideDown("fast", () => {
							$target.addClass("expanded");
							$target.attr("aria-expanded", "true");
						});
					}
				}
			}
		);
	}

	applyCloseEffect(): void {
		$(document).on("click", () => {
			if (this.subNavToggle.hasClass("expanded")) {
				$(".accord-toggle-content").slideUp();
				this.subNavToggle.removeClass("expanded");
				this.subNavToggle.attr("aria-expanded", "false");
			}
		});
	}

	applyLangClickEvt(): void {
		const languages = this._element.querySelectorAll(
			".accord-toggle-content li"
		);

		if (!languages) {
			console.warn(
				"No languages dropdown found in dropdown for elem: .accord-toggle-content"
			);

			return;
		}

		languages.forEach(l =>
			l.addEventListener("click", () => {
				if (!l.classList.contains("selected")) {
					languages.forEach(_l => _l.classList.remove("selected"));
					l.classList.add("selected");

					const params = {
						ds: this._element.dataset.ds,
						page: 1,
						pagesize: this._element.dataset.pageSize,
						language: l.dataset.val
					};

					// tslint:disable-next-line: no-floating-promises
					ArticleResultsService.getListViewResults(
						this._element.dataset.endpoint || "",
						params,
						document.querySelector(
							`#${this._element.dataset.listTarget}`
						) as HTMLElement
					).then(result => {
						const { listView } = this._element.dataset;
						const [data, err] = result;
						const evt = new CustomEvent(`filterChange${listView}`, {
							detail: {
								pageNumber: data.PageNumber,
								totalResults: data.TotalResults,
								totalPages: data.MaxPage
							}
						});
						this.updateListView(l.dataset.lookupVal);
						window.dispatchEvent(evt);
						if (listView) {
							const scope = document.querySelector(listView);
              // tslint:disable-next-line no-floating-promises
							this._app.services.componentFactory.loadComponents(
								scope as HTMLElement
							);
						}
					});
				}
			})
		);
	}

	updateListView(valSelected?: string): void {
		this._element
			.querySelectorAll(".accord-toggle-content li")
			.forEach(li => li.classList.remove("hidden"));
		const liElem = this._element.querySelector(
			`[data-lookup-val=${valSelected}]`
		);
		const liContent = liElem?.innerHTML || "";
		const selectedLiElem = this._element.querySelector("#lang-accord-toggle");
		if (!liElem || !selectedLiElem) {
			console.warn(
				`No language found in dropdown for elem: [data-val=${valSelected}]`
			);
			return;
		}

		liElem.classList.add("hidden");
		$(selectedLiElem).hide().html(liContent).fadeIn("slow");
	}
}

ComponentFactory.registerComponent("languageNav", LanguageNav);
