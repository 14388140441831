import { IAutoCompleteResponseTransformed } from "./IAutoCompleteResponseTransformed";
import { ValidateFieldResponse } from "./Models/ValidateFieldResponse";
// import { MockValidateResponse } from "./MockValidateResponse";
// import { MockDXEResult } from "./MockDXEResult";
interface IAutoCompleteResponse {
	placeId: string;
	structuredFormatting: {
		mainText: string;
		secondaryText: string;
	};
}
export class AddressAutocompleteService {
	private readonly _addressEndpoint: string;
	private readonly _bearerTokenEndpoint: string;
	private readonly _cache: object = {};
	private _dxeBearerToken: string | undefined;
	private _addressSuggestionUrl: string = "";

	constructor(bearerTokenEndpoint: string, addressEndpoint: string) {
		this._addressEndpoint = addressEndpoint;
		this._bearerTokenEndpoint = bearerTokenEndpoint;
		// tslint:disable-next-line: no-floating-promises
		this.initialize();
	}

	public static async validateField(
		bearerToken: string | undefined,
		validateEndpoint: string,
		value: { placeId: string; mainText: string; secondaryText: string }
	): Promise<ValidateFieldResponse | undefined> {
		try {
			const { placeId, mainText, secondaryText } = value;
			const promise = $.ajax({
				contentType: "application/json",
				data: JSON.stringify({
					autocompleteResult: {
						placeId,
						structuredFormatting: {
							mainText,
							secondaryText
						}
					},
					sessionToken: bearerToken
				}),
				dataType: "json",
				headers: {
					Authorization: `JWTBearer ${bearerToken}`
				},
				type: "POST",
				url: validateEndpoint
			});
			const result = await Promise.resolve(promise);
			// const result = MockValidateResponse;

			return new ValidateFieldResponse(result);
		} catch (err) {
			console.warn(
				`Error in validateField method AddressAutocompleteService: ${JSON.stringify(
					err
				)}`
			);
		}
	}

	async initialize(): Promise<void> {
		this._dxeBearerToken = await this.getDXEBearerToken(
			this._bearerTokenEndpoint
		);
		if (!this._dxeBearerToken) {
			throw Error("No DXE Bearer Token set.");
		}

		const event = new CustomEvent("dxeBearerToken", {
			detail: { bearerToken: this._dxeBearerToken }
		});

		document.dispatchEvent(event);
		this.setAddressSuggestionUrl();
	}

	public async getAddressSuggestions(
		userInput: string
	): Promise<IAutoCompleteResponseTransformed[] | undefined> {
		if (userInput in this._cache) {
			return this._cache[userInput];
		}
		try {
			const url = `${this._addressSuggestionUrl}address=${userInput}`;
			const promise = $.ajax({
				headers: {
					Authorization: `JWTBearer ${this._dxeBearerToken}`
				},
				type: "GET",
				url
			});
			const result = (await Promise.resolve(promise)) as [
				IAutoCompleteResponse
			];
			// const result = await new Promise(resolve => {
			// 	setTimeout(() => resolve(MockDXEResult), 250);
			// });
			const resultsTransformed = this.transformResponseData(result);
			this._cache[userInput] = resultsTransformed;

			return resultsTransformed;
		} catch (err) {
			console.warn(
				`Error retrieving Address Suggestions:${JSON.stringify(err)}`
			);
		}
	}

	setAddressSuggestionUrl(): void {
		const userCoordinates = this.getUserLocationCoordinates();
		if (userCoordinates) {
			const { lon, lat } = userCoordinates;
			this._addressSuggestionUrl = `${this._addressEndpoint}?lat=${lat}&lon=${lon}&`;
		} else {
			this._addressSuggestionUrl = `${this._addressEndpoint}?`;
		}
	}

	getUserLocationCoordinates(): { lat: string; lon: string } | null {
		navigator.geolocation.getCurrentPosition(position => {
			const lat = position.coords.latitude;
			const lon = position.coords.longitude;
			if (lat && lon) {
				return {
					lat,
					lon
				};
			}
		});

		return null;
	}

	private transformResponseData(
		response: IAutoCompleteResponse[]
	): IAutoCompleteResponseTransformed[] {
		const responseTransformed = response.map(item => {
			const {
				mainText,
				secondaryText
			}: {
				mainText: string;
				secondaryText: string;
			} = item.structuredFormatting;

			return {
				mainText,
				placeId: item.placeId,
				secondaryText,
				value: `${mainText}, ${secondaryText}`
			};
		});

		return responseTransformed;
	}

	private async getDXEBearerToken(): Promise<string> {
		try {
			const promise = $.get(this._bearerTokenEndpoint);
			const result = await Promise.resolve(promise);
			// tslint:disable-next-line: no-unsafe-any

			return result.Bearer;
		} catch (err) {
			throw new Error(
				`Problem retrieving DXE Bearer Token: ${JSON.stringify(err)}`
			);
		}
	}
}
