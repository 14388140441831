import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

export class ProfileCard extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.applyDataLayerCtaDownloadEvent();
		this.applyDataLayerCtaEvent();
	}

	applyDataLayerCtaDownloadEvent(): void {
		const selector = ".cta-download-click";
		const elements = this._element.querySelectorAll(selector);

		if (!elements) {
			console.warn("No elements found for selector", selector);
			return;
		}

		elements.forEach(element => {
			element.addEventListener("click", e => {
				const evtModel = {
					module_name: element.dataset.tag,
					link_category: "CFA One",
					link_text: element.innerHTML || "Get it on Store",
					link_url: element.getAttribute("href")
				};
				DataLayerService.pushDataLayerEvent("cfa_one_click_to_download", evtModel);
			});
		});
	}

	applyDataLayerCtaEvent(): void {
		const selector = ".cta-click";
		const elements = this._element.querySelectorAll(selector);

		if (!elements) {
			console.warn("No elements found for selector", selector);
			return;
		}

		elements.forEach(element => {
			element.addEventListener("click", e => {
				const evtModel = {
					module_name: "ProfileCard",
					link_category: "CFA One",
					link_text: element.innerHTML || "Join the program"
				};
				DataLayerService.pushDataLayerEvent("cta_click", evtModel);
			});
		});
	}
}

ComponentFactory.registerComponent("ProfileCard", ProfileCard);
