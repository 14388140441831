import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

enum pageStateIndex {
	stepOne = 0,
	stepTwo = 1,
	stepThree = 2
}

interface IObjectLiteral {
	[key: string]: string;
}

interface IStateProps {
	data?: IObjectLiteral;
	currentState: number;
	previousSection?: HTMLElement | null;
}

export class OneSurvey extends Component {
	public uri: string[] = [];
	public step: number = 0;
	public paginationStartCounter: number = -1;
	public paginationMaxCounter: number = 2;
	public totalPages: number = 3;
	public apiUrl: string = "/SurveyFeedback/Survey/SubmitCFAOneMemberSurvey";
	private readonly pageOneGroup: HTMLElement | null;
	private readonly pageTwoGroup: HTMLElement | null;
	private readonly pageThreeGroup: HTMLElement | null;
	private readonly progressBars: NodeListOf<HTMLElement> | null;
	private readonly endScreen: HTMLElement | null;
	private readonly mainWrapper: HTMLElement | null;
	private readonly footer: HTMLElement | null;
	private readonly State: IStateProps = {
		currentState: 0,
		data: {},
		previousSection: null
	};

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.pageOneGroup =
			_element.querySelector<HTMLElement>(".questions-set-one");
		this.pageTwoGroup =
			_element.querySelector<HTMLElement>(".questions-set-two");
		this.pageThreeGroup = _element.querySelector<HTMLElement>(
			".questions-set-three"
		);
		this.progressBars = _element.querySelectorAll<HTMLElement>(".progress-bar");
		this.endScreen = _element.querySelector<HTMLElement>(".one-survey-end");
		this.mainWrapper = _element.querySelector<HTMLElement>(".main-wrapper");
		this.footer = _element.querySelector<HTMLElement>(".one-survey-footer");

		const nextBtn = _element.querySelector<HTMLElement>(".nextBtn");
		const prevBtn = _element.querySelector<HTMLElement>(".prevBtn");
		const nextBtnLabel = nextBtn?.getAttribute("data-value-next") || "";
		const submitBtnLabel = nextBtn?.getAttribute("data-value-submit") || "";
		const thankYouUrl = nextBtn?.getAttribute("data-value-thankyou-url") || "";
		const errorUrl = nextBtn?.getAttribute("data-value-error-url") || "";

		if (prevBtn) {
			prevBtn?.addEventListener("click", () => {
				this.setSection();
				this.updateState(this.paginationStartCounter);

				if (this.currentState === 0) {
					prevBtn.style.opacity = "0";
					prevBtn.style.pointerEvents = "none";
				}

				if (nextBtn) {
					nextBtn.innerHTML =
						this.currentState !== this.paginationMaxCounter
							? nextBtnLabel
							: submitBtnLabel;
				}
			});
		}

		if (nextBtn) {
			nextBtn?.addEventListener("click", () => {
				const sectionContent = this.getSection();

				if (sectionContent) {
					this.errorCheck(sectionContent);
					if (this.validation(sectionContent)) {
						if (this.currentState !== this.paginationMaxCounter) {
							this.updateState(1);
							this.setSection();

							if (prevBtn) {
								prevBtn.style.opacity = "1";
								prevBtn.style.pointerEvents = "auto";
							}
						} else {
							const results = this.submitResults(this.apiUrl);

							window.location.href = thankYouUrl;
						}

						nextBtn.innerHTML =
							this.currentState === this.paginationMaxCounter
								? submitBtnLabel
								: nextBtnLabel;
					}
				}
			});
		}

		const uri = this.getURI(window.location.search);
		this.State.data = uri;
		this.setSection();
		this.updateState(this.paginationStartCounter);
	}

	private getURI(url: string): IObjectLiteral {
		const params = new URLSearchParams(url);
		const parsedQuery: IObjectLiteral = {};
		for (const [key, value] of params) {
			Object.defineProperty(parsedQuery, key, {
				configurable: true,
				enumerable: true,
				value,
				writable: true
			});
		}

		return parsedQuery;
	}

	private buildResponseObj(data: []): {}[] {
		const newArr: {}[] = [];

		const getIndex = (val: string) => {
			return val === "y" ? "1" : "2";
		};

		Object.entries(data).forEach(([key, val]) => {
			const dataObj: IObjectLiteral = {};
			if (key !== "token") {
				if (!key.includes("modulec_q")) {
					dataObj.questionId = key;
					// tslint:disable-next-line: prefer-conditional-expression
					if (key.includes("moduled_yn")) {
						dataObj.responseId = getIndex(val);
					} else {
						dataObj.responseId = val;
					}
					newArr.push(dataObj);
				} else {
					dataObj.questionId = key;
					dataObj.responseId = String(parseInt(val, 10) + 1);
					newArr.push(dataObj);
				}
			}
		});

		return newArr;
	}

	private async submitResults(endpoint: string): Promise<boolean> {
		let response: boolean;
		const questionsData = this.buildResponseObj(this.data);

		try {
			const request = $.ajax({
				data: {
					json: JSON.stringify(questionsData),
					token: this.State.data?.token
				},
				type: "GET",
				url: endpoint
			});

			response = (await Promise.resolve(request)) as boolean;

			return response;
		} catch (err) {
			return false;
		}

		return false;
	}

	private setFinalScreen(): void {
		if (this.mainWrapper) {
			this.mainWrapper.style.display = "none";
		}

		if (this.endScreen) {
			this.endScreen.style.display = "block";
		}

		if (this.footer) {
			this.footer.style.display = "none";
		}
	}

	private updateState(offset: number): void {
		window.scrollTo({
			top: 0
		});

		const max: number = Math.max(this.currentState + offset, 0);
		this.currentState = Math.min(max, this.totalPages);

		if (this.previousSection) {
			this.previousSection.style.display = "none";
		}

		const sectionEl = this._element.querySelector<HTMLElement>(
			`.set-${this.currentState}`
		);

		if (sectionEl) {
			sectionEl.style.display = "block";
			this.previousSection = sectionEl;
		}

		this.progressBars?.forEach((item, index) => {
			const statBar = item.querySelector<HTMLElement>(".progress-bar-status");
			statBar?.classList.remove("half");
			statBar?.classList.remove("full");

			if (index < this.currentState) {
				statBar?.classList.add("full");
			}

			if (index > this.currentState) {
				statBar?.classList.remove("half");
				statBar?.classList.remove("full");
			}

			if (index === this.currentState) {
				statBar?.classList.add("half");
			}
		});
	}

	private setSection(): void {
		const sectionContent = this.getSection();

		if (sectionContent) {
			this.populateSurvey(sectionContent);
		}
	}

	private handleClick(e: MouseEvent, section: HTMLElement): void {
		const selected = e.target as HTMLInputElement;
		this.data = selected;

		if (section) {
			if (section.classList.contains("error")) {
				section.classList.remove("error");
			}
		}
	}

	private populateSurvey(questions: NodeListOf<HTMLElement>): void {
		if (questions) {
			questions.forEach(section => {
				const label = section.querySelectorAll<HTMLElement>("label");

				label.forEach(labelEl => {
					const inputItem = labelEl.querySelector<HTMLInputElement>("input");
					inputItem?.addEventListener("click", e =>
						this.handleClick(e, section)
					);

					if (inputItem) {
						const name = inputItem?.name;

						Object.entries(this.data).forEach(([key, val], index) => {
							if (name === key) {
								const selectedEl = document.getElementById(
									`${key}${val}`
								) as HTMLInputElement;

								if (selectedEl) {
									selectedEl.checked = true;

									return;
								}
							}
						});
					}
				});
			});
		}
	}

	private getSection(): NodeListOf<HTMLElement> {
		let questions: NodeListOf<HTMLElement> | undefined;

		switch (this.currentState) {
			case pageStateIndex.stepOne:
				if (this.pageOneGroup) {
					questions =
						this.pageOneGroup?.querySelectorAll<HTMLElement>(".questions");
				}
				break;

			case pageStateIndex.stepTwo:
				if (this.pageTwoGroup) {
					questions =
						this.pageTwoGroup?.querySelectorAll<HTMLElement>(".questions");
				}
				break;

			case pageStateIndex.stepThree:
				if (this.pageThreeGroup) {
					questions =
						this.pageThreeGroup?.querySelectorAll<HTMLElement>(".questions");
				}
				break;
		}

		return questions as NodeListOf<HTMLElement>;
	}

	private validation(questions: NodeListOf<HTMLElement>): boolean {
		if (questions) {
			for (const element of questions) {
				const el = element;
				if (el) {
					if (el.classList.contains("error")) {
						return false;
					}
				}
			}
		}

		return true;
	}

	private errorCheck(questions: NodeListOf<HTMLElement>): void {
		if (questions) {
			questions.forEach(section => {
				let label: NodeListOf<HTMLElement>;
				label = section?.querySelectorAll<HTMLElement>("label");

				let cnt: number = -1;

				for (let i = label.length - 1; i > -1; i -= 1) {
					const inputItem = label[i].querySelector<HTMLInputElement>("input");
					if (inputItem?.checked) {
						cnt = i;
						i = -1;
					}
				}
				if (cnt > -1) {
					section.classList.remove("error");

					return label[cnt];
				}

				if (!section.classList.contains("error")) {
					section.classList.add("error");
				}
			});
		}
	}

	public get previousSection(): HTMLElement {
		return this.State.previousSection;
	}

	public set previousSection(el: HTMLElement) {
		this.State.previousSection = el;
	}

	public get data(): {}[] {
		return this.State.data;
	}

	public set data(obj: HTMLInputElement) {
		const name: string = obj.name;
		if (this.State.data) {
			this.State.data[name] = obj.value;
		}
	}

	public get currentState(): number {
		return this.State.currentState;
	}

	public set currentState(index: number) {
		this.State.currentState = index;
	}
}

ComponentFactory.registerComponent("OneSurvey", OneSurvey);
