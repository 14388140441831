import { Breakpoints } from "../../../../breakpoints";
import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { brightcoveVideo } from "./BrightcoveVideo";
import { ISlide } from "./models/ISlide";

// two slick-sliders that control each other:
// 1) the selected slide, with arrows
// 2) a grid for controls

function extractTemplate(context: HTMLElement, selector: string): string {
	const template = context.querySelector<HTMLTemplateElement>(selector);
	const html = template?.innerHTML ?? "";
	template?.parentElement?.removeChild(template);

	return html;
}

export class CarouselGrid extends Component {
	public readonly showcaseEl: JQuery;
	public readonly gridEl: JQuery;
	public slides: ISlide[];
	public showcase: JQuery | null = null;

	constructor(elem: HTMLElement, _app: ICfa) {
		super(elem, _app);
		this.handleSlideChange = this.handleSlideChange.bind(this);

		this.showcaseEl = $(".g-carousel-grid__showcase", elem);
		this.gridEl = $(".g-carousel-grid__grid", elem);
		this.slides = [];

		this.initCarousels();
		this.initVideos();
		this.previewPanelPlayOnClick();
	}
	// On mobile, the navigation arrows should be disabled
	// and only the pagination dots should display

	initCarousels(): void {
		const showcaseOptions: JQuerySlickOptions = {
			asNavFor: this.gridEl,
			dots: true,
			focusOnChange: true,
			focusOnSelect: true,
			infinite: false,
			mobileFirst: true,
			nextArrow: extractTemplate(this._element, "template[data-next-arrow]"),
			prevArrow: extractTemplate(this._element, "template[data-prev-arrow]"),
			slidesToScroll: 1,
			slidesToShow: 1,
			useTransform: false
		};

		this.showcase = this.showcaseEl.slick(showcaseOptions);

		const gridChildElems = $(this.gridEl).children().length;
		const desktopSlidesPerRow = 3;
		const mobileSlidesPerRow = 2;

		const gridOptions: JQuerySlickOptions = {
			arrows: false,
			asNavFor: this.showcaseEl,
			focusOnChange: true,
			focusOnSelect: true,
			responsive: [
				{
					breakpoint: Breakpoints.mobile,
					settings: {
						rows: Math.ceil(gridChildElems / mobileSlidesPerRow),
						slidesPerRow: mobileSlidesPerRow
					}
				}
			],
			rows: Math.ceil(gridChildElems / desktopSlidesPerRow),
			slidesPerRow: desktopSlidesPerRow,
			slidesToScroll: 1,
			slidesToShow: 1
		};

		this.gridEl.slick(gridOptions);

		// set tabindex on initial play button
		this.showcase.find(".slick-current .g-video__play-btn").attr("tabindex", 0);

		// set tabindex on slick arrows
		this.showcase.find(".slick-arrow").attr("tabindex", 0);

		this.showcase
			.find(".g-carousel-grid__nav--next")
			.on("click keypress", e => {
				if (e.key === "Enter" || e.type === "click") {
					const showcase = this.showcase;
					if (showcase) {
						showcase.slick("slickNext");
					}
				}
			});

		this.showcase
			.find(".g-carousel-grid__nav--prev")
			.on("click keypress", e => {
				if (e.key === "Enter" || e.type === "click") {
					const showcase = this.showcase;
					if (showcase) {
						showcase.slick("slickPrev");
					}
				}
			});

		this.showcase.on(
			"beforeChange",
			(
				_: JQuery.TriggeredEvent,
				slick: JQuerySlick,
				currentSlide: number,
				nextSlide: number
			) => this.handleSlideChange(slick, currentSlide, nextSlide)
		);

		this.showcase.on(
			"afterChange",
			(_: JQuery.TriggeredEvent, slick: JQuerySlick, currentSlide: number) =>
				this.handleSlideTabindex(slick, currentSlide)
		);
	}

	initVideos(): void {
		const slideEls = this.showcaseEl.find(
			".g-carousel-grid__slide:not(.slick-cloned)"
		);

		// build an array of slide + video data
		this.slides = [];
		slideEls.each((index, slideEl) => {
			const videoEls = $(slideEl).find(".video-js");
			const videoEl = videoEls.length ? $(videoEls[0]) : null;
			const videoId = videoEl ? (videoEl.data("videoId") as string) : null;
			const videoElId = videoEl ? videoEl.attr("id") : null;
			if (videoElId) {
				this.slides.push({
					index,
					videoEl,
					videoElId,
					videoId,
					wrapEl: slideEl
				});
			}
		});

		// init BrightcoveVideo with the slides
		this.slides
			.filter(val => !!val.videoId)
			.map(slide => brightcoveVideo.queuePlayer(slide));
	}

	previewPanelPlayOnClick(): void {
		const selector = $(this.gridEl).find(".g-carousel-grid__item");
		console.log("selector", selector);
		const previewPanelVids = selector;
		Array.from(previewPanelVids).forEach((vid, i) => {
			vid.addEventListener("click", () => {
				const vidId = vid.dataset.videoElement;
				if (vidId) {
					this.showcaseEl.slick("goTo", i);
					brightcoveVideo.playPlayer(vidId);
				}
			});
			vid.addEventListener("keypress", e => {
				if (e.key === "Enter") {
					vid.click();
				}
			});
		});
	}

	handleSlideChange(
		slick: JQuerySlick,
		currentSlide: number,
		nextSlide: number
	): void {
		if (currentSlide !== nextSlide) {
			const slide = this.slides[currentSlide];
			const { videoElId } = slide;
			brightcoveVideo.pausePlayer(videoElId);
		}

		// adjust tabindex on current slide's play button to prevent
		// incorrect tabbing
		$(slick.$slides[currentSlide])
			.find(".g-video__play-btn")
			.attr("tabindex", null);
	}

	// set tabindex on current slide's play button
	handleSlideTabindex(slick: JQuerySlick, currentSlide: number): void {
		$(slick.$slides[currentSlide])
			.find(".g-video__play-btn")
			.attr("tabindex", 0);
	}
}

ComponentFactory.registerComponent("carouselGrid", CarouselGrid);
