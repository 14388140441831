import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class ImgSliderGrid extends Component {
	public readonly sliderEl: JQuery;
	public slider: JQuery | null = null;
	public sliderContainer: JQuery;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.sliderEl = $(_element);
		this.sliderContainer = this.sliderEl.find(".container--grid-tile");
		this.initSlider();
		this.toggleImageOverlay();
		this.toggleButtonState();
	}

	toggleImageOverlay(): void {
		$(this.sliderEl)
			.find(".container--tile[data-frontcard='true'][data-backcard='true']")
			.on("click", function (): void {
				$(this).find(".thumbnail--overlay").toggleClass("active");
			});
	}

	initSlider(): void {
		const sliderOptions: JQuerySlickOptions = {
			accessibility: false,
			arrows: false,
			centerMode: true,
			cssEase: "linear",
			dots: false,
			draggable: false,
			infinite: false,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1023,
					settings: "unslick"
				},
				{
					breakpoint: 767,
					settings: {
						slidesToScroll: 1,
						slidesToShow: 2
					}
				}
			],
			slidesToScroll: 1,
			slidesToShow: 1,
			speed: 500,
			useTransform: false
		};

		this.sliderContainer = this.sliderContainer.slick(sliderOptions);

		$(this.sliderEl)
			.find("[data-next-arrow]")
			.on("click", () => {
				if (this.isMobileView) {
					this.sliderContainer.slick("slickNext");
				}
				if (!this.isMobileView) {
					const slideIndex = this.sliderContainer.slick("slickCurrentSlide");
					if (slideIndex < 1) {
						this.sliderContainer.slick("slickNext");
					}
				}
			});

		$(this.sliderEl)
			.find("[data-prev-arrow]")
			.on("click", () => {
				this.sliderContainer.slick("slickPrev");
			});

		this.setChildCount();
	}

	setChildCount(): void {
		const childCount = this._element.querySelectorAll(
			".container--grid-tile"
		).length;

		this._element.dataset.childCount = childCount.toString();
	}

	toggleButtonState(): void {
		const prevArrow = $(this.sliderEl).find("[data-prev-arrow]");
		const nextArrow = $(this.sliderEl).find("[data-next-arrow]");
		const firstSlide = $(this.sliderEl).find("[data-slick-index=0]");
		const lastSlide = $(this.sliderEl).find(".slick-slide:last");

		$(this.sliderEl)
			.find("[data-prev-arrow]")
			.on("click", () => {
				if (firstSlide.hasClass("slick-current")) {
					prevArrow.prop("disabled", true);
				}
				if (!lastSlide.hasClass("slick-current")) {
					nextArrow.prop("disabled", false);
				}
			});

		$(this.sliderEl)
			.find("[data-next-arrow]")
			.on("click", () => {
				prevArrow.prop("disabled", false);

				if (this.isMobileView) {
					if (lastSlide.hasClass("slick-current")) {
						nextArrow.prop("disabled", true);
					}
				} else {
					nextArrow.prop("disabled", true);
				}
			});
	}

	get isMobileView(): boolean {
		const tabletMinWidth = 767;

		return window.innerWidth < tabletMinWidth;
	}
}

ComponentFactory.registerComponent("imgSliderGrid", ImgSliderGrid);
