import { ISlide } from "./models/ISlide";

class BrightcoveVideo {
	private readonly accountId: string;
	private readonly elements: ISlide[] = [];
	private readonly playerId: string;
	private currentVideoId?: string;
	private bcUrl?: string;
	private bcScript?: HTMLElement;

	constructor({
		accountId = "1918791242001",
		playerId = "qY8LyOaAw_default"
	}: {
		accountId?: string;
		playerId?: string;
	} = {}) {
		if (!playerId || !accountId) {
			throw Error("BrightcoveVideo: Missing required parameters");
		}

		this.pausePlayer = this.pausePlayer.bind(this);

		this.playerId = playerId;
		this.accountId = accountId;

		// load the player if it's not already loaded
		this.initBcScript();

		// init the player
		this.initPlayer();
	}

	initBcScript(): void {
		this.bcUrl =
			`//players.brightcove.net/${this.accountId}` +
			`/${this.playerId}/index.min.js`;

		const existingBcScript = $(`script[src="${this.bcUrl}"]`);

		// check if a script already exists
		if (existingBcScript.length > 0) {
			this.bcScript = existingBcScript[0];
		} else {
			// append brightcove script if it doesnt exist
			this.loadBcScript();
		}
	}

	loadBcScript(): void {
		// append brightcove script if it doesnt exist
		this.bcScript = document.createElement("script");
		// Hardcoded to account ID.
		this.bcScript.setAttribute("src", `${this.bcUrl}`);
		// append to document
		document.head.appendChild(this.bcScript);
	}

	pausePlayer(videoElId: string | null): void {
		if (videoElId) {
			const player = videojs.getPlayer(videoElId.toString());
			if (player) {
				player.ready(() => player.pause());
			}
		}
	}

	playPlayer(videoElId: string | null): void {
		if (videoElId) {
			if (this.currentVideoId !== undefined) {
				this.pausePlayer(this.currentVideoId.toString());
			}

			const player = videojs.getPlayer(videoElId.toString());
			player?.ready(() => {
				player.play();
				this.currentVideoId = videoElId;
			});
		}
	}

	queuePlayer(player: ISlide): void {
		this.elements.push(player);
	}

	initPlayer(): void {
		const script = this.bcScript;
		if (!script) {
			return;
		}

		script.onload = () => {
			// loop over elements
			this.elements.map(({ wrapEl, videoElId }) => {
				if (!videoElId) {
					return;
				}

				// init the player
				const bcPlayer = videojs.getPlayer(videoElId.toString());

				if (!bcPlayer) {
					return;
				}

				bcPlayer.ready(() => {
					bcPlayer.on("pause", () => {
						playBtn.closest(".g-video").removeClass("g-video--playing");
					});

					bcPlayer.on("play", () => {
						playBtn.closest(".g-video").addClass("g-video--playing");
					});

					const playBtn = $(wrapEl).find(".g-video__play-btn");
					const playCtrl = $(wrapEl).find(".video-js");

					playBtn.on("click keypress", e => {
						if (e.key === "Enter" || e.type === "click") {
							if (this.currentVideoId !== undefined) {
								this.pausePlayer(this.currentVideoId.toString());
							}
							this.playPlayer(videoElId.toString());
							playCtrl.triggerHandler("focus");
						}
					});

					playCtrl.on("click keypress", e => {
						if (e.key === "Enter" || e.type === "click") {
							bcPlayer.pause();
							playBtn.triggerHandler("focus");
						}
					});

					// set the time in the grid
					bcPlayer.on("loadstart", () => {
						const secondsPerMinute = 60;

						const min = Math.floor(
							bcPlayer.mediainfo.duration / secondsPerMinute
						);

						const sec = Math.floor(
							bcPlayer.mediainfo.duration % secondsPerMinute
						);

						const time = `${min}:${sec}`;

						const gridItem = $(`*[data-video-element="${videoElId}"]`);
						const itemDur = gridItem.find(".g-carousel-grid__item-dur");
						if (itemDur) {
							itemDur.html(time);
						}
					});
				});
			});
		};
	}
}

export const brightcoveVideo = new BrightcoveVideo();
