import { API } from "../../../util/API";

// tslint:disable-next-line: no-unnecessary-class
export class CampaignDBService {
	static async sendEmailToDb(
		endpoint: string,
		payload: { email: string; source: string }
	): Promise<[null | object, null | object | null]> {
		const [response, error]: [{} | null, {} | null] = await API.postReq(
			endpoint,
			payload
		);

		if (error) {
			console.warn(`Error in sending email to Database. Error: ${error}`);

			return [null, error];
		}

		return [response, null];
	}
}
