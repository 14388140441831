import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

interface IGeoLocationError {
	code: number;
	message: string;
}

export class PWOSearchForm extends Component {
	focused: boolean;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.handleForm();

		if (this.useLocationBtn) {
			this.initUseLocationBtn();
		}

		if (this.isAutocompleteEnabled) {
			this.initAutocomplete();
		}

		// CFAC-34725 Fire whenever a user starts filling out a form
		this.searchInput?.addEventListener("focus", e => this.handleUserInput(e));
		this.focused = false;
	}

	// CFAC-34725 Fire whenever a user starts filling out a form
	private async handleUserInput(e: Event): Promise<void> {
		if (!this.focused) {
			DataLayerService.pushDataLayerEvent_Forms("form_start", {
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "PWOSearchForm"
			});

			this.focused = true;
		}
	}

	private redirectUser(redirectURL: string, queryString: string): string {
		return (window.location.href = `${redirectURL}${queryString}`);
	}

	private handleForm(): void {
		this._element.addEventListener("submit", e => {
			this.validateUserInput(e);
		});

		this.searchInput?.addEventListener("focus", () => {
			this.formErrorMsg?.classList.remove("display");
			if (this.isSlideAnimationTrue) {
				this._element.classList.add("btnAnimation");
				if (this.searchInput) {
					this.searchInput.placeholder = "";
				}
			}
		});
	}

	private initUseLocationBtn(): void {
		const redirectURL = `${this.redirectURL}?isGeoLocation=true&locationSearchString=`;

		this.useLocationBtn?.addEventListener("click", e => {
			e.preventDefault();
			if (navigator.geolocation) {
				const geoOptions = { timeout: 5000 };

				navigator.geolocation.getCurrentPosition(
					position => {
						const { latitude, longitude } = position.coords;
						const searchString = encodeURIComponent(
							latitude.toString() + "," + longitude.toString()
						);

						this.redirectUser(redirectURL, searchString);
					},
					error => this.geoLocationError(error),
					geoOptions
				);
			} else {
				alert("The Browser does not support Geolocation");
			}
		});
	}

	private initAutocomplete(): void {
		const searchContainer = this._element.querySelector(
			"[data-element='pwoSearchContainer']"
		);

		if (this.searchInput) {
			$(this.searchInput).autocomplete({
				appendTo: searchContainer,
				close(): void {
					searchContainer?.classList.remove("autocompleteOpen");
				},
				open(): void {
					searchContainer?.classList.add("autocompleteOpen");
				},
				source: this.favoritedAddresses
			});
		}
	}

	private validateUserInput(e: Event): void {
		const userInput = this.searchInput?.value.trim();

		if (userInput) {
			if (this.searchInput?.dataset.pwo === "true") {
				const queryString = `?locationSearchString=${encodeURIComponent(
					userInput
				)}`;

				// CFAC-34725 Fire whenever a user successfully completes a form
				DataLayerService.pushDataLayerEvent_Forms("form_complete", {
					identifier: this.getlayerIdentifier(),
					name: this.getlayerName(),
					type: "PWOSearchForm"
				});

				this.redirectUser(this.redirectURL, queryString);
				e.preventDefault();
			}

			if (this.searchInput?.dataset.pdp === "true") {
				// last item returned from url or we get menuId from elsewhere?
				const menuItem = window.location.href.split("/").pop();
				const itemId = `?item=${menuItem}`;

				// CFAC-34725 Fire whenever a user successfully completes a form
				DataLayerService.pushDataLayerEvent_Forms("form_complete", {
					identifier: this.getlayerIdentifier(),
					name: this.getlayerName(),
					type: "PWOSearchForm"
				});

				this.redirectUser(this.redirectURL, itemId);
				e.preventDefault();
			}
		} else {
			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "PWOSearchFormSubmission",
				error_message: "User input is empty",
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "PWOSearchForm"
			});

			this.formErrorMsg?.classList.add("display");
			e.preventDefault();
		}
	}

	private geoLocationError(error: IGeoLocationError): void {
		throw new Error(`Received error type: ${error.code}, ${error.message}`);
	}

	get formErrorMsg(): HTMLElement | null {
		return this._element.querySelector("[data-element='formErrorMsg']");
	}

	get redirectURL(): string {
		return this._element.dataset.redirectUrl || "";
	}

	get searchInput(): HTMLInputElement | null {
		return this._element.querySelector("[data-element='pwoSearchInput']");
	}

	get useLocationBtn(): HTMLElement | null {
		return this._element.querySelector("[data-element='useLocation']");
	}

	get isSlideAnimationTrue(): boolean {
		return this._element.dataset.slideAnimation === "true";
	}

	get isAutocompleteEnabled(): boolean {
		return this._element.dataset.autocompleteEnabled === "true";
	}

	get favoritedAddresses(): ReadonlyArray<string> {
		const favoritedAddressesString = this._element.querySelector(
			"[data-element='favoritedAddresses']"
		) as HTMLInputElement;

		return (
			favoritedAddressesString?.value
				.split("|")
				.map(x => x.trim())
				.filter(Boolean) || []
		);
	}

	getlayerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	getlayerName(): string {
		return this._element.dataset.layerName || "";
	}
}

ComponentFactory.registerComponent("pwoSearchForm", PWOSearchForm);
