import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class DeliveryPartnerList extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		_element
			.querySelectorAll<HTMLElement>("[data-action='3rdPartyDelivery']")
			.forEach(el => el.addEventListener("click", e => this.trackDelivery(e)));

		if (this.isGridView) {
			this._element.classList.add("grid-view");
		}
	}

	private trackDelivery(e: MouseEvent): void {
		const target = e.target as HTMLElement;
		const data = target?.dataset || {};

		(dataLayer || []).push({
			category: "ThirdPartyDelivery",
			event: "engagement",
			partner: data.partner || ""
		});
	}

	private get isGridView(): boolean {
		const elemsForGridView = 3;
		if (this._element.dataset.gridView) {
			return this._element.childElementCount > elemsForGridView;
		}

		return false;
	}
}

ComponentFactory.registerComponent("DeliveryPartnerList", DeliveryPartnerList);
