export const LocalStorage = {
	keys: {
		isUserLoggedIn: "loginStatus"
	},

	getLocalStorageKey(key: string): string | null {
		return localStorage.getItem(key);
	},

	setLocalStorageValue({ key, value }: { key: string; value: string }): void {
		localStorage.setItem(key, value);
	}
};
