// tslint:disable: no-unnecessary-class
export class LoadingSpinner {
	public static start(id: string): void {
		if (!id) {
			throw new Error("No spinner id provided");
		}

		const spinnerHTML = `<div id=${id} class="loading-spinner-wrapper"><div class="loading-spinner-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>`;

		$(document.body).append(spinnerHTML);
	}

	public static stop(id: string): void {
		if (!id) {
			throw new Error("No spinner id provided");
		}

		$(`#${id}`).remove();
	}
}
