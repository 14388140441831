import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

// CFAC-34764 login
export class UserName extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		DataLayerService.pushDataLayerEvent_Login(_element as HTMLElement);
	}
}

ComponentFactory.registerComponent("userName", UserName);
