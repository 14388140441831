import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { DeliveryService } from "./DeliveryService";
import { AddressAutocompleteService } from "../signature-drop/AddressAutocompleteService";
import { DeliveryServiceAvailability } from "./Models/DeliveryServiceAvailability";
import { getToken } from "../services/tokens";
import { LoadingSpinner } from "../common/LoadingSpinner/LoadingSpinner";
import { DataLayerService } from "../services/DataLayerService";

interface IDeliverySubscriptionPayload {
	streetNumber: string;
	route: string;
	locality: string;
	administrativeAreaLevelOne: string;
	administrativeAreaLevelTwo?: string;
	country: string;
	postalCode: string;
}

export class SubscriptionAddressVerify extends Component {
	form: HTMLFormElement;
	dxeBearerToken: string | undefined;
	deliverySubscriptionToken: Promise<string> | string = "";
	spinnerID: string;
	focused: boolean;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		if (this._element.tagName !== "FORM") {
			throw new Error(`Non-form element passed in, element: ${this._element}`);
		}

		this.form = this._element as HTMLFormElement;
		this.spinnerID = "loading-spinner";

		// CFAC-34725 Fire whenever a user starts filling out a form.
		this._element
			.querySelector("#CustomAddress")
			?.addEventListener("focus", e => this.handleUserInput(e));
		this.focused = false;

		// CFAC-34725 Fire whenever a user is presented with a form on a page.
		DataLayerService.pushDataLayerEvent_Forms("form_view", {
			identifier: this.getlayerIdentifier(),
			name: this.getlayerName(),
			type: "SubscriptionAddressVerify"
		});
	}

	// CFAC-34725 Fire whenever a user starts filling out a form.
	async handleUserInput(e: Event): Promise<void> {
		if (!this.focused) {
			DataLayerService.pushDataLayerEvent_Forms("form_start", {
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "SubscriptionAddressVerify"
			});

			this.focused = true;
		}
	}

	async init(): Promise<void> {
		this.form.addEventListener("submit", e => this.handleSubmission(e));

		this.applyValidationRules();
		this.storeBearerToken();
		await this.fetchDeliveryQueryToken();
	}

	async fetchDeliveryQueryToken(): Promise<void> {
		const { deliveryToken: endpoint } = this._element.dataset;

		if (!endpoint) {
			throw new Error("Delivery token endpoint not provided in rendering.");
		}

		this.deliverySubscriptionToken = await getToken(endpoint);

		if (!this.deliverySubscriptionToken) {
			throw new Error(
				`Unable to retrieve delivery subscription token at endpoint: ${endpoint}`
			);
		}
	}

	private storeBearerToken(): void {
		document.addEventListener("dxeBearerToken", e => {
			this.dxeBearerToken = e.detail.bearerToken;

			if (!this.dxeBearerToken) {
				throw new Error("Problem dispatching dxeBearer token value.");
			}
		});
	}

	private async handleSubmission(e: Event): Promise<void> {
		e.preventDefault();
		const formHasErrors = !this.validate();
		const { userEnteredAddress: errMsg = "" } = this.form.dataset;

		if (formHasErrors) {
			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "SubscriptionAddressVerifyFormSubmission",
				error_message: "Form has errors",
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "SubscriptionAddressVerify"
			});

			return;
		}

		this.toggleSubmitBtn(false);
		LoadingSpinner.start(this.spinnerID);
		const validateFieldResponse =
			await AddressAutocompleteService.validateField(
				this.dxeBearerToken,
				this._element.dataset.validateEndpoint ?? "",
				this.getCustomAddressValue()
			);

		console.log({ validateFieldResponse });

		if (!validateFieldResponse?.isValidAddress) {
			const validator = $(this._element).validate();
			validator.showErrors({
				CustomAddress: errMsg
			});

			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "SubscriptionAddressVerifyFormSubmission",
				error_message: errMsg,
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "SubscriptionAddressVerify"
			});

			this.toggleSubmitBtn(true);
			LoadingSpinner.stop(this.spinnerID);

			return;
		}
		await this.submitForm(validateFieldResponse._data);
	}

	private async submitForm(
		payload: IDeliverySubscriptionPayload
	): Promise<void> {
		const { submissionEndpoint } = this._element.dataset;

		if (!submissionEndpoint) {
			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "SubscriptionAddressVerifyFormSubmission",
				error_message: `Submission endpoint value not found for ${submissionEndpoint}`,
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "SubscriptionAddressVerify"
			});

			throw new Error(
				`Submission endpoint value not found for ${submissionEndpoint}`
			);
		}

		try {
			const response: DeliveryServiceAvailability | undefined =
				await DeliveryService.checkServiceAvailability(
					this.deliverySubscriptionToken,
					submissionEndpoint,
					payload
				);
			const updatedAddressResponse = {
				...payload,
				origin: "dotcom"
			};

			const encodedAddress = btoa(JSON.stringify(updatedAddressResponse));
			LoadingSpinner.stop(this.spinnerID);

			// CFAC-34725 Fire whenever a user successfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_complete", {
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "SubscriptionAddressVerify"
			});

			this.handleSubmitFormRedirect(response, encodedAddress);
		} catch (err) {
			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "SubscriptionAddressVerifyFormSubmission",
				error_message: `Error in Delivery Service checkServiceAvailability call. Err:${JSON.stringify(
					err
				)}`,
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "SubscriptionAddressVerify"
			});

			console.warn(
				`Error in Delivery Service checkServiceAvailability call. Err:${JSON.stringify(
					err
				)}`
			);
		}
	}

	private handleSubmitFormRedirect(
		response: DeliveryServiceAvailability,
		encodedAddress: string
	): void {
		const {
			redirectError = "",
			redirectSuccess = "",
			redirectFailure = ""
		} = this._element.dataset;

		console.log("hasEligibleAddress", response.hasEligibileAddress);
		if (!response.hasEligibileAddress) {
			window.location.href = redirectFailure;

			return;
		}
		console.log("Response is successful");
		console.log({ response });
		window.location.href = `${redirectSuccess}?deliveryDetails=${encodedAddress}`;
	}

	private toggleSubmitBtn(toggleOn: boolean): void {
		const submitBtn = this._element.querySelector("button[type='submit']");
		const disabledClass = "disabled";
		if (!submitBtn) {
			throw new Error(`Submit button not found for ${submitBtn}`);
		}

		toggleOn
			? submitBtn.classList.remove(disabledClass)
			: submitBtn.classList.add(disabledClass);
	}

	private getCustomAddressValue(): {
		placeId: string;
		mainText: string;
		secondaryText: string;
	} {
		const customAddressInput = this._element.querySelector(
			"#CustomAddress"
		) as HTMLInputElement;
		const value = customAddressInput.getAttribute("value") || "";

		return value.split("|").reduce(
			(accum, currentVal, i) => {
				const currentKey = Object.keys(accum)[i];

				return {
					...accum,
					[currentKey]: currentVal
				};
			},
			{ placeId: "", mainText: "", secondaryText: "" }
		);
	}

	private validate(): boolean {
		if (this._element) {
			return $(this._element).valid();
		}

		return false;
	}

	private applyValidationRules(): void {
		const messages = this._element.dataset;
		const errorClass = "hasError";

		$(this.form).validate({
			errorClass: "hasError",
			errorPlacement: (error, element) => {
				error.appendTo(element.next(".error-container"));
			},
			highlight: element => {
				$(element).parent().addClass(errorClass);
			},
			messages: {
				CustomAddress: {
					hasUserSelectedAddress: messages.userEnteredAddress,
					required: messages.customAddressMsg ?? ""
				}
			},
			rules: {
				CustomAddress: {
					hasUserSelectedAddress: true,
					required: true
				}
			},
			unhighlight: element => {
				$(element).parent().removeClass(errorClass);
			}
		});
		this.addCustomValidationMethods();
	}

	private addCustomValidationMethods(): void {
		$.validator.addMethod("hasUserSelectedAddress", () => {
			const hasUserSelectedAddress = this.getHasUserSelectedAddress();

			return hasUserSelectedAddress;
		});
	}

	private getHasUserSelectedAddress(): boolean {
		const customAddressField = this.form.querySelector("#CustomAddress");

		if (!customAddressField) {
			throw new Error(
				"No customAddressField found with querySelector: '$CustomAddress'"
			);
		}
		const hasUserSelectedAddress = customAddressField?.getAttribute(
			"data-has-user-selected-address"
		);

		return hasUserSelectedAddress === "true";
	}

	getlayerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	getlayerName(): string {
		return this._element.dataset.layerName || "";
	}
}

ComponentFactory.registerComponent(
	"subscriptionAddressVerify",
	SubscriptionAddressVerify
);
