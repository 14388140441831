import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { QuizService } from "./QuizService";

interface IResultsInterface {
	correct: string;
	total: number;
}

interface IQuestionsInterface {
	answer: string;
	answerExplanation: string;
	question: string;
	suggestion: string;
}

interface IResultsInterface {
	[key: string]: string[];
}

interface IQuestionaireInterface {
	questionnaire: IQuestionsInterface[];
	scoreReasonPerQuestion: IResultsInterface[];
}

export class ComplexQuiz extends Component {
	private response?: IQuestionaireInterface;
	private noResultsContainer: HTMLElement;
	private resultsContainer: HTMLElement;
	private questionsCountContainer: HTMLElement;
	private questionsListContainer: HTMLElement;
	private questionLabel: HTMLElement;
	private nextQuestionBtn: HTMLButtonElement | null;
	private submitBtn: HTMLButtonElement | null;
	private showResultBtn: HTMLButtonElement | null;
	private selectedButton?: HTMLElement;
	private explainationElement: HTMLElement;
	private correctCountElement: HTMLElement;
	private totalCountElement: HTMLElement;
	private currentQuestionIndex: number = 0;
	private questions: IQuestionaireInterface[] = [];
	private endpoint: String | "";
	private postUrl: String | "";
	private authorization: String | "";
	private questionnaireguid: String | "";
	private correctCount: number = 0;
	private resultMessages: IResultsInterface[] = [];
	private resultsMessageElement: HTMLElement;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.endpoint = _element.dataset.endpoint as string;
		this.postUrl = _element.dataset.post as string;
		this.authorization = _element.dataset.authorization as string;
		this.questionnaireguid = _element.dataset.questionnaireguid as string;

		this.noResultsContainer = document.getElementById(
			"NoResults"
		) as HTMLElement;

		this.resultsContainer = document.getElementById("Results") as HTMLElement;

		this.questionLabel = document.getElementById("Question") as HTMLElement;

		this.questionsCountContainer = document.getElementById(
			"QuestionsCountList"
		) as HTMLElement;

		this.questionsListContainer = document.getElementById(
			"QuestionsList"
		) as HTMLElement;

		this.submitBtn = document.getElementById(
			"SubmitQuestionBtn"
		) as HTMLButtonElement;

		this.nextQuestionBtn = document.getElementById(
			"NextQuestionBtn"
		) as HTMLButtonElement;

		this.showResultBtn = document.getElementById(
			"ShowResultBtn"
		) as HTMLButtonElement;

		this.explainationElement = document.getElementById(
			"Explaination"
		) as HTMLElement;

		this.correctCountElement = document.getElementById(
			"ResultsCorrect"
		) as HTMLElement;

		this.totalCountElement = document.getElementById(
			"ResultsTotal"
		) as HTMLElement;

		this.resultsMessageElement = document.getElementById(
			"ResultsMessage"
		) as HTMLElement;

		this.shareBtn = document.getElementById("ShareBtn") as HTMLButtonElement;

		this.submitBtn?.addEventListener("click", e =>
			this.submit(
				this.questions[this.currentQuestionIndex - 1].answer,
				this.questions[this.currentQuestionIndex - 1].answerExplanation
			)
		);
		this.nextQuestionBtn?.addEventListener("click", e =>
			this.setNextQuestion(this.questions[this.currentQuestionIndex])
		);
		this.showResultBtn?.addEventListener("click", e => this.showResults());
		this.shareBtn?.addEventListener("click", e => this.generateShareLink());
    // tslint:disable-next-line
		this.buildQuestions();
		this.setResultsFromQueryString();
	}

	private generateShareLink(): void {
		const url = this.getUrl();

		const shareUrl = `${url}?correct=${this.correctCountElement.innerHTML}&total=${this.totalCountElement.innerHTML}`;

		navigator.clipboard
			.writeText(shareUrl)
			.then(() => {
				this.shareBtn.innerHTML = "Copied!";
			})
			.catch(err => {
				console.error("Failed to copy: ", err);
			});
	}
	private getUrl(): string {
		let url = window.location.href;

		if (url.includes("?")) {
			url = url.split("?")[0];
		}

		return url;
	}

	private showResults(): void {
		this.noResultsContainer?.classList.add("disabled");
    // tslint:disable-next-line
		this.postResults({
			correct: this.correctCount,
			total: this.questions.length
		});
	}

	private panelMgm(): void {
		setTimeout(() => {
			if (this.noResultsContainer) {
				this.noResultsContainer.style.visibility = "hidden";
			}

			this.resultsContainer?.classList.remove("disabled");
			this.scrollTo(this.resultsContainer);
		}, 1);
	}
	private async postResults(data: IResultsInterface): Promise<void> {
		this.correctCountElement.innerHTML = data.correct.toString();
		this.totalCountElement.innerHTML = data.total.toString();
		this.resultsMessageElement.innerHTML =
			this.resultMessages[data.correct].toString();

		this.panelMgm();

		const [result, err] = await this.postData(this.postUrl, {
			authorization: this.authorization,
			questionnaireguid: this.questionnaireguid,
			correct: data.correct,
			total: data.total
		});
	}

	private scrollTo(target: HTMLElement): void {
		const element = target;
		const top = target.style.top;
		element.style.top = "-130px";
		element.scrollIntoView({ behavior: "smooth" });
		element.style.top = top;
	}

	private async buildQuestions(): Promise<void> {
		this.questions = await this.fetchData(this.endpoint);
		this.setNextQuestion(this.questions[this.currentQuestionIndex]);
	}

	private toggleAnswersAbility(container: HTMLCollection, bool: Boolean): void {
		Array.from(container).forEach((item: Element) => {
			if (bool) {
				item.classList.remove("disabled");
			} else {
				item.classList.add("disabled");
			}
		});

		if (bool) {
			this.submitBtn?.classList.remove("disabled");
			this.nextQuestionBtn?.classList.add("disabled");
		}
	}

	private submit(answer: String, explaination: String): void {
		if (this.selectedButton?.innerText !== answer) {
			this.selectedButton?.classList.add("error");
		} else if (this.selectedButton?.innerText === answer) {
      // tslint:disable-next-line
			++this.correctCount;
		}

		const questionsContainer = this.getQuestionNodes(
			this.questionsListContainer
		);
		Array.from(questionsContainer).forEach((item: HTMLElement) => {
			if (item?.innerText === answer) {
				item.classList.add("correct");
			}
		});
		this.explainationElement.innerHTML = explaination as string;
		this.toggleAnswersAbility(
			this.getQuestionNodes(this.questionsListContainer),
			false
		);
		this.submitBtn?.classList.add("disabled");

		if (this.currentQuestionIndex < this.questions.length) {
			this.nextQuestionBtn?.classList.remove("disabled");
		} else {
			this.showResultBtn?.classList.remove("disabled");
		}
	}

	private setNextQuestion(questions: IQuestionsInterface): void {
		this.resetState();
		this.showQuestions(questions);

		// answers
		const options = questions.suggestion.split("|");

		options.forEach((question: String) => {
			const li = document.createElement("li");
			li.innerText = question as string;
			li.addEventListener("click", e => {
				this.selectAnswer(
					e,
					this.questions[this.currentQuestionIndex - 1].answer
				);
			});

			this.questionsListContainer.appendChild(li);
		});

		// count
		this.questions.forEach((_, index: number) => {
			const countLi = document.createElement("li");
      // tslint:disable-next-line
			const indexCount = ++index;

			if (this.currentQuestionIndex === index - 1) {
				countLi.classList.add("current");
			}

			countLi.innerText = String(indexCount);

			if (index - 1 < this.currentQuestionIndex) {
				countLi.classList.add("completed");
			}

			this.questionsCountContainer.appendChild(countLi);
		});
    // tslint:disable-next-line
		++this.currentQuestionIndex;
	}

	private selectAnswer(e: Event, answer: String): void {
		this.selectedButton = e.target as HTMLElement;
		const questionsContainer = this.getQuestionNodes(
			this.questionsListContainer
		);

		Array.from(questionsContainer).forEach((item, index) => {
			item.classList.remove("selected");
		});

		this.selectedButton?.classList.add("selected");
	}

	private resetState(): void {
		while (this.questionsListContainer.firstChild) {
			this.questionsListContainer.removeChild(
				this.questionsListContainer.firstChild
			);
		}

		while (this.questionsCountContainer.firstChild) {
			this.questionsCountContainer.removeChild(
				this.questionsCountContainer.firstChild
			);
		}

		while (this.explainationElement.firstChild) {
			this.explainationElement.removeChild(this.explainationElement.firstChild);
		}

		this.toggleAnswersAbility(
			this.getQuestionNodes(this.questionsListContainer),
			true
		);
	}

	private showQuestions(question: IQuestionsInterface): void {
		this.questionLabel.innerHTML = question?.question;
	}

	private async fetchData(type: String): Promise<IQuestionaireInterface> {
		try {
			this.response = await QuizService.fetchQuestions(this.endpoint as string);
		} catch (err) {
      console.warn(err);
    }

		this.resultMessages = this.response?.scoreReasonPerQuestion;

		return this.response?.questionnaire;
	}

	private async postData(endpoint: String, payload: {}): Promise<{}> {
		let response;

		try {
			response = await QuizService.postResults(endpoint as string, payload);
		} catch (err) {
      console.warn(err);
    }

		return response;
	}

	private getQuestionNodes(
		element: HTMLElement
	): HTMLCollectionOf<HTMLElement> {
		return element.getElementsByTagName("li");
	}

	private setResultsFromQueryString(): void {
		const urlParams = new URLSearchParams(window.location.search);
		const correct = urlParams.get("correct");
		const total = urlParams.get("total");
		const correctTotal = `${correct}/${total}`;

		if (correct && total) {
			const shareMessage = $("input#ShareMessage")
				.val()
				.replace("{0}", correctTotal);

			const url = this.getUrl();
			const previewImage = `${url}${$("input#ResultImage").val()}`;

      const metaConfig = {
        description: shareMessage,
        "og:description": shareMessage,
        "og:title": shareMessage,
        "og:image": previewImage,
        "og:image:secure_url": previewImage
      };

      Object.keys(metaConfig).forEach(property => {
        const metaTag = document.querySelector(`meta[name='${property}']`);
        if (!metaTag) {
          console.warn(`No meta tag found for ${property}`);
          return;
        }
        // tslint:disable-next-line
        metaTag.setAttribute("content", metaConfig[property]);
      });
		}
	}
}

ComponentFactory.registerComponent("complexQuiz", ComplexQuiz);
