import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { Cookie } from "../../../util/Cookie";

export class SetCookie extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.addCookieClickEv(_element);
	}

	private addCookieClickEv(item: HTMLElement): void {
		item.addEventListener("click", () => {
			const { cookieName, cookieValue } = this.cookieVals;

			if (!cookieName || !cookieValue) {
				const msg = "cookieName or cookieValue not defined. Cannot set cookie";
				throw new Error(msg);
			}

			Cookie.setCookie(cookieName, cookieValue);
		});
	}

	private get cookieVals(): {
		cookieName: string | undefined;
		cookieValue: string | undefined;
	} {
		const cookieName = this._element.dataset.cookieName;
		const cookieValue = this._element.dataset.cookieValue;

		return { cookieName, cookieValue };
	}
}

ComponentFactory.registerComponent("setCookie", SetCookie);
