class TypeWriterBehavior {
	private _progress: Promise<string> | null;
	private index: number;
	private readonly speed: number;
	private readonly text: string;

	constructor(private readonly element: HTMLElement) {
		const defaultSpeed = 100;
		this.index = 0;
		this.speed = defaultSpeed;
		this.text = element.dataset.typewritertxt || "";
		this._progress = null;
	}

	async typewriter(): Promise<string> {
		if (this._progress !== null) {
			return this._progress;
		}

		this.element.textContent = "";
		this.index = 0;
		const promise = (this._progress = new Promise(resolve => {
			const updateAllText = () => {
				if (this.advanceCursor()) {
					setTimeout(updateAllText, this.speed);
				} else {
					resolve("Complete");
				}
			};

			updateAllText();
		}));

		return promise.then(x => {
			this._progress = null;

			return x;
		});
	}

	advanceCursor(): boolean {
		if (this.index < this.text.length) {
			this.element.textContent += this.text.charAt(this.index);
			this.index += 1;
		}

		return this.index < this.text.length;
	}
}

document.querySelectorAll<HTMLElement>(".typewriterTxt .txt").forEach(el => {
	const behavior = new TypeWriterBehavior(el);

	// Justification: Top-level floating promise
	// tslint:disable-next-line: no-floating-promises
	behavior.typewriter();
});
