import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import SeeMore, { getSeeMoreParams } from "../../../util/SeeMore";

export class ThreeColumn extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.handleModifiers();
	}

	handleModifiers(): void {
		const classes = this._element.classList;

		// Apply See More to module if mobile view
		if (classes.contains("withSeeMore") && this.isMobileView) {
			const { index, buttonText, btnType } = getSeeMoreParams(this._element);
			const params = {
				parent: this._element.querySelector("ul") as HTMLElement,
				index,
				buttonText,
				btnType
			};

			SeeMore(params);
		}
	}

	get isMobileView(): boolean {
		return window.innerWidth < this._app.layout.desktopWidth;
	}
}

ComponentFactory.registerComponent("threeColumn", ThreeColumn);
