import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";
export class MenuCategoryCallout extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.setChildCount();
		this.applyDataLayerCtaEvent();
	}

	private setChildCount(): void {
		const childCount = this._element.childElementCount;
		this._element.setAttribute("data-child-count", childCount.toString());
	}

	applyDataLayerCtaEvent(): void {
		const selector = ".cta-click";
		const elements = this._element.querySelectorAll(selector);

		if (!elements) {
			console.warn("No elements found for selector", selector);
			return;
		}

		elements.forEach(element => {
			element.addEventListener("click", e =>
				DataLayerService.pushDataLayerEvent_Menu_CtaClick(element, "menu card")
			);
		});
	}
}

ComponentFactory.registerComponent("MenuCategoryCallout", MenuCategoryCallout);
