import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { CustomEvent } from "../../util/EventUtil";
import { YextRedirectService } from "../location/YextRedirectService";
import { DataLayerService } from "../services/DataLayerService";

interface ILocationRedirectResponse {
	readonly RedirectUrl?: string;
	readonly ShowLeavingPopup?: boolean;
}

interface IYextMessageEvent {
	readonly storeId: string;
	readonly menuItem: string;
}

export class YextLocationIframe extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		window.addEventListener("message", evt =>
			this.yextMessage(evt as CustomEvent<IYextMessageEvent>)
		);
	}

	async yextMessage(evt: CustomEvent<IYextMessageEvent>): void {
		evt.preventDefault();

		DataLayerService.pushDataLayerEvent_IFrameMessage(
			this._element,
			evt.data.storeId
		);

		if (evt.data.storeId !== undefined) {
			let url = "/locationredirecturl/" + evt.data.storeId;

			if (typeof evt.data.menuItem !== "undefined") {
				url += "?menuItem=" + evt.data.menuItem;
			}

			const [response, error] = await YextRedirectService.getRedirectData(url);

			if (error) {
				console.warn(
					`Error in getting LocationRedirectUrl response. Error: ${error}`
				);
			}

			console.log(response);

			if (response !== null) {
				const resp = response as ILocationRedirectResponse;
				if (resp.ShowLeavingPopup) {
					const reqDomain = this.getReqDomain();

					if (!reqDomain) {
						return;
					}

					$.ajax({
						data: {
							OverlayKey: "default",
							UrlRedirectValue: resp.RedirectUrl
						},
						type: "GET",
						url: `/${reqDomain}/PageContent/ConfirmationOverlay`
					}).done(function (partialViewResult) {
						$("#defaultsiteoverlay").html(partialViewResult);

						$.magnificPopup.open({
							items: {
								src: "#defaultsiteoverlay",
								type: "inline"
							}
						});
					});
				} else {
					if (resp.RedirectUrl) {
						window.location.href = resp.RedirectUrl;
					}
				}
			}
		}
	}

	getReqDomain(): string | null {
		const domainArray = window.location.origin.split(".");
		const domain = domainArray[domainArray.length - 1];
		let domainVal = null;
		switch (domain) {
			case "com":
				domainVal = "CFACOM";
				break;
			case "ca":
				domainVal = "CFACanada";
				break;
			default:
				console.warn(`No equivalent domain value found for: ${domain}`);
				domainVal = null;
		}

		return domainVal;
	}
}

ComponentFactory.registerComponent("YextLocationIframe", YextLocationIframe);
