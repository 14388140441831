import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { Validation } from "../../util/Validation";
import { DataLayerService } from "../services/DataLayerService";

export class StcDonationForm extends Component {
	focused: boolean;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		const form = this.form;
		if (!form) {
			throw new Error("Could not locate form element");
		}

		this.applyValidationRules(form);
		form.addEventListener("submit", e => this.handleSubmission(e));

		this.focused = false;

		// CFAC-34725 Fire whenever a user starts filling out a form.
		// This event is generally fired after user input in the first form field
		this._element
			.querySelector("#FirstName")
			?.addEventListener("focus", e => this.handleUserInput(e));

		// CFAC-34725 Fire whenever a user is presented with a form on a page.
		DataLayerService.pushDataLayerEvent_Forms("form_view", {
			identifier: this.layerIdentifier,
			name: this.layerName,
			type: "StcDonationForm"
		});
	}

	// CFAC-34725 Fire whenever a user starts filling out a form.
	// This event is generally fired after user input in the first form field
	private async handleUserInput(e: Event): Promise<void> {
		if (!this.focused) {
			DataLayerService.pushDataLayerEvent_Forms("form_start", {
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "StcDonationForm"
			});

			this.focused = true;
		}
	}

	private get layerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	private get layerName(): string {
		return this._element.dataset.layerName || "";
	}

	private get form(): HTMLFormElement | null {
		return this._element.querySelector<HTMLFormElement>("form");
	}

	private handleSubmission(e: Event): void {
		if (!this.validate()) {
			e.preventDefault();

			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "StcDonationFormSubmission",
				error_message: "Form Has Errors",
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "StcDonationForm"
			});

			return;
		}

		// CFAC-34725 Fire whenever a user successfully completes a form
		DataLayerService.pushDataLayerEvent_Forms("form_complete", {
			identifier: this.layerIdentifier,
			name: this.layerName,
			type: "StcDonationForm"
		});
	}

	private validate(): boolean {
		const form = this.form;
		if (form) {
			return $(form).valid();
		}

		return false;
	}

	private applyValidationRules(form: HTMLFormElement): void {
		const messages = this._element.dataset;

		$(form).validate({
			highlight: Validation.cfaHighlight.bind(null),
			messages: {
				Email: {
					email: messages.invalidEmail ?? "Email address is invalid",
					required: messages.requiredEmail ?? "Email field is required",
					validateEmail: messages.invalidEmail ?? "Email address is invalid"
				},
				FirstName: {
					required: messages.requiredFirstName ?? "First Name field is required"
				},
				LastName: {
					required: messages.requiredLastName ?? "Last Name field is required"
				},
				Message: {
					required: messages.requiredMessage ?? "Message field is required"
				}
			},
			rules: {
				Email: {
					email: true,
					required: true,
					validateEmail: true
				},
				FirstName: {
					required: true
				},
				LastName: {
					required: true
				},
				Message: {
					required: true
				}
			},
			unhighlight: Validation.cfaUnhighlight.bind(null)
		});
	}
}

ComponentFactory.registerComponent("StcDonationForm", StcDonationForm);
