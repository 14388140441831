import { DeliveryServiceAvailability } from "./Models/DeliveryServiceAvailability";
// import { deliveryResponseMock } from "./Mocks/deliveryResponse";
// tslint:disable-next-line: no-unnecessary-class
export class DeliveryService {
	static async checkServiceAvailability(
		token: string,
		endpoint: string,
		payload: {}
		// tslint:disable-next-line: no-any
	): Promise<any> {
		const promise = $.ajax({
			contentType: "application/json",
			data: JSON.stringify(payload),
			headers: {
				Authorization: `JWTBearer ${token}`
			},
			type: "POST",
			url: endpoint
		});
		const result = await Promise.resolve(promise);
		// const result = deliveryResponseMock;
		console.log({ result });

		return new DeliveryServiceAvailability(result);
	}
}
