// import { saveAddressResponseMock } from "./mocks/saveAddressResponseMock";
export interface IValidateResponse {
	streetNumber: string;
	route: string;
	locality: string;
	administrativeAreaLevelOne: string;
	country: string;
	postalCode: string;
}

interface IAddressPayload {
	address: {
		streetNumber: number;
		route: string;
		locality: string;
		administrativeAreaLevelOne: string;
		country: string;
		postalCode: number;
		subpremise: string;
	};
	deliveryInstructions: string;
	primaryAddress: boolean;
	type: string;
}

export async function saveAddressToUserProfile({
	address,
	endpoint,
	userProfileToken
}: {
	address: IValidateResponse;
	endpoint: string;
	userProfileToken: { Authenticated: boolean; Bearer: string; CFAID: string };
}): Promise<void> {
	const payload = transformPayload(address);
	const { Bearer, CFAID } = userProfileToken;
	try {
		const promise = $.ajax({
			contentType: "application/json",
			data: JSON.stringify(payload),
			dataType: "json",
			headers: {
				Authorization: `JWTBearer ${Bearer}`
			},
			type: "POST",
			url: `${endpoint}?userId=${CFAID}`
		});
		const result = await Promise.resolve(promise);
		// const result = saveAddressResponseMock;

		return result;
	} catch (err) {
		console.warn(err);
	}
}

function transformPayload(address: IValidateResponse): IAddressPayload {
	const _streeNumber = "streetNumber";
	const _postalCode = "postalCode";

	return {
		address: {
			...address,
			postalCode: parseInt(address[_postalCode], 10),
			streetNumber: parseInt(address[_streeNumber], 10)
		},
		deliveryInstructions: "",
		primaryAddress: false,
		type: ""
	};
}
