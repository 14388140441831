import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { Cookie } from "../../util/Cookie";

class InformBanner extends Component {
	constructor(element: HTMLElement, app: ICfa) {
		super(element, app);

		this._app.services.layoutService.adjustFrameTopBy(
			this._element.offsetHeight
		);
		this.addCloseClickEvent();
	}

	private addCloseClickEvent(): void {
		const closeIcon = this._element.querySelector(".icon-close");
		if (!(closeIcon instanceof HTMLElement)) {
			throw new Error("expected closeIcon to be an HTMLElement");
		}
		closeIcon.addEventListener("click", event => {
			event.preventDefault();
			$(this._element).slideUp("fast");
			this._app.services.layoutService.deRegisterStickyNode(this._element);
			this._app.services.layoutService.adjustFrameTopBy(0);
			this.setCookie(closeIcon);
		});
	}

	private setCookie(closeIcon: HTMLElement): void {
		const cookieId: string = closeIcon.dataset.href as string;
		const cookieName: string = closeIcon.dataset.cookie as string;
		const expireStamp: number = 86400000;
		Cookie.setBooleanCookie(cookieId, cookieName, expireStamp);
	}
}

ComponentFactory.registerComponent("InformBanner", InformBanner);
