import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";
export class FeatureCategory extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.applyDataLayerEvents();
		this.applyDataLayerCtaEvent();
		this.applyDataLayerContentEvent();
	}

	applyDataLayerEvents(): void {
		const selector = "a[data-item-tag]";
		const elements = this._element.querySelectorAll(selector);
		const evt = "Order_CTA_Click";
		if (!elements) {
			console.warn("No elements found for selector", selector);
			return;
		}

		elements.forEach(element => {
			element.addEventListener("click", e => {
				const evtModel = {
					module_name: element.dataset.itemTag,
					link_category: "Ordering",
					link_text: element.innerHTML || "Order Now"
				};
				DataLayerService.pushDataLayerEvent(evt, evtModel);
			});
		});
	}

	applyDataLayerCtaEvent(): void {
		const selector = ".cta-click";
		const elements = this._element.querySelectorAll(selector);

		if (!elements) {
			console.warn("No elements found for selector", selector);
			return;
		}

		elements.forEach(element => {
			element.addEventListener("click", e =>
				DataLayerService.pushDataLayerEvent_Menu_CtaClick(
					element,
					"menu category callout"
				)
			);
		});
	}

	applyDataLayerContentEvent(): void {
		const selector = ".content-click";
		const elements = this._element.querySelectorAll(selector);

		if (!elements) {
			console.warn("No elements found for selector", selector);
			return;
		}
		elements.forEach(element => {
			element.addEventListener("click", e =>
				DataLayerService.pushDataLayerEvent_Menu_ContentClick(element)
			);
		});
	}
}

ComponentFactory.registerComponent("FeatureCategory", FeatureCategory);
