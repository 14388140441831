function setVideoId(lightboxElems: readonly Element[]): void {
	lightboxElems.forEach((elem, i) => {
		elem.id = `lightbox${i}`;
		elem.children[0].id = `myPlayerID${i}`;
	});
}

function initializeBcModal(): void {
	$(".bc-lb-modal").magnificPopup({
		closeOnBgClick: true,
		modal: false,
		preloader: false,
		type: "inline"
	});
}

function addCloseClickEvent(
	videoElId: string,
	lightboxElems: readonly Element[],
	lightboxId: string
): void {
	const bcPlayer = videojs.getPlayer(videoElId.toString());

	bcPlayer?.ready(() => {
		if (bcPlayer.hasStarted()) {
			const lightbox = lightboxElems.find(
				b => b.id === lightboxId.split("#")[1]
			);

			lightbox
				?.querySelector(".mfp-close")
				?.addEventListener("click", () => bcPlayer.pause());

			// narrow click event possible?
			document.addEventListener("click", e => {
				if (
					!lightbox?.classList.contains("mfp-hide") ||
					(e.target as HTMLElement).matches("svg")
				) {
					return;
				}

				bcPlayer.pause();
			});
		}
	});
}

function setImgThumbnail(
	videoPlayers: readonly Element[],
	lightboxElems: readonly Element[]
): void {
	const grid = document.getElementById("grid");

	videoPlayers.map((video, i) => {
		const bcPlayer = videojs.getPlayer(video.id.toString());
		bcPlayer?.ready(() => {
			bcPlayer.on("loadstart", () => {
				// create thumbnail image based on video img
				const aTag = document.createElement("a");
				const lightboxId = `#lightbox${i}`;
				const headline = video.getAttribute("data-headline") || "";
				aTag.classList.add("bc-lb-modal");
				aTag.setAttribute("href", lightboxId);

				// Set img as background img
				aTag.style.backgroundImage = `url('${bcPlayer.mediainfo.posterSources[0].src}')`;

				aTag.innerHTML = `
						<div
							class="g-video__play-btn g-video__play-btn-absol-center-align"
							tabindex="0"
							role="button">
							<svg viewBox="0 0 18 24" xmlns="http://www.w3.org/2000/svg">
								<path
									d="m1.99947369 1.72506301c.12879887-.0717763.26687127-.10739461.40391327-.10739461.16589294 0 .3307555.05180846.47707102.15542537l13.19518682 9.29583983c.2380204.1678378.3760927.4360545.3787053.7366515.0025394.3000573-.1308962.571512-.3658254.7436672l-13.19518683 9.6687527c-.26738646.1948214-.59814197.2164083-.88665144.0604432-.28902467-.157584-.46109996-.4554826-.46109996-.7965549v-18.96459268c0-.33729462.16949931-.63357422.45388722-.79223761m14.93860841 8.00008882-13.19467168-9.29530018c-.73827513-.52132257-1.68468925-.57151201-2.47036237-.1338385-.78567312.4376735-1.27304805 1.2887353-1.27304805 2.22128747v18.96459268c0 .9417265.49510287 1.7971057 1.29262548 2.2320809.35084813.1915833.7310624.2860258 1.11076148.2860258.48273817 0 .96341556-.1538063 1.37608715-.4554827l13.19518689-9.6682131c.6491463-.4759901 1.0324517-1.256355 1.025339-2.0863696-.0078279-.8294749-.4045285-1.601205-1.0619179-2.06478277"
									fill-rule="evenodd" />
							</svg>
						</div>
						<p class="headline">${headline}</p>`;

				grid?.appendChild(aTag);

				aTag.addEventListener("click", () => {
					bcPlayer.play();
					addCloseClickEvent(video.id, lightboxElems, lightboxId);
				});

				initializeBcModal();
			});
		});
	});
}

if (document.querySelector(".careers-franchise")) {
	const lightboxElems = Array.from(document.querySelectorAll(".lightbox"));
	const videoPlayers = Array.from(document.querySelectorAll("video-js"));
	setVideoId(lightboxElems);
	setImgThumbnail(videoPlayers, lightboxElems);
	// add lazy loading and animation
}
