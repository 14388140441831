// For local only. Example see delivery/SubscriptionAddressVerify
import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { LoadingSpinner } from "./LoadingSpinner";

export class LoadingSpinnerComponent extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.initSpinnerBtns();
	}

	initSpinnerBtns(): void {
		const spinnerID = "spinner-local";
		const spinnerBtns = this._element.querySelectorAll("button");

		spinnerBtns.forEach(btn => {
			btn.addEventListener("click", e => {
				e.preventDefault();
				const action = btn.dataset.action;

				if (!action) {
					throw new Error("Action data attribute not found on element.");
				}

				switch (action) {
					case "start":
						LoadingSpinner.start(spinnerID);
						break;
					case "stop":
						LoadingSpinner.stop(spinnerID);
						break;
				}
			});
		});
	}
}

ComponentFactory.registerComponent(
	"LoadingSpinnerComponent",
	LoadingSpinnerComponent
);
