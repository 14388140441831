import React from "react";

const App = () => {
	return (
		<div>
			<h1 className="mb-8">Hello World, I love you!</h1>
		</div>
	);
};

export default App;
