import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class LongFormVideo extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		const { modalView } = this._element.dataset;
		if (modalView) {
			$(`#${this.buttonId}`).magnificPopup({
				items: {
					src: `${this.iframeLink}&autoplay` || "",
					type: "iframe"
				},
				mainClass: "video-popout"
			});
		}
	}

	get iframeLink(): string {
		return this._element.dataset.iframe || "";
	}

	get buttonId(): string {
		return this._element.dataset.buttonId || "";
	}
}

ComponentFactory.registerComponent("LongFormVideo", LongFormVideo);
