import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class GOpeningZipcodeCheck extends Component {
	private readonly classToToggleVisibility: string;
	private readonly failMsg: HTMLElement | null;
	private readonly successMsg: HTMLElement | null;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.classToToggleVisibility = "display";
		this.failMsg = this._element.querySelector(".fail-msg");
		this.successMsg = this._element.querySelector(".success-msg");

		this._element.addEventListener("submit", e => {
			e.preventDefault();
			this.resetMsgVisibility();
			this.toggleMsg(this.userInput);
		});
	}

	resetMsgVisibility(): void {
		this.successMsg?.classList.remove(this.classToToggleVisibility);
		this.failMsg?.classList.remove(this.classToToggleVisibility);
	}

	toggleMsg(userInput: string): void {
		const msgToDisplay = this.eligibleZipcodes.includes(userInput)
			? this.successMsg
			: this.failMsg;
		msgToDisplay?.classList.add(this.classToToggleVisibility);
	}

	get eligibleZipcodes(): string[] {
		const zipcodeList = this._element.querySelector(
			"#zipcode-list"
		) as HTMLInputElement;

		if (!zipcodeList.value) {
			const msg = "Sorry, but no zipcode list was provided";
			throw new Error(msg);
		}

		return zipcodeList.value.split(",");
	}

	get userInput(): string {
		const zipcodeField = this._element.querySelector(
			"input"
		) as HTMLInputElement;

		return zipcodeField.value;
	}
}

ComponentFactory.registerComponent(
	"gOpeningZipcodeCheck",
	GOpeningZipcodeCheck
);
