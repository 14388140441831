import { API } from "../../../util/API";
// tslint:disable-next-line: no-unnecessary-class
export class MessageGearsService {
	static async getJWTToken(
		endpoint: string,
		payload: { username: number; password: string }
	): Promise<{} | null> {
		const [response, error] = await API.postReq(endpoint, payload);

		if (error) {
			console.warn(`Error in getting MessageGears token. Error: ${error}`);

			return null;
		}

		return response;
	}

	static async submitEmail(
		authorization: string,
		endpoint: string,
		email: string,
		source: string
	): Promise<{} | null> {
		const payload = {
			recipient: {
				data: {
					EmailAddress: email,
					source
				},
				format: "JSON"
			}
		};
		const [response, error] = await API.postReq(
			endpoint,
			payload,
			authorization
		);

		if (error) {
			console.warn(
				`Error in submitting email to MessageGears. Error: ${error}`
			);

			return null;
		}

		return response;
	}
}
