function scrollTo(target: HTMLElement, additionalOffset: number = 0): void {
	const targetTop = target.offsetTop;
	const scrollPos = targetTop - additionalOffset;

	window.scrollTo({
		behavior: "smooth",
		top: scrollPos
	});
}

export default scrollTo;
