import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { preventLayoutShift } from "../../../util/PreventLayoutShift";
export class ClassicContainer extends Component {
	childCount: number;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.childCount = this.setChildCount();
	}

	setChildCount(): number {
		const count = this._element.childElementCount;

		//this._element.setAttribute("data-child-count", count.toString());
		this._element.setAttribute("data-has-multiple", (count > 1).toString());

		return count;
	}
}

ComponentFactory.registerComponent("classicContainer", ClassicContainer);
