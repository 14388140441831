﻿(function ($, document) {
	$(function () {
		var isPageEditor = function () {
			return !!(
				typeof Sitecore !== 'undefined' &&
				Sitecore.PageModes &&
				Sitecore.PageModes.PageEditor);
		};

		if (!isPageEditor()) {
			$("div.rtr-rendering").hide();
			$("div.rtr-rendering .rtr-item-container").empty();
			var renderingPhContents = $(".rt-placeholder").children();
			var contentRenderings = $('div.rtr-rendering');
			if (contentRenderings.length) {
				if (renderingPhContents.length) {
					contentRenderings.each(function () {
						var currentRendering = $(this).find('.rtr-item-container');
						var renderingId = currentRendering.attr("data-rtr-id");
						if (renderingId.length) {
							$(this).replaceWith($(".rt-" + renderingId));
						}
					});
				}
			}
		}
	});
}(window.jQuery, document));
