import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

// CFAC-38373 scroll_milestone
export class ScrollMilestone extends Component {
	_scroll90: boolean;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this._scroll90 = true;
		window.addEventListener("scroll", e => this.scrollHandler());
	}

	private async scrollHandler(): Promise<void> {
		const winHeight = $(window).height();
		const docHeight = $(document).height();
		const scrollTop = $(window).scrollTop();
		const trackLength = docHeight - winHeight;
		const scrolled = Math.floor((scrollTop / trackLength) * 100);

		if (scrolled >= 90 && this._scroll90) {
			DataLayerService.pushDataLayerEvent_ScrollMilestone("90");
			this._scroll90 = false;
		}
	}
}

ComponentFactory.registerComponent("scrollMilestone", ScrollMilestone);
