export class ArticleResultsService {
	public static async getListViewResults(
		endpoint: string,
		queryParams: {},
		resultTarget: HTMLElement
	): Promise<T> {
		try {
			const searchParamsApi = new URLSearchParams();
			Object.entries(queryParams).forEach(([key, val], index) => {
				searchParamsApi.set(key, val);
			});

			const url = `${endpoint}?${searchParamsApi}`;

			const response = await fetch(url);

			if (!response.ok) {
				throw new Error(`Error: ${response.status} ${response.statusText}`);
			}

			const result = await response.json();

			resultTarget.innerHTML = result.Result;

			return [result, null];
		} catch (err) {
			const typed = err as JQuery.jqXHR;
			console.error(`Could not load ${endpoint} : ${typed.responseText}`);
			$(resultTarget).html("<p>Error loading results.</p>");

			return [null, err];
		}
	}
}
