import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import getChildCount from "../../util/getChildCount";

export class ImgCarousel extends Component {
	sliderElem: HTMLElement;
	slickSlider: JQuerySlick | null = null;
	count: number;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.sliderElem = this._element.querySelector(".slider") as HTMLElement;
		this.count = this.setChildCount();

		if (this.count > 1) {
			this.handleCarousel();
			this.handleCount();
		}
	}

	setChildCount(): number {
		const count = getChildCount(this.sliderElem);

		this._element.dataset.childCount = count;
		this._element.dataset.hasMultiple = count > 1 ? "true" : "false";

		return count;
	}

	handleCarousel(): void {
		const carouselOptions: JQuerySlickOptions = {
			accessibility: false,
			arrows: false,
			centerMode: false,
			cssEase: "linear",
			dots: false,
			fade: false,
			infinite: true,
			mobileFirst: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 500,
			useTransform: false
		};
		this.slickSlider = $(this.sliderElem).slick(carouselOptions);
		const prevArrow = $(this._element).find("[data-prev-arrow]");
		const nextArrow = $(this._element).find("[data-next-arrow]");

		nextArrow.on("click", () => {
			const slick = this.slickSlider?.slick("getSlick");
			this.slickSlider?.slick("slickNext");
		});

		prevArrow.on("click", () => {
			const slick = this.slickSlider?.slick("getSlick");
			this.slickSlider?.slick("slickPrev");
		});
	}

	handleCount(): void {
		const countElem = this._element.querySelector("[data-carousel-count]");
		if (!countElem) {
			console.warn(
				"No data-carousel-count elem found. Check HTML for element."
			);

			return;
		}
		countElem.innerHTML = `1 of ${this.count}`;
		this.slickSlider?.on("afterChange", (evt, slick) => {
			countElem.innerHTML = `${parseInt(slick.currentSlide, 10) + 1} of ${
				this.count
			}`;
		});
	}
}

ComponentFactory.registerComponent("imgCarousel", ImgCarousel);
