import { ICfa } from "../../models/ICfa";

export abstract class Component {
	protected constructor(
		protected readonly _element: HTMLElement,
		protected readonly _app: ICfa
	) {}

	public async init?(): Promise<void> {
		return;
	}
}
