import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class FeaturedArticles extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		const isMobileView = window.innerWidth < this._app.layout.desktopWidth;

		const articles = this.getArticles();
		articles?.forEach((el, index) => {
			if (isMobileView) {
				if (index > 2) {
					$(el).hide();
				}
			}
		});
	}

	getArticles(): Array<Element> | null {
		const articles: NodeListOf<HTMLElement> =
			this._element.querySelectorAll(".article");

		return [...articles];
	}
}

ComponentFactory.registerComponent("featuredArticles", FeaturedArticles);
