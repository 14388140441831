import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

export class ImageTextCarousel extends Component {
	public readonly sliderEl: JQuery;
	public slider: JQuery | null = null;
	public sliderContainer: JQuery;
	private sliderBtnContainer: HTMLElement;
	private readonly sliderLength: number;
	private prevSlide: number | undefined;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.sliderEl = $(_element);
		this.sliderContainer = this.sliderEl.find("[data-element='slider']");
		this.sliderBtnContainer = this._element.querySelector(
			".slider-btn-container"
		) as HTMLElement;
		this.sliderLength = this.sliderContainer.children().length;
		this.initSlider();
		this.toggleButtonState();
		this.handleBtnConfig();
		const slick = this.sliderContainer.slick("getSlick");
		this.prevSlide = slick.defaults.initialSlide;
	}

	initSlider(): void {
		this.sliderContainer.slick();

		this.sliderContainer.on(
			"beforeChange",
			(
				_: JQuery.TriggeredEvent,
				slick: JQuerySlick,
				currentSlide: number,
				nextSlide: number
			) => {
				const currentSlickOptions = slick.$slides[currentSlide];
				if (currentSlickOptions) {
					const videoIsPlayingElem = $(currentSlickOptions).find(".isPlaying");
					if (videoIsPlayingElem.length > 0) {
						const videoJS = $(currentSlickOptions).find("video-js");

						if (!videoJS) {
							return;
						}

						const evt = new CustomEvent("slideChange", {
							detail: {
								playerId: videoJS.attr("id")
							}
						});
						window.dispatchEvent(evt);
					}
				}
			}
		);

		$(this.sliderEl)
			.find("button[data-next-arrow]")
			.on("click", () => {
				const slick = this.sliderContainer.slick("getSlick");
				this.sliderContainer.slick("slickNext");
				DataLayerService.pushDataLayerEvent_Carousel(
					this.sliderEl,
					"next_carousel_slide",
					slick.currentSlide,
					this.prevSlide,
					"ImageTextCarousel"
				);
				this.prevSlide = slick.currentSlide;
			});

		$(this.sliderEl)
			.find("button[data-prev-arrow]")
			.on("click", () => {
				const slick = this.sliderContainer.slick("getSlick");
				this.sliderContainer.slick("slickPrev");
				DataLayerService.pushDataLayerEvent_Carousel(
					this.sliderEl,
					"previous_carousel_slide",
					slick.currentSlide,
					this.prevSlide,
					"ImageTextCarousel"
				);
				this.prevSlide = slick.currentSlide;
			});
	}

	toggleButtonState(): void {
		const prevArrow = $(this.sliderEl).find("[data-prev-arrow]");
		const nextArrow = $(this.sliderEl).find("[data-next-arrow]");
		const firstSlide = $(this.sliderEl).find("[data-slick-index=0]");
		const lastSlide = $(this.sliderEl).find(".slick-slide:last");
		$(this.sliderEl)
			.find("[data-prev-arrow]")
			.on("click", () => {
				if (firstSlide.hasClass("slick-current")) {
					prevArrow.prop("disabled", true);
				}
				if (!lastSlide.hasClass("slick-current")) {
					nextArrow.prop("disabled", false);
				}
			});

		$(this.sliderEl)
			.find("[data-next-arrow]")
			.on("click", () => {
				prevArrow.prop("disabled", false);
			});
	}

	handleBtnConfig(): void {
		const configs = Object.keys(this.sliderBtnContainer.dataset);

		if (configs.length <= 0) {
			return;
		}

		configs.forEach(c => this.mapConfig(c));
	}

	mapConfig(config: string): void {
		switch (config) {
			case "showCount":
				this.handleCount();
				break;
			default:
				console.warn(`No corresponding functionality found for: ${config}.`);
		}
	}

	handleCount(): void {
		const countElem = this.sliderBtnContainer.querySelector(
			"[data-carousel-count]"
		);
		if (!countElem) {
			console.warn(
				"No data-carousel-count elem found. Check HTML for element."
			);

			return;
		}
		countElem.innerHTML = `1 of ${this.sliderLength}`;
		$("[data-element='slider']").on("afterChange", (evt, slick) => {
			countElem.innerHTML = `${parseInt(slick.currentSlide, 10) + 1} of ${
				this.sliderLength
			}`;
		});
	}
}

ComponentFactory.registerComponent("imageTextCarousel", ImageTextCarousel);
