import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class CareersTiles extends Component {
	clickIndex: number = 0;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.addChildCount();
		this.showHideCards();
	}

	addChildCount(): void {
		this._element.setAttribute("data-child-count", this.childCount.toString());
	}

	showHideCards(): void {
		if (this.childCount > 6) {
			this._element.parentElement?.classList.add("show-view-more");
			this.hideCards();
			this.handleShowMoreClick();
		}
	}

	hideCards(): void {
		const liElems = this._element.querySelectorAll(".open-position-tile");
		for (let i = 5; i < liElems.length; i++) {
			liElems[i].classList.add("hidden");
		}
	}

	handleShowMoreClick(): void {
		const showMoreBtn =
			this._element.parentElement?.querySelector(".show-more-btn");
		if (!showMoreBtn) {
			console.warn("show more button not found");
			return;
		}
		showMoreBtn.addEventListener("click", () => {
			this.clickIndex++;
			const liElems = this._element.querySelectorAll(
				".open-position-tile.hidden"
			);
			const cardsToDisplayOnClick = this.clickIndex === 1 ? 6 : 3;
			for (let i = 0; i < cardsToDisplayOnClick; i++) {
				liElems[i].classList.remove("hidden");
			}

			// conduct another lookup
			if (
				this._element.querySelectorAll(".open-position-tile.hidden").length ===
				0
			) {
				this._element.parentElement?.classList.remove("show-view-more");
			}
		});
	}

	get childCount(): number {
		return this._element.children.length;
	}
}

ComponentFactory.registerComponent("careersTiles", CareersTiles);
