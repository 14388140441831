import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { brightcoveVideo } from "../../legacy/BrightcoveVideo";
import { IMixedSlide } from "../../legacy/models/IMixedSlide";
import { DataLayerService } from "../../services/DataLayerService";

function extractTemplate(context: HTMLElement, selector: string): string {
	const template = context.querySelector<HTMLTemplateElement>(selector);
	const html = template?.innerHTML ?? "";
	template?.parentElement?.removeChild(template);

	return html;
}

export class MixedCarousel extends Component {
	public readonly carouselEl: JQuery;
	public carousel: JQuery | null = null;
	public readonly showcaseEl: JQuery;
	public slides: IMixedSlide[];
	private prevSlide: number | undefined;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.carouselEl = $(_element);
		this.handleSlideChange = this.handleSlideChange.bind(this);
		this.showcaseEl = $(".mixed-carousel__showcase", _element);
		this.slides = [];
		this.initCarousel();
		this.initVideos();
		const slick = this.carouselEl.slick("getSlick");
		this.prevSlide = slick.defaults.initialSlide;
	}

	initCarousel(): void {
		const carouselOptions: JQuerySlickOptions = {
			accessibility: false,
			cssEase: "linear",
			dots: false,
			fade: false,
			infinite: true,
			mobileFirst: true,
			nextArrow: extractTemplate(this._element, "template[data-next-arrow]"),
			prevArrow: extractTemplate(this._element, "template[data-prev-arrow]"),
			slidesToShow: 1,
			speed: 500,
			useTransform: false
		};

		if ($(".panel-btn").length > 0) {
			$(".panel-btn").on("click", () => {
				this.carousel = this.carouselEl.slick(carouselOptions);
			});
		} else {
			this.carousel = this.carouselEl.slick(carouselOptions);
		}

		$(this.carouselEl)
			.find("div[data-next-arrow]")
			.on("click", () => {
				const slick = this.carouselEl.slick("getSlick");
				this.carouselEl.slick("slickNext");
				DataLayerService.pushDataLayerEvent_Carousel(
					this.carouselEl,
					"next_carousel_slide",
					slick.currentSlide,
					this.prevSlide,
					"MixedCarousel"
				);
				this.prevSlide = slick.currentSlide;
			});

		$(this.carouselEl)
			.find("div[data-prev-arrow]")
			.on("click", () => {
				const slick = this.carouselEl.slick("getSlick");
				this.carouselEl.slick("slickPrev");
				DataLayerService.pushDataLayerEvent_Carousel(
					this.carouselEl,
					"previous_carousel_slide",
					slick.currentSlide,
					this.prevSlide,
					"MixedCarousel"
				);
				this.prevSlide = slick.currentSlide;
			});

		// Fixes problem of carousel items not displaying on tabChng original render
		document.addEventListener("accordTabChange", () => {
			const parent = this.carousel?.parent().closest(".g-tabs__content");
			const isActiveCarousel = parent?.hasClass("g-tabs__content--active");
			if (isActiveCarousel) {
				if (this.carousel) {
					this.carousel?.slick("slickGoTo", 0);
					DataLayerService.pushDataLayerEvent_Carousel(
						this.carouselEl,
						"specific_carousel_slide",
						0,
						this.prevSlide,
						"MixedCarousel"
					);
					this.prevSlide = 0;
				}
			}
		});

		this.carouselEl.on(
			"beforeChange",
			(
				_: JQuery.TriggeredEvent,
				slick: JQuerySlick,
				currentSlide: number,
				nextSlide: number
			) => this.handleSlideChange(slick, currentSlide, nextSlide)
		);

		this.carouselEl.on(
			"afterChange",
			(_: JQuery.TriggeredEvent, slick: JQuerySlick, currentSlide: number) =>
				this.handleSlideTabindex(slick, currentSlide)
		);
	}

	initVideos(): void {
		const slideEls = this.carouselEl.find(".mixed-carousel__slide");

		// build an array of slide + video data
		this.slides = [];
		slideEls.each((index, slideEl) => {
			const videoEls = $(slideEl).find(".video-js");
			const videoEl = videoEls.length ? $(videoEls[0]) : null;

			if (videoEl) {
				const videoId = videoEl ? (videoEl.data("videoId") as string) : null;
				const videoElId = videoEl ? videoEl.attr("id") : null;
				if (videoElId) {
					this.slides.push({
						index,
						videoEl,
						videoElId,
						videoId,
						wrapEl: slideEl
					});
				}
			} else {
				this.slides.push({
					index,
					videoEl: null,
					videoElId: null,
					videoId: null,
					wrapEl: slideEl
				});
			}
		});

		// init BrightcoveVideo with the slides
		if (this.slides.length) {
			this.slides
				.filter(val => !!val.videoId)
				.map(slide => brightcoveVideo.queuePlayer(slide));
		}
	}

	handleSlideChange(
		slick: JQuerySlick,
		currentSlide: number,
		nextSlide: number
	): void {
		if (currentSlide !== nextSlide) {
			const slide = this.slides[currentSlide];
			if (slide?.videoElId) {
				const { videoElId } = slide;
				brightcoveVideo.pausePlayer(videoElId);
			}
		}

		// adjust tabindex on current slide's play button to prevent
		// incorrect tabbing
		$(slick.$slides[currentSlide])
			.find(".g-video__play-btn")
			.attr("tabindex", null);
	}

	handleSlideTabindex(slick: JQuerySlick, currentSlide: number): void {
		$(slick.$slides[currentSlide])
			.find(".g-video__play-btn")
			.attr("tabindex", 0);
	}
}

ComponentFactory.registerComponent("mixedCarousel", MixedCarousel);
