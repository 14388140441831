// tslint:disable: no-unsafe-any
export function inputDebounce(
	fn: (e: Event) => void,
	delay: number
): () => void {
	let inDebounce: number | undefined;

	// tslint:disable-next-line: no-any
	return (...args: any) => {
		if (inDebounce) {
			clearTimeout(inDebounce);
		}
		inDebounce = setTimeout(() => {
			fn.apply(null, args);
		}, delay);
	};
}
