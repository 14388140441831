export interface ISubmissionCountResponse {
	Success: boolean;
	Count: number;
}

export class SubmissionCount {
	_data;
	constructor(data: ISubmissionCountResponse) {
		this._data = data;
	}

	hasReachedLimit(maxSubmissionsAllowed: string): boolean | null {
		const { Count } = this._data;

		if (!Count) {
			console.warn("No count value found on returned response.");

			return null;
		}

		return Count >= parseInt(maxSubmissionsAllowed, 10);
	}
}
