import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { DataLayerService } from "../services/DataLayerService";

export class MembershipTabContent extends Component {
	activeIndex: number;
	prevIndex: number;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.prevIndex = 0;
		this.activeIndex = 0;

		this.handleArrowEvents();
		this.handleNavClickEvent();
	}

	handleArrowEvents(): void {
		const selectors = ["left-arrow", "right-arrow"];
		selectors.forEach(selector => {
			const arrow = this._element.querySelector(`[data-element='${selector}']`);
			if (arrow) {
				arrow.addEventListener("click", () => {
					const { incrementBy } = arrow.dataset;
					this.prevIndex = this.activeIndex;
					this.activeIndex += parseInt(incrementBy, 10);
					this.toggleSelection();
				});
			}
		});
	}

	handleNavClickEvent(): void {
		const selector = "[data-element='navigation'] > li";
		const navLiElems = this._element.querySelectorAll(selector);

		if (!navLiElems) {
			console.warn(`No content wrapper found for attribute: ${selector}`);
			return;
		}

		navLiElems.forEach((li, index) =>
			li.addEventListener("click", (e: Event) => {
				this.prevIndex = this.activeIndex;
				this.activeIndex = index;
				this.toggleSelection();

				const span = li.querySelector<HTMLTemplateElement>("span");
				const evtModel = {
					element: "Tab",
					module_name: "MembershipTabContent",
					link_category: "Navigation",
					link_text: span?.innerHTML || ""
				};
				DataLayerService.pushDataLayerEvent("element_click", evtModel);
			})
		);
	}

	toggleSelection(): void {
		// get li by index value from querySelector
		const selector = "[data-element='content']";
		const navLiElems = this._element.querySelector(selector);
		const navParent = this._element.querySelector(
			"[data-element='navigation']"
		);
		if (!navLiElems || !navParent) {
			console.warn(`No content/nav wrapper found for attribute: ${selector}`);
			return;
		}
		// find by index of li element
		const liElementsContent = navLiElems.querySelectorAll(":scope > li");
		const liElementsNav = navParent.querySelectorAll(":scope > li");

		if (this.activeIndex > liElementsContent.length - 1) {
			this.activeIndex = 0;
		} else if (this.activeIndex < 0) {
			this.activeIndex = liElementsContent.length - 1;
		}

		const selectedLi = liElementsContent[this.activeIndex];
		const selectedNavLi = liElementsNav[this.activeIndex];
		const unselectedLi = liElementsContent[this.prevIndex];
		const unselectedNavLi = liElementsNav[this.prevIndex];

		unselectedLi.classList.remove("active");
		selectedLi.classList.add("active");

		unselectedNavLi.classList.remove("active");
		selectedNavLi.classList.add("active");
	}
}

ComponentFactory.registerComponent(
	"MembershipTabContent",
	MembershipTabContent
);
