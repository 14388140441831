import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class IngredientSteps extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.applyCopyEvt();
	}

	applyCopyEvt() {
		const btn = this._element.querySelector(".copy-btn");

		if (!btn) {
			console.warn("No copy button found");
			return;
		}

		btn.addEventListener("click", () => {
			console.log("button clicked");
			// copy text to clipboard from other element
			const copyText = this._element.querySelector("[data-target='copy-text']");
			console.log({ copyText });
			navigator.clipboard.writeText(copyText?.innerText || "");
			// $(this._element).append("<p class='copied-msg'>Test</p>");
			$(".copied-msg").fadeIn().delay(1000).fadeOut();
		});
	}
}

ComponentFactory.registerComponent("ingredientSteps", IngredientSteps);
