(function ($, window, document) {
	$(function () {
		if (!window.Cookies) {
			return;
		}

		var frame = $(".story-carousel-frame");
		var carousel = frame.find(".story-carousel");
		var overlay = frame.find(".story-carousel-overlay");

		var cookieName = "story-carousel-animation-played";
		var cookieValue = Cookies.get(cookieName);
		var hasCookie = typeof (cookieValue) !== "undefined";

		if (hasCookie) {
			if (console && console.log) console.log("Cookie story-carousel-animation-played: " + cookieValue);
		}

		if (overlay.length == 0 || hasCookie) {
			return;
		}

		// cookie valid only on the current page and expires in 1 day.
		Cookies.set(cookieName, 'true', { expires: 1, path: '' });

		var plankLongestAnimationDuration = 2000;

		var plank1AnimationDuration = plankLongestAnimationDuration;
		var plank1AnimationDelay = 0;

		var plank2AnimationDuration = 1700;
		var plank2AnimationDelay = 0;

		var plank3AnimationDuration = 1300;
		var plank3AnimationDelay = 0;

		var plank4AnimationDuration = 1500;
		var plank4AnimationDelay = 0;

		var largeLogoAnimationDuration = 1500;
		var largeLogoAnimationDelay = plankLongestAnimationDuration;

		var largeLogoSize = 400;
		var smallLogoSize = 150;

		var smallLogoLeftOffset = 25;
		var smallLogoTopOffset = 65;
		var logoFromLargeToSmallAnimationDuration = 1000;
		var logoFromLargeToSmallAnimationDelay = 0;

		var step1Duration = 3000;
		var step2Duration = plankLongestAnimationDuration + largeLogoAnimationDuration + 2000;

		var overlaySteps = overlay.find(".story-carousel-step");
		var overlayPlanks = overlay.find(".plank");

		var sameWidthSelector = frame
			.find(".story-carousel-step-logo-frame, .story-carousel-step-logo-slogan");

		var logoFrame = frame.find(".story-carousel-step-logo-frame");
		var logo = frame.find(".story-carousel-step-logo");
		var logoSlogan = frame.find(".story-carousel-step-logo-slogan");

		var step1CenteredText = frame.find(".story-carousel-step-1 .centered");
		var step1CenteredInner = frame.find(".story-carousel-step-1 .inner");

		var flowers = frame.find(".flower");

		var resizeOverlay = function () {
			var width = carousel.width();
			var height = carousel.height() + 57;

			overlaySteps.width(width);
			overlaySteps.height(height);
			overlayPlanks.width(width);

			if (!overlay.hasClass("step-3-active")) {
				sameWidthSelector.width(width);

				overlay.width(width);
				overlay.height(height);
				logoFrame.height(height);
			}

			step1RefreshOnResize();
		}

		var animatePlank = function (element, from, to, animationDuration, animationDelay) {
			if (element == null || typeof (element) === "undefined") {
				return;
			}

			var $this = $(element);
			$this.css("left", from + "px");

			setTimeout(function () {
				$this.animate({
					left: to + "px"
				}, animationDuration);
			}, animationDelay);
		}

		var showLargeLogoAndFlowers = function () {

			logo.width(largeLogoSize);
			logo.height(largeLogoSize);
			logo.css("left", "calc(50% - " + largeLogoSize + "px/2)");
			logo.css("top", "calc(40% - " + largeLogoSize + "px/2)");
			logoSlogan.css("top", "calc((40% - " + largeLogoSize + "px/2) + " + (largeLogoSize + 30) + "px)");

			logoFrame
				.add(flowers)
				.stop()
				.delay(largeLogoAnimationDelay)
				.animate({ opacity: 1 }, largeLogoAnimationDuration);
		}

		var animateLogoFromLargeToSmall = function () {
			logoSlogan.hide();
			overlaySteps.hide();

			logo.stop()
				.delay(logoFromLargeToSmallAnimationDelay)
				.animate({
					left: smallLogoLeftOffset + "px",
					top: smallLogoTopOffset + "px",
					width: smallLogoSize + "px",
					height: smallLogoSize + "px"
				}, {
					duration: logoFromLargeToSmallAnimationDuration,
					complete: function () {
						overlay.add(logoFrame)
							.css({
								width: smallLogoSize + "px",
								height: smallLogoSize + "px"
							});
						carousel[0].slick.slickPlay();
					}
				});
		}

		var step1RefreshOnResize = function () {
			step1CenteredText.css("width", overlay.width() + "px");
		}

		var step1 = function () {
			overlay.addClass("step-1-active");

			step1RefreshOnResize();
			step1CenteredText.css("top", "calc(40% - " + step1CenteredInner.height() + "px/2)");
		}

		var step2 = function () {
			overlay.removeClass("step-1-active");
			overlay.addClass("step-2-active");

			var width = overlay.width();

			animatePlank(overlayPlanks.get(0), width, 0, plank1AnimationDuration, plank1AnimationDelay);
			animatePlank(overlayPlanks.get(1), -width, 0, plank2AnimationDuration, plank2AnimationDelay);
			animatePlank(overlayPlanks.get(2), width, 0, plank3AnimationDuration, plank3AnimationDelay);
			animatePlank(overlayPlanks.get(3), -width, 0, plank4AnimationDuration, plank4AnimationDelay);

			showLargeLogoAndFlowers();
		}

		var step3 = function () {
			overlay.addClass("step-3-active");

			var width = overlay.width();
			animatePlank(overlayPlanks.get(0), 0, width, plank1AnimationDuration, plank1AnimationDelay);
			animatePlank(overlayPlanks.get(1), 0, -width, plank2AnimationDuration, plank2AnimationDelay);
			animatePlank(overlayPlanks.get(2), 0, width, plank3AnimationDuration, plank3AnimationDelay);
			animatePlank(overlayPlanks.get(3), 0, -width, plank4AnimationDuration, plank4AnimationDelay);
			flowers.stop().animate({ opacity: 0 }, plankLongestAnimationDuration);

			setTimeout(function () {
				animateLogoFromLargeToSmall();
			}, plankLongestAnimationDuration);
		}

		var step4 = function () {
			overlay.removeClass("step-3-active");
			overlay.addClass("step-4-active");
		}

		$(window)
			.on('resize',
				function () {
					setTimeout(function () {
						carousel.trigger('custom-resize');
					},
						500);
				});

		carousel.bind('custom-resize',
			function () {
				resizeOverlay();
			});

		carousel.trigger('custom-resize');

		step1();
		setTimeout(function () {
			step2();
		}, step1Duration);
		setTimeout(function () {
			step3();
			carousel[0].slick.slickGoTo(0, true);
		}, step1Duration + step2Duration);

		carousel.trigger('custom-resize');
	});

}(window.jQuery, window, document));
