export class ReceiptHighlighter {
	constructor(private readonly element: JQuery) {
		this.initHighlight();
	}

	initHighlight(): void {
		const inputs = this.element.find("input");
		const targets = $("span.highlight");

		// highlight fields
		inputs.on("focus", e => {
			const dataAttrib = $(e.target).attr("data-target");
			if (dataAttrib) {
				const targetEl = $(`#${dataAttrib}`);
				targets.removeClass("highlight--active");
				targetEl.addClass("highlight--active");
			}
		});

		// un-highlight fields, when all inputs are unfocused
		inputs.on("blur", () => {
			if (!inputs.is(":focus")) {
				targets.removeClass("highlight--active");
			}
		});
	}
}
