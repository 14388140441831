import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { AddressAutocompleteService } from "./AddressAutocompleteService";
import { SignatureDropService } from "./SignatureDropService";
import { IFormSubmitResponse } from "./Models/FormSubmitResponse";
import { getUserProfileToken } from "../services/tokens";
import {
	IValidateResponse,
	saveAddressToUserProfile
} from "../services/UserProfile";
export class SignatureDropClaimForm extends Component {
	isCustomFieldChecked = false;
	dxeBearerToken: string | undefined;
	form: HTMLFormElement;
	userProfileToken:
		| { CFAID: string; Authenticated: boolean; Bearer: string }
		| undefined;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		if (this._element.tagName !== "FORM") {
			throw new Error(`Non-form element passed in, element: ${this._element}`);
		}

		this.form = this._element as HTMLFormElement;
		// tslint:disable-next-line
		this.initialize();
	}

	private async initialize(): Promise<void> {
		await this.checkSubmissionCount();
		this.applyValidationRules(this.form);
		this.form.addEventListener("submit", e => this.handleSubmission(e));

		if (this.getCustomShippingInput()) {
			this.handleCustomShippingEffect();
		}
		this.storeBearerToken();
		await this.storeUserProfileToken();

		this.isCustomFieldChecked = this.getCustomShippingInput()?.checked || false;
	}

	private async checkSubmissionCount(): Promise<string | null> {
		const {
			submissionsCountEndpoint: endpoint,
			redirectSubmissionsSurpassed: redirect,
			dropId,
			submissionsLimit
		} = this._element.dataset;
		if (!endpoint || !redirect || !submissionsLimit || !dropId) {
			console.warn(
				"No redirect submission link/submissions value/or endpoint passed to component. Please check rendering."
			);

			return null;
		}

		const response = await SignatureDropService.getCount(
			endpoint + "/" + dropId
		);

		if (!response) {
			console.warn("Unable to get count value");

			return;
		}

		const hasReachedLimit = response?.hasReachedLimit(submissionsLimit);

		if (hasReachedLimit) {
			window.location.href = redirect;

			return null;
		}

		return null;
	}

	private storeBearerToken(): void {
		document.addEventListener("dxeBearerToken", e => {
			this.dxeBearerToken = e.detail.bearerToken;

			if (!this.dxeBearerToken) {
				throw new Error("Problem dispatching dxeBearer token value.");
			}
		});
	}

	private async storeUserProfileToken(): Promise<void> {
		const { userProfileTokenEndpoint: endpoint } = this.getEndpoints();
		this.userProfileToken = await getUserProfileToken(endpoint);
	}

	private applyValidationRules(form: HTMLFormElement): void {
		const messages = this._element.dataset;
		const errorClass = "hasError";
		$(form).validate({
			errorClass: "hasError",
			errorPlacement: (error, element) => {
				error.appendTo(element.next(".error-container"));
			},
			highlight: element => {
				$(element).parent().addClass(errorClass);
			},
			messages: {
				CustomAddress: {
					hasUserSelectedAddress: "User has not selected Address",
					required: messages.customAddressMsg ?? ""
				},
				SizeSelection: {
					required: messages.sizeSelectionMsg ?? ""
				}
			},
			rules: {
				CustomAddress: {
					hasUserSelectedAddress: "#custom:checked",
					required: "#custom:checked"
				},
				SizeSelection: {
					required: true
				}
			},
			unhighlight: element => {
				$(element).parent().removeClass(errorClass);
			}
		});
		this.addCustomValidationMethods();
	}

	private addCustomValidationMethods(): void {
		$.validator.addMethod("hasUserSelectedAddress", () => {
			const hasUserSelectedAddress = this.getHasUserSelectedAddress();

			return hasUserSelectedAddress;
		});
	}

	private async handleSubmission(e: Event): Promise<void> {
		e.preventDefault();
		this.toggleSubmitBtn(false);
		await this.checkSubmissionCount();
		const formHasErrors = !this.validate();
		if (formHasErrors) {
			this.toggleSubmitBtn(true);

			return;
		}

		const { validateEndpoint } = this.getEndpoints();

		if (!validateEndpoint) {
			throw new Error(`No validate endpoint found for: ${validateEndpoint}`);
		}

		if (this.isCustomFieldChecked) {
			const validateResponse = await AddressAutocompleteService.validateField(
				this.dxeBearerToken,
				validateEndpoint,
				this.getCustomAddressValue()
			);

			if (!validateResponse?.isValidAddress) {
				const errMsg = "Not a valid Address. Try again please.";
				this.toggleCustomAddressFieldError(errMsg);
				this.toggleSubmitBtn(true);

				return;
			}

			const saveAddressResponse = await this.saveAddressToUserProfile(
				validateResponse._data
			);

			await this.submitForm(saveAddressResponse?.id);
		} else {
			await this.submitForm();
		}
	}

	private toggleCustomAddressFieldError(errMsg: string): void {
		const validator = $(this._element).validate();
		validator.showErrors({
			CustomAddress: errMsg
		});
	}

	private async saveAddressToUserProfile(
		address: IValidateResponse
	): Promise<void> {
		if (this.userProfileToken?.Authenticated) {
			const { deliveryAddressEndpoint: endpoint } = this.getEndpoints();
			const { AddressUnit = "" } = this.getFormValues();
			const addressWithUnit = { ...address, subpremise: AddressUnit };
			const userProfileToken = this.userProfileToken;
			const result = await saveAddressToUserProfile({
				address: addressWithUnit,
				endpoint,
				userProfileToken
			});

			return result;
		}
	}

	private async submitForm(addressId?: string): Promise<void> {
		let payload = this.getFormValues();

		if (addressId) {
			payload = {
				...payload,
				AddressId: addressId
			};
		}

		if (!payload.AddressId) {
			const errMsg =
				"We experienced a problem. Please refresh the page and try again.";
			this.toggleCustomAddressFieldError(errMsg);
			this.toggleSubmitBtn(true);

			return;
		}
		const { submissionEndpoint } = this.getEndpoints();

		if (!submissionEndpoint) {
			throw new Error(
				`Submission endpoint value not found for ${submissionEndpoint}`
			);
		}
		const response: IFormSubmitResponse | undefined =
			await SignatureDropService.sendForm(submissionEndpoint, payload);
		if (!response) {
			this.toggleSubmitBtn(true);
			throw new Error(
				`Error in retrieving response: ${JSON.stringify(response)}`
			);
		}
		this.handleSubmitFormRedirect(response);
	}

	private getFormValues(): {
		productType: string;
		TShirtSize?: string | null;
		AddressId?: string | null;
		AddressUnit?: string | null;
	} {
		const values = $(this.form).serializeArray();

		return values.reduce(
			(accum, currentVal) => {
				switch (currentVal.name) {
					case "dropId":
						return { ...accum, dropId: currentVal.value };
					case "productType":
						return { ...accum, productType: currentVal.value };
					case "SizeSelection":
						return { ...accum, TShirtSize: currentVal.value };
					case "shipping-address":
						return { ...accum, AddressId: currentVal.value };
					case "CustomAddress":
						if (accum.AddressId === "custom") {
							return { ...accum, AddressId: "" };
						}

						return accum;
					case "CustomAddress-2":
						return { ...accum, AddressUnit: currentVal.value };
					default:
						return { ...accum };
				}
			},
			{ productType: "", AddressId: "" }
		);
	}

	private validate(): boolean {
		if (this._element) {
			return $(this._element).valid();
		}

		return false;
	}

	private handleCustomShippingEffect(): void {
		const customShippingDiv = ".custom-shipping";

		this._element?.addEventListener("change", () => {
			if (this.getCustomShippingInput()?.checked) {
				$(customShippingDiv).slideDown();
			} else {
				$(customShippingDiv).slideUp();
			}
			this.isCustomFieldChecked =
				this.getCustomShippingInput()?.checked || false;
		});
	}

	private getHasUserSelectedAddress(): boolean {
		const customAddressField = this.form.querySelector("#CustomAddress");

		if (!customAddressField) {
			throw new Error(
				"No customAddressField found with querySelector: '$CustomAddress'"
			);
		}
		const hasUserSelectedAddress = customAddressField?.getAttribute(
			"data-has-user-selected-address"
		);

		return hasUserSelectedAddress === "true";
	}

	private getCustomShippingInput(): HTMLInputElement | null {
		return this._element.querySelector("#custom") || null;
	}

	private getEndpoints(): {
		deliveryAddressEndpoint: string;
		submissionEndpoint: string | undefined;
		userProfileTokenEndpoint: string;
		validateEndpoint: string | undefined;
		submissionsCountEndpoint: string | undefined;
	} {
		const {
			deliveryAddressEndpoint = "https://profile.api.qa.crndev.chick-fil-a.com/users/2.0/me/deliveryAddresses",
			submissionEndpoint,
			userProfileTokenEndpoint = "/jwt/userjwt",
			validateEndpoint,
			submissionsCountEndpoint
		} = this._element.dataset;

		return {
			deliveryAddressEndpoint,
			submissionEndpoint,
			userProfileTokenEndpoint,
			validateEndpoint,
			submissionsCountEndpoint
		};
	}

	private getCustomAddressValue(): {
		placeId: string;
		mainText: string;
		secondaryText: string;
	} {
		const customAddressInput = this._element.querySelector(
			"#CustomAddress"
		) as HTMLInputElement;
		const value = customAddressInput.getAttribute("value") || "";

		return value.split("|").reduce(
			(accum, currentVal, i) => {
				const currentKey = Object.keys(accum)[i];

				return {
					...accum,
					[currentKey]: currentVal
				};
			},
			{ placeId: "", mainText: "", secondaryText: "" }
		);
	}

	private handleSubmitFormRedirect(response: IFormSubmitResponse): void {
		const {
			redirectError = "",
			redirectSuccess = "",
			redirectDuplicate = ""
		} = this._element.dataset;

		if (!response.isSuccessful()) {
			if (response.isDuplicateEntry()) {
				window.location.href = redirectDuplicate;

				return;
			}
			window.location.href = redirectError;

			return;
		}

		window.location.href = redirectSuccess;
	}

	private toggleSubmitBtn(toggleOn: boolean): void {
		const submitBtn = this._element.querySelector("button[type='submit']");
		const disabledClass = "disabled";
		if (!submitBtn) {
			throw new Error(`Submit button not found for ${submitBtn}`);
		}

		toggleOn
			? submitBtn.classList.remove(disabledClass)
			: submitBtn.classList.add(disabledClass);
	}
}

ComponentFactory.registerComponent(
	"signatureDropClaimForm",
	SignatureDropClaimForm
);
