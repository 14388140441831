import { Accordion } from "./Accordion";
import { IAccordionStrategy } from "./IAccordionStrategy";

const defaultSectionHeight = "100%";

export class ExpandStrategy implements IAccordionStrategy {
	constructor(private readonly _accordion: Accordion) {
		this.showAll();
	}

	showAll(): void {
		this._accordion.toggles.forEach(x => {
			x.setAttribute("aria-expanded", "true");
			const thisSection = x.nextElementSibling;

			if (thisSection) {
				$(thisSection)
					.css("height", defaultSectionHeight)
					.attr("aria-hidden", "false")
					.show();
			}
		});
	}
}
