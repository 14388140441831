import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { animationDebounce } from "../../../util/AnimationDebounce";
import { HorizontalScroll } from "./HorizontalScroll";

enum StickyNavState {
	unknown = 0,

	// Bits:
	mobile = 1,
	desktop = 2,
	open = 4,
	closed = 8,
	slickSlider = 16,
	sticky = 32,
	notSticky = 64,
	hidden = 128,

	// Valid states:
	mobileOpen = 5,
	mobileClosed = 9,
	mobileSlickSlider = 17,
	mobileNotSticky = 65,
	desktopSticky = 34,
	desktopNotSticky = 66,
	desktopHidden = 130
}

export class StickyNav extends Component {
	private readonly addHorizontalScroll: HorizontalScroll;
	private readonly _elemTopPos: number = 0;
	private _state: StickyNavState = StickyNavState.unknown;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this._elemTopPos = this._element.offsetTop;

		if (window.innerWidth >= _app.layout.desktopWidth) {
			if (this.slickSliderEnabled) {
				this.state = StickyNavState.desktopHidden;
			} else {
				this.state = this.scrolledPastElem
					? StickyNavState.desktopSticky
					: StickyNavState.desktopNotSticky;
			}
		} else {
			if (!this.mobileSlickSliderEnabled) {
				this.state = this.slickSliderEnabled
					? StickyNavState.mobileNotSticky
					: StickyNavState.mobileClosed;
			} else {
				this.state = this.slickSliderEnabled
					? StickyNavState.mobileSlickSlider
					: StickyNavState.mobileClosed;
			}
		}

		this.addHorizontalScroll = new HorizontalScroll(this);

		this.addStickyScrollEvent();
		this.addResizeEvent();

		window.addEventListener("click", () => this.transitionOnWindowClick());

		_element
			.querySelector(".current-nav-item")
			?.addEventListener("click", e => {
				e.stopPropagation();
				this.transitionOnCurrentNavClick();
			});
	}

	private addResizeEvent(): void {
		window.addEventListener(
			"resize",
			animationDebounce(
				() => window.innerWidth,
				width => this.transitionOnResize(width)
			)
		);
	}

	private addStickyScrollEvent(): void {
		window.addEventListener(
			"scroll",
			animationDebounce(
				() => window.scrollY,
				scrollY => this.transitionOnScroll(scrollY)
			)
		);
	}

	public get state(): StickyNavState {
		return this._state;
	}

	public set state(value: StickyNavState) {
		if (value === this.state) {
			return;
		}

		this._element.classList.remove(
			"mobileOpen",
			"mobileClosed",
			"mobileSlickSlider",
			"mobileNotSticky",
			"desktopSticky",
			"desktopNotSticky",
			"desktopHidden"
		);

		switch (value) {
			case StickyNavState.mobileOpen:
				this._element.classList.add("mobileOpen");
				this._element.setAttribute("aria-expanded", "true");
				this.dropdownList?.setAttribute("aria-hidden", "false");
				break;

			case StickyNavState.mobileClosed:
				this._element.classList.add("mobileClosed");
				this._element.setAttribute("aria-expanded", "false");
				this.dropdownList?.setAttribute("aria-hidden", "true");
				this.addHorizontalScroll?.update();
				break;

			case StickyNavState.mobileSlickSlider:
				this.initializeSlickSlider();
				this._element.classList.add("mobileSlickSlider");
				this._element.removeAttribute("aria-expanded");
				this._app.services.layoutService.deRegisterStickyNode(this._element);
				this.dropdownList?.removeAttribute("aria-hidden");
				break;

			case StickyNavState.mobileNotSticky:
				this._element.classList.add("mobileNotSticky");
				this._element.removeAttribute("aria-expanded");
				this._element.removeAttribute("data-slick-slider");
				this._app.services.layoutService.deRegisterStickyNode(this._element);
				this.dropdownList?.removeAttribute("aria-hidden");
				break;

			case StickyNavState.desktopSticky:
				this._element.classList.add("desktopSticky");
				break;

			case StickyNavState.desktopNotSticky:
				this._element.classList.add("desktopNotSticky");
				break;

			case StickyNavState.desktopHidden:
				this._app.services.layoutService.deRegisterStickyNode(this._element);
				this._element.classList.add("desktopHidden");
				break;

			default:
				throw new Error(`Invalid state: ${value}; current: ${this.state}`);
		}

		if (this._state === StickyNavState.mobileSlickSlider) {
			if (this.dropdownList) {
				$(this.dropdownList).slick("unslick");
			}
		}

		if ((value & StickyNavState.desktop) !== 0) {
			this.addHorizontalScroll?.update();
			this._element.removeAttribute("aria-expanded");
			this.dropdownList?.removeAttribute("aria-hidden");
		} else if (this.addHorizontalScroll) {
			this.addHorizontalScroll.scrollButtonVisible = false;
		}

		this._state = value;
	}

	private initializeSlickSlider(): void {
		if (this.dropdownList) {
			$(this.dropdownList).slick({
				arrows: false,
				centerMode: true,
				dots: true,
				infinite: false,
				slidesToShow: 1
			});
		}
	}

	private transitionOnResize(width: number): void {
		switch (this.state) {
			case StickyNavState.mobileClosed:
			case StickyNavState.mobileOpen:
				if (width > this._app.layout.desktopWidth) {
					this.state = this.scrolledPastElem
						? StickyNavState.desktopSticky
						: StickyNavState.desktopNotSticky;
				}
				break;

			case StickyNavState.mobileSlickSlider:
				if (width > this._app.layout.desktopWidth) {
					this.state = StickyNavState.desktopHidden;
				}
				break;

			case StickyNavState.mobileNotSticky:
				if (width <= this._app.layout.desktopWidth) {
					this.state = StickyNavState.mobileNotSticky;
				}
				break;

			case StickyNavState.desktopSticky:
			case StickyNavState.desktopNotSticky:
				if (width <= this._app.layout.desktopWidth) {
					this.state = StickyNavState.mobileClosed;
				}
				break;

			case StickyNavState.desktopHidden:
				if (width <= this._app.layout.desktopWidth) {
					this.state = StickyNavState.mobileSlickSlider;
				}
				break;
		}
	}

	private transitionOnScroll(scrollY: number): void {
		// Only the desktop state responds to scrolling.
		if (
			this.state !== StickyNavState.desktopSticky &&
			this.state !== StickyNavState.desktopNotSticky
		) {
			return;
		}

		this.state =
			scrollY > this._elemTopPos
				? StickyNavState.desktopSticky
				: StickyNavState.desktopNotSticky;
	}

	private transitionOnCurrentNavClick(): void {
		// Only the mobile state responds to current nav clicks.
		if (
			this.state !== StickyNavState.mobileOpen &&
			this.state !== StickyNavState.mobileClosed
		) {
			return;
		}

		this.state =
			(this.state & StickyNavState.open) !== 0
				? StickyNavState.mobileClosed
				: StickyNavState.mobileOpen;
	}

	private transitionOnWindowClick(): void {
		// Only the mobileOpen state responds to window clicks.
		if (this.state === StickyNavState.mobileOpen) {
			this.state = StickyNavState.mobileClosed;
		}
	}

	private get scrolledPastElem(): boolean {
		return window.scrollY > this._element.offsetTop;
	}

	get dropdownList(): HTMLElement | null {
		return this._element.querySelector(".dropdown") || null;
	}

	get slickSliderEnabled(): boolean {
		return this._element.getAttribute("data-slick-slider") === "true";
	}

	get mobileSlickSliderEnabled(): boolean {
		return this._element.getAttribute("data-slick-slider") === "false";
	}

	get scrollButton(): HTMLElement | null {
		return this._element.querySelector(".scroll") || null;
	}
}

ComponentFactory.registerComponent("stickyNav", StickyNav);
