// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
if (!Element.prototype.closest) {
	Element.prototype.closest = function (s: string): Element | null {
		// justification: In this case, we are not concerned with capturing
		// closures and we do not expect `this` to be an unexpected value.
		// tslint:disable-next-line no-this-assignment
		let el: Node | null = this;

		do {
			if (Element.prototype.matches.call(el, s)) {
				return el as Element;
			}

			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);

		return null;
	};
}
