// tslint:disable file-name-casing
export const pageArrowsConfig = {
	articleResultList: {
		getParams: elem => {
			if (!elem) {
        return;
      }
			return {
				ds: elem.dataset.ds,
				pageNumber: elem.dataset.pageNumber,
				pageSize: elem.dataset.pageSize,
				dateSort: elem.dataset.dateSort,
				filtersToApply: elem.dataset.filtersToApply,
				articleTagFilter: elem.dataset.articleTagFilter
			};
		}
	},
	littleThingsResultList: {
		getParams: elem => {
			if (!elem) {
        return;
      }
			return {
				ds: elem.dataset.ds,
				page: elem.dataset.pageNumber,
				pagesize: elem.dataset.pageSize,
				language: elem.dataset.language
			};
		}
	}
};
