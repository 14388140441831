import { LocalStorage } from "./LocalStorage";

enum LoginStatus {
	loggedIn = "logged-in",
	loggedOut = "not-logged-in"
}

export const isUserLoggedIn = (): boolean => {
	const loggedInStatus = LocalStorage.getLocalStorageKey(
		LocalStorage.keys.isUserLoggedIn
	);

	if (!loggedInStatus) {
		console.error("LoggedIn status value not available");
	}

	return loggedInStatus === LoginStatus.loggedIn;
};
