import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { ArticleResultsService } from "./ArticleResultsService";

export class FilterModal extends Component {
	form: HTMLFormElement;
  // tslint:disable-next-line no-any
	modal: any = null;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.setFormState();
		this.form = this._element.querySelector("form") as HTMLFormElement;
		this.applyModalClick();
		this.form.addEventListener("submit", e => this.handleSubmission(e));

		const { listView } = this._element.dataset;
		window.addEventListener(`pagingChange${listView}`, e =>
			this.updateFilterParams(e as CustomEvent)
		);
	}

	private applyModalClick(): void {
		const modalLink = this._element.querySelector("[data-target='modal']");
    // Justification: need to make context available in modal popup
    // tslint:disable-next-line no-this-assignment
		const _this = this;
		this.modal = $(modalLink).magnificPopup({
			preloader: true,
			callbacks: {
        // tslint:disable-next-line
				open: function () {
					_this.setFormState();
				},
        // tslint:disable-next-line
				close: function () {
					_this.setFormState();
				}
			}
		});
	}

	private setFormState(): void {
		// check queryParams for values
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		const hasParams = Object.keys(params).length > 0;

		if (hasParams) {
			const { dateSort, filtersToApply } = params;
			const dateSortRadios = this.form.querySelectorAll(
				"input[name='dateSort']"
			);
			const filterCheckboxes = this.form.querySelectorAll(
				"input[name='filter-type']"
			);

			dateSortRadios.forEach((radio: HTMLInputElement) => {
				if (radio.value === dateSort) {
					radio.checked = true;
				}
			});

			filterCheckboxes.forEach((checkbox: HTMLInputElement) => {
				if (filtersToApply.includes(checkbox.value)) {
					checkbox.checked = true;
				}
			});
		}
	}

	private async handleSubmission(e: Event): void {
		e.preventDefault();
		const formData = this.getFormData();
		const { ds, pageNumber, pageSize, listView, listTarget, endpoint } =
			this._element.dataset;
		this.modal.magnificPopup("close");

		const params = {
			ds: ds,
			pageNumber: pageNumber,
			pageSize: pageSize,
			dateSort: formData.dateSort,
			filtersToApply: formData.filtersToApply
		};
    const selector = `#${listTarget}`
    // tslint:disable-next-line no-floating-promises
		ArticleResultsService.getListViewResults(
			endpoint,
			params,
			this._element.parentElement.querySelector(selector) as HTMLElement
		).then(result => {
			const [data, err] = result;
			console.log(data);
			const evt = new CustomEvent(`filterChange${listView}`, {
				detail: {
					pageNumber: data.PageNumber,
					totalResults: data.totalResults,
					totalPages: data.MaxPage,
					dateSort: formData.dateSort,
					filtersToApply: formData.filtersToApply
				}
			});
			window.dispatchEvent(evt);
		});
	}

	getFormData(): { dateSort: string; filtersToApply: string[] } {
		const checkboxes = this.form.querySelectorAll(
			"input[name='filter-type']:checked"
		);
		const checkedValues: string[] = [];
    // tslint:disable-next-line
		checkboxes.forEach(function (checkbox) {
			checkedValues.push(checkbox.value);
		});
		const dateSortVal = this.form.querySelectorAll(
			"input[name='dateSort']:checked"
		);

		return { dateSort: dateSortVal[0].value, filtersToApply: checkedValues };
	}
}

ComponentFactory.registerComponent("FilterModal", FilterModal);
