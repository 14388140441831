import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import shareWindowProps from "../../util/shareWindowProps";

export class ShareBanner extends Component {
	rightPosStart: number;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.applyClickHandlers();
		this.rightPosStart = this.applyRightPos();
		this.displayBanner();
		this.childCount();
		this.handleShare();
	}

	displayBanner(): void {
		this._element.classList.add("display");
	}

	applyRightPos(): number {
		const width = this._element.offsetWidth;
		const childElemsToShow = 2;
		const offset = window.innerWidth < this._app.layout.desktopWidth ? 50 : 20; // isMobileView
		const rightPos = -(width / childElemsToShow + offset);
		$(this._element).css("right", `${rightPos}px`);

		return rightPos;
	}

	applyClickHandlers(): void {
		const openHandler = this._element.querySelector('[data-target="open"]');
		const closeHandler = this._element.querySelector('[data-target="close"]');

		if (!openHandler || !closeHandler) {
			console.warn(`Missing a handler - ${{ openHandler, closeHandler }}`);
			return;
		}

		[openHandler, closeHandler].forEach(handler =>
			handler?.addEventListener("click", () => {
				this._element.classList.toggle("expanded");
				const isExpanded = this._element.classList.contains("expanded");
				const rightPos = isExpanded ? `0px` : `${this.rightPosStart}px`;

				$(this._element).css("right", rightPos);
			})
		);
	}

	childCount(): number {
		const count = this._element.querySelectorAll(
			"[data-target='social-link']"
		).length;
		this._element.dataset.childCount = count.toString();

		return count;
	}

	handleShare(): void {
		this._element.querySelectorAll<HTMLElement>(".share-btn").forEach(el => {
			el.addEventListener("click", e => {
				const target = e.target as Element;
				if (!target) {
					return;
				}

				const toggle = target.getAttribute("data-action");

				if (toggle !== "email") {
					e.preventDefault();
				}

				const shareValue = target.getAttribute("href") || "";
				const shareWindowProperties = shareWindowProps(target);
				if (toggle !== "email") {
					window.open(shareValue, "Share", shareWindowProperties);
				}
			});
		});
	}
}

ComponentFactory.registerComponent("ShareBanner", ShareBanner);
