import { Component } from "../../../components/base/Component";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { ICfa } from "../../../models/ICfa";
import { API } from "../../../util/API";
import { DataLayerService } from "../../services/DataLayerService";

export class InternationalCares extends Component {
	form: HTMLFormElement;
	focused: boolean;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		if (this._element.tagName !== "FORM") {
			throw new Error(`Non-form element passed in, element: ${this._element}`);
		}

		this.form = this._element as HTMLFormElement;
		// tslint:disable-next-line
		this.initialize();

		// CFAC-34725 Fire whenever a user starts filling out a form.
		this._element
			.querySelector("#firstName")
			?.addEventListener("focus", e => this.handleUserInput(e));
		this.focused = false;

		// CFAC-34725 Fire whenever a user is presented with a form on a page.
		DataLayerService.pushDataLayerEvent_Forms("form_view", {
			identifier: this.getlayerIdentifier(),
			name: this.getlayerName(),
			type: "InternationalCares"
		});
	}

	// CFAC-34725 Fire whenever a user starts filling out a form.
	async handleUserInput(e: Event): Promise<void> {
		if (!this.focused) {
			DataLayerService.pushDataLayerEvent_Forms("form_start", {
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "InternationalCares"
			});

			this.focused = true;
		}
	}

	private handleAgreementCheckboxes(): void {
		const contactEmail = this._element.querySelector(
			"#contactEmail"
		) as HTMLInputElement;
		const contactPhone = this._element.querySelector(
			"#contactPhone"
		) as HTMLInputElement;

		this.toggleSubmitBtn(contactEmail?.checked || contactPhone?.checked);
	}

	private async initialize(): Promise<void> {
		this.applyValidationRules(this.form);
		this.form.addEventListener("submit", e => this.handleSubmission(e));
		this.form.addEventListener("change", e => this.handleAgreementCheckboxes());
	}

	private applyValidationRules(form: HTMLFormElement): void {
		const messages = this._element.dataset;
		const errorClass = "hasError";

		$.validator.addMethod(
			"phoneUS",
			// tslint:disable-next-line: typedef
			function (a, b) {
				return (
					// tslint:disable-next-line: no-parameter-reassignment
					(a = a.replace(/\s+/g, "")),
					a.length > 9 &&
						a.match(
							/^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]\d{2}-?\d{4}$/
						)
				);
			},
			"Please specify a valid phone number"
		);

		const baseValidations = {
			firstName: {
				required: true
			},
			lastName: {
				required: true
			},
			phone: {
				required: true,
				phoneUS: true
			},
			email: {
				email: true,
				required: true
			},
			location: {
				required: true
			},
			additionalDetails: {
				required: true,
				minlength: 5
			}
		};

		$(form).validate({
			errorClass: "hasError",
			errorPlacement: (error, element) => {
				error.appendTo(element.next(".error-container"));
			},
			highlight: element => {
				$(element).parent().addClass(errorClass);
			},
			messages: {
				firstName: {
					required: messages.customNameMsg ?? "Please enter your first name"
				},
				lastName: {
					required: messages.customNameMsg ?? "Please enter your last name"
				},
				phone: {
					required:
						messages.customPhoneMsg ?? "Please enter a valid phone number"
				},
				location: {
					required: messages.customLocationMsg ?? "Please select a location"
				},
				email: messages.customEmailMsg ?? "",
				additionalDetails: {
					required: messages.customAdditionalMsg ?? ""
				}
			},
			rules: baseValidations,
			unhighlight: element => {
				$(element).parent().removeClass(errorClass);
			}
		});
	}

	private async handleSubmission(e: Event): Promise<void> {
		e.preventDefault();
		this.toggleSubmitBtn(false);
		const formHasErrors = !this.validate();

		if (formHasErrors) {
			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "InternationalCaresFormSubmission",
				error_message: "Form has errors",
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "InternationalCares"
			});

			this.toggleSubmitBtn(true);

			return;
		}

		const recaptchaVal = this.form["g-recaptcha-response"]?.value;
		if (!recaptchaVal) {
			console.warn("No recaptcha value found in payload");

			this.toggleFieldErr("recaptchaField", true);
			this.toggleSubmitBtn(true);

			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "InternationalCaresFormSubmission",
				error_message: "Recaptcha failed.",
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "InternationalCares"
			});

			return;
		}

		await this.submitForm();
	}

	private async submitForm(): Promise<null | object> {
		const {
			submissionEndpoint = "",
			formDatasourceId = "",
			redirectSuccess,
			redirectError
		} = this._element.dataset;
		const payload = $(this.form)
			.serializeArray()
			.reduce(
				(ac, cv) => {
					// Only included in form when checked
					if (cv.name === "flagCheck") {
						return { ...ac, [cv.name]: true };
					}

					if (cv.name === "g-recaptcha-response") {
						return { ...ac, ["grecaptcharesponse"]: cv.value };
					}

					return { ...ac, [cv.name]: cv.value };
				},
				{ flagCheck: false, formDatasourceId }
			);
		console.log({ payload });

		const [result, err] = await API.postReq(submissionEndpoint, payload);

		console.log({ result });
		console.log({ err });

		if (err) {
			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "InternationalCaresFormSubmission",
				error_message: "Error in submitting form.",
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "InternationalCares"
			});

			console.warn("Error in submitting form.");
			//alert("Error in submitting form. Please try again.");

			window.location.href = redirectError;

			return;
		}

		if (result && result.success) {
			// CFAC-34725 Fire whenever a user successfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_complete", {
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "InternationalCares"
			});

			window.location.href = redirectSuccess;
		} else if (result && result.error) {
			// Account for Form Validation Failed response from BE:

			Object.keys(result.errors).forEach(key => {
				console.warn(`${key}, ${result.errors[key]}`);
				this.toggleFieldErr(key, true);
			});
		} else {
			// Account for ERROR response from BE:
			console.warn("Error while submitting the form");

			window.location.href = redirectError;
		}
	}

	private validate(): boolean {
		if (this._element) {
			return $(this._element).valid();
		}

		return false;
	}

	private toggleFieldErr(fieldName: string, toggleOn: boolean): void {
		const field = this._element.querySelector(`[data-element='${fieldName}']`);

		if (!field) {
			console.warn(`No field ${fieldName} found. Check DOM.`);

			return;
		}

		toggleOn
			? field.classList.add("display")
			: field.classList.remove("display");
	}

	private toggleSubmitBtn(toggleOn: boolean): void {
		const submitBtn = this._element.querySelector("button[type='submit']");
		const disabledClass = "disabled";

		if (!submitBtn) {
			throw new Error(`Submit button not found for ${submitBtn}`);
		}

		toggleOn
			? submitBtn.classList.remove(disabledClass)
			: submitBtn.classList.add(disabledClass);
	}

	getlayerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	getlayerName(): string {
		return this._element.dataset.layerName || "";
	}
}

ComponentFactory.registerComponent("internationalCares", InternationalCares);
