// Toggle functionality, changing UI messaging depending on false or
// positive check. Added check for length as one possibility but other use
// cases may come up which can be added to this sheet. The HTML structure,
// however will follow what we see on location-GrandOpenings with container
// and children divs that have successMsg and failMsg containers. If not
// suitable, we can remove these hard-coded values and add them as params
// to the function itself.

class SuccessFailToggleDisplay {
	constructor(private readonly elem: HTMLElement) {}

	msgToDisplay(bool: boolean): void {
		const successMsg = this.elem.querySelector(".successMsg");
		const failMsg = this.elem.querySelector(".failMsg");
		const msgToDisplay = bool ? successMsg : failMsg;

		// Reset to default
		successMsg?.classList.remove("display");
		failMsg?.classList.remove("display");

		// Add display
		msgToDisplay?.classList.add("display");
	}

	checkForLength(elem: HTMLElement): boolean {
		return elem.children.length > 0;
	}

	checkInputValues(
		elem: HTMLInputElement,
		inputField: HTMLInputElement
	): boolean {
		const arrayZips = elem.value.replace(/ /g, "").split(",");
		const x = inputField.value.replace(/ /g, "").toString();

		return arrayZips.includes(x);
	}
}

document.querySelectorAll<HTMLElement>(".toggleDisplay").forEach(elem => {
	const toggleDisplay = new SuccessFailToggleDisplay(elem);

	// Grand Openings Location UI Toggle--------------------------------
	const grandOpeningLocation = document.querySelector(".submiss-msg");

	if (grandOpeningLocation) {
		// Find btn within DOM
		const checkZipCodeBtn = $(elem).siblings(".g-form").find(".btn")[0];

		const zipInputVals = document.getElementById(
			"zipcode-list"
		) as HTMLInputElement;

		checkZipCodeBtn?.addEventListener("click", e => {
			e.preventDefault();

			const inputField = document.querySelector(
				".g-form__group input"
			) as HTMLInputElement;

			const boolVal = toggleDisplay.checkInputValues(zipInputVals, inputField);

			toggleDisplay.msgToDisplay(boolVal);
		});
	}
});
