import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

export class ModalVideo extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		$(`#${this.buttonId}`).magnificPopup({
			items: {
				src: this.iframeLink || "",
				type: "iframe"
			},
			mainClass: "video-popout"
		});

		_element.addEventListener("click", e => this.onPlayBtnClick(e));
	}

	async onPlayBtnClick(e: Event): Promise<void> {
		DataLayerService.pushDataLayerEvent_Video_Start({
			video_provider: "iframe",
			video_title: this.iframeLink
		});
	}

	get iframeLink(): string {
		return this._element.dataset.iframe || "";
	}

	get buttonId(): string {
		return this._element.dataset.buttonId || "";
	}
}

ComponentFactory.registerComponent("ModalVideo", ModalVideo);
