import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class ModalCarousel extends Component {
	public readonly carouselEl: JQuery;
	public carousel: JQuery | null = null;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.carouselEl = $(".carousel-item");
		this.initCarousel();
	}

	initCarousel(): void {
		const carouselOptions: JQuerySlickOptions = {
			arrows: false,
			autoplay: true,
			autoplaySpeed: 2000,
			cssEase: "linear",
			dots: true,
			infinite: true,
			mobileFirst: true,
			slidesToScroll: 1,
			slidesToShow: 1,
			useTransform: false
		};

		this.carousel = this.carouselEl.slick(carouselOptions);
	}
}

ComponentFactory.registerComponent("ModalCarousel", ModalCarousel);
