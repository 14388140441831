import { IBugsnagService } from "./IBugsnagService";

export class BugsnagService implements IBugsnagService {
	private readonly _metaKey: HTMLMetaElement;
	private readonly _metaVersion: HTMLMetaElement;
	private readonly _metaReleaseStage: HTMLMetaElement;

	constructor() {
		this._metaKey = document.querySelector(
			"meta[name='bsk']"
		) as HTMLMetaElement;

		this._metaVersion = document.querySelector(
			"meta[name='bskv']"
		) as HTMLMetaElement;

		this._metaReleaseStage = document.querySelector(
			"meta[name='bsrs']"
		) as HTMLMetaElement;
	}

	clearApiKey(): void {
		if (this._metaKey) {
			this._metaKey.content = "";
		}
	}

	getApiKey(): string {
		return this._metaKey?.content;
	}

	getReleaseVersion(): string {
		return this._metaVersion?.content;
	}

	getReleaseStage(): string {
		return this._metaReleaseStage?.content;
	}

	hasApiKey(): boolean {
		if (this._metaKey?.content) {
			return true;
		}

		return false;
	}
}
