(function () {
	// Brandify JS for Location iframe
	// replace with id of the iframe to dynamically resize
	var eventMethod = window.addEventListener
		? "addEventListener"
		: "attachEvent";

	var eventer = window[eventMethod];
	var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
	var iframe_id = "locatoriframe";

	eventer(
		messageEvent,
		function (e) {
			if (
				e.data &&
				e.data.indexOf &&
				e.data.indexOf("w2gi:iframeHeightUpdated") !== -1
			) {
				var dimensions = e.data.split("//");
				autoResize(dimensions[1], dimensions[2]);
			}

			if (
				e.data &&
				e.data.indexOf &&
				e.data.indexOf("w2gi:scrollPage") !== -1
			) {
				var offsets = document
					.getElementById(iframe_id)
					.getBoundingClientRect();
				var values = e.data.split("//");
				var destination = values[1];
				window.scrollTo(0, destination + offsets.top);
			}
		},
		false
	);

	function autoResize(newheight) {
		if (document.getElementById(iframe_id)) {
			document.getElementById(iframe_id).style.height =
				parseInt(newheight) + 40 + "px";
		}
	}
	// End brandify JS
})();

// this function can be called to prevent forms from being submitted multiple
// times while the initial submit is processing
jQuery.fn.preventDoubleSubmission = function () {
	$(this).on("submit", function (e) {
		var $form = $(this);

		if ($form.data("submitted") === true) {
			alert("Form already submitted. Please wait.");
			e.preventDefault();
		} else {
			// Mark it so that the next submit can be ignored, and form is valid
			if ($form.valid()) {
				$form.data("submitted", true);
			}
		}
	});

	// Keep chainability
	return this;
};

$(window).on("load", function () {
	ssm.addStates([
		{
			id: "report-mobile",
			query: "(max-width: 767px)",
			onEnter: function () {
				if (
					window.digitalData &&
					window.digitalData.page &&
					window.digitalData.page.pageInfo
				) {
					digitalData.page.pageInfo.experienceType = "mobile";
				}
			}
		},
		{
			id: "report-tablet",
			query: "(min-width: 768px) and (max-width: 1023px)",
			onEnter: function () {
				if (
					window.digitalData &&
					window.digitalData.page &&
					window.digitalData.page.pageInfo
				) {
					digitalData.page.pageInfo.experienceType =
						$("body").hasClass("android") || $("body").hasClass("ipad")
							? "tablet"
							: "desktop";
				}
			}
		},
		{
			id: "report-desktop",
			query: "(min-width: 1024px)",
			onEnter: function () {
				if (
					window.digitalData &&
					window.digitalData.page &&
					window.digitalData.page.pageInfo
				) {
					digitalData.page.pageInfo.experienceType = "desktop";
				}
			}
		}
	]);
});

$(window).on("load", function () {
	var expEditor = $(".hdnExpEditor").val() === "true";
	var expPreview = $(".hdnPreview").val() === "true";
	var geoOptions = {
		maximumAge: 5 * 60 * 1000
	};

	// update cfaone links with trademarks
	$("a.cfa-one").append("<sup>&trade;</sup>");

	$(function () {
		// Checks to see if Little Things Success form is present and visible and
		// shift page location to the Little Things Success and Thank You Message,
		// if both are true.
		var formLittleThingsSuccessRef = $("#formLittleThingsSuccess");
		if (formLittleThingsSuccessRef) {
			var submitBtn = $("#btnformLittleThingsSuccess");
			var littleFormContainer = $("#containerLittleThings");

			submitBtn.on("click", function () {
				formLittleThingsSuccessRef.hide();
				if (littleFormContainer.length > 0) {
					littleFormContainer.show();
					littleFormContainer.find("input[type=text], textarea").val("");
				}
			});
			if (formLittleThingsSuccessRef.attr("style") === "display:block;") {
				$("html, body").animate(
					{ scrollTop: formLittleThingsSuccessRef.offset().top + -80 },
					500
				);
			}
		}

		// Same for DSAR form
		var formDataSubjectAccessRequestSuccessRef = $(
			"#formDataSubjectAccessRequestSuccess"
		);
		if (formDataSubjectAccessRequestSuccessRef) {
			if (
				formDataSubjectAccessRequestSuccessRef.attr("style") ===
				"display:block;"
			) {
				$("html, body").animate(
					{
						scrollTop: formDataSubjectAccessRequestSuccessRef.offset().top + -80
					},
					500
				);
			}
		}

		$("#cateringCTAButton").on("click", function () {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "startcateringorderPDP"
			});
		});

		$("#downloadCTAButton").on("click", function () {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "downloadapptoorderPDP"
			});
		});

		$("input[name=pdp-radio-option]").change(function () {
			var tag = $("input[name='pdp-radio-option']:checked").data(
				"tag"
			);
			var variantTag = $("input[name='pdp-radio-option']:checked").data(
				"variant"
			);
			var imageUrl = $("input[name='pdp-radio-option']:checked").data(
				"image-url"
			);
			var menuType = $("input[name='pdp-radio-option']:checked").data(
				"menutype"
			);
			var clientKey = $("input[name='pdp-radio-option']:checked").data(
				"clientkey"
			);
			var category = $("input[name='pdp-radio-option']:checked").data(
				"category"
			);
			var price = $("input[name='pdp-radio-option']:checked").data(
				"price"
			);

			if (imageUrl) {
				$("#mainImage").attr("src", imageUrl);
				$("#mainImage").attr("srcset", imageUrl);
			}

			var orderPickupLink = $(".pdp-pwo-cta").find('a[data-id="orderpickup"]');
			if (orderPickupLink !== undefined) {
				var newUrl = ReplaceQueryStringParameter(
					orderPickupLink.attr("href"),
					"itemTag",
					variantTag
				);
				orderPickupLink.attr("href", newUrl);
			}

			$.ajax({
				url: "/CfaMenu/menu/GetPdpPartials",
				cache: false,
				type: "GET",
				data: {
					clientKey: clientKey,
					menuType: menuType,
					category: category,
					tag: tag,
					variantTag: variantTag,
					price: price
				}
			}).done(function (data) {
				$("#nutrition-panel").replaceWith(data.Message);
				$("#productDetailPrice").replaceWith(data.Price);
			});
		});

		$("input[name=pdp-radio-option]").change(function () {
			var tag = $("input[name='pdp-radio-option']:checked").data(
				"tag"
			);
			var variantTag = $("input[name='pdp-radio-option']:checked").data(
				"variant"
			);
			var menuType = $("input[name='pdp-radio-option']:checked").data(
				"menutype"
			);
			var clientKey = $("input[name='pdp-radio-option']:checked").data(
				"clientkey"
			);
			var category = $("input[name='pdp-radio-option']:checked").data(
				"category"
			);

			// update any emergency messages related to the selected item
			$.ajax({
				url: "/CfaMenu/menu/GetEmergencyMessaging",
				cache: false,
				type: "GET",
				data: {
					clientKey: clientKey,
					menuType: menuType,
					category: category,
					tag: tag,
					variantTag: variantTag
				}
			}).done(function (data) {
				$("#pdp-message-container p[data-tag-level='ENTREES']").remove();

				if ($("#pdp-message-container").find("p").length === 0) {
					$("#pdp-message-container").hide();
				}

				if (data.emergencyMessage) {
					// add a new message
					$(
						"<p data-tag-level='ENTREES'>" + data.emergencyMessage + "</p>"
					).appendTo("#pdp-message-container");

					$("#pdp-message-container").show();
				}
			});
		});

		$("input[name=pdp-radio-option]").change(function () {
			var tag = $("input[name='pdp-radio-option']:checked").data(
				"tag"
			);
			var variantTag = $("input[name='pdp-radio-option']:checked").data(
				"variant"
			);
			var clientKey = $("input[name='pdp-radio-option']:checked").data(
				"clientkey"
			);
			var category = $("input[name='pdp-radio-option']:checked").data(
				"category"
			);
			var menuType = $("input[name='pdp-radio-option']:checked").data(
				"menutype"
			);

			$.ajax({
				url: "/CfaMenu/menu/GetIngredientStatement",
				type: "Get",
				data: {
					clientKey: clientKey,
					menuType: menuType,
					category: category,
					tag: tag,
					variantTag: variantTag
				}
			}).done(function (results) {
				if (results !== "") {
					$("#pdp-ingredients-accord p").html(results);
				}
			});
		});

		function ReplaceQueryStringParameter(uri, key, value) {
			if (uri === undefined) {
				return "";
			}

			var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
			var separator = uri.indexOf("?") !== -1 ? "&" : "?";
			if (uri.match(re)) {
				return uri.replace(re, "$1" + key + "=" + value + "$2");
			} else {
				return uri + separator + key + "=" + value;
			}
		}

		$("#showFullNutrition").on("click", function () {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "showinformationPDP"
			});
		});
	});

	// Close alert
	$(".emergency-alert .icon-close").click(function (event) {
		event.preventDefault();
		var alertId = $(this).data("href");
		var cookieName = $(this).data("cookie");
		var expireStamp = 86400000;
		CFACom.Cookie.setBooleanCookie(alertId, cookieName, expireStamp);

		$(this).parent().slideUp("fast");
		var alertWrapper = $(this).parent();
		if (alertWrapper != null) {
			if ($(alertWrapper).children(":visible").length === 1) {
				$(alertWrapper).slideUp("fast");
			}
		}
	});

	// Close sunday
	$(".sunday-video .close").click(function (event) {
		event.preventDefault();
		var overlayId = $(this).data("href");
		var expireStamp = 3600 * 1000 * 8800;
		CFACom.Cookie.setBooleanCookie(overlayId, "cfahideoverlay", expireStamp);
	});

	// Cow Appreciation Day
	if ($(".cad-cta").length > 0) {
		CFACom.Cookie.setBooleanCookie("", "cfahidecad");
	}

	//Mobile Promo
	$(window).on("load", function () {
		$(".cfa1-promo .icon-close, .cfa1-promo .apple, .cfa1-promo .google").click(
			function (event) {
				if (CFACom.Cookie.getCookie("cfahidemp") != null) {
					CFACom.Cookie.setCookie("cfahidemp", "2");
				} else {
					CFACom.Cookie.setCookie("cfahidemp", "1");
				}
			}
		);
	});

	function error(err) {
		if (err.code == 1) {
			$(".distance.calcdistance").hide();
		}
	}
	// calculate distances if cookie available

	$(".calcdistance").each(function () {
		if (CFACom.Cookie.getCookie != null) {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(setLocationDetails, error);
				var locLat = $(this).data("lat");
				var locLong = $(this).data("long");
				var miText = $(this).data("mileText");
				var distanceType = $(this).data("distanceType");

				var userCoords = {
					latitude: CFACom.Cookie.getCookie("cfacomvisitorlat"),
					longitude: CFACom.Cookie.getCookie("cfacomvisitorlong")
				};
				if (locLat != null) {
					var locationCoords = {
						latitude: locLat,
						longitude: locLong
					};
					var distance = computeDistance(
						userCoords,
						locationCoords,
						distanceType
					);
					$(this).html(Math.round(distance * 100) / 100 + " " + miText);
				}
			} else {
				$(this).hide();
			}
		}
	});
	function updateDistances() {
		$(".calcdistance").each(function () {
			if (CFACom.Cookie.getCookie("cfacomvisitorlat") != null) {
				var locLat = $(this).data("lat");
				var locLong = $(this).data("long");
				var miText = $(this).data("mileText");
				var distanceType = $(this).data("distanceType");
				var userCoords = {
					latitude: CFACom.Cookie.getCookie("cfacomvisitorlat"),
					longitude: CFACom.Cookie.getCookie("cfacomvisitorlong")
				};
				if (locLat != null) {
					var locationCoords = {
						latitude: locLat,
						longitude: locLong
					};
					var distance = computeDistance(
						userCoords,
						locationCoords,
						distanceType
					);
					$(this).html(Math.round(distance * 100) / 100 + " " + miText);
				}
			}
		});
	}

	function geoError(error) {
		var errorTypes = {
			0: "Unknown error",
			1: "Permission denied by user",
			2: "Position is not available",
			3: "Request timed out"
		};
		var errorMessage = errorTypes[error.code];
		if (error.code == 0 || error.code == 2) {
			errorMessage = errorMessage + " " + error.message;
		}
	}

	// Function to calculate distance between two coordinates on earth surface
	function computeDistance(startCoords, destCoords, distanceType) {
		var startLatRads = degreesToRadians(startCoords.latitude);
		var startLongRads = degreesToRadians(startCoords.longitude);
		var destLatRads = degreesToRadians(destCoords.latitude);
		var destLongRads = degreesToRadians(destCoords.longitude);
		var Radius = 6371; // radius of the Earth in km
		var distance =
			Math.acos(
				Math.sin(startLatRads) * Math.sin(destLatRads) +
					Math.cos(startLatRads) *
						Math.cos(destLatRads) *
						Math.cos(startLongRads - destLongRads)
			) * Radius;

		if (distanceType === "km") {
			return distance;
		} else {
			return distance * 0.62137119; //return distance in miles
		}
	}

	// Converts degree to radians
	function degreesToRadians(degrees) {
		var radians = (degrees * Math.PI) / 180;
		return radians;
	}

	// set cookies and update calculated distances
	function setLocationDetails(position) {
		var userLat = position.coords.latitude;
		var userLong = position.coords.longitude;

		CFACom.Cookie.setCookie("cfacomvisitorlat", userLat);
		CFACom.Cookie.setCookie("cfacomvisitorlong", userLong);
		//update calculations
		$(".calcdistance").each(function () {
			var locLat = $(this).data("lat");
			var locLong = $(this).data("long");
			var miText = $(this).data("mileText");
			var distanceType = $(this).data("distanceType");
			if (locLat != null) {
				var locationCoords = {
					latitude: locLat,
					longitude: locLong
				};
				var distance = computeDistance(
					position.coords,
					locationCoords,
					distanceType
				);
				$(this).html(Math.round(distance * 100) / 100 + " " + miText);
			}
		});
		$("#loclatitude").val(userLat);
		$("#loclongitude").val(userLong);
	}

	function displayGeoError(error) {
		geoError(error);
		if ($("#loclatitude").val() !== 0 && $("#loclongitude").val() !== 0) {
			document.getElementById("my-location-button-form").submit();
		}
	}

	$(".frame").on("click", ".update-search-btn", function (event) {
		event.preventDefault();
		var controller = $(this).data("controller");
		var total = $(this).data("totalResults");
		var containerId = $(this).data("containerId");
		var templates = $(this).data("includeTemplates");
		var location = $(this).data("locationId");
		var pageNum = $(this).data("page") + 1;
		var resultSize = pageNum * 6;
		var keywords = $("input[type=hidden]#hiddenSearchKeywords").val();
		$(this).data("page", pageNum + 1);

		$.ajax({
			url: controller,
			type: "GET",
			data: {
				keyword: keywords,
				StartingPageIndex: pageNum,
				PageSize: resultSize,
				IncludedTemplates: templates,
				LocationId: location
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);

			$(".story-img").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(this).find("img").prop("src") + ")"
				);
				$(this).css("background-size", "cover");
				$(this).css("background-position", "center");
				$(this).find("img").hide();
			});
		});
	});

	// related stories
	$(".frame").on("click", ".show-more-related-stories-btn", function (event) {
		event.preventDefault();
		var controller = $(this).data("controller");
		var total = $(this).data("totalResults");
		var tagValue = $(this).data("tags");
		var excludedIds = $(this).data("excludedId");
		var tagMatch = $(this).data("tagMatch");
		var datasourceId = $(this).data("datasourceId");
		var containerId = $(this).data("containerId");
		var pageNum = $(this).data("page") + 1;
		var resultSize = pageNum * 6 - 3;

		$(this).data("page", pageNum + 1);

		$.ajax({
			url: controller,
			type: "GET",
			cache: false,
			data: {
				Tags: tagValue,
				StartingPageIndex: pageNum,
				PageSize: 3,
				DisplayResultSize: resultSize,
				ExcludedIds: excludedIds,
				DatasourceId: datasourceId,
				IsCareersPage: false
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);

			$(".related .story")
				.not(".full, .top-shared")
				.each(function () {
					$(this)
						.children("a")
						.first()
						.css(
							"background-image",
							"url(" + $(this).find("img").prop("src") + ")"
						);
					$(this).children("a").first().css("background-size", "cover");
					$(this).children("a").first().css("background-position", "center");
					$(this).find("img").hide();
				});
			$(".related .story").click(function () {
				window.location.href = $(this).find("h3 a").attr("href");
			});

			$(".related .story a, div.story > a").click(function () {
				window.location.href = $(this).attr("href");
				e.preventDefault();
			});
		});
	});

	// careers related stories
	$(".frame").on(
		"click",
		".careers-show-more-related-stories-btn",
		function (event) {
			event.preventDefault();
			var controller = $(this).data("controller");
			var tagValue = $(this).data("tags");
			var excludedIds = $(this).data("excludedId");
			var datasourceId = $(this).data("datasourceId");
			var containerId = $(this).data("containerId");
			var pageNum = $(this).data("page") + 1;
			var resultSize = pageNum * 6 - 3;

			$(this).data("page", pageNum + 1);

			$.ajax({
				url: controller,
				type: "GET",
				cache: false,
				data: {
					Tags: tagValue,
					StartingPageIndex: pageNum,
					PageSize: 3,
					DisplayResultSize: resultSize,
					ExcludedIds: excludedIds,
					DatasourceId: datasourceId,
					IsCareersPage: true
				}
			}).done(function (partialViewResult) {
				$("#" + containerId).replaceWith(partialViewResult);
			});
		}
	);

	// category feed (press release)
	$(".frame").on("click", ".latest-cat-feed-load-more-btn", function (event) {
		event.preventDefault();
		var controller = $(this).data("controller");
		var catContainer = $(this).data("catContainer");
		var excludedIds = $(this).data("excludedIds");
		var containerId = $(this).data("containerId");
		var pageNum = $(this).data("page") + 1;
		var resultSize = pageNum * 4;
		$(this).data("page", pageNum + 1);

		$.ajax({
			url: controller,
			type: "GET",
			data: {
				StartingPageIndex: pageNum,
				PageSize: 4,
				DisplayResultSize: resultSize,
				ContainerId: catContainer,
				ExcludedIds: excludedIds
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);
		});
	});

	// Catering Product List - Load More

	$(".frame").on("click", ".catering-list-load-more-btn", function (event) {
		event.preventDefault();
		var controller = $(this).data("controller");

		var catName = $(this).data("catName");
		var catTitle = $(this).data("catTitle");
		var locationId = $(this).data("locationId");
		var totalResults = $(this).data("totalResults");
		var containerId = $(this).data("containerId");
		var pageNum = $(this).data("page") + 1;
		var datasource = $(this).data("datasource");
		var resultSize = pageNum * 6;
		$(this).data("page", pageNum + 1);

		$.ajax({
			url: controller,
			type: "GET",
			data: {
				PageIndex: pageNum,
				PageSize: 6,
				DisplayResultSize: resultSize,
				CategoryName: catName,
				LocationId: locationId,
				CategoryTitle: catTitle,
				TotalResults: totalResults,
				DataSource: datasource
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);
		});
	});

	$(".frame").on("click", ".openings-show-more-btn", function (event) {
		event.preventDefault();
		var controller = $(this).data("controller");
		var containerId = $(this).data("containerId");
		var pageNum = $(this).data("page") + 1;
		var resultSize = pageNum * 3;
		$(this).data("page", pageNum + 1);

		$.ajax({
			url: controller,
			type: "GET",
			data: {
				StartingPageIndex: pageNum,
				PageSize: 3,
				DisplayResultSize: resultSize
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);
		});
	});

	// story landing feed
	$(".frame").on("click", ".story-feed-load-more-btn", function (event) {
		event.preventDefault();
		var controller = $(this).data("controller");
		var catContainer = $(this).data("catContainer");
		var tagValue = $(this).data("tags");
		var containerId = $(this).data("containerId");
		var excludedIds = $(this).data("excludedIds");
		var pageNum = $(this).data("page") + 1;
		var resultSize = pageNum * 7;
		$(this).data("page", pageNum + 1);

		$.ajax({
			url: controller,
			type: "GET",
			data: {
				Tags: tagValue,
				StartingPageIndex: pageNum,
				PageSize: 7,
				DisplayResultSize: resultSize,
				ContainerId: catContainer,
				ExcludedIds: excludedIds
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);
			var windowHeight = $(window).height();
			var storyHeight = windowHeight - 71;
			$(".lead-story").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(this).find(".lrg-img").prop("src") + ")"
				);
				$(this).css("background-size", "cover");
				$(this).css("height", storyHeight);
				$(".slide-bkgd").hide();
			});
			$(".related .story")
				.not(".full, .top-shared")
				.each(function () {
					$(this)
						.children("a")
						.first()
						.css(
							"background-image",
							"url(" + $(this).find("img").prop("src") + ")"
						);
					$(this).children("a").first().css("background-size", "cover");
					$(this).children("a").first().css("background-position", "center");
					$(this).find("img").hide();
				});
			$(".related .story").click(function () {
				window.location.href = $(this).find("h3 a").attr("href");
			});

			$(".related .story a, div.story > a, .lead-story .slide-content a").click(
				function () {
					window.location.href = $(this).attr("href");
					e.preventDefault();
				}
			);
		});
	});

	$(".frame").on("click", ".story-tags-load-more-btn", function (event) {
		event.preventDefault();
		var controller = $(this).data("controller");
		var catContainer = $(this).data("catContainer");
		var tagValue = $(this).data("tags");
		var displayTag = $(this).data("displayTag");
		var containerId = $(this).data("containerId");
		var pageNum = $(this).data("page") + 1;
		var resultSize = pageNum * 7;
		$(this).data("page", pageNum + 1);

		$.ajax({
			url: controller,
			type: "GET",
			data: {
				Tags: tagValue,
				StartingPageIndex: pageNum,
				PageSize: 7,
				DisplayResultSize: resultSize,
				ContainerId: catContainer,
				DisplayTag: displayTag
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);
			var windowHeight = $(window).height();
			var storyHeight = windowHeight - 71;
			$(".lead-story").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(this).find(".lrg-img").prop("src") + ")"
				);
				$(this).css("background-size", "cover");
				$(this).css("height", storyHeight);
				$(".slide-bkgd").hide();
			});
			$(".related .story")
				.not(".full, .top-shared")
				.each(function () {
					$(this)
						.children("a")
						.first()
						.css(
							"background-image",
							"url(" + $(this).find("img").prop("src") + ")"
						);
					$(this).children("a").first().css("background-size", "cover");
					$(this).children("a").first().css("background-position", "center");
					$(this).find("img").hide();
				});
			$(".related .story").click(function () {
				window.location.href = $(this).find("h3 a").attr("href");
			});

			$(".related .story a, div.story > a, .lead-story .slide-content a").click(
				function () {
					window.location.href = $(this).attr("href");
					e.preventDefault();
				}
			);
		});
	});

	// Rep Management Story Listing Show More
	$("body").on("click", ".rep-story-set-load-more-btn", function (event) {
		event.preventDefault();
		var controller = $(this).data("controller");
		var storyId = $(this).data("storyid");
		var hasLead = $(this).data("hasLead");
		var containerId = $(this).data("containerId");
		var displayCount = parseInt($(this).data("displayLimit")) || 0;
		var resultSize = displayCount + 3;

		$.ajax({
			url: controller,
			type: "GET",
			data: {
				HasLeadStory: hasLead,
				DisplayLimit: resultSize,
				StorySetId: storyId
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);
			var windowHeight = $(window).height();
			var storyHeight = windowHeight - 71;
			$(".lead-story").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(this).find(".lrg-img").prop("src") + ")"
				);
				$(this).css("background-size", "cover");
				$(this).css("height", storyHeight);
				$(".slide-bkgd").hide();
			});
			if ($(window).width() > 959) {
				$(".story-img").each(function () {
					$(this).css(
						"background-image",
						"url(" + $(this).find("img").prop("src") + ")"
					);
					$(this).css("background-size", "cover");
					$(this).css("background-position", "center");
					$(this).find("img").hide();
				});
			}

			var i = 0;
			$(".story.full").each(function () {
				i++;
				var newID = "fullImg" + i;
				$(".story.full img").hide();
				$(this).attr("id", newID);
				$(this).val(i);
				$("#fullImg" + i).css(
					"background-image",
					"url(" +
						$("#fullImg" + i)
							.find("img")
							.prop("src") +
						")"
				);
				$("#fullImg" + i).css("background-position", "50% 50%");
			});
			$(".related .story").click(function () {
				window.location.href = $(this).find("h3 a").attr("href");
			});

			$(".related .story a, div.story > a, .lead-story .slide-content a").click(
				function () {
					window.location.href = $(this).attr("href");
					e.preventDefault();
				}
			);
		});
	});

	$("body").on("click", ".curated-story-set-load-more-btn", function (event) {
		event.preventDefault();
		var controller = $(this).data("controller");
		var storyId = $(this).data("storyid");
		var hasLead = $(this).data("hasLead");
		var containerId = $(this).data("containerId");
		var displayCount = parseInt($(this).data("displayLimit")) || 0;
		var resultSize = displayCount + 3;

		$.ajax({
			url: controller,
			type: "GET",
			data: {
				HasLeadStory: hasLead,
				DisplayLimit: resultSize,
				StorySetId: storyId
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);
			var windowHeight = $(window).height();
			var storyHeight = windowHeight - 71;
			$(".lead-story").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(this).find(".lrg-img").prop("src") + ")"
				);
				$(this).css("background-size", "cover");
				$(this).css("height", storyHeight);
				$(".slide-bkgd").hide();
			});
			$(".story-img").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(this).find("img").prop("src") + ")"
				);
				$(this).css("background-size", "cover");
				$(this).css("background-position", "center");
				$(this).find("img").hide();
			});
			var i = 0;
			$(".story.full").each(function () {
				i++;
				var newID = "fullImg" + i;
				$(".story.full img").hide();
				$(this).attr("id", newID);
				$(this).val(i);
				$("#fullImg" + i).css(
					"background-image",
					"url(" +
						$("#fullImg" + i)
							.find("img")
							.prop("src") +
						")"
				);
				$("#fullImg" + i).css("background-position", "50% 50%");
			});
			$(".related .story").click(function () {
				window.location.href = $(this).find("h3 a").attr("href");
			});

			$(".related .story a, div.story > a, .lead-story .slide-content a").click(
				function () {
					window.location.href = $(this).attr("href");
					e.preventDefault();
				}
			);
		});
	});

	$(".frame").on("click", ".story-author-load-more-btn", function (event) {
		event.preventDefault();
		var controller = $(this).data("controller");
		var catContainer = $(this).data("catContainer");
		var authorValue = $(this).data("author");
		var displayTag = $(this).data("displayTag");
		var containerId = $(this).data("containerId");
		var pageNum = $(this).data("page") + 1;
		var resultSize = pageNum * 7;
		$(this).data("page", pageNum + 1);

		$.ajax({
			url: controller,
			type: "GET",
			data: {
				AuthorId: authorValue,
				StartingPageIndex: pageNum,
				PageSize: 7,
				DisplayResultSize: resultSize,
				ContainerId: catContainer,
				DisplayTag: displayTag
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);
			var windowHeight = $(window).height();
			var storyHeight = windowHeight - 71;
			$(".lead-story").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(this).find(".lrg-img").prop("src") + ")"
				);
				$(this).css("background-size", "cover");
				$(this).css("height", storyHeight);
				$(".slide-bkgd").hide();
			});
			$(".related .story")
				.not(".full, .top-shared")
				.each(function () {
					$(this)
						.children("a")
						.first()
						.css(
							"background-image",
							"url(" + $(this).find("img").prop("src") + ")"
						);
					$(this).children("a").first().css("background-size", "cover");
					$(this).children("a").first().css("background-position", "center");
					$(this).find("img").hide();
				});
			$(".related .story").click(function () {
				window.location.href = $(this).find("h3 a").attr("href");
			});

			$(".related .story a, div.story > a, .lead-story .slide-content a").click(
				function () {
					window.location.href = $(this).attr("href");
					e.preventDefault();
				}
			);
		});
	});

	$(".support-landing .continue").click(function (event) {
		var selectedOption = $("#feedback-category option:selected");
		if (selectedOption != null) {
			var formUrl = $(selectedOption).attr("data-url");
			if (formUrl != null) {
				var form = $(selectedOption).closest("form");
				if (form != null) {
					$(form).attr("action", formUrl);
				}
			} else {
				alert("Please select a feedback category");
				event.preventDefault();
				return;
			}
		}
	});

	$(".contact-support #RestaurantState").change(function () {
		var state = $(this).val();
		if (state != "") {
			$.ajax({
				url: "/CFACOM/Location/LocationCitiesByState?state=" + state,
				type: "GET"
			}).done(function (jsonCities) {
				var placeholderText = $(
					".contact-support #RestaurantCity option:first"
				).text();

				$(".contact-support #RestaurantCity")
					.find("option")
					.remove()
					.end()
					.append('<option value="">' + placeholderText + "</option>");

				$.each(jsonCities, function (key, value) {
					$(".contact-support #RestaurantCity").append(
						$("<option>").text(value).attr("value", value)
					);
				});

				$(".contact-support #RestaurantCity").parent().removeClass("disabled");
				$(".contact-support #RestaurantCity").removeAttr("disabled");
			});
		} else {
			$(".contact-support #RestaurantCity").val("");
			$(".contact-support #RestaurantCity").parent().addClass("disabled");

			$(".contact-support #RestaurantLocationCode").val("");
			$(".contact-support #RestaurantLocationCode")
				.parent()
				.addClass("disabled");
		}
	});

	$(".contact-support #RestaurantCity").change(function () {
		var city = $(this).val();
		if (city != "") {
			var state = $(".contact-support #RestaurantState").val();
			$.ajax({
				url:
					"/CFACOM/Location/LocationsByCityAndState?state=" +
					state +
					"&city=" +
					city,
				type: "GET"
			}).done(function (jsonLocations) {
				var placeholderText = $(
					".contact-support #RestaurantLocationCode option:first"
				).text();

				$(".contact-support #RestaurantLocationCode")
					.find("option")
					.remove()
					.end()
					.append('<option value="">' + placeholderText + "</option>");

				$.each(jsonLocations, function (key, value) {
					$(".contact-support #RestaurantLocationCode").append(
						$("<option>")
							.text(value.LocationName)
							.attr("value", value.ClientKey)
							.attr("data-locationcode", value.LocationCode)
							.attr("data-hasdrivethru", value.HasDriveThru)
					);
				});

				$(".contact-support #RestaurantLocationCode")
					.parent()
					.removeClass("disabled");
				$(".contact-support #RestaurantLocationCode").removeAttr("disabled");
			});
		} else {
			$(".contact-support #RestaurantLocationCode").val("");
			$(".contact-support #RestaurantLocationCode")
				.parent()
				.addClass("disabled");
		}
	});

	$(".contact-support #RestaurantLocationCode").change(function () {
		var driveThruOption = $("#SelectedDiningType").find(
			'option:contains("Drive Thru")'
		);
		$(driveThruOption).removeClass("hidden");

		var clientKey = $(this).val();
		if (clientKey != "") {
			var hasDriveThru = $(this).find(":selected").attr("data-hasdrivethru");
			var isMall =
				$(this).find(":selected").attr("data-locationcode") == "MALL";
			if (hasDriveThru == "false" || isMall) {
				$(driveThruOption).addClass("hidden");
			}
		}
	});

	$.validator.addMethod(
		"dateInPast",
		function (value, element) {
			var now = new Date();
			return value == "" || now > new Date(value);
		},
		"Date may not be greater than today"
	);

	$.validator.addMethod(
		"daterange",
		function (value, element) {
			if (this.optional(element)) {
				return true;
			}

			var endDate = new Date();
			var startDate = new Date(
				endDate.getFullYear() - 1,
				endDate.getMonth(),
				endDate.getDate()
			);
			var enteredDate = new Date(value);

			//console.log('start date: ' + startDate);
			//console.log('end date: ' + endDate);
			//console.log('entered date: ' + enteredDate);

			if (isNaN(enteredDate)) return false;

			return (
				Date.parse(startDate) <= Date.parse(enteredDate) &&
				Date.parse(enteredDate) <= Date.parse(endDate)
			);
		},
		"Your time of visit must be within the last year"
	);

	$.validator.addMethod(
		"postalFormat",
		function (value, element) {
			return (
				this.optional(element) ||
				/^[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]$/.test(value) ||
				/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(value)
			);
		},
		"Please enter a valid US or Canadian postal code"
	);

	$.validator.addMethod(
		"addressDependency",
		function (value, element) {
			return $('input[name="Address1"]').val() == "" || value != "";
		},
		"This field is required"
	);

	$.validator.addMethod(
		"atLeastOneCheckbox",
		function (value, element) {
			return $('input[name="SelectedInterests"]:checked').length > 0;
		},
		"You must select at least one option"
	);

	$.validator.addMethod(
		"captchaValueExists",
		function (value, element) {
			return grecaptcha.getResponse().length > 0;
		},
		"You must complete reCaptcha"
	);

	$.validator.addMethod(
		"phoneFormat",
		function (value, element) {
			return this.optional || /^\d{3}-\d{3}-\d{4}$/.test(value);
		},
		"Please enter a valid phone number in the format 000-000-0000"
	);

	$.validator.addMethod(
		"phoneCFA",
		function (value, element) {
			return (
				this.optional(element) ||
				//|| /^(\([0-9]{3}\)\s*|[0-9]{3}\-)[0-9]{3}-[0-9]{4}$/.test(value) /*this guy will account for format (000) 000-0000, (000)000-0000, and 000-000-0000*/
				/^\d{3}-\d{3}-\d{4}$/.test(value)
			);
		},
		"Please enter valid number in format 000-000-0000"
	);

	function cfaErrorPlacement(error, element) {
		if (element.attr("type") == "checkbox") {
			error.insertAfter(element.closest(".checkbox")); //put it after the syled checkbox and/or list
		} else {
			error.insertAfter(element);
		}
	}

	$("#formEmailInsiders").validate({
		rules: {
			FirstName: {
				required: true,
				maxlength: 100
			},
			LastName: {
				required: true,
				maxlength: 100
			},
			Email: {
				required: true,
				email: true,
				maxlength: 100
			},
			confirm_email: {
				required: true,
				equalTo: "#Email"
			},
			PostalCode: {
				required: true,
				postalFormat: true
			},
			AcceptTerms: "required",
			SelectedInterests: {
				atLeastOneCheckbox: true
			}
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight,
		errorPlacement: cfaErrorPlacement
	});

	$("#formRestExp").validate({
		rules: {
			RestaurantState: "required",
			RestaurantCity: "required",
			RestaurantLocationCode: "required",
			SelectedDiningType: "required",
			TimeOfVisit: {
				required: true,
				dateInPast: true,
				daterange: true
			},
			FirstName: {
				required: true,
				maxlength: 30
			},
			LastName: {
				required: true,
				maxlength: 50
			},
			Phone: {
				required: true,
				phoneCFA: true,
				maxlength: 12
			},
			Address1: {
				required: true
			},
			City: {
				required: true,
				addressDependency: true,
				maxlength: 50
			},
			State: {
				required: true,
				addressDependency: true
			},
			PostalCode: {
				required: true,
				addressDependency: true,
				postalFormat: true
			},
			Email: {
				required: true,
				email: true,
				maxlength: 100
			},
			confirm_email: {
				required: true,
				equalTo: "#Email"
			},
			Feedback: {
				required: true,
				maxlength: 1500,
				minlength: 5
			}
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight
	});

	$("#formMealKit").validate({
		rules: {
			FirstName: {
				required: true,
				maxlength: 30
			},
			LastName: {
				required: true,
				maxlength: 50
			},
			PostalCode: {
				required: true,
				addressDependency: true,
				postalFormat: true
			},
			Email: {
				required: true,
				email: true,
				maxlength: 100,
				validateEmail: true
			},
			confirm_email: {
				required: true,
				email: true,
				equalTo: "#Email",
				confirmEmail: true
			},
			Feedback: {
				required: true,
				maxlength: 1500,
				minlength: 5
			}
		},
		messages: {
			confirm_email: {
				required: "This is not a valid email address.",
				equalTo: "Your emails do not match."
			}
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight
	});

	$.validator.addMethod(
		"validateEmail",
		function (value, element) {
			return (
				this.optional(element) ||
				/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,})+$/.test(
					value
				)
			);
		},
		"This is not a valid email address."
	);

	$.validator.addMethod(
		"confirmEmail",
		function (value, element) {
			return (
				this.optional(element) ||
				/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,})+$/.test(
					value
				)
			);
		},
		"Your emails do not match."
	);

	$("#formTechAssistance").validate({
		rules: {
			SelectedCategory: "required",
			FirstName: {
				required: true,
				maxlength: 30
			},
			LastName: {
				required: true,
				maxlength: 50
			},
			Phone: {
				required: true,
				phoneCFA: true,
				maxlength: 12
			},
			Address1: {
				required: true
			},
			City: {
				required: true,
				maxlength: 50
			},
			State: {
				required: true
			},
			PostalCode: {
				required: true,
				postalFormat: true
			},
			Email: {
				required: true,
				email: true,
				maxlength: 100
			},
			confirm_email: {
				required: true,
				equalTo: "#Email"
			},
			Feedback: {
				required: true,
				maxlength: 1500,
				minlength: 5
			}
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight
	});

	$("#formMedia").validate({
		ignore: "",
		rules: {
			FirstName: {
				required: true,
				maxlength: 100
			},
			LastName: {
				required: true,
				maxlength: 100
			},
			Phone: {
				required: true,
				phoneFormat: true
			},
			MediaOutlet: {
				required: true
			},
			State: {
				required: true
			},
			Deadline: {
				required: true
			},
			Hour: {
				required: true
			},
			Minute: {
				required: true
			},
			AMPM: {
				required: true
			},
			Email: {
				required: true,
				email: true,
				maxlength: 100
			},
			Topic: {
				required: true,
				maxlength: 1500,
				minlength: 5
			},
			captcha: {
				captchaValueExists: true
			}
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight
	});

	$("#formInquiries").validate({
		rules: {
			FirstName: {
				required: true,
				maxlength: 30
			},
			LastName: {
				required: true,
				maxlength: 50
			},
			Phone: {
				required: true,
				phoneCFA: true,
				maxlength: 12
			},
			Address1: {
				required: true
			},
			City: {
				required: true,
				maxlength: 50
			},
			State: {
				required: true
			},
			PostalCode: {
				required: true,
				postalFormat: true
			},
			Email: {
				required: true,
				email: true,
				maxlength: 100
			},
			confirm_email: {
				required: true,
				equalTo: "#Email"
			},
			Feedback: {
				required: true,
				maxlength: 1500,
				minlength: 5
			}
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight
	});

	$("#formLicensing").validate({
		rules: {
			SelectedOperatingVenue: "required",
			CompanyName: {
				required: true,
				maxlength: 100
			},
			ContactName: {
				required: true,
				maxlength: 100
			},
			ContactEmail: {
				required: true,
				email: true,
				maxlength: 100
			},
			confirm_email: {
				required: true,
				equalTo: "#ContactEmail"
			},
			Phone: {
				phoneFormat: true
			},
			Feedback: {
				required: true,
				maxlength: 1500
			}
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight
	});

	$("#formRealEstateSiteSplash").validate({
		rules: {
			AcceptTerms: "required"
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight,
		errorPlacement: cfaErrorPlacement
	});

	$("#formRealEstateSite").validate({
		rules: {
			ContactName: {
				required: true,
				maxlength: 100
			},
			ContactEmail: {
				required: true,
				email: true,
				maxlength: 100
			},
			confirm_email: {
				required: true,
				equalTo: "#ContactEmail"
			},
			Phone: {
				phoneFormat: true
			},
			SiteAddress: {
				required: true,
				maxlength: 100
			},
			CityOfSite: "required",
			StateOfSite: "required",
			PostalCodeOfSite: {
				required: true,
				postalFormat: true
			},
			SiteOwner: "required"
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight,
		errorPlacement: cfaErrorPlacement
	});

	$("#formSuppliersSplash").validate({
		rules: {
			AcceptTerms: "required"
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight,
		errorPlacement: cfaErrorPlacement
	});

	$("#formSuppliersOne #SelectedProductServiceCategory").change(function (
		event
	) {
		var selectedProductServiceCategoryId = $(this).val();
		if (selectedProductServiceCategoryId != "") {
			//hide the original dropdown and clear the new one
			$("#formSuppliersOne #SelectedProductServiceType").hide();
			$("#formSuppliersOne #SelectedProductServiceTypeGenarated").empty();

			//filter it
			$("#formSuppliersOne #SelectedProductServiceTypeGenarated").append(
				'<option value="" selected="selected">Select an option</option>'
			);
			$(
				'#formSuppliersOne  #SelectedProductServiceType option[data-serviceCategoryId="' +
					selectedProductServiceCategoryId +
					'"]'
			)
				.clone()
				.appendTo($("#formSuppliersOne #SelectedProductServiceTypeGenarated"));

			//default to first item
			$("#formSuppliersOne #SelectedProductServiceTypeGenarated").val("");

			//enable it
			$("#formSuppliersOne #SelectedProductServiceType")
				.parent()
				.removeClass("disabled");
			$("#formSuppliersOne #SelectedProductServiceTypeGenarated").removeAttr(
				"disabled"
			);
			$("#formSuppliersOne #SelectedProductServiceTypeGenarated").show();
			$("#formSuppliersOne #SelectedProductServiceTypeGenarated option").css(
				"display",
				""
			);
			$("#formSuppliersOne #SelectedProductServiceType").removeAttr("disabled");
		} else {
			$("#formSuppliersOne #SelectedProductServiceTypeGenarated").hide();
			$("#formSuppliersOne #SelectedProductServiceTypeGenarated").attr(
				"disabled",
				"disabled"
			);
			$("#formSuppliersOne #SelectedProductServiceType").show();
			$("#formSuppliersOne #SelectedProductServiceType").val("");
			$("#formSuppliersOne #SelectedProductServiceType")
				.parent()
				.addClass("disabled");
			$("#formSuppliersOne #SelectedProductServiceType").attr(
				"disabled",
				"disabled"
			);
			$("#formSuppliersOne #SelectedProductService").show();
			$("#formSuppliersOne #SelectedProductService").val("");
			$("#formSuppliersOne #SelectedProductService")
				.parent()
				.addClass("disabled");
			$("#formSuppliersOne #SelectedProductService").attr(
				"disabled",
				"disabled"
			);
			$("#formSuppliersOne #SelectedProductServiceGenarated").hide();
			$("#formSuppliersOne #SelectedProductServiceTypeGenarated").attr(
				"disabled",
				"disabled"
			);
		}
	});

	$("#formSuppliersOne #SelectedProductServiceTypeGenarated").change(function (
		event
	) {
		var selectedProductServiceTypeId = $(this).val();
		if (selectedProductServiceTypeId != "") {
			//hide everything but the first
			$("#formSuppliersOne #SelectedProductService").hide();
			$("#formSuppliersOne #SelectedProductServiceGenarated").empty(),
				//filter it

				$("#formSuppliersOne #SelectedProductServiceGenarated").append(
					'<option value="" selected="selected">Select an option</option>'
				),
				$(
					'#formSuppliersOne  #SelectedProductService option[data-serviceTypeId="' +
						selectedProductServiceTypeId +
						'"]'
				)
					.clone()
					.appendTo($("#formSuppliersOne #SelectedProductServiceGenarated"));
			$("#formSuppliersOne #SelectedProductServiceGenarated option").css(
				"display",
				""
			);

			//default to first item
			$("#formSuppliersOne #SelectedProductServiceGenarated").val("");

			//set the value of the original dropdown with the selected one
			$("#formSuppliersOne #SelectedProductServiceType").val(
				selectedProductServiceTypeId
			);
			//enable it
			$("#formSuppliersOne #SelectedProductService")
				.parent()
				.removeClass("disabled");
			$("#formSuppliersOne #SelectedProductServiceGenarated").removeAttr(
				"disabled"
			);
			$("#formSuppliersOne #SelectedProductServiceGenarated").show();
			$("#formSuppliersOne #SelectedProductService").removeAttr("disabled");
		} else {
			$("#formSuppliersOne #SelectedProductService").val("");
			$("#formSuppliersOne #SelectedProductService").show();
			$("#formSuppliersOne #SelectedProductServiceGenarated").hide();
			$("#formSuppliersOne #SelectedProductService")
				.parent()
				.addClass("disabled");
			$("#formSuppliersOne #SelectedProductServiceGenarated").attr(
				"disabled",
				"disabled"
			);
		}
	});

	$("#formSuppliersOne #SelectedProductServiceGenarated").change(function (
		event
	) {
		var selectedService = $(this).val();
		//set the value of the original dropdown with the selected one
		$("#formSuppliersOne #SelectedProductService").val(selectedService);
	});

	$("#formSuppliersOne").validate({
		rules: {
			SelectedProductServiceCategory: "required",
			SelectedProductServiceTypeGenarated: "required",
			SelectedProductServiceGenarated: "required"
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight
	});

	$("#formSuppliersTwo").validate({
		rules: {
			SelectedProductServiceCategory: "required",
			SelectedProductServiceType: "required",
			SelectedProductService: "required",
			CountryOfRegistration: "required",
			SupplierName: {
				required: true,
				maxlength: 100
			},
			ContactName: {
				required: true,
				maxlength: 100
			},
			ContactEmail: {
				required: true,
				email: true,
				maxlength: 100
			},
			confirm_email: {
				required: true,
				equalTo: "#ContactEmail"
			},
			Phone: {
				phoneFormat: true
			},
			Website: {
				maxlength: 200
			},
			Duns: {
				maxlength: 10
			},
			SelectedServiceCapability: "required",
			SelectedCompanyOwnership: "required",
			ServicesDescription: {
				required: true,
				maxlength: 1500
			}
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight,
		errorPlacement: cfaErrorPlacement
	});

	$("#formMedia").submit(function (e) {
		var self = this;
		e.preventDefault();
		if ($("#formMedia").valid()) {
			$("#g-recaptcha-response").attr("name", "CaptchaResponse");
			self.submit();
		}
	});

	$("#formRestExp").preventDoubleSubmission();
	$("#formTechAssistance").preventDoubleSubmission();
	$("#formInquiries").preventDoubleSubmission();
	$("#formMealKit").preventDoubleSubmission();

	$("#formLittleThings").validate({
		rules: {
			FirstName: {
				required: true
			},
			LastName: {
				required: true
			},
			Email: {
				required: true,
				email: true,
				validateEmail: true
			},
			Message: {
				required: true
			}
		},
		messages: {
			FirstName: {
				required: "First Name field is required"
			},
			LastName: {
				required: "Last Name field is required"
			},
			Email: {
				required: "Email field is required",
				email: "Email address is invalid",
				validateEmail: "Email address is invalid"
			},
			Message: {
				required: "Message field is required"
			}
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight
	});

	$("#formLittleThings").submit(function (e) {
		var self = this;
		e.preventDefault();
		if (
			$("#formLittleThings").valid() &&
			$("#termsLittleThings").prop("checked")
		) {
			self.submit();
		} else if (!$("#termsLittleThings").prop("checked")) {
			alert("Please read and select the checkbox prior to submission");
		}
	});

	$("#formDataSubjectAccessRequest").validate({
		rules: {
			FirstName: {
				required: true
			},
			LastName: {
				required: true
			},
			UserEmail: {
				required: true,
				ValidateEmail: true
			},
			Confirm_Email: {
				required: true,
				equalTo: "#UserEmail",
				Confirm_Email: true
			},
			captcha: {
				captchaValueExists: true
			}
		},
		messages: {
			FirstName: {
				required: "First Name field is required"
			},
			LastName: {
				required: "Last Name field is required"
			},
			UserEmail: {
				required: "Email field is required",
				ValidateEmail: "Email address is invalid"
			},
			Confirm_Email: {
				required: "Confirm email, this is not a valid email address.",
				equalTo: "Confirm email, your emails do not match."
			}
		},
		highlight: CFACom.Validation.cfaHighlight,
		unhighlight: CFACom.Validation.cfaUnhighlight
	});

	$.validator.addMethod(
		"ValidateEmail",
		function (value, element) {
			return (
				this.optional(element) ||
				/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,})+$/.test(
					value
				)
			);
		},
		"This is not a valid email address."
	);

	$.validator.addMethod(
		"Confirm_Email",
		function (value, element) {
			return (
				this.optional(element) ||
				/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,})+$/.test(
					value
				)
			);
		},
		"Confirm email, your emails do not match."
	);

	$("#formDataSubjectAccessRequest").submit(function (e) {
		var self = this;
		e.preventDefault();
		if (
			$("#formDataSubjectAccessRequest").valid() &&
			$("#termsDataSubjectAccessRequest").prop("checked")
		) {
			if (grecaptcha.getResponse().length > 0) {
				self.submit();
			} else {
				alert("The reCaptcha has not been verified ");
			}
		} else if (!$("#termsDataSubjectAccessRequest").prop("checked")) {
			alert("Please read and select the checkbox prior to submission");
		}
	});

	$("#formDataSubjectAccessRequest").preventDoubleSubmission();

	function SandwichBuilder() {
		console.log("invoking sandwich builder");
		if (document.documentElement.clientWidth > 959) {
			return;
		}

		if (typeof items != "undefined" && items != null) {
			// check if underscorejs is available
			if (typeof _ !== "undefined") {
				// sort using underscorejs
				items = _.sortBy(items, "order");
			}

			var options = {
				items: items,
				displayProgressIndicator: true,
				animateOnFirstLoad: false,
				animateDuration: 500 /*0.5 second, default 1 second*/,
				animateEasing:
					"easeInOutCubic" /* jQuery UI - Effect is needed for this easing*/
			};
			var sandwich = new Sandwich("#sandwich", options);
		}
	}

	// TODO: build this function
	function changeVariationIngredients(_rawVariationId) {
		var _dataSourceId = $("#dataSourceId").val();
		$.ajax({
			url: "/CFACOM/Products/GetProductComponentIngredients/",
			type: "POST",
			data: {
				dataSourceId: _dataSourceId,
				rawVariationId: _rawVariationId
			},
			async: false
		}).done(function (response) {
			$("#product-component-ingredients").html(response);
			SandwichBuilder();
		});
	}

	function changeVariation(link, variation) {
		changeVariationIngredients(variation.id);

		$("#itemPrice").html(parseFloat(variation.Price).toFixed(2));
		if (variation.Price > 0) {
			$("#priceWrapper").removeClass("hidden");
			$("#itemAvailable").addClass("hidden");
			$("#itemAvailable").parent().removeClass("na");
		} else {
			$("#priceWrapper").addClass("hidden");
			$("#itemAvailable").removeClass("hidden");
			$("#itemAvailable").parent().addClass("na");
		}

		if (variation.DesktopImageSrc != null && variation.DesktopImageSrc != "") {
			$("#desktopImage").attr("src", variation.DesktopImageSrc);
			$("#desktopImage").attr("srcset", variation.DesktopImageSrc);
			$("#srcDesktopImage").attr("srcset", variation.DesktopImageSrc);
		}

		if (variation.MobileImageSrc != null && variation.MobileImageSrc != "") {
			//$('#desktopImage').attr('src', variation.MobileImageSrc);
			$("#srcMobileImage").attr("srcset", variation.MobileImageSrc);
		}
		$("#servSizeVariation").removeClass("hidden");
		$("#servSizeVariation").html(variation.ServingSizeVariation);

		$("#ingredients").html(variation.Ingredients);

		$("#allergens").html(variation.Allergens);
		if (variation.HasAllergens == true) {
			$("#allergenWrapper").removeClass("hidden");
		} else {
			$("#allergenWrapper").addClass("hidden");
		}

		if (variation.HasNutritionInfo == true) {
			$("#totalCalories").html(variation.TotalCalories);
			$("#totalCalories2").html(variation.TotalCalories);

			if (variation.HasTotalFatAmount == true) {
				$("#totalFatAmount").html(variation.TotalFatAmount);
				$("#totalFatAmount").parent().removeClass("hidden");
				$("#totalFatAmount2").html(variation.TotalFatAmount);
				$("#totalFatAmount2").parent().removeClass("hidden");
			} else {
				$("#totalFatAmount").parent().addClass("hidden");
				$("#totalFatAmount2").parent().addClass("hidden");
			}

			if (variation.HasFiberAmount == true) {
				$("#fiberAmount").html(variation.FiberAmount);
				$("#fiberAmount").parent().removeClass("hidden");
				$("#fiberAmount2").html(variation.FiberAmount);
				$("#fiberAmount2").parent().removeClass("hidden");
			} else {
				$("#fiberAmount").parent().addClass("hidden");
				$("#fiberAmount2").parent().addClass("hidden");
			}

			if (variation.HasSugarAmount == true) {
				$("#sugarAmount").html(variation.SugarAmount);
				$("#sugarAmount").parent().removeClass("hidden");
				$("#sugarAmount2").html(variation.SugarAmount);
				$("#sugarAmount2").parent().removeClass("hidden");
			} else {
				$("#sugarAmount").parent().addClass("hidden");
				$("#sugarAmount2").parent().addClass("hidden");
			}

			if (variation.HasProteinAmount == true) {
				$("#proteinAmount").html(variation.ProteinAmount);
				$("#proteinAmount").parent().removeClass("hidden");
				$("#proteinAmount2").html(variation.ProteinAmount);
				$("#proteinAmount2").parent().removeClass("hidden");
			} else {
				$("#proteinAmount").parent().addClass("hidden");
				$("#proteinAmount2").parent().addClass("hidden");
			}

			if (variation.HasSaturatedFatAmount == true) {
				$("#transFatAmount").html(variation.SaturatedFatAmount);
				$("#transFatAmount").parent().removeClass("hidden");
				$("#transFatAmount2").html(variation.SaturatedFatAmount);
				$("#transFatAmount2").parent().removeClass("hidden");
			} else {
				$("#transFatAmount").parent().addClass("hidden");
				$("#transFatAmount2").parent().addClass("hidden");
			}

			if (variation.HasTransFatAmount == true) {
				$("#transFatAmount").html(variation.TransFatAmount);
				$("#transFatAmount").parent().removeClass("hidden");
				$("#transFatAmount2").html(variation.TransFatAmount);
				$("#transFatAmount2").parent().removeClass("hidden");
			} else {
				$("#transFatAmount").parent().addClass("hidden");
				$("#transFatAmount2").parent().addClass("hidden");
			}

			if (variation.HasCholesterolAmount == true) {
				$("#cholesterolAmount").html(variation.CholesterolAmount);
				$("#cholesterolAmount").parent().removeClass("hidden");
				$("#cholesterolAmount2").html(variation.CholesterolAmount);
				$("#cholesterolAmount2").parent().removeClass("hidden");
			} else {
				$("#cholesterolAmount").parent().addClass("hidden");
				$("#cholesterolAmount2").parent().addClass("hidden");
			}

			if (variation.HasSodiumAmount == true) {
				$("#sodiumAmount").html(variation.SodiumAmount);
				$("#sodiumAmount").parent().removeClass("hidden");
				$("#sodiumAmount2").html(variation.SodiumAmount);
				$("#sodiumAmount2").parent().removeClass("hidden");
			} else {
				$("#sodiumAmount").parent().addClass("hidden");
				$("#sodiumAmount2").parent().addClass("hidden");
			}

			if (variation.HasCarbohydratesAmount == true) {
				$("#carbohydratesAmount").html(variation.CarbohydratesAmount);
				$("#carbohydratesAmount").parent().removeClass("hidden");
				$("#carbohydratesAmount2").html(variation.CarbohydratesAmount);
				$("#carbohydratesAmount2").parent().removeClass("hidden");
			} else {
				$("#carbohydratesAmount").parent().addClass("hidden");
				$("#carbohydratesAmount2").parent().addClass("hidden");
			}

			if (variation.HasIronDailyValue == true) {
				$("#ironDailyValue").html(variation.IronDailyValue);
				$("#ironDailyValue").parent().removeClass("hidden");
				$("#ironDailyValue2").html(variation.IronDailyValue);
				$("#ironDailyValue2").parent().removeClass("hidden");
			} else {
				$("#ironDailyValue").parent().addClass("hidden");
				$("#ironDailyValue2").parent().addClass("hidden");
			}

			if (variation.HasCalciumDailyValue == true) {
				$("#calciumDailyValue").html(variation.CalciumDailyValue);
				$("#calciumDailyValue").parent().removeClass("hidden");
				$("#calciumDailyValue2").html(variation.CalciumDailyValue);
				$("#calciumDailyValue2").parent().removeClass("hidden");
			} else {
				$("#calciumDailyValue").parent().addClass("hidden");
				$("#calciumDailyValue2").parent().addClass("hidden");
			}

			if (variation.HasVitaminADailyValue == true) {
				$("#vitaminADailyValue").html(variation.VitaminADailyValue);
				$("#vitaminADailyValue").parent().removeClass("hidden");
				$("#vitaminADailyValue2").html(variation.VitaminADailyValue);
				$("#vitaminADailyValue2").parent().removeClass("hidden");
			} else {
				$("#vitaminADailyValue").parent().addClass("hidden");
				$("#vitaminADailyValue2").parent().addClass("hidden");
			}

			if (variation.HasVitaminCDailyValue == true) {
				$("#vitaminCDailyValue").html(variation.VitaminCDailyValue);
				$("#vitaminCDailyValue").parent().removeClass("hidden");
				$("#vitaminCDailyValue2").html(variation.VitaminCDailyValue);
				$("#vitaminCDailyValue2").parent().removeClass("hidden");
			} else {
				$("#vitaminCDailyValue").parent().addClass("hidden");
				$("#vitaminCDailyValue2").parent().addClass("hidden");
			}

			$("#nutritionWrapper").removeClass("hidden");
			$("#nutritionWrapper2").removeClass("hidden");
		} else {
			$("#nutritionWrapper").addClass("hidden");
			$("#nutritionWrapper2").addClass("hidden");
		}

		var list = $(link).closest(".selectlist");
		$(list).find(".label").html(variation.name);

		$(list).find(".variationSelection").removeClass("hidden");
		$(link).addClass("hidden");
	}

	$(".item-details a.variationSelection").click(function (event) {
		event.preventDefault();

		var link = $(this);
		var variation = $(this).attr("href");
		if (typeof variations != "undefined" && variations != null) {
			$.each(variations, function (i, v) {
				if (v.id == variation || v.FriendlyName == variation) {
					var currentUrl = window.location.href.split(/[?#]/)[0];
					window.history.replaceState(
						"",
						"",
						currentUrl + "?variation=" + variation
					);
					changeVariation(link, v);
					return;
				}
			});
		}
	});

	// Overlay form
	bindFormSubmit();

	function bindFormSubmit() {
		$(".formOverlay").on("submit", function (e) {
			e.preventDefault();
			var idString = $(this).data("formId");
			var hfEventDataSourceItemId = $("#" + idString)
				.find("#hfEventDataSourceItemId")
				.val();
			var hfEventOrganizer = $("#" + idString)
				.find("#hfEventOrganizer")
				.val();
			var hfEventCity = $("#" + idString)
				.find("#hfEventCity")
				.val();
			var url =
				"/CFACOM/Forms/OverlayForm?dsId=" +
				hfEventDataSourceItemId +
				"&organizer=" +
				hfEventOrganizer +
				"&city=" +
				hfEventCity;

			var self = this;
			if ($(".formOverlay").valid()) {
				$.ajax({
					type: "POST",
					url: url,
					data: $(this).serialize(),
					success: function (result) {
						$("#lb-content").html(result);
						bindFormSubmit();
					},
					error: function (data) {
						bindFormSubmit();
					}
				});
			}
		});
	}

	$(".formOverlay").each(function () {
		$(this).validate({
			rules: {
				FirstName: {
					required: true,
					maxlength: 100
				},
				LastName: {
					required: true,
					maxlength: 100
				},
				Email: {
					required: true,
					email: true,
					maxlength: 100
				}
			},
			highlight: CFACom.Validation.cfaHighlight,
			unhighlight: CFACom.Validation.cfaUnhighlight
		});
	});

	$(".contact-support #PostalCode").change(function () {
		var postalCode = $(this).val();

		if (postalCode != "") {
			$.ajax({
				url: "/CFACOM/Forms/GeocodePostalCode?postalCode=" + postalCode,
				type: "GET"
			}).done(function (jsonResult) {
				if (jsonResult.Country == "US" || jsonResult.Country == "CA") {
					$("#City").val(jsonResult.City);
					$("#City").valid();
					$("#State").val(jsonResult.State);
					$("#State").valid();
				} else {
					$("#City").val("");
					$("#State").val("");
				}
			});
		}
	});

	$(".product-focus").on("click", ".change", function (e) {
		$(".location-search-frame").show();
		$(".current-location, .nav-toggle").hide();
		e.preventDefault();
	});

	// quiz and poll functionality

	// marks the list of poll options, marking them with correct, selected
	// classes to modify their style properties.
	//
	// the _submittingVote takes a boolean value. "true" will make a check to
	// ensure validation. "false" if the style properties need to be set without
	// user selection (i.e. "cookie")
	function TallyPollList(_selector, _submittingVote) {
		if ($(_selector).parents().hasClass("tallied")) {
			return;
		}

		var listOfOptions = $(_selector)
			.prev()
			.children("section.question.poll li");
		var flag = false;
		var count = 0;

		$.each(listOfOptions, function (key, value) {
			if ($(value).attr("class") == "correct") {
				flag = true;
				count++;
			}
		});

		if (_submittingVote) {
			if (flag == false) {
				alert("make a selection");
				return;
			}

			if (count > 1) {
				alert("select only one option");
				return;
			}
		}

		$(_selector).parent().addClass("tallied");
		$(_selector).prev().addClass("tallied");
		listOfOptions.parent().addClass("selected");
	}

	// gets the poll result response from the server and populates the slide down
	function PopulatePollSlideDown(_pollId, _pollOptionId, _selector) {
		var _sitecoreContextItemId = $(_selector)
			.parents(".quiz")
			.children(".sitecoreContextItemId")
			.val();
		$.ajax({
			url: "/CFACOM/PollContent/GetPollResult",
			type: "POST",
			data: {
				pollId: _pollId,
				pollOptionId: _pollOptionId,
				sitecoreContextItemId: _sitecoreContextItemId
			}
		}).done(function (response) {
			$(_selector)
				.parents(".quiz")
				.children(".pollResultPlaceholder")
				.replaceWith(response);

			var slideDownSelector = $(_selector)
				.parents(".quiz")
				.children("section.results");
			slideDownSelector.slideDown();
		});
	}

	// populates the list of poll options from the object returned from the server
	function PopulatePollData(response, _selector) {
		var pollListElements = $(_selector).prev().children();
		for (var i = 0; i < pollListElements.length; i++) {
			var elem = 0;
			var pollListElementId = $(pollListElements[i]).attr("id");

			for (var j = 0; j < response.PollDataResponse.length; j++) {
				if (pollListElementId === response.PollDataResponse[j].PollOptionId) {
					elem = response.PollDataResponse[j].Percentage;
				}
			}

			$(pollListElements[i])
				.children(".poll-stat")
				.text(elem + "%");
			$(pollListElements[i])
				.children(".poll-img")
				.children(".stat")
				.text(elem + "%");
			$(pollListElements[i])
				.children(".poll-text")
				.children(".count")
				.html("<span>" + elem + "%" + "</span>");
			$(pollListElements[i])
				.children(".poll-text")
				.children(".count")
				.width(elem + "%");
		}

		$(_selector)
			.prev()
			.after('<p class="votes">Total Votes: ' + response.Total + "</p>");

		//hide the vote button
		$(_selector).hide();
	}

	// submits the vote to chic-fila and gets the poll result
	function SubmitVote(_pollId, _pollOptionId, _selector) {
		var _contactId = $(_selector).parents(".quiz").children(".contactId").val();
		var _interactionId = $(_selector)
			.parents(".quiz")
			.children(".interactionId")
			.val();

		$.ajax({
			url: "/CFACOM/PollContent/VoteonPoll",
			type: "POST",
			data: {
				pollId: _pollId,
				pollOptionId: _pollOptionId,
				contactId: _contactId,
				interactionId: _interactionId
			}
		}).done(function (response) {
			PopulatePollData(response, _selector);
		});

		PopulatePollSlideDown(_pollId, _pollOptionId, _selector);
	}

	// gets the poll results w/o submitting a vote
	function GetPollVoting(_pollId, _pollOptionId, _selector) {
		$.ajax({
			url: "/CFACOM/PollContent/GetPollData",
			type: "POST",
			data: { pollId: _pollId }
		}).done(function (response) {
			PopulatePollData(response, _selector);
			PopulatePollSlideDown(_pollId, _pollOptionId, _selector);
		});
	}

	// submits the answer to a quiz question and returns the correct answer id,
	// and then renders appropriately based on whether selection was correct
	// or incorrect
	function SubmitQuestionAnswer(
		_quizId,
		_questionId,
		_questionOptionId,
		_selector
	) {
		var _contactId = $(_selector).parents(".quiz").children(".contactId").val();
		var _interactionId = $(_selector)
			.parents(".quiz")
			.children(".interactionId")
			.val();

		$.ajax({
			url: "/CFACOM/QuizContent/SubmitQuizOptionResponse",
			type: "POST",
			data: {
				quizId: _quizId,
				questionId: _questionId,
				questionOptionId: _questionOptionId,
				contactId: _contactId,
				interactionId: _interactionId
			},
			async: false
		}).done(function (response) {
			var selectionId = $(_selector).attr("id");
			var siblingList = $(_selector).siblings();

			if ($(_selector).attr("id") === response.CorrectQuizAnswerId) {
				$(_selector).addClass("correct");
				$(_selector).parent().siblings("div.answer").addClass("correct");

				$.each(siblingList, function (key, value) {
					$(this).addClass("disabled");
				});
			} else {
				$(_selector).addClass("incorrect");
				$(_selector).parent().siblings("div.answer").addClass("incorrect");

				$.each(siblingList, function (key, value) {
					if ($(this).attr("id") === response.CorrectQuizAnswerId) {
						$(this).addClass("correct");
					} else {
						$(this).addClass("disabled");
					}
				});
			}

			$(_selector)
				.parent()
				.siblings("div.answer")
				.children("p")
				.html(response.QuizAnswer);

			$(_selector).parent().siblings("div.answer").slideDown();
		});
	}

	// gets the answer to a quiz question and returns correct answer id, and then
	// renders appropriately based on whether selection was correct or incorrect,
	// used if quiz cookie is present
	function GetQuestionAnswer(
		_quizId,
		_questionId,
		_questionOptionId,
		_selector
	) {
		$.ajax({
			url: "/CFACOM/QuizContent/GetQuizOptionResponse",
			type: "POST",
			data: {
				quizId: _quizId,
				questionId: _questionId,
				questionOptionId: _questionOptionId
			},
			async: false
		}).done(function (response) {
			var selectionId = $(_selector).attr("id");
			var siblingList = $(_selector).siblings();

			if ($(_selector).attr("id") === response.CorrectQuizAnswerId) {
				$(_selector).addClass("correct");
				$(_selector).parent().siblings("div.answer").addClass("correct");

				$.each(siblingList, function (key, value) {
					$(this).addClass("disabled");
				});
			} else {
				$(_selector).addClass("incorrect");
				$(_selector).parent().siblings("div.answer").addClass("incorrect");
				//console.log('selection was incorrect');
				$.each(siblingList, function (key, value) {
					if ($(this).attr("id") === response.CorrectQuizAnswerId) {
						$(this).addClass("correct");
					} else {
						$(this).addClass("disabled");
					}
				});
			}

			$(_selector)
				.parent()
				.siblings("div.answer")
				.children("p")
				.html(response.QuizAnswer);
			$(_selector).parent().siblings("div.answer").slideDown();
		});
	}

	// gets the quiz result
	function GetQuestionResult(
		_quizId,
		_correctAnswers,
		_selectedAnswers,
		_selector
	) {
		var _sitecoreContextItemId = $(_selector)
			.parents(".quiz")
			.children(".sitecoreContextItemId")
			.val();
		$.ajax({
			url: "/CFACOM/QuizContent/GetQuizResult",
			type: "POST",
			data: {
				quizId: _quizId,
				sitecoreContextItemId: _sitecoreContextItemId,
				selectedAnswers: _selectedAnswers,
				score: _correctAnswers
			}
		}).done(function (response) {
			$(".quizResultPlaceholder").replaceWith(response);
			$(_selector).parents(".quiz").children("section.results").slideDown();
		});
	}

	// selects the answer picked by the user from the provided selector, marks as
	// correct or incorrect
	function ToggleQuizOptions(_selector) {
		if ($(_selector).attr("class")) {
			return false;
		} else {
			$(_selector).closest("section.question").addClass("answered");
			$(_selector).addClass("selected");
		}
	}

	// returns a cookie by name
	function ReadCookie(cookieName) {
		var name = cookieName + "=";
		var ca = document.cookie.split(";");

		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	// returns an object from a query parameter string format (which is how Poll
	// cookies are formatted)
	function ConvertCookieValueToObject(cookieValues) {
		var obj = {};
		cookieValues.replace(/([^=&]+)=([^&]*)/g, function (m, key, value) {
			obj[decodeURIComponent(key)] = decodeURIComponent(value);
		});

		return obj;
	}

	// Reads any poll cookies that the user has and locks them out of the poll to
	// prevent them from voting again
	function TallyPollsFromCookies(list) {
		for (var i = 0; i < list.length; i++) {
			var cookieValues = ReadCookie("PollCookie_" + $(list[i]).attr("id"));

			if (cookieValues.length > 0) {
				var obj = ConvertCookieValueToObject(cookieValues);

				var selector = $(list[i])
					.children("section.question.poll")
					.children(".vote");
				$(list[i])
					.find("#" + obj.pollOptionId)
					.addClass("correct");

				GetPollVoting(obj.pollId, obj.pollOptionId, selector);
				TallyPollList(selector, false);
			}
		}
	}

	// Reads any quiz cookies that the user has and locks t hem out of the quiz
	// to prevent them from taking the quiz again
	function TallyQuizFromCookies(list) {
		for (var i = 0; i < list.length; i++) {
			var cookieValues = ReadCookie("QuizCookie_" + $(list[i]).attr("id"));

			if (cookieValues.length > 0) {
				var obj = ConvertCookieValueToObject(cookieValues);

				obj.selectedAnswers = obj.selectedAnswers.split("|");

				var theQuestions = $("#" + obj.quizId).children(
					"section.question"
				).length;

				for (var i = 0; i < obj.selectedAnswers.length; i++) {
					var selectionId = obj.selectedAnswers[i];
					var selector = $("#" + selectionId);
					var questionId = selector.parents("section.question").attr("id");

					ToggleQuizOptions(selector);

					var answeredQuestions = $("section.answered").length;
					var quizId = obj.quizId;

					GetQuestionAnswer(quizId, questionId, selectionId, selector);

					//var correctAnswers = $(selector).parents('.quiz').children('section.question').children('.answer.correct').length;

					if (answeredQuestions === theQuestions) {
						var selectedAnswers = [];

						var correctAnswers = $(selector)
							.parents(".quiz")
							.find("div.answer.correct").length;

						var selectionList = $(selector)
							.parents(".quiz")
							.children("section.question")
							.children("ul")
							.children("li.selected");
						//console.log(selectionList);
						$(selectionList).each(function (index, element) {
							selectedAnswers.push($(this).attr("id"));
						});

						GetQuestionResult(
							quizId,
							correctAnswers,
							selectedAnswers,
							selector
						);
					}
				}
			}
		}
	}

	// event handler to select a poll option
	$("section.question.poll li").click(function () {
		if (!$(this).parents().hasClass("tallied")) {
			$(this).siblings("li").removeClass("correct");
			$(this).addClass("correct");
		}
	});

	// event handler to vote on a poll
	$("section.question.poll .vote").click(function (event) {
		event.preventDefault();

		var selector = this;

		var pollId = $(this).parent().attr("id");
		var selectionId = $(this)
			.prev()
			.children("section.question.poll li.correct")
			.attr("id");

		TallyPollList(selector, true);

		if (
			$(this)
				.parents(".quiz")
				.children("section.question.poll")
				.children("ul")
				.children("li.correct").length > 0
		) {
			SubmitVote(pollId, selectionId, selector, true);
		}
	});

	// event handler to select a quiz option for a question
	$("section.question li").click(function () {
		if ($(this).parents("section.question").hasClass("poll")) {
			return;
		}

		if ($(this).parents("section.question").hasClass("answered")) {
			return;
		}

		var theQuestions = $(this)
			.parents(".quiz")
			.children("section.question").length;

		ToggleQuizOptions($(this));

		var quizId = $(this).parents(".quiz").attr("id");
		var questionId = $(this).parents(".question").attr("id");
		var selectionId = $(this).attr("id");
		var selector = this;

		SubmitQuestionAnswer(quizId, questionId, selectionId, selector);

		var answeredQuestions = $("section.answered").length;
		var correctAnswers = $(this)
			.parents(".quiz")
			.find("div.answer.correct").length;
		//console.log(correctAnswers);

		if (answeredQuestions === theQuestions) {
			var selectedAnswers = [];

			var selectionList = $(this)
				.parents(".quiz")
				.children("section.question")
				.children("ul")
				.children("li.selected");
			$(selectionList).each(function (index, element) {
				selectedAnswers.push($(this).attr("id"));
			});

			GetQuestionResult(quizId, correctAnswers, selectedAnswers, this);
		}
	});

	// event handler for poll and quiz facebook share
	$("body").on("click", ".facebook", function (event) {
		event.preventDefault();
		window.open(
			$(this).attr("href"),
			"fbShareWindow",
			"height=450, width=550, top=" +
				($(window).height() / 2 - 275) +
				", left=" +
				($(window).width() / 2 - 225) +
				", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
		);
		return false;
	});

	// event handler for poll and quiz twitter share
	$("body").on("click", ".twitter", function (event) {
		event.preventDefault();
		window.open(
			$(this).attr("href"),
			"fbShareWindow",
			"height=450, width=550, top=" +
				($(window).height() / 2 - 275) +
				", left=" +
				($(window).width() / 2 - 225) +
				", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
		);
		return false;
	});

	// disable onclick functions for quiz and poll while in the Experience Editor
	if (expEditor) {
		$("section.question.poll li").off();
		$("section.question li").off();
	}

	// find any quiz or poll cookies and lock out appropriate quizzes and polls
	var quizPollList = $.find(".quiz");
	TallyPollsFromCookies(quizPollList);
	TallyQuizFromCookies(quizPollList);

	//update search result numbers
	if ($(".returned-results").length) {
		$(".has-results").show();
		var searchSum = 0;

		$(".searchresults-counter").each(function () {
			searchSum += parseFloat($(this).val());
		});

		if (searchSum > 0) {
			$(".searchresults-total").html(searchSum);
		}
	}

	// Datepicker Last Time of Visit
	$(".datepicker-last-time-of-visit").datepicker({
		changeMonth: true,
		changeYear: true,
		minDate: -365,
		maxDate: 0
	});

	// story video
	$(function () {
		$(".youtube-video").each(function () {
			var $this = $(this);
			var overlay = $("<div class='youtube-video-overlay'/>");
			$this.append(overlay);
		});
		$(".story-video").each(function () {
			var $this = $(this);
			var figure = $this.parents("figure").first();
			figure.addClass("youtube-video");
			var overlay = $("<div class='youtube-video-overlay'/>");
			figure.append(overlay);

			var img = figure.find("img");

			var play = function (e) {
				e.preventDefault();
				$.magnificPopup.open(
					{
						items: {
							src: $this.attr("href")
						},
						type: "iframe"
					},
					0
				);
			};
			overlay.click(play);
			img.click(play);
		});
	});

	// story image carousel
	$(function () {
		$(".story-image-carousel").each(function () {
			var $this = $(this);
			var $pageInfo = $this.find(".page-info");
			var total = $this.children().length;

			$this.on(
				"init reInit afterChange",
				function (e, slick, currentSlide, nextSlide) {
					var i = (currentSlide ? currentSlide : 0) + 1;
					$pageInfo.text(i + " of " + total);
				}
			);

			$this.slick({
				infinite: true,
				adaptiveHeight: false,
				dots: false
			});
		});
	});

	// online order buttons
	$(window).on("load", function () {
		var userAgentString = navigator.userAgent;

		if ($("#oloorderlbid .app").length) {
			var link = $("#oloorderlbid .app");

			if (
				userAgentString.indexOf("iPhone") > -1 ||
				userAgentString.indexOf("iPod") > -1 ||
				userAgentString.indexOf("iPad") > -1
			) {
				link.attr("href", $("#oloorderlbid .apple").attr("href"));
			} else if (/Android/.test(userAgentString)) {
				link.attr("href", $("#oloorderlbid .google").attr("href"));
			}
		}
		if ($(".phone-home").length) {
			var phoneHomeLink = $(".phone-home");

			if (
				userAgentString.indexOf("iPhone") > -1 ||
				userAgentString.indexOf("iPod") > -1 ||
				userAgentString.indexOf("iPad") > -1
			) {
				phoneHomeLink.attr("href", phoneHomeLink.attr("data-iphone"));
			} else if (/Android/.test(userAgentString)) {
				phoneHomeLink.attr("href", phoneHomeLink.attr("data-android"));
			} else {
				phoneHomeLink.remove();
			}
		}
	});

	$("body").on("click touchstart", ".btn-order-delivery", function () {
		var isIphone = $("body").hasClass("iphone");
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {
				src: $(this).attr("href"),
				type: "inline"
			},
			closeOnBgClick: false,
			callbacks: {
				open: function () {
					// prevent overflow and scrolling
					// add default no-scroll class
					$("html, body").addClass("no-scroll-default");
					// add ios no-scroll class (bugfix)
					if (isIphone) $("html, body").addClass("no-scroll-ios");
				},
				close: function () {
					// remove default no-scroll class
					$("html, body").removeClass("no-scroll-default");
					// remove ios no-scroll class (bugfix)
					if (isIphone) $("html, body").removeClass("no-scroll-ios");
				}
			}
		});
	});

	$("body").on("click", ".btn-order-pickup", function () {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {
				src: $(this).attr("href"),
				type: "inline"
			}
		});
	});

	$("body").on("click", ".btn-keep-location-pickup", function () {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {
				src: $(this).attr("href"),
				type: "inline"
			},
			closeOnContentClick: false,
			closeOnBgClick: false,
			showCloseBtn: false,
			enableEscapeKey: false
		});
	});

	$("body").on("click", ".btn-keep-location-delivery", function () {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {
				src: $(this).attr("href"),
				type: "inline"
			},
			closeOnContentClick: false,
			closeOnBgClick: false,
			showCloseBtn: false,
			enableEscapeKey: false
		});
	});

	$("body").on("click", ".btn-confirm-exit", function () {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {
				src: $(this).attr("href"),
				type: "inline"
			}
		});
	});

	$("body").on("click", ".keep-selected__ctas", function (e) {
		var target = $(e.target);
		if (target.hasClass("btn-order-pickup")) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "findadifferentrestaurant"
			});
		} else if (target.hasClass("btn-keep-order-delivery-submit")) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "findadifferentrestaurantDelivery"
			});
		} else if (target.hasClass("keep-current-pickup")) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "usethisrestaurant"
			});
		} else if (target.hasClass("keep-current-delivery")) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "usethisrestaurantDelivery"
			});
		}
	});

	$("body").on(
		"click",
		"#keep-selected-restaurant-pickup .mfp-close",
		function (e) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "closelightbox"
			});
		}
	);

	$("body").on("click", "#olodeliverylbid .btn", function (e) {
		e.preventDefault();

		if ($("#olodeliverylbid .btn").hasClass("disabled")) {
			return false;
		}

		$(".delivery-error").remove();
		$("#olodeliverylbid .btn").addClass("disabled");

		$.ajax({
			url: "/OnlineOrdering/OloLocation/Delivery",
			type: "POST",
			data: {
				Address: $("#olodeliverylbid #Address").val(),
				Address2: $("#olodeliverylbid #Address2").val(),
				City: $("#olodeliverylbid #City").val(),
				State: $("#olodeliverylbid #State").val(),
				Zip: $("#olodeliverylbid #Zip").val()
			},
			success: function (result) {
				if (result.formError) {
					$("#olodeliverylbid .btn").removeClass("disabled");
					$("#catering-delivery-form").html(result.message);
				} else {
					deliveryAddressInBrandify();
				}
			},
			error: function (result) {
				var errorValue =
					"<p class='delivery-error'>" +
					$("#delivery-order-form").attr("data-msg") +
					"</p>";
				$("#delivery-order-form button").before(errorValue);
				$("#olodeliverylbid .btn").removeClass("disabled");
			}
		});
	});

	$("body").on("keyup paste change", "#olopickuplbid #address", function () {
		if ($("#olopickuplbid #address").val()) {
			$("#olopickuplbid .btn").removeClass("disabled");
		} else {
			$("#olopickuplbid .btn").addClass("disabled");
		}
	});

	$("body").on("click", "#olopickuplbid .btn", function (e) {
		e.preventDefault();
		if (!$("#olopickuplbid .btn").hasClass("disabled")) {
			$("#olopickuplbid .userform").submit();
		}
	});

	function deliveryAddressInBrandify() {
		$(".delivery-error").remove();
		var controller = "/OnlineOrdering/OloLocation/GeocodeDeliveryAddress";

		var address =
			$("#olodeliverylbid #Address").val() +
			" " +
			$("#olodeliverylbid #Address2").val() +
			" " +
			$("#olodeliverylbid #City").val() +
			", " +
			$("#olodeliverylbid #State").val() +
			" " +
			$("#olodeliverylbid #Zip").val();

		$.ajax({
			type: "POST",
			url: controller,
			data: {
				Address: address
			},
			success: function (result) {
				if (result.Success) {
					$("#delivery-order-form").submit();
				} else if (result.HtmlResult === "") {
					var errorValue =
						"<p class='delivery-error'>Sorry, but we are experiencing technical difficulties at this time.</p>";
					$("#delivery-order-form button").before(errorValue);
				} else {
					$("#brandify-overlay").html(result.HtmlResult);
					$.magnificPopup.close();
					$.magnificPopup.open({
						items: {
							src: "#brandify-overlay",
							type: "inline"
						}
					});
				}
			},
			error: function (jqXHR, exception) {
				var errorValue =
					"<p class='delivery-error'>" +
					$("#delivery-order-form").attr("data-msg") +
					"</p>";
				$("#delivery-order-form button").before(errorValue);
			}
		});
	}

	$("body").on("change", "input[name=addressSelect]", function () {
		var selected = $("input[name=addressSelect]:checked");

		$("#olodeliverylbid #Address").val(selected.attr("data-address"));
		$("#olodeliverylbid #Address2").val(selected.attr("data-address2"));
		$("#olodeliverylbid #City").val(selected.attr("data-city"));
		$("#olodeliverylbid #State").val(selected.attr("data-state"));
		$("#olodeliverylbid #Zip").val(selected.attr("data-zip"));

		$(".use-brandify-address").removeClass("disabled");
	});

	$("body").on("click", ".use-brandify-address", function (e) {
		e.preventDefault();

		if ($(this).hasClass("disabled")) {
			return;
		}

		$("#delivery-order-form").submit();
	});

	$("body").on("click", ".btn-keep-order-delivery-submit", function (e) {
		e.preventDefault();
		$("#keep-delivery-order-form").submit();
	});

	$("body").on(
		"click",
		"#brandify-addresses-form .btn-order-delivery",
		function () {
			$("#delivery-order-form .btn").removeClass("disabled");
		}
	);

	$("body").on("click", ".close-overlay", function (e) {
		e.preventDefault();
		$.magnificPopup.close();
	});

	$("body").on("click touchstart", ".delivery-close", function () {
		if ($("body").hasClass("iphone") || $("body").hasClass("android")) {
			$("body").css("overflowY", "auto");
		}
	});

	if ($(".delivery-subscription-urlparam").attr("href") != null) {
		$(".delivery-subscription-urlparam").attr(
			"href",
			$(".delivery-subscription-urlparam").attr("href").split("?")[0] +
				window.location.search
		);
	}

	$(".btn-cp-cdismiss").on("click", function () {
		$.ajax({
			url: "/cfacom/profilecalloutaction/dismisscallout",
			type: "post",
			cache: false,
			dataType: "json",
			success: function () {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: "engagement",
					category: "calloutDismiss"
				});
				$(".login-popout").css("display", "none");
			},
			error: function () {}
		});
	});

	$(".btn-cp-confirm").on("click", function () {
		$.ajax({
			url: "/cfacom/profilecalloutaction/confirmcallout",
			type: "post",
			cache: false,
			dataType: "json",
			success: function (msg) {
				window.dataLayer.push({
					event: "engagement",
					category: "calloutSeewhatsnew"
				});

				$(".login-popout").css("display", "none");
				var btnData = $(".btn-cp-confirm").data();
				window.location = btnData.confirmUrl;
			},
			error: function () {}
		});
	});

	// individual delivery form
	$("#individual-delivery-form").validate({
		rules: {
			dfAddress: {
				required: true,
				maxlength: 40
			},
			dfAddress2: {
				required: false,
				maxlength: 40
			},
			dfCity: {
				required: true,
				maxlength: 100
			},
			dfState: {
				required: true
			},
			dfZip: {
				required: true,
				minlength: 5,
				maxlength: 5,
				digits: true
			}
		},
		errorElement: "p",
		errorPlacement: individualDeliveryErrorPlacement,
		success: individualDeliverySuccess
	});

	function individualDeliveryErrorPlacement(error, element) {
		error.insertAfter(element);
		$(element).closest(".cp-form__group").addClass("cp-form__group--error");
	}

	function individualDeliverySuccess(label, element) {
		if (!$(element).hasClass("error")) {
			if (
				$(element).closest(".cp-form__group").hasClass("cp-form__group--error")
			) {
				$(element).siblings("p.error:first").remove();
			}
			$(element)
				.closest(".cp-form__group")
				.removeClass("cp-form__group--error");
		}
	}

	$("#individual-delivery-form").on(
		"blur keyup change paste",
		"input, select",
		function (event) {
			validateIndividualDeliveryForm("#individual-delivery-form");
		}
	);

	function validateIndividualDeliveryForm(id) {
		var valid = $(id).validate().checkForm();
		if (valid) {
			$("#individual-delivery-submit").prop("disabled", false);
			$("#individual-delivery-submit").removeClass("disable");
		} else {
			$("#individual-delivery-submit").prop("disabled", "disabled");
			$("#individual-delivery-submit").addClass("disable");
		}
	}

	$("#individual-delivery-submit").on("click", function () {
		if ($(this).prop("disabled")) {
			return;
		}
		$("#individual-delivery-submit").prop("disabled", "disabled");
		$("#individual-delivery-submit").addClass("disable");

		$.ajax({
			type: "POST",
			url: "/CFACOM/Delivery/SubmitDeliveryAddress",
			data: {
				Address: $("#individual-delivery-form #dfAddress").val(),
				Address2: $("#individual-delivery-form #dfAddress2").val(),
				City: $("#individual-delivery-form #dfCity").val(),
				State: $("#individual-delivery-form #dfState").val(),
				Zip: $("#individual-delivery-form #dfZip").val(),
				Datasource: $("#DeliveryFormDatasource").val(),
				Goal: $("#deliveryFormGoal").val()
			},
			success: function (result) {
				$("#delivery-response").remove();
				$(".address-entry").hide();
				$(".msg.wrapper").hide();
				$(".address-entry").after(result.AddressHtml);

				$("html, body").animate(
					{ scrollTop: $("#delivery-response").offset().top + -80 },
					500
				);

				$("#individual-delivery-submit").prop("disabled", "");
				$("#individual-delivery-submit").removeClass("disable");
			},
			error: function (jqXHR, exception) {
				$("#individual-delivery-submit").prop("disabled", "");
				$("#individual-delivery-submit").removeClass("disable");
			}
		});
	});

	$("body").on("click", ".delivery-try-again", function (e) {
		e.preventDefault();
		$("#delivery-response").remove();
		$(".msg.wrapper").show();
		$(".address-entry").show();
		$("html, body").animate(
			{ scrollTop: $(".address-entry").offset().top + -80 },
			500
		);
		$("#dfAddress").focus();
	});

	$("body").on("click", ".df-download-app", function (e) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "DeliveryDownloadApp",
			zipcode: $("#individual-delivery-form #dfZip").val(),
			restaurant: $(this).parents(".location-module").find("h4").text()
		});
	});
	// end individual delivery form

	// login iframe POC
	$("#showPocLoginIframe").on("click", function () {
		var redirectUrl = "/";

		if ($("#iframePocRedirectUrl").val()) {
			redirectUrl = $("#iframePocRedirectUrl").val();
		}

		$("#login-iframe").attr(
			"src",
			"/Account/Login?iframe=true&returnUrl=" + redirectUrl + "?iframe=1"
		);

		$.magnificPopup.open({
			items: {
				src: "#loginIframePocLightbox",
				type: "inline"
			}
		});
	});
});
