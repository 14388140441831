import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { animationDebounce } from "../../util/AnimationDebounce";

enum BreadcrumbsNavState {
	unknown = 0,

	mobile = 1,
	desktop = 2,
	notSticky = 4,
	sticky = 8,

	mobileNotSticky = 5,
	mobileSticky = 9,
	desktopNotSticky = 6,
	desktopSticky = 10
}

export class BreadcrumbsNav extends Component {
	private _state: BreadcrumbsNavState = BreadcrumbsNavState.unknown;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.state =
			window.innerWidth >= _app.layout.desktopWidth
				? BreadcrumbsNavState.desktopNotSticky
				: BreadcrumbsNavState.mobileNotSticky;

		this.addResizeEvent();
		this.addStickyScrollEvent();
	}

	private get state(): BreadcrumbsNavState {
		return this._state;
	}

	private get cta(): HTMLElement | null {
		return this._element.querySelector("[data-element='cta']");
	}

	// justification: state signatures cannot be adjacent here
	// tslint:disable-next-line: adjacent-overload-signatures
	private set state(value: BreadcrumbsNavState) {
		if (value === this.state) {
			return;
		}

		this._element.classList.remove(
			"mobileNotSticky",
			"mobileSticky",
			"desktopNotSticky",
			"desktopSticky"
		);

		switch (value) {
			case BreadcrumbsNavState.mobileNotSticky:
				this._element.classList.add("mobileNotSticky");
				break;

			case BreadcrumbsNavState.mobileSticky:
				this._element.classList.add("mobileSticky");
				break;

			case BreadcrumbsNavState.desktopNotSticky:
				this._element.classList.add("desktopNotSticky");
				break;

			case BreadcrumbsNavState.desktopSticky:
				this._element.classList.add("desktopSticky");
				break;

			default:
				throw new Error(`Invalid state: ${value}; current: ${this.state}`);
		}

		this._state = value;
	}

	private addResizeEvent(): void {
		window.addEventListener(
			"resize",
			animationDebounce(
				() => window.innerWidth,
				width => this.transitionOnResize(width)
			)
		);
	}

	private addStickyScrollEvent(): void {
		window.addEventListener(
			"scroll",
			animationDebounce(
				() => window.scrollY,
				scrollY => this.transitionOnScroll(scrollY)
			)
		);
	}

	private transitionOnResize(width: number): void {
		// chng to desktop from mobile
		switch (this.state) {
			case BreadcrumbsNavState.mobileNotSticky:
			case BreadcrumbsNavState.mobileSticky:
				if (width > this._app.layout.desktopWidth) {
					this.state =
						this.state -
						BreadcrumbsNavState.mobile +
						BreadcrumbsNavState.desktop;
				} else {
					return;
				}
				break;

			case BreadcrumbsNavState.desktopNotSticky:
			case BreadcrumbsNavState.desktopSticky:
				if (width <= this._app.layout.desktopWidth) {
					this.state =
						this.state -
						BreadcrumbsNavState.desktop +
						BreadcrumbsNavState.mobile;
				} else {
					return;
				}
				break;
		}
	}

	private transitionOnScroll(scrollY: number): void {
		if (!this.cta) {
			return;
		}

		if (scrollY === 0) {
			this.state =
				window.innerWidth >= this._app.layout.desktopWidth
					? BreadcrumbsNavState.desktopNotSticky
					: BreadcrumbsNavState.mobileNotSticky;
		} else {
			this.state =
				window.innerWidth < this._app.layout.desktopWidth
					? BreadcrumbsNavState.mobileSticky
					: BreadcrumbsNavState.desktopSticky;
		}
	}
}

ComponentFactory.registerComponent("breadcrumbsNav", BreadcrumbsNav);
