import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class SearchForm extends Component {
	private readonly _inputField: HTMLInputElement | null = null;
	private readonly form: HTMLFormElement | null = null;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this._inputField =
			this._element.querySelector<HTMLInputElement>(".search-input");

		this.form = this._element as HTMLFormElement;
		this.addEvents();
	}

	private addEvents(): void {
		const form = this.form;

		if (form) {
			form.noValidate = true;
			form.addEventListener("submit", e => this.handleSubmit(e));

			this._inputField?.addEventListener("focus", () =>
				form.classList.remove("error-state")
			);
		}
	}

	private handleSubmit(e: Event): void {
		const form = this.form;

		if (form) {
			if (form.checkValidity()) {
				(dataLayer || []).push({
					category: "DeliverySearchSubmission",
					event: "engagement"
				});
			} else {
				e.preventDefault();
				form.classList.add("error-state");
			}
		}
	}
}

ComponentFactory.registerComponent("searchForm", SearchForm);
