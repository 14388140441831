import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { NavToggle } from "./NavToggle";

interface IAutocompleteResponse {
	readonly label?: string;
	readonly value?: string;
}

export class MainNav extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.applyLowercaseBehavior();
		this.initAutocompletes();

		this._element.addEventListener(NavToggle.ShowEvent, () =>
			this.handleNavToggleShow()
		);

		this._element.addEventListener(NavToggle.HideEvent, () =>
			this.handleNavToggleHide()
		);
	}

	private applyLowercaseBehavior(): void {
		// convert to lowercase (bugfix for response case sensitive data)
		this._element
			.querySelectorAll<HTMLInputElement>("input[data-behavior='lowercase']")
			.forEach(el =>
				el.addEventListener("input", () => (el.value = el.value.toLowerCase()))
			);
	}

	private initAutocompletes(): void {
		this._element
			.querySelectorAll<HTMLInputElement>("input[data-autocomplete]")
			.forEach(el => {
				const source = el.dataset.autocomplete;

				if (source) {
					$(el).autocomplete({
						appendTo: this._element,
						minLength: 2,
						source,
						select(evt: Event, result: JQueryUI.AutocompleteUIParams): void {
							const item = result.item as IAutocompleteResponse;
							const searchTerm = item?.label;

							if (searchTerm) {
								el.value = searchTerm;
							}
						}
					});
				}
			});
	}

	private handleNavToggleShow(): void {
		this._element.querySelectorAll<HTMLElement>(".main-nav").forEach(el => {
			el.style.display = "block";
			el.setAttribute("aria-hidden", "false");
		});
	}

	private handleNavToggleHide(): void {
		this._element.querySelectorAll<HTMLElement>(".main-nav").forEach(el => {
			el.style.display = "none";
			el.setAttribute("aria-hidden", "true");
		});

		this._element
			.querySelectorAll<HTMLElement>(".main-nav > ul")
			.forEach(el => {
				el.querySelectorAll("li").forEach(li => li.classList.remove("active"));

				el.querySelectorAll(".icon-arrow-down").forEach(i =>
					i.setAttribute("aria-expanded", "false")
				);
			});
	}
}

ComponentFactory.registerComponent("MainNav", MainNav);
