// tslint:disable-next-line: max-line-length
// Idea taken from following article: https://blog.logrocket.com/write-declarative-javascript-promise-wrapper/

export const API = {
	async postReq(
		endpoint: string,
		payload: {},
		authorization?: string
		// tslint:disable-next-line: no-any
	): Promise<[{} | null, any | null]> {
		let options = {
			contentType: "application/json",
			data: JSON.stringify(payload),
			headers: {},
			type: "POST",
			url: endpoint
		};

		if (authorization) {
			options = { ...options, headers: { Authorization: authorization } };
		}
		try {
			const promise = $.ajax(options);
			const result = await Promise.resolve(promise);

			return [result, null];
		} catch (error) {
			return [null, error];
		}
	},
	async getReq(
		endpoint: string,
		payload: {},
		authorization?: string
		// tslint:disable-next-line: no-any
	): Promise<[{} | null, any | null]> {
		let options = {
			contentType: "application/json",
			data: JSON.stringify(payload),
			headers: {},
			type: "GET",
			url: endpoint
		};

		if (authorization) {
			options = { ...options, headers: { Authorization: authorization } };
		}
		try {
			const promise = $.ajax(options);
			const result = await Promise.resolve(promise);

			return [result, null];
		} catch (error) {
			return [null, error];
		}
	}
};
