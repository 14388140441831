import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class CopyToClipboard extends Component {
	private readonly _inputField: HTMLInputElement | null = null;
	private readonly _buttonElem: HTMLButtonElement | null = null;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this._inputField = this._element.querySelector("input");
		this._buttonElem = this._element.querySelector("button");
		this.addCopyTextEvent();
	}

	private addCopyTextEvent(): void {
		this._buttonElem?.addEventListener("click", e => {
			e.preventDefault();
			this.copyText();
			this.toggleText();
		});
	}

	private copyText(): Boolean {
		this._inputField?.select();

		return document.execCommand("copy");
	}

	private toggleText(): String | undefined {
		const textElem = this._buttonElem?.querySelector<HTMLElement>("span");
		if (textElem) {
			return (textElem.innerHTML = "Copied!");
		}
	}
}

ComponentFactory.registerComponent("copyToClipboard", CopyToClipboard);
