import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class InfoTiles extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.addChildCount();
	}

	private addChildCount() {
		const count = this._element.children.length;
		this._element.dataset.childCount = count.toString();
	}
}

ComponentFactory.registerComponent("InfoTiles", InfoTiles);
