import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import scrollTo from "../../util/ScrollTo";

export class AnchorLink extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.handleClickEvt();
	}

	private handleClickEvt(): void {
		const target = this._element.dataset.target;
		if (!target) {
			console.warn("AnchorLink: No target specified");
			return;
		}

		// find elem by id or class name
		const targetElem = document.querySelector(target) as HTMLElement;
		if (!targetElem) {
			console.warn(`AnchorLink: No target element found for ${target}`);
			return;
		}
		this._element.addEventListener("click", (e: Event) => {
			scrollTo(targetElem);
		});
	}
}

ComponentFactory.registerComponent("anchorLink", AnchorLink);
