import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { GiftCardService } from "./GiftCardService";

export class LittleThingsForm extends Component {
	form: HTMLFormElement;
	containerLittleThings: HTMLFormElement;
	formLittleThingsSuccess: HTMLFormElement;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.form = this._element as HTMLFormElement;
		this.form.addEventListener("submit", e => this.handleSubmission(e));
		this.containerLittleThings = document.querySelector('#containerLittleThings') as HTMLFormElement;
		this.formLittleThingsSuccess = document.querySelector('#formLittleThingsSuccess') as HTMLFormElement;
	}

	private async handleSubmission(e: Event): Promise<void> {
		e.preventDefault();
		this.toggleSubmitBtn(false);
		await this.submitForm();
	}

	private async submitForm(): Promise<void | null> {
		const {
			submissionEndpoint = "",
			formDatasourceId = ""
		} = this._element.dataset;

		let formData = new FormData(this.form);
		formData.append("formDatasourceId", formDatasourceId);
		const recaptchaVal = this.form["g-recaptcha-response"]?.value;

		if (!recaptchaVal) {
			console.warn(`No recaptcha value found in payload`);
			this.toggleFieldErr("recaptchaField", true);
			this.toggleSubmitBtn(true);

			return null;
		}

		const [result, err] = await GiftCardService.sendForm(
			submissionEndpoint,
			formData
		);

		if(result && result.errors){
			Object.keys(result.errors).forEach(key => {
				console.warn(`${key}, ${result.errors[key]}`);
				this.toggleFieldErr(key, true);
			});

			return;
		}

		if (result && result.message) {
			const messageEl = this.formLittleThingsSuccess.querySelector(".g-inline-form__success-msg");
			messageEl?.innerHTML = result.message;
		}

		this.containerLittleThings?.classList.add("hidden");
		this.formLittleThingsSuccess?.classList.remove("hidden");
	}

	private toggleFieldErr(fieldName: string, toggleOn: boolean): void {
		const field = this._element.querySelector(`[data-element='${fieldName}']`);

		if (!field) {
			console.warn(`No field ${fieldName} found. Check DOM.`);
			return;
		}

		toggleOn
			? field.classList.add("display")
			: field.classList.remove("display");
	}

	private toggleSubmitBtn(toggleOn: boolean): void {
		const submitBtn = this._element.querySelector("button[type='submit']");
		const disabledClass = "disabled";

		if (!submitBtn) {
			throw new Error(`Submit button not found for ${submitBtn}`);
		}

		toggleOn
			? submitBtn.classList.remove(disabledClass)
			: submitBtn.classList.add(disabledClass);
	}
}

ComponentFactory.registerComponent("LittleThingsForm", LittleThingsForm);
