/* global LLL:writable */

$(function () {
	var lllTemplate = $(".lll");
	if (lllTemplate !== null) {
		LLL.buttonSelectToggle();
		LLL.tabButtonToggle();
		LLL.textAreaCount();
		LLL.resultsClick();
	}

	LLL.endFadingAnim();
});

var LLL = {
	clickToToggle: function (elem) {
		elem.closest("div").toggleClass("active");
	},

	buttonSelectToggle: function () {
		[].slice
			.call(document.querySelectorAll(".lll-cards__toggle button"))
			.map(function (element) {
				element.addEventListener("click", LLL.clickToSelect(element));
			});
	},

	clickToSelect: function (elem) {
		var toggleContainer = elem;
		var toggleActive = toggleContainer.classList.contains("active");

		if (toggleActive) {
			toggleContainer.classList.remove("active");
		} else {
			toggleContainer.classList.add("active");
		}
	},

	endFadingAnim: function () {
		$(".spinner-container").css("margin-bottom", 0);

		if ($(".lll-cards-animation").length) {
			$(".lll-cards-animation").animate(
				{ top: 0, opacity: 1 },
				"slow",
				function () {
					$(".spinner-container").removeClass("cfa-spinner--visible");
				}
			);
		} else {
			$(".spinner-container").removeClass("cfa-spinner--visible");
		}
	},

	tabButtonToggle: function () {
		[].slice
			.call(document.querySelectorAll(".btn--toggle"))
			.map(function (element) {
				element.addEventListener("click", LLL.tabToggle(element));
			});
	},

	tabToggle: function () {
	},

	textAreaCount: function () {
		var textArea = $("#contact-form-message");
		var textAreaCount = $(".field-count");

		textArea.on("keyup", function () {
			textAreaCount.html(textArea.val().length);
		});
	},

	resultsClick: function () {
		var $resultsBtn = $(".btn.restaurant-locator");

		// scroll to results
		$resultsBtn.on("click", function () {
			LLL.scrollToResults();
		});
	},

	scrollToResults: function () {
		var resultsOffset = $(".lll-search-results").offset().top - 90;
		// scroll to results
		$("html, body").animate({ scrollTop: resultsOffset }, 500);
	},

	startFadingAnim: function () {
		// create empty div w/ height to prevent footer from sliding down.
		var heightOfDiv = $(".lll-cards-animation").height();

		// where lll-cards exist or parent div where contents live,
		// add class "lll-cards-animation"
		$(".lll-cards-animation").animate(
			{ opacity: 0, top: 0 },
			"fast",
			function () {
				$(".spinner-container")
					.css("margin-bottom", heightOfDiv)
					.addClass("cfa-spinner--visible");
			});

		$("html, body").animate({ scrollTop: 0 }, 300);
	}
}

// The LLL object is used in listenlovelearn.js as a module import and
// in FAQs.cshtml as a global on the window object.

if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
	module.exports = LLL;
}

if (typeof window !== "undefined") {
	window.LLL = LLL;
}
