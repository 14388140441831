import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

// TODO: Evaluate RadioSelection for code consolidation.

export class OloRadio extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		_element
			.querySelectorAll("input")
			.forEach(i => i.addEventListener("change", e => this.handleChange(e)));
	}

	private handleChange(e: Event): void {
		const target = e.target as HTMLElement;
		const activeClass = "p-radio--active";

		this._element
			.querySelectorAll("[data-container-for='input']")
			.forEach(c => {
				if (c.contains(target)) {
					c.classList.add(activeClass);
				} else {
					c.classList.remove(activeClass);
				}
			});
	}
}

ComponentFactory.registerComponent("OloRadio", OloRadio);
