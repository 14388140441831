import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class ListicleCarousel extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.initCarousel();
	}

	initCarousel(): void {
		const carouselOptions: JQuerySlickOptions = {
			accessibility: false,
			centerMode: false,
			cssEase: "linear",
			fade: true,
			arrows: false,
			dots: true,
			// mobileFirst: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 500,
			useTransform: false
		};

		const sliderElem = this._element.querySelector(
			".listicle-items"
		) as HTMLElement;
		const slider = $(sliderElem).slick(carouselOptions);
		this.handleButtonState();

		$(this._element)
			.find("[data-prev-arrow]")
			.on("click", item => {
				slider?.slick("slickPrev");
				this.handleButtonState();
			});

		$(this._element)
			.find("[data-next-arrow]")
			.on("click", () => {
				slider?.slick("slickNext");
				this.handleButtonState();
			});
	}

	handleButtonState(): void {
		const firstSlide = $(this._element).find("[data-slick-index=0]");
		const lastSlide = $(this._element).find(".slick-slide:last");

		this._element.classList.remove("disable-prev-arrow");
		this._element.classList.remove("disable-next-arrow");

		if (firstSlide.hasClass("slick-current")) {
			this._element.classList.add("disable-prev-arrow");
		}
		if (lastSlide.hasClass("slick-current")) {
			this._element.classList.add("disable-next-arrow");
		}
	}
}

ComponentFactory.registerComponent("ListicleCarousel", ListicleCarousel);
