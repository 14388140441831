import { Component } from "../../components/base/Component";
import { ComponentFactory } from "../../services/ComponentFactory";
import { ICfa } from "../../models/ICfa";
import { GiftCardService } from "./GiftCardService";
import { DataLayerService } from "../services/DataLayerService";

export class First100Form extends Component {
	dxeBearerToken: string | undefined;
	form: HTMLFormElement;
	focused: boolean;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		if (this._element.tagName !== "FORM") {
			throw new Error(`Non-form element passed in, element: ${this._element}`);
		}

		this.form = this._element as HTMLFormElement;
		this.focused = false;

		// tslint:disable-next-line
		this.initialize();
	}

	private async initialize(): Promise<void> {
		this.applyValidationRules(this.form);
		this.form.addEventListener("submit", e => this.handleSubmission(e));

		this.form.addEventListener("change", e => this.handleAgreementCheckboxes());
		this._element
			.querySelector("#streetAddress")
			?.addEventListener("focus", e => this.handleUserInput(e));

		DataLayerService.pushDataLayerEvent_Forms("form_view", {
			identifier: this.layerIdentifier,
			name: this.layerName,
			type: "First100Form"
		});
	}

	private handleAgreementCheckboxes(): void {
		const termsAgreement = this._element.querySelector(
			"#termsAgreement"
		) as HTMLInputElement;
		const ageAgreement = this._element.querySelector(
			"#ageAgreement"
		) as HTMLInputElement;

		this.toggleSubmitBtn(
			termsAgreement?.checked &&
				ageAgreement?.checked
		);
	}

	private get layerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	private get layerName(): string {
		return this._element.dataset.layerName || "";
	}

	private applyValidationRules(form: HTMLFormElement): void {
		const messages = this._element.dataset;
		const errorClass = "hasError";

		$.validator.addMethod(
			"equalToField",
			(value, element, param) => {
				const target = $(param);
				return this.optional(element) || value === target.val();
			},
			"Both fields must have the same value."
		);

		$(form).validate({
			errorClass: "hasError",
			errorPlacement: (error, element) => {
				error.appendTo(element.next(".error-container"));
			},
			highlight: element => {
				$(element).parent().addClass(errorClass);
			},
			messages: {
				streetAddress: {
					required: messages.customStreetAddressMsg ?? ""
				},
				city: messages.customCityMsg ?? "",
				state: messages.customStateMsg ?? "",
				zipcode: messages.customZipcodeMsg ?? "",
				termsAgreement: messages.customTermsAgreementMsg ?? "",
				ageAgreement: messages.customAgeAgreementMsg ?? ""
			},
			rules: {
				streetAddress: {
					required: true
				},
				city: {
					required: true
				},
				state: {
					required: true
				},
				zipcode: {
					required: true
				},
				termsAgreement: {
					required: true
				},
				ageAgreement: {
					required: true
				}
			},
			unhighlight: element => {
				$(element).parent().removeClass(errorClass);
			}
		});
	}
	optional(element: HTMLElement): boolean {
		throw new Error("Method not implemented.");
	}

	private async handleUserInput(e: Event): Promise<void> {
		if (!this.focused) {
			DataLayerService.pushDataLayerEvent_Forms("form_start", {
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "First100Form"
			});

			this.focused = true;
		}
	}

	private async handleSubmission(e: Event): Promise<void> {
		e.preventDefault();
		this.toggleSubmitBtn(false);
		const formHasErrors = !this.validate();
		if (formHasErrors) {
			this.toggleSubmitBtn(true);

			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "First100FormSubmission",
				error_message: "Form Has Errors",
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "First100Form"
			});

			return;
		}

		await this.submitForm();
	}

	private async submitForm(): Promise<void | null> {
		const {
			submissionEndpoint = "",
			formDatasourceId = "",
			redirectSuccess,
			redirectError
		} = this._element.dataset;

		const formData = new FormData(this.form);
		formData.append("formDatasourceId", formDatasourceId);
		const recaptchaVal = this.form["g-recaptcha-response"]?.value;

		if (!recaptchaVal) {
			console.warn("No recaptcha value found in payload");
			this.toggleFieldErr("recaptchaField", true);
			this.toggleSubmitBtn(true);

			return null;
		}

		const [result, err] = await GiftCardService.sendForm(
			submissionEndpoint,
			formData
		);

		if (err) {
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "First100FormSubmission",
				error_message: "Error in submitting form.",
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "First100Form"
			});

			console.warn("Error in submitting form.");
			if (redirectError) {
				window.location.href = redirectError;
			}

			return;
		}

		if (result && result.Success) {
			console.warn("Form submitted successfully");
			DataLayerService.pushDataLayerEvent_Forms("form_complete", {
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "First100Form"
			});

			if (redirectSuccess) {
				window.location.href = redirectSuccess;
			}
		} else {
			console.warn("Error while submitting the form");
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "First100FormSubmission",
				error_message: "Error in submitting form - BE.",
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "First100Form"
			});

			if (redirectError) {
				window.location.href = redirectError;
			}
		}
	}

	private toggleFieldErr(fieldName: string, toggleOn: boolean): void {
		const field = this._element.querySelector(`[data-element='${fieldName}']`);

		if (!field) {
			console.warn(`No field ${fieldName} found. Check DOM.`);

			return;
		}

		toggleOn
			? field.classList.add("display")
			: field.classList.remove("display");
	}

	private validate(): boolean {
		if (this._element) {
			return $(this._element).valid();
		}

		return false;
	}

	private toggleSubmitBtn(toggleOn: boolean): void {
		const submitBtn = this._element.querySelector("button[type='submit']");
		const disabledClass = "disabled";

		if (!submitBtn) {
			throw new Error(`Submit button not found for ${submitBtn}`);
		}

		toggleOn
			? submitBtn.classList.remove(disabledClass)
			: submitBtn.classList.add(disabledClass);
	}
}

ComponentFactory.registerComponent("first100Form", First100Form);
