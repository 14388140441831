class UpdateCharacterCount {
	private readonly inputField: HTMLInputElement;
	private readonly spanTxt: HTMLDivElement | null;
	private readonly txtLimit: string | undefined;

	constructor(private readonly element: HTMLElement) {
		const field = element.querySelector<HTMLInputElement>(".characCountInput");
		const sendGiftModal =
			document.querySelector<HTMLElement>("#send-gift-modal");
		const closeBtn = sendGiftModal?.querySelector<HTMLElement>(".mfp-close");

		if (!field) {
			throw new Error("Could not locate .characCountInput");
		}

		this.inputField = field;

		if (field.value) {
			this.updateCharacLength();
		}

		this.spanTxt = this.element.querySelector("span") as HTMLDivElement;
		if (this.spanTxt) {
			this.txtLimit = this.spanTxt?.innerText.split("/")[1] || "0";
		}

		if (closeBtn) {
			closeBtn?.addEventListener("click", () => {
				if (this.spanTxt) {
					this.spanTxt.innerHTML = `0/${this.txtLimit}`;
				}
			});
		}
	}

	updateCharacLength(): void {
		const inputLength = this.inputField.value.length;

		if (this.spanTxt) {
			this.spanTxt.innerHTML = `${inputLength}/${this.txtLimit}`;

			this.spanTxt.style.color =
				inputLength >= parseInt(`${this.txtLimit}`, 10) ? "#dd0031" : "inherit";
		}
	}
}

document

	.querySelectorAll<HTMLElement>(".characCountTracker")

	.forEach(elem => {
		const counter = new UpdateCharacterCount(elem);

		const trackerInputField = elem.querySelector(".characCountInput");

		trackerInputField?.addEventListener("keyup", () =>
			counter.updateCharacLength()
		);
	});
