import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class IsSticky extends Component {
	private readonly stickyContainerTop: HTMLElement | null;
	private readonly stickyContainer: HTMLElement | null;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.stickyContainer = _element.querySelector(".location-tabbed-nav");
		this.stickyContainerTop = this.createStickyTarget(_element);

		const observer = new IntersectionObserver(
			entries => {
				if (this.stickyContainer) {
					this.stickyContainer.classList.toggle(
						"is-pinned",
						entries[0].intersectionRatio === 0
					);
				}
			},
			{ threshold: [0, 1] }
		);

		if (this.stickyContainerTop) {
			observer.observe(this.stickyContainerTop);
		}
	}

	private createStickyTarget(_element: HTMLElement): HTMLElement {
		const stickyDiv = document.createElement("div");
		stickyDiv.classList.add("location-tabbed-nav-top");
		_element?.prepend(stickyDiv);

		return stickyDiv;
	}
}

ComponentFactory.registerComponent("IsSticky", IsSticky);
