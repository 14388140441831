interface IDessertOptionRequest {
	readonly datasource: string;
	readonly selectedVariation: string;
	readonly isDelivery: boolean;
	readonly slug: string;
}

export class OloDessertService {
	public constructor(
		private readonly _endpoint: string,
		private readonly _datasource: string
	) {
		if (!_endpoint) {
			console.warn("No dessert endpoint");
		}

		if (!_datasource) {
			console.warn("No dessert datasource");
		}
	}

	public async loadHtml(
		selectedVariation: string,
		isDelivery: boolean,
		slug: string
	): Promise<string> {
		if (!this._endpoint) {
			return "";
		}

		if (!this._datasource) {
			return "";
		}

		const data: IDessertOptionRequest = {
			datasource: this._datasource,
			isDelivery,
			selectedVariation,
			slug
		};

		const settings: JQuery.AjaxSettings<IDessertOptionRequest> = {
			cache: false,
			data,
			type: "GET",
			url: this._endpoint
		};

		try {
			const promise = $.ajax(settings);

			return (await Promise.resolve(promise)) as string;
		} catch (err) {
			const typed = err as JQuery.jqXHR;
			console.error(`Could not load ${this._endpoint} : ${typed.responseText}`);

			return "";
		}
	}
}
