import { StickyNav } from "./StickyNav";

export class HorizontalScroll {
	constructor(private readonly _stickNav: StickyNav) {
		this.update();
		this.addHorizontalScrollEvent();
	}

	get isHorizontallyScrollable(): boolean {
		const offsetAmount: number = 30;
		const list = this._stickNav.dropdownList;

		return list ? list.scrollWidth > list.offsetWidth + offsetAmount : false;
	}

	public update(): void {
		this.scrollButtonVisible = this.isHorizontallyScrollable;
	}

	addHorizontalScrollEvent(): void {
		this._stickNav.scrollButton?.addEventListener("click", () =>
			this.horizontallyScrollTo()
		);
	}

	horizontallyScrollTo(): void {
		const list = this._stickNav.dropdownList;
		if (list) {
			const totalScrollableWidth = list.scrollWidth;
			this.scrollingDirection
				? (list.scrollLeft = totalScrollableWidth)
				: (list.scrollLeft = 0);
		}

		this.toggleButtonSettings();
	}

	get scrollingDirection(): boolean {
		return (
			this._stickNav.scrollButton?.getAttribute("data-scroll-right") === "true"
		);
	}

	toggleButtonSettings(): void {
		this._stickNav.scrollButton?.classList.toggle("rotate");

		this._stickNav.scrollButton?.setAttribute(
			"data-scroll-right",
			(!this.scrollingDirection).toString()
		);
	}

	set scrollButtonVisible(value: boolean) {
		const button = this._stickNav.scrollButton;
		if (button) {
			button.style.display = value ? "inline" : "none";
		}
	}
}
