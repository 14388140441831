import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class Parallax extends Component {
	scrollPosition: number;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.scrollPosition = -1;
		if (window.innerWidth >= this._app.layout.desktopWidth) {
			this.animate();
		}
	}

	private animate = () => {
		this.scrollElements.forEach(el => {
			let delta = el.getAttribute("data-delta") || "";
			el.style.transform =
				"translateY(-" + window.pageYOffset * parseFloat(delta) + "px)";
		});

		requestAnimationFrame(this.handleOnScrollEvt.bind(this));
	};

	public get scrollElements(): NodeListOf<HTMLElement> {
		return this._element.querySelectorAll<HTMLElement>("[data-delta]");
	}

	public handleOnScrollEvt(): void {
		if (this.scrollPosition !== window.pageYOffset) {
			window.removeEventListener("scroll", this.handleOnScrollEvt);
			this.scrollPosition = window.pageYOffset;
			this.animate();
		} else {
			window.addEventListener("scroll", this.animate);
		}
	}
}

ComponentFactory.registerComponent("Parallax", Parallax);
