import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";

export abstract class GiftingLightbox extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
	}

	private static async hideCurrentModal(): Promise<void> {
		return new Promise(resolve => {
			$.magnificPopup.close(); // probably.

			// magnific needs a delay between two modals
			window.setTimeout(resolve, 1);
		});
	}

	protected async show(): Promise<void> {
		await GiftingLightbox.hideCurrentModal();

		$.magnificPopup.open({
			items: {
				src: this._element,
				type: "inline"
			}
		});
	}
}
