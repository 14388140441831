import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class GridView extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		// Design calls for GridView when childELems >= 4
		const elemsForGridView: number = 4;

		if (this.gridChildren >= elemsForGridView) {
			this.applyGridClass();
		}
	}

	private applyGridClass(): void {
		this._element.classList.add("gridView");
	}

	private get gridChildren(): number {
		return (
			this._element.querySelector("[data-apply='grid-view']")
				?.childElementCount ?? 0
		);
	}
}

ComponentFactory.registerComponent("GridView", GridView);
