/* global LLL:writable, pushDataLayer:writable */

var LLL = require("./lll.js");

var pushDataLayer = (function () {
	window.lll = window.lll || { stepNumber: 0 };
	var lastTitle = "";

	return function (step, question) {
		var question = $(".lll-title-header p").text();
		var stepName = $(".lll-title-header h1").text();
		var step = stepName;
		var formName = "Customer Service";

		var firstStep = window.lll.stepNumber == 0;
		var lastStep = false;

		var continueButton = $(".single.btn.lll-event");
		if (
			continueButton.length &&
			continueButton.text() === "Back to Customer Service"
		) {
			step = lastTitle + " - finalStep";
			lastStep = true;
		} else {
			lastTitle = step;
			window.lll.stepNumber = window.lll.stepNumber + 1;
			step = step + " - step" + window.lll.stepNumber;
		}

		if (firstStep) {
			//console.log("custom_form_step_view - firstStep");
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "custom_form_step_view",
				form_name: formName,
				form_step: window.lll.stepNumber,
				form_question: question
			});
		}
		else if (lastStep) {
			//console.log("custom_form_submit");
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "custom_form_submit",
				form_name: formName,
				form_topic: stepName
			});
		} else {
			//console.log("custom_form_step_view");
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "custom_form_step_view",
				form_name: formName,
				form_step: window.lll.stepNumber,
				form_question: question,
				form_topic: stepName
			});
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "feedbackPageView",
			formStep: step,
			formQuestion: question
		});
	};
})();

(function () {
	function disableBackButton() {
		window.history.pushState(null, "", window.location.href);
		window.onpopstate = function () {
			$.magnificPopup.open({
				items: {
					src: ".lll-overlay-question-text",
					type: "inline"
				},
				closeOnContentClick: false,
				closeOnBgClick: false,
				showCloseBtn: false,
				enableEscapeKey: false
			});
			window.history.pushState(null, "", window.location.href);
		};

		$(".lll-overlay-question-text").on("click", "a.cancel-btn", function (e) {
			e.preventDefault();
			$.magnificPopup.close();
		});

		$(".lll-overlay-question-text").on("click", "a.go-back", function (e) {
			e.preventDefault();

			window.location.replace("/contact-support");
		});
	}

	if ($(".lll-overlay-question-text").length) {
		if ($(".skip-link").length) {
			$(".skip-link").on("click", function (e) {
				e.preventDefault();
				var skipToElem = $(this).attr("href");
				$(skipToElem).focus();
			});
		}
		disableBackButton();
	}
})();

(function () {
	function trackJavaScriptError(e) {
		e = e || window.event;
		if (!e || !e.message || !e.lineno) {
			return true;
		}

		ga(
			"send",
			"event",
			"JavaScript Error",
			e.message,
			e.filename + ": " + e.lineno,
			{ nonInteraction: 1 }
		);
	}

	function tryTrackError(e) {
		try {
			trackJavaScriptError(e);
		} catch (trackJavaScriptFunctionError) {
			console.error(trackJavaScriptFunctionError);
		}
	}

	$(window).on("error", tryTrackError);
})();

$(function () {
	$.fn.keepAlive = function (options) {
		var base = this;

		base.init = function () {
			base.options = $.extend({}, options, {});
			base.ajax = { lll_KeepAlive: "/cfacom/listenlovelearnajax/keepalive" };
		};

		base.init();

		function debug(message) {
			if (window.console && window.console.log) {
				window.console.log(message);
			}
		}

		setInterval(function () {
			$.ajax({
				url: base.ajax.lll_KeepAlive,
				type: "Post"
			}).done(function () {
				debug("Keep Alive...");
			});
		}, 300000);
	};

	$.fn.creditCard = function (options) {
		var base = this;
		base.$el = $(this);

		base.init = function () {
			base.options = $.extend({}, options, {});
			base.elements = {
				container: ".lll-container",
				creditType: ".credit-card select",
				creditNum: ".credit-card input",
				nextBtn: "a.set-cc"
			};
			base.classNames = {
				disabled: "disabled",
				error: "error"
			};
			base.ajax = {
				SetCc: "/cfacom/listenlovelearnajax/recordcreditcard"
			};
		};

		base.init();
		checkFieldValidity();

		function checkFieldValidity() {
			var $nextBtn = $(base.elements.nextBtn);
			var creditType = $(base.elements.creditType).val();
			var creditNum = $(base.elements.creditNum).val();

			if (
				creditType !== "" &&
				creditNum !== undefined &&
				creditNum.length === 4 &&
				$.isNumeric(creditNum)
			) {
				$nextBtn.removeClass(base.classNames.disabled);
			}
		}

		base.$el.on("change", base.elements.creditType, function () {
			var $this = $(this);
			var $creditNum = $(base.elements.creditNum);
			var $nextBtn = $(base.elements.nextBtn);
			var creditNumIsValid =
				$creditNum.val().length === 4 && $.isNumeric($creditNum.val());

			if ($this.val() === "") {
				$this.parent().addClass(base.classNames.error);
				$nextBtn.addClass(base.classNames.disabled);
			} else {
				if ($this.val() !== "") {
					$this.parent().removeClass(base.classNames.error);
				}

				if ($this.val() !== "" && creditNumIsValid) {
					$nextBtn.removeClass(base.classNames.disabled);
				}
			}
		});

		$(base.elements.creditNum).keydown(function (event) {
			if (event.keyCode === 13) {
				event.preventDefault();
				return false;
			}
		});

		base.$el.on("keyup", base.elements.creditNum, function () {
			var $this = $(this);
			var $creditType = $(base.elements.creditType);
			var $nextBtn = $(base.elements.nextBtn);
			var creditTypeIsValid =
				!$creditType.parent().hasClass(base.classNames.error) &&
				$creditType.val() !== "";
			var creditNumIsValid =
				$this.val().length === 4 && $.isNumeric($this.val());

			if (!creditTypeIsValid || !creditNumIsValid) {
				$nextBtn.addClass(base.classNames.disabled);

				if (!$this.parent().hasClass(base.classNames.error)) {
					$this.parent().addClass(base.classNames.error);
				}
			} else {
				if (creditTypeIsValid && creditNumIsValid) {
					$nextBtn.removeClass(base.classNames.disabled);
				}
			}
		});

		base.$el.on("blur", base.elements.creditNum, function () {
			var $this = $(this);
			var creditNumIsValid =
				$this.val().length === 4 && $.isNumeric($this.val());

			if (!creditNumIsValid) {
				$this.parent().addClass(base.classNames.error);
			} else {
				$this.parent().removeClass(base.classNames.error);
			}
		});

		base.$el.on("click", base.elements.nextBtn, function (e) {
			var $this = $(this);

			if ($this.hasClass(base.classNames.disabled)) {
				return;
			}

			LLL.startFadingAnim();

			$.ajax({
				url: base.ajax.SetCc,
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: {
					pageId: $this.data("page"),
					type: $(base.elements.creditType).val(),
					num: $(base.elements.creditNum).val()
				}
			}).done(function (result) {
				if (result === "True") {
					$(base.elements.container).load($this.data("url"), function () {
						pushDataLayer();
						LLL.endFadingAnim();
					});
				}
			});
		});
	};

	$.fn.transactionHistory = function (options) {
		var base = this;
		base.$el = $(this);

		base.init = function () {
			base.options = $.extend({}, options, {});
			base.elements = {
				container: ".lll-container",
				findAnotherWay: ".find-another-way",
				setTransaction: ".set-transaction"
			};
			base.ajax = {
				setTransactionHistory:
					"/cfacom/listenlovelearnajax/recordtransactionhistoryresponse"
			};
		};

		base.init();

		base.$el.on("click", base.elements.setTransaction, function (e) {
			var $this = $(this);
			var url = $this.data("url");
			var pageId = $this.data("page");
			var restaurantId = $this.data("id");
			var orderNumber = $this.data("order");
			var dateOfVisit = $this.data("date");

			LLL.startFadingAnim();

			$.ajax({
				url: base.ajax.setTransactionHistory,
				type: "POST",
				data: {
					pageId: pageId,
					restaurantId: restaurantId,
					orderNumber: orderNumber,
					date: dateOfVisit
				}
			}).done(function (results) {
				if (results === "True") {
					$(base.elements.container).load(url, function () {
						LLL.endFadingAnim();
						pushDataLayer();
					});
				} else {
					LLL.endFadingAnim();
				}
			});

			e.preventDefault();
		});

		base.$el.on("click", base.elements.findAnotherWay, function (e) {
			LLL.startFadingAnim();

			$(base.elements.container).load($(this).data("url"), function () {
				LLL.endFadingAnim();
				pushDataLayer();
			});

			e.preventDefault();
		});
	};

	$.fn.restaurantMap = function (options) {
		var base = this;
		base.$el = $(this);

		base.init = function () {
			base.options = $.extend({}, options, {});
			base.elements = {
				container: ".lll-container",
				iFrame: "#mclrRestaurantMap",
				nextStep: "#mclrNextStep",
				pageId: "#mclrPageId",
				previousStepDate: "#previousStepDate",
				previousStepOrderNumber: "#previousStepOrderNumber"
			};
			base.ajax = {
				SetRestaurantMap:
					"/cfacom/listenlovelearnajax/recordrestaurantmapresponse"
			};
		};

		base.init();

		if ($(base.elements.iFrame).length) {
			if (window.addEventListener) {
				window.addEventListener("message", getRestaurantId, false);
			} else {
				window.attachEvent("onmessage", getRestaurantId);
			}
		}

		function getRestaurantId(evt) {
			if (typeof evt.data.restaurantId !== "undefined") {
				var pageId = $(base.elements.pageId).val();
				var nextStep = $(base.elements.nextStep).val();
				var previousStepDate = $(base.elements.previousStepDate).val();
				var previousStepOrderNumber = $(
					base.elements.previousStepOrderNumber
				).val();
				var restaurantId = evt.data.restaurantId;

				if (restaurantId !== "") {
					LLL.startFadingAnim();

					$.ajax({
						url: base.ajax.SetRestaurantMap,
						type: "Post",
						async: false,
						cache: false,
						dataType: "text",
						data: {
							pageId: pageId,
							restaurantId: restaurantId,
							previousStepDate: previousStepDate,
							previousStepOrderNumber: previousStepOrderNumber
						}
					}).done(function (result) {
						if (result === "True") {
							$(base.elements.container).load(nextStep, function () {
								LLL.endFadingAnim();
								pushDataLayer();
							});
						}
					});
				}
			}
		}
	};

	$.fn.dateOfVisit = function (options) {
		var base = this;
		base.$el = $(this);

		base.init = function () {
			base.options = $.extend({}, options, {});
			base.elements = {
				continueBtn: ".single.set-date-of-visit",
				dateOfVisit: ".date-of-visit",
				dateOfVisitParent: "fieldset.input",
				timeOfVisit: ".time-of-visit",
				timeOfVisitParent: "span.selectlist"
			};
			base.classNames = {
				disabled: "disabled",
				error: "error"
			};
			base.ajax = {
				IsInThePresentOrPast: "/cfacom/dateofvisit/isinthepresentorpast",
				GetStoreHourIntervals: "/cfacom/dateofvisit/getstorehourintervals"
			};
		};

		base.init();

		function debug(message) {
			if (window.console && window.console.log) {
				window.console.log(message);
			}
		}

		function validateDate(testdate) {
			var date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
			return date_regex.test(testdate);
		}

		function getValidTimeIntervals(val) {
			var timeOfVisitParent = $(base.elements.timeOfVisit).closest(
				base.elements.timeOfVisitParent
			);
			var dateTime = new Date();
			var localeDateTime =
				dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString();

			$.ajax({
				url: base.ajax.GetStoreHourIntervals,
				type: "GET",
				data: { date: val, visitorsLocalTime: localeDateTime }
			}).done(function (results) {
				$(base.elements.timeOfVisit).empty();

				if (results === "") {
					timeOfVisitParent.addClass(base.classNames.error);
					return;
				} else {
					timeOfVisitParent.removeClass(base.classNames.error);
				}

				var timeIntervals = results.split(",");

				$(base.elements.timeOfVisit).append(
					$("<option />").val("-").text($("input.default-time").val())
				);

				for (var i = 0; i < timeIntervals.length; i++) {
					$(base.elements.timeOfVisit).append(
						$("<option />").val(timeIntervals[i]).text(timeIntervals[i])
					);
				}
			});
		}

		base.$el.on("focus", ".date-of-visit", function () {
			$(this).datepicker({
				changeMonth: true,
				changeYear: true,
				minDate: -365,
				maxDate: 0,
				beforeShowDay: function (date) {
					var dateTime = new Date();
					//If the date = today and the Hour < 5am (Store Opening), disable today
					if (
						dateTime.getHours() < 5 &&
						dateTime.getDate() === date.getDate()
					) {
						return [date.getDay() !== dateTime.getDay(), ""];
					}

					var day = date.getDay();

					// CFMCOMFOUR-2260: Disable Sundays
					return [day !== 0, ""];
				},
				onSelect: function (val, obj) {
					getValidTimeIntervals(val);
					debug("Date Selected: " + val);
				}
			});
		});

		base.$el.on("change", base.elements.timeOfVisit, function () {
			var $this = $(this);
			var $timeOfVisit = $(base.elements.timeOfVisit);
			var $timeOfVisitParent = $this.closest(base.elements.timeOfVisitParent);
			var $dateOfVisit = $(base.elements.dateOfVisit);
			var $continueBtn = $(base.elements.continueBtn);

			if ($timeOfVisit.val() === "" || $timeOfVisit.val() === "-") {
				$timeOfVisitParent.addClass(base.classNames.error);
				$continueBtn.addClass(base.classNames.disabled);
			} else {
				$timeOfVisitParent.removeClass(base.classNames.error);

				if (validateDate($dateOfVisit.val())) {
					$continueBtn.removeClass(base.classNames.disabled);
				}
			}
		});

		base.$el.on("keyup", base.elements.dateOfVisit, function () {
			var $this = $(this);
			var $dateOfVisitParent = $this.closest(base.elements.dateOfVisitParent);
			var $timeOfVisit = $(base.elements.timeOfVisit);
			var $continueBtn = $(base.elements.continueBtn);
			var val = $this.val();

			if (!validateDate(val)) {
				$dateOfVisitParent.addClass(base.classNames.error);
				return;
			}

			var dateTime = new Date();
			var localeDateTime =
				dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString();

			$.ajax({
				url: base.ajax.IsInThePresentOrPast,
				type: "Get",
				data: { date: val, visitorsLocalTime: localeDateTime }
			}).done(function (results) {
				if (results === "True") {
					getValidTimeIntervals(val, $(base.elements.timeOfVisit));
					$dateOfVisitParent.removeClass(base.classNames.error);

					if ($timeOfVisit.val() !== "") {
						$continueBtn.removeClass(base.classNames.disabled);
					}

					return;
				} else {
					$continueBtn.addClass(base.classNames.disabled);
					$dateOfVisitParent.addClass(base.classNames.error);
				}
			});
		});
	};

	$.fn.feedback = function (options) {
		var base = this;
		base.$el = $(this);

		base.init = function () {
			base.options = $.extend({}, options, {});

			base.elements = {
				acceptTerms: "#AcceptTerms",
				acceptTermsContainer: "#accept-terms-container",
				acceptTermsCheckbox: "#accept-terms-container #AcceptTerms",
				container: "#feedbackForm",
				error: ".error-message",
				singleSelect: "a.set-feedback",
				feedbackText: ".feedback-text",
				textCount: ".field-count",
				surveyId: "#surveyId"
			};
			base.classNames = {
				required: "required",
				disabled: "disabled"
			};
		};

		base.init();

		$(document).ajaxComplete(function () {
			if (
				$(base.elements.acceptTermsContainer).find('input[type="checkbox"]')
					.length
			) {
				// Set the disabled continue to appear enabled - CFAC-17671
				base.$el
					.find(base.elements.singleSelect)
					.css(
						"background",
						"linear-gradient(-180deg, rgba(254,67,108,0.99) 0%, rgba(221,0,49,0.99) 49%, #C20726 100%)"
					);
			}
		});

		function debug(message) {
			if (window.console && window.console.log) {
				window.console.log(message);
			}
		}

		function removeEmoticons(val) {
			val = val.replace("\u201c", '"').replace("\u201d", '"'); //left and right double quote
			val = val.replace("\u2018", "'").replace("\u2019", "'"); //left and right single quote
			return val.replace(/[^\x00-\x7F]/g, "");
		}

		function trimStart(character, string) {
			var startIndex = 0;

			while (string[startIndex] === character) {
				startIndex++;
			}

			return string.substr(startIndex);
		}

		function cleanTheText(textArea) {
			var txt = removeEmoticons(textArea);
			txt = trimStart(" ", txt);
			txt = txt.trim();
			return txt;
		}

		function validatateFeedback() {
			var textArea = $(base.elements.feedbackText);
			var acceptTerms = $(base.elements.acceptTermsCheckbox);
			var surveyId = $(base.elements.surveyId);
			var singleSelect = base.$el.find(base.elements.singleSelect).last();
			var min = textArea.data("minchar");
			var max = textArea.data("maxchar");
			var txt = removeEmoticons(textArea.val());
			txt = trimStart(" ", txt);
			textArea.val(txt);

			$(base.elements.container).find(base.elements.textCount).html(txt.length);

			txt = txt.trim();

			if (
				base.$el
					.find(base.elements.container)
					.hasClass(base.classNames.required)
			) {
				if (txt >= min && txt <= max) {
					if (acceptTerms.length && acceptTerms.is("checked")) {
						singleSelect.removeClass(base.classNames.disabled);
					} else if (surveyId.length && surveyId.val().length > 0) {
						if (validateSurveyId(surveyId)) {
							singleSelect.removeClass(base.classNames.disabled);
						}
					} else if (!acceptTerms.length) {
						singleSelect.removeClass(base.classNames.disabled);
					}
				}
			} else {
				if (txt.length < min || txt.length > max) {
					singleSelect.addClass(base.classNames.disabled);

					// TODO: FE needs to add error style to textarea
					textArea.css("border", "3px solid #dd0031");
					textArea.addClass("error");
				} else if (txt.length >= min && txt.length <= max) {
					textArea.removeClass("error");

					// TODO: FE needs to add error style to textarea
					textArea.css("border", "");

					if (acceptTerms.length && acceptTerms.prop("checked")) {
						singleSelect.removeClass(base.classNames.disabled);
					} else if (surveyId.length && surveyId.val().length > 0) {
						if (validateSurveyId(surveyId)) {
							singleSelect.removeClass(base.classNames.disabled);
						}
					} else if (!acceptTerms.length) {
						singleSelect.removeClass(base.classNames.disabled);
					}
				}
			}
		}

		function toggleContinueState() {
			var textArea = $(base.elements.feedbackText);
			var acceptTerms = $(base.elements.acceptTermsCheckbox);
			var surveyId = $(base.elements.surveyId);
			var singleSelect = base.$el.find(base.elements.singleSelect).last();
			var min = textArea.data("minchar");
			var max = textArea.data("maxchar");
			var txt = cleanTheText(textArea.val());

			if (
				(acceptTerms.length && !acceptTerms.prop("checked")) ||
				(surveyId.length &&
					surveyId.val().length > 0 &&
					!validateSurveyId(surveyId.val())) ||
				txt.length < min ||
				txt.length > max
			) {
				singleSelect.addClass(base.classNames.disabled);
			} else {
				singleSelect.removeClass(base.classNames.disabled);
			}
		}

		function toggleCheckboxErrorMessage() {
			var acceptTerms = $(base.elements.acceptTermsCheckbox);

			if (!acceptTerms.prop("checked")) {
				$(".err-msg").show();
			} else {
				$(".err-msg").hide();
			}
		}

		function fireDatalayer() {
			var acceptTerms = $(base.elements.acceptTermsCheckbox);

			if (acceptTerms.length) {
				var textArea = $(base.elements.feedbackText);
				var txt = cleanTheText(textArea.val());
				var min = textArea.data("minchar");
				var max = textArea.data("maxchar");

				if (
					txt.length > min &&
					txt.length < max &&
					acceptTerms.prop("checked")
				) {
					debug("Datalayer Event: engagement, Category: acceptterms");
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: "engagement",
						category: "acceptterms"
					});
				}
			}
		}

		function validateSurveyId(val) {
			var regex = /^([0-9]{7})+\-+([0-9]{5})+\-+([0-9]{4})+\-+([0-9]{4})+\-+([0-9]{2})$/;
			return regex.test(val);
		}

		base.$el.on("click", base.elements.acceptTermsCheckbox, function (e) {
			toggleContinueState();
			toggleCheckboxErrorMessage();
		});

		base.$el.on("keyup", base.elements.acceptTermsCheckbox, function (e) {
			if (e.keyCode == 32) {
				toggleContinueState();
				toggleCheckboxErrorMessage();
			}
		});

		base.$el.on("blur", base.elements.surveyId, function (e) {
			var $surveyId = $(this);
			var $container = $surveyId.closest("div.input");
			var $error = $(".serial-err-msg");
			var txtLength = $surveyId.val().length;

			toggleContinueState();

			if (txtLength === 26 || txtLength === 0) {
				$surveyId.removeClass("error");
				$container.removeClass("error");
				$error.hide();
			} else if (txtLength > 0) {
				$surveyId.addClass("error");
				$container.addClass("error");
				$error.show();
			}
		});

		base.$el.on("keyup", base.elements.surveyId, function (e) {
			toggleContinueState();
		});

		base.$el.on("click", base.elements.singleSelect, function () {
			validatateFeedback();
			toggleCheckboxErrorMessage();
			fireDatalayer();
		});

		base.$el.on("keyup blur", base.elements.feedbackText, function () {
			validatateFeedback();

			if ($(base.elements.surveyId).length) {
				toggleContinueState();
			}
		});
	};

	$.fn.progressIndicator = function (options) {
		var base = this;
		base.$el = $(this);
		base.init = function () {
			var defaults = {};
			base.options = $.extend({}, defaults, options);
			base.elements = {
				container: ".lll-container",
				backButton: ".progress-back"
			};
			base.ajax = { getPreviousStep: "/cfacom/visitorflow/getpreviousstep" };
		};

		base.init();

		function debug(message) {
			if (window.console && window.console.log) {
				window.console.log(message);
			}
		}

		base.$el.on("click", base.elements.backButton, function () {
			var url = $(this).data("url");

			if ($(this).hasClass("disabled")) {
				return;
			} else {
				$(base.elements.backButton).addClass("disabled");
			}

			LLL.startFadingAnim();

			$.ajax({
				url: base.ajax.getPreviousStep,
				type: "GET",
				data: {
					currentPageId: $(this).data("current"),
					previousPageId: $(this).data("previous")
				}
			}).done(function (results) {
				if (results === "1") {
					location.reload(true);
				} else if (results > "1") {
					$(base.elements.container).load(url);

					setInterval(function () {
						if ($(".lll-cards-animation").css("opacity") != 1) {
							LLL.endFadingAnim();
						}
					}, 1);
				}
			});
		});
	};

	$.fn.lllFormSubmission = function (options) {
		var base = this;
		base.$el = $(this);

		base.init = function () {
			var defaults = {
				phone: { regex: /\D/g, characters: 10, beginningCharsToRemove: "+,1" }
			};
			base.settings = $.extend({}, defaults, options);
			base.elements = {
				container: ".lll-container",
				submit: ".submit-form",
				tel: 'input[type="tel"]'
			};
			base.classNames = {
				error: "error"
			};
			base.ajax = {
				submit: "/cfacom/VisitorInformation/recordvisitorinformation",
				getNextPage: "/cfacom/listenlovelearnajax/setnextpage"
			};
		};

		function validateZip(zip) {
			if (zip === undefined) {
				return true;
			}
			var cleansedZip = zip.replace(/\D+/g, "");
			if (cleansedZip.length == 5) {
				return true;
			} else {
				return false;
			}
		}

		base.init();

		function debug(message) {
			if (window.console && window.console.log) {
				window.console.log(message);
			}
		}

		function validateEmail(email) {
			var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,})+$/;
			return regex.test(email);
		}

		function replaceAt(string, index, replace) {
			return string.substring(0, index) + replace + string.substring(index + 1);
		}

		function cleansePhone(phone) {
			// TODO: Move Phone Functions outside plugin to global if possible.
			// Per Jira Ticket: https://alm.cfadevelop.net/jira/browse/CFAC-1819
			var cleansedPhone = phone.trim();
			var beginningChars = base.settings.phone.beginningCharsToRemove.split(
				","
			);

			for (var i = 0; i < beginningChars.length; i++) {
				if (cleansedPhone.startsWith(beginningChars[i])) {
					cleansedPhone = replaceAt(cleansedPhone, 0, "");
				}
			}

			return cleansedPhone;
		}

		function validatePhone(phone) {
			// TODO: Move Phone Functions outside plugin to global if possible.
			// Per Jira Ticket: https://alm.cfadevelop.net/jira/browse/CFAC-1819
			var beginningChars = base.settings.phone.beginningCharsToRemove.split(
				","
			);

			for (var i = 0; i < beginningChars.length; i++) {
				if (phone.trim().startsWith(beginningChars[i])) {
					return false;
				}
			}

			var cleanIt = cleansePhone(phone);
			var retVal = cleanIt.replace(base.settings.phone.regex, "");
			return retVal.length === base.settings.phone.characters;
		}

		var getAndLoadNextPage = function (id) {
			$.ajax({
				url: base.ajax.getNextPage,
				type: "Get",
				data: { buttonId: id }
			}).done(function (results) {
				if (results !== "") {
					$(base.elements.container).load(results, function () {
						pushDataLayer();
					});
				}
			});
		};

		base.$el.on("blur", 'input[type="email"]', function (e) {
			var $this = $(this);
			var $emailAddress = $(".email-address");
			var $confirmEmail = $(".confirm-email");
			var $errorMessage = $(".confirm-email-error.error-message");
			var $invalidEmailMessage = $(".email-error.error-message");
			var emailVal = $emailAddress.val();
			var confirmEmailVal = $confirmEmail.val();

			if ($this.val() === "") {
				return;
			}

			if (!validateEmail($this.val())) {
				$this.addClass("error");
			} else {
				$this.removeClass("error");
			}

			if (!validateEmail(emailVal)) {
				$invalidEmailMessage.show();
			} else {
				$invalidEmailMessage.hide();
			}

			if (emailVal !== confirmEmailVal && confirmEmailVal.length) {
				$this.addClass("error");
				$errorMessage.show();
			} else {
				if (validateEmail(emailVal) && validateEmail(confirmEmailVal)) {
					$this.removeClass("error");
					$errorMessage.hide();
				}
			}
		});

		base.$el.on("click", base.elements.submit, function (e) {
			e.preventDefault();
			var $this = $(this);
			var $form = $this.closest("form");
			var $tel = $form.find('input[type="tel"]');
			var $email = $form.find('input[type="email"]');
			var $stateDD = $form.find("select");
			var $stateDDContainer = $stateDD.closest("div.selectlist");
			var $zip = $form.find('input[name="PostalCode"]');
			var $disableRecaptcha = $('input[name="Puzzle"]');

			if ($stateDDContainer.hasClass("required")) {
				if ($stateDD.val() === "") {
					$stateDDContainer.addClass(base.classNames.error);
				} else {
					$stateDDContainer.removeClass(base.classNames.error);
				}
			}

			if (!validatePhone($tel.val())) {
				debug("Telephone is not valid.");
				$tel.addClass("error");
			}

			if (!validateZip($zip.val())) {
				if ($zip.hasClass("required") || $zip.val() !== "") {
					debug("Zip is not valid.");
					$zip.addClass("error");
				}
			}

			$email.each(function () {
				if (!validateEmail($(this).val())) {
					$(this).addClass("error");
				}
			});

			if ($(".email-address").val() !== $(".confirm-email").val()) {
				return;
			}

			if (!$form.valid()) {
				if (!validateEmail($email.val())) {
					debug("Email is not valid.");
					$email.addClass("error");
				}

				return;
			}

			if ($disableRecaptcha.val() === "false") {
				if (
					grecaptcha !== null &&
					grecaptcha !== undefined &&
					grecaptcha.getResponse().length === 0
				) {
					return;
				}
			}

			if ($this.not("[disabled=disabled]")) {
				$this.prop("disabled", true);
				$this.addClass("disabled");

				LLL.startFadingAnim();

				$.ajax({
					url: base.ajax.submit,
					type: "Post",
					data: { frmVals: $form.serialize() }
				}).done(function (data) {
					LLL.endFadingAnim();

					if (data === "True") {
						getAndLoadNextPage($this.data("id"));
					} else {
						$this.prop("disabled", false);
						$this.removeClass("disabled");
					}
				});
			}
		});
	};

	$.fn.lllbutton = function (options) {
		var base = this;
		base.$el = $(this);

		base.init = function () {
			var defaults = {};
			base.options = $.extend({}, defaults, options);
			base.elements = {
				container: ".lll-container",
				buttonGroup: ".lll-cards__reasons-group > div > ul",
				multiActive: ".multi.active",
				single: ".single",
				singleActive: ".single.active",
				singleBtn: ".single.btn"
			};
			base.classNames = {
				active: "active",
				dataUrl: "data-url",
				disabled: "disabled",
				multi: "multi",
				single: "single",
				event: "lll-event"
			};
			base.menuItems = {
				active: "li.active",
				container: ".lll-cards__menu",
				li: "li.menu-item",
				setBtn: ".single.set-menu-items"
			};
			base.dateOfVisit = {
				dateOfVisit: ".date-of-visit",
				timeOfVisit: ".time-of-visit",
				continueBtn: ".single.set-date-of-visit"
			};
			base.restaurant = {
				city: ".restaurant-city",
				state: ".restaurant-state",
				order: ".order-number",
				phone: ".restaurant-phone",
				ext: ".restaurant-ext"
			};
			base.feedback = {
				acceptTerms: "#AcceptTerms",
				textArea: ".feedback-text",
				surveyId: "#surveyId"
			};
			base.ajax = {
				lll: "/cfacom/listenlovelearnajax/recordvisitorstate",
				lll_AssistanceCategory:
					"/cfacom/listenlovelearnajax/recordassistancecategory",
				lll_CaresCategories:
					"/cfacom/listenlovelearnajax/recordcarescategories",
				lll_DiningType: "/cfacom/listenlovelearnajax/recorddiningtype",
				lll_Feedback: "/cfacom/listenlovelearnajax/recordfeedback",
				lll_FeedbackType: "/cfacom/listenlovelearnajax/recordfeedbacktype",
				lll_Followup: "/cfacom/listenlovelearnajax/recordfollowup",
				lll_MenuItems: "/cfacom/listenlovelearnajax/recordselectedmenuitems",
				lll_OrderMethod: "/cfacom/listenlovelearnajax/recordordermethod",
				lll_Restaurant: "/cfacom/RestaurantLocator/recordrestaurant",
				lll_VisitDateTime: "/cfacom/dateofvisit/recordvisitdatetime",
				pushDataLayer: "/cfacom/listenlovelearnajax/pushdatalayer",
				redirectIfSessionIsInvalid:
					"/cfacom/listenlovelearnajax/redirectifsessionisinvalid",
				setConditionalNextPage:
					"/cfacom/listenlovelearnajax/setConditionalnextpage",
				setNextPage: "/cfacom/listenlovelearnajax/setnextpage",
				setSectionName: "/cfacom/listenlovelearnajax/setprevioussectionname",
				setSectionVisibility: "/cfacom/listenlovelearnajax/setsectionvisibility"
			};
		};

		base.toggleDisabled = function () {
			var multiSelected = $(base.elements.container).find(
				$(base.elements.multiActive)
			);
			if (multiSelected.length > 0) {
				var single = $(base.elements.container)
					.find(base.elements.single)
					.last();
				single.removeClass(base.classNames.disabled);
			}
		};

		base.init();
		$.ajaxSetup({ cache: false });
		base.toggleDisabled();

		function debug(message) {
			if (window.console && window.console.log) {
				window.console.log(message);
			}
		}

		var loadUrl = function (url) {
			LLL.startFadingAnim();
			if (url !== undefined && url !== "" && url !== "#") {
				$(base.elements.container).load(url, function () {
					LLL.endFadingAnim();
					pushDataLayer();
				});
			}
		};

		var getAndLoadNextPage = function (id) {
			if (id === undefined || id === "") {
				return;
			}

			debug("Get & Load Next Page - ID: " + id);

			LLL.startFadingAnim();

			$.ajax({
				url: base.ajax.setNextPage,
				type: "Get",
				async: false,
				cache: false,
				dataType: "text",
				data: { buttonId: id }
			}).done(function (results) {
				if (results !== "") {
					$(base.elements.container).load(results, function () {
						LLL.endFadingAnim();
						pushDataLayer();
					});
				}
			});
		};

		var getSessionStatus = function () {
			$.ajax({
				url: base.ajax.redirectIfSessionIsInvalid,
				type: "Get",
				cache: false,
				dataType: "text"
			}).done(function (results) {
				if (results !== undefined && results !== "") {
					debug("Session is Null, Redirect to: " + results);
					$(base.elements.container).load(results);
				}
			});
		};

		var setConditionalNextPage = function (
			ele,
			page,
			id,
			remove,
			multiSelected
		) {
			if (id === undefined || id === "") {
				return;
			}

			debug(
				"Set Conditional Next Page. Page Id: " +
					page +
					" - ID: " +
					id +
					" - Add/Remove: " +
					remove
			);

			if (remove) {
				ele.attr("href", "#");
			}

			$.ajax({
				url: base.ajax.setConditionalNextPage,
				type: "Get",
				cache: false,
				dataType: "text",
				data: { currentPageId: page, buttonId: id, remove: remove }
			}).done(function (results) {
				ele.attr("href", results !== "" ? results : "#");
				toggleActiveState(ele, multiSelected);
			});
		};

		function toggleActiveState(continueBtn, multiSelected) {
			if (multiSelected.length > 0) {
				continueBtn.addClass(base.classNames.active);
				continueBtn.removeClass(base.classNames.disabled);
			} else {
				continueBtn.addClass(base.classNames.disabled);
				continueBtn.removeClass(base.classNames.active);
			}
		}

		var setNextPage = function (ele, id) {
			if (id === undefined || id === "") {
				return;
			}

			debug("Set Next Page - ID: " + id);

			$.ajax({
				url: base.ajax.setNextPage,
				type: "Get",
				async: false,
				cache: false,
				dataType: "text",
				data: { buttonId: id }
			}).done(function (results) {
				ele.attr("href", results !== "" ? results : "#");
			});
		};

		var setSectionVisibility = function (id) {
			if (id === undefined || id === "") {
				return;
			}

			debug("Set Section Visibility - ID: " + id);

			$.ajax({
				url: base.ajax.setSectionVisibility,
				type: "Post",
				cache: false,
				dataType: "text",
				data: { id: id }
			});
		};

		var recordAssistanceCategory = function (page, id) {
			$.ajax({
				url: base.ajax.lll_AssistanceCategory,
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: { pageId: page, buttonId: id }
			}).done(function (result) {
				debug(
					"Record Assistance Category - Result: " +
						result +
						" - Page: " +
						page +
						" - Id: " +
						id
				);
			});
		};

		var recordFollowup = function (page, id) {
			$.ajax({
				url: base.ajax.lll_Followup,
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: { pageId: page, buttonId: id }
			}).done(function (result) {
				debug(
					"Record Followup - Result: " +
						result +
						" - Page: " +
						page +
						" - Id: " +
						id
				);
			});
		};

		var recordOrderMethod = function (page, id, data) {
			$.ajax({
				url: base.ajax.lll_OrderMethod,
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: { pageId: page, buttonId: id, data: data }
			}).done(function (result) {
				debug(
					"Record Order Method - Result: " +
						result +
						" - Page: " +
						page +
						" - Id: " +
						id +
						" - Data: " +
						data
				);
			});
		};

		var recordDiningState = function (page, id, data) {
			$.ajax({
				url: base.ajax.lll_DiningType,
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: { pageId: page, buttonId: id, data: data }
			}).done(function (result) {
				debug(
					"Record Dining State - Result: " +
						result +
						" - Page: " +
						page +
						" - Id: " +
						id +
						" - Data: " +
						data
				);
			});
		};

		var recordFeedback = function (
			page,
			id,
			data,
			acceptTerms,
			surveyId,
			dsId
		) {
			acceptTerms = acceptTerms != undefined ? acceptTerms : false;

			$.ajax({
				url: base.ajax.lll_Feedback,
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: {
					pageId: page,
					buttonId: id,
					data: data,
					acceptTerms: acceptTerms,
					surveyId: surveyId,
					datasourceId: dsId
				}
			}).done(function (result) {
				debug(
					"Record Feedback - Page: " +
						page +
						" - ID: " +
						id +
						" - data: " +
						data +
						" - Datasource: " +
						dsId +
						" - Success: " +
						result
				);
				if (result === "True") {
					getAndLoadNextPage(id);
				}
			});
		};

		var recordFeedbackType = function (page, id) {
			$.ajax({
				url: base.ajax.lll_FeedbackType,
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: { pageId: page, buttonId: id }
			}).done(function (result) {
				debug("Record Feedback Type: " + result);
			});
		};

		var recordRestaurantState = function (
			ele,
			pageId,
			restaurantId,
			url,
			city,
			state,
			orderNum,
			phone,
			ext
		) {
			$.ajax({
				url: base.ajax.lll_Restaurant,
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: {
					pageId: pageId,
					restaurantId: restaurantId,
					city: city,
					state: state,
					orderNum: orderNum,
					phone: phone,
					extension: ext
				}
			}).done(function (results) {
				if (results === "True") {
					if (url !== "") {
						loadUrl(url);
						return;
					}
				}
			});
		};

		var recordState = function (ele, page, id, classes) {
			var data = "";

			$.ajax({
				url: base.ajax.lll,
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: { pageId: page, buttonId: id, classes: classes, data: data }
			}).done(function (result) {
				// debug('Record State: ' + result);
			});
		};

		var recordVisitDateTime = function (page, id, url, date, time) {
			if (url !== undefined) {
				loadUrl(url);
			}

			$.ajax({
				url: base.ajax.lll_VisitDateTime,
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: { pageId: page, date: date, time: time }
			}).done(function (result) {
				debug(
					"Record Visit Date/Time: " +
						result +
						" - Page: " +
						page +
						" - ID: " +
						id +
						" - Date: " +
						date +
						" - Time: " +
						time
				);
				getAndLoadNextPage(id);
			});
		};

		base.$el.on("click", "a.single, a.multi, .lll-container button", function (
			e
		) {
			e.preventDefault();
			var $this = $(this);

			var question = $(".lll-title-header p").text();
			var stepName = $(".lll-title-header h1").text();
			var formName = "Customer Service";
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "custom_form_step_complete",
				form_name: formName,
				form_question: question,
				form_step: window.lll.stepNumber,
				form_topic: stepName
			});

			var $data = {
				classes: $this.attr("class"),
				diningType: $this.data("diningtype"),
				dataUrl: $this.data("url"),
				href: $this.attr("href"),
				id: $this.data("id"),
				page: $this.data("page"),
				orderMethod: $this.data("ordermethod"),
				restaurant: $this.data("restaurant"),
				text: $this.text()
				//, sectionId: $this.data('section')
			};
			var actions = {
				dateOfVisit: "set-date-of-visit",
				dining: "set-dining-type",
				event: "lll-event",
				feedback: "set-feedback",
				feedbackType: "set-feedback-type",
				followup: "set-followup",
				menuItems: "set-menu-items",
				nextPage: "next-page",
				orderMethod: "set-order-method",
				restaurant: "set-restaurant",
				sectionName: "set-section-name",
				sectionVisibility: "set-section-visibility",
				setAssistanceCategory: "set-assistance-category",
				setNextPage: "set-next-page"
			};

			if ($this.hasClass(actions.menuItems)) {
				return;
			}

			if ($this.hasClass(actions.event)) {
				recordState($this, $data.page, $data.id, $data.classes);
			}

			if (
				$this.hasClass(base.classNames.single) ||
				$this.hasClass(base.classNames.multi)
			) {
				getSessionStatus();
			}

			if ($this.hasClass(base.classNames.single)) {
				$(base.elements.container)
					.find(base.elements.singleActive)
					.each(function () {
						$(this).removeClass(base.classNames.active);
					});

				if (
					!$this.hasClass(base.classNames.active) &&
					!$this.hasClass(base.classNames.disabled)
				) {
					$this.addClass(base.classNames.active);
				}

				if ($this.hasClass(actions.dining)) {
					recordDiningState($data.page, $data.id, $data.diningType);
				}

				if ($this.hasClass(actions.orderMethod)) {
					recordOrderMethod($data.page, $data.id, $data.orderMethod);
				}

				if ($this.hasClass(actions.feedbackType)) {
					recordFeedbackType($data.page, $data.id);
				}

				if ($this.hasClass(actions.followup)) {
					recordFollowup($data.page, $data.id);
				}

				if ($this.hasClass(actions.setAssistanceCategory)) {
					recordAssistanceCategory($data.page, $data.id);
				}

				if (!$this.hasClass(base.classNames.disabled)) {
					if ($this.hasClass(actions.dateOfVisit)) {
						recordVisitDateTime(
							$data.page,
							$data.id,
							$data.dataUrl,
							$(base.dateOfVisit.dateOfVisit).val(),
							$(base.dateOfVisit.timeOfVisit).find(":selected").text()
						);
						return;
					}

					if ($this.hasClass(actions.restaurant)) {
						recordRestaurantState(
							$this,
							$data.page,
							$data.restaurant,
							$data.dataUrl,
							$(base.restaurant.city).val(),
							$(base.restaurant.state).val(),
							$(base.restaurant.order).val(),
							$(base.restaurant.phone).val()
						);
						return;
					}

					if ($this.hasClass(actions.feedback)) {
						recordFeedback(
							$data.page,
							$data.id,
							$(base.feedback.textArea).val(),
							$(base.feedback.acceptTerms).prop("checked"),
							$(base.feedback.surveyId).val(),
							$(base.feedback.textArea).data("source")
						);
						return;
					}

					if ($this.hasClass(base.classNames.dataUrl)) {
						// Used on pages like: What was the problem? i.e. My Order, Service & Staff and Restaurant Experience
						// As of Feb. 15th, My Order overrides S&S and RE.
						if ($data.href !== "" && $data.href !== "#") {
							debug("Load Href: " + $data.dataUrl);
							loadUrl($data.href);
							return;
						} else if ($data.dataUrl !== undefined && $data.dataUrl !== "") {
							debug("Load Data-Url: " + $data.dataUrl);
							loadUrl($data.dataUrl);
						}
					}

					// Default
					if ($data.id !== undefined && $data.id !== "") {
						if ($this.hasClass("reload")) {
							location.reload();
							return;
						}

						getAndLoadNextPage($data.id);
					}
				}
			}

			if ($this.hasClass(base.classNames.multi)) {
				var singleSelect = $(base.elements.container)
					.find($(base.elements.single))
					.last();

				if ($this.hasClass(base.classNames.active)) {
					$this.removeClass(base.classNames.active);
				} else {
					$this.addClass(base.classNames.active);
				}

				if (singleSelect !== null && singleSelect !== undefined) {
					var multiSelected = $(base.elements.container).find(
						$(base.elements.multiActive)
					);
					var buttonGroup = $(base.elements.container).find(
						$(base.elements.buttonGroup)
					);

					if ($this.hasClass(actions.sectionVisibility)) {
						setSectionVisibility($data.id);
					}

					// -- DATA URL - Set Conditional Next Page
					var url = singleSelect.data("url");
					if (url !== undefined) {
						setConditionalNextPage(
							singleSelect,
							$data.page,
							$data.id,
							!$this.hasClass(base.classNames.active),
							multiSelected
						);
						return;
					} else {
						setNextPage(singleSelect, $data.id);
					}

					if (!buttonGroup.length || buttonGroup.length === 1) {
						if (multiSelected.length > 0) {
							singleSelect.addClass(base.classNames.active);
							singleSelect.removeClass(base.classNames.disabled);
						} else {
							singleSelect.addClass(base.classNames.disabled);
							singleSelect.removeClass(base.classNames.active);
						}
					} else if (buttonGroup.length > 1) {
						var selectedItems = 0;

						buttonGroup.each(function () {
							if ($(this).is(":visible")) {
								selectedItems += $(this).find(".multi").hasClass("active")
									? 1
									: 0;
							}
						});

						if (buttonGroup.length === selectedItems) {
							singleSelect.addClass(base.classNames.active);
							singleSelect.removeClass(base.classNames.disabled);
						} else {
							singleSelect.addClass(base.classNames.disabled);
							singleSelect.removeClass(base.classNames.active);
						}
					} else {
						singleSelect.addClass(base.classNames.disabled);
						singleSelect.removeClass(base.classNames.active);
					}
				}
			}
		});
	};

	$.fn.restaurantLocator = function (options) {
		var base = this;
		base.$el = $(this);
		base.init = function () {
			var defaults = {
				phone: { regex: /\D/g, characters: 10, beginningCharsToRemove: "+,1" }
			};
			base.settings = $.extend({}, defaults, options);
			base.elements = {
				container: ".restaurant-locator",
				city: ".restaurant-city",
				state: ".restaurant-state",
				phone: ".restaurant-phone",
				phoneParentDiv: "div.input.input--full",
				searchByReceipt: ".search-by-receipt",
				searchResults: ".lll-search-results",
				template: "#restaurantLocatorTemplate",
				select: "select",
				selectList: ".selectlist"
			};
			base.classNames = {
				active: "active",
				error: "error",
				findByPhone: "find-by-phone",
				findByLocation: "find-by-location",
				restaurantState: "restaurant-state"
			};
			base.ajax = {
				getCitiesByState: "/cfacom/restaurantlocator/getcitiesbystate",
				findByCityState: "/cfacom/restaurantlocator/findbycityandstate",
				findByPhone: "/cfacom/restaurantlocator/findbyphone"
			};
		};

		base.init();

		function debug(message) {
			if (window.console && window.console.log) {
				window.console.log(message);
			}
		}

		function replaceAt(string, index, replace) {
			return string.substring(0, index) + replace + string.substring(index + 1);
		}

		function cleansePhone(phone) {
			// TODO: Move Phone Functions outside plugin to global if possible.
			// Per Jira Ticket: https://alm.cfadevelop.net/jira/browse/CFAC-1819
			var cleansedPhone = phone.trim();
			var beginningChars = base.settings.phone.beginningCharsToRemove.split(
				","
			);

			for (var i = 0; i < beginningChars.length; i++) {
				if (cleansedPhone.startsWith(beginningChars[i])) {
					cleansedPhone = replaceAt(cleansedPhone, 0, "");
				}
			}

			return cleansedPhone;
		}

		function validatePhone(phone) {
			// TODO: Move Phone Functions outside plugin to global if possible.
			// Per Jira Ticket: https://alm.cfadevelop.net/jira/browse/CFAC-1819
			var cleanIt = cleansePhone(phone);
			var retVal = cleanIt.replace(base.settings.phone.regex, "");
			return retVal.length === base.settings.phone.characters;
		}

		function handleBarsConditional() {
			Handlebars.registerHelper("eq", function () {
				var args = Array.prototype.slice.call(arguments, 0, -1);
				return args.every(function (expression) {
					return args[0] === expression;
				});
			});
		}

		function tabToggle(ele) {
			var tab = ele,
				tabContent = ele.data("tab"),
				tabButtons = $(".btn--toggle"),
				currentTab = $('.btn--toggle[data-tab="' + tabContent + '"]'),
				tabContainers = $(".lll-search-content"),
				currentTabContent = $(
					'.lll-search-content[data-tab="' + tabContent + '"]'
				);

			tabButtons.removeClass(base.classNames.active);
			currentTab.addClass(base.classNames.active);
			tabContainers.removeClass(base.classNames.active);
			currentTabContent.addClass(base.classNames.active);
		}

		function getCitiesByState(state) {
			if (state === undefined || state === "") {
				debug("Get Cities by State: State isn't defined");
				return;
			}

			debug("Get Cities by State: " + state);

			$.ajax({
				url: base.ajax.getCitiesByState,
				type: "Get",
				data: { state: state }
			}).done(function (results) {
				if (results !== "") {
					$(base.elements.city).find("option:gt(0)").remove();

					var jsonData = JSON.parse(results);
					$(jsonData).each(function (index, o) {
						var $option = $("<option/>").attr("value", o.Value).text(o.Value);
						$(base.elements.city).append($option);
					});
				}
			});
		}

		function findByPhone(datasourceId, phone, errorElement) {
			debug("Find by Phone: " + phone);
			var listTemplate = Handlebars.compile($(base.elements.template).html());

			$(base.elements.searchResults).html("");
			errorElement.removeClass(base.classNames.error);

			$.ajax({
				url: base.ajax.findByPhone,
				type: "Get",
				data: { datasourceId: datasourceId, phone: phone }
			}).done(function (data) {
				if (data !== "") {
					handleBarsConditional();
					$(base.elements.searchResults).append(
						listTemplate(JSON.parse(data).Collection)
					);
				} else {
					$(base.elements.searchResults).append(listTemplate(""));
				}
			});
		}

		function findByCityState(
			datasourceId,
			state,
			city,
			stateErrorEle,
			cityErrorEle
		) {
			if (state === "" || city === "") {
				return;
			}

			debug("Find by City & State - State: " + state + " - City: " + city);

			var listTemplate = Handlebars.compile($(base.elements.template).html());

			$(base.elements.searchResults).html("");
			stateErrorEle.removeClass(base.classNames.error);
			cityErrorEle.removeClass(base.classNames.error);

			$.ajax({
				url: base.ajax.findByCityState,
				type: "Get",
				data: { datasourceId: datasourceId, city: city, state: state }
			}).done(function (data) {
				if (data !== "") {
					handleBarsConditional();
					$(base.elements.searchResults).html(
						listTemplate(JSON.parse(data).Collection)
					);
				} else {
					$(base.elements.searchResults).html(listTemplate(""));
				}
			});
		}

		base.$el.on("change", base.elements.select, function () {
			if ($(this).hasClass(base.classNames.restaurantState)) {
				var selected = $(base.elements.state).find(":selected").val();
				getCitiesByState(selected);
			}
		});

		base.$el.on("click", "span.btn--toggle", function (e) {
			var $this = $(this);
			tabToggle($this);
		});

		base.$el.on("click", ".btn.restaurant-locator", function (e) {
			LLL.scrollToResults();
		});

		base.$el.on("click", ".restaurant-locator", function (e) {
			e.preventDefault();
			var $this = $(this);
			var datasourceId = $this.data("source");

			if ($this.hasClass(base.classNames.findByLocation)) {
				var stateErrorElement = $(base.elements.state).closest(
					base.elements.selectList
				);
				var cityErrorElement = $(base.elements.city).closest(
					base.elements.selectList
				);
				var state = $(base.elements.state).find(":selected").val();
				var city = $(base.elements.city).find(":selected").val();
				var hasError = false;

				if (state === "") {
					stateErrorElement.addClass(base.classNames.error);
					hasError = true;
				} else {
					stateErrorElement.removeClass(base.classNames.error);
				}

				if (city === "") {
					cityErrorElement.addClass(base.classNames.error);
					hasError = true;
				} else {
					cityErrorElement.removeClass(base.classNames.error);
				}

				if (!hasError) {
					findByCityState(
						datasourceId,
						state,
						city,
						stateErrorElement,
						cityErrorElement
					);
				}
			} else if ($this.hasClass(base.classNames.findByPhone)) {
				var errorElement = $(base.elements.phone).closest(
					base.elements.phoneParentDiv
				);
				var phone = $(base.elements.phone).val();
				var cleanPhone = cleansePhone(phone);

				if (validatePhone(cleanPhone)) {
					findByPhone(datasourceId, cleanPhone, errorElement);
				} else {
					errorElement.addClass(base.classNames.error);
				}
			}
		});
	};

		$.fn.restaurantByReceipt = function (options) {
		var base = this;
		base.$el = $(this);
		base.init = function () {
			var defaults = {
				number: { regex: /\D/g, characters: 5 }
			};
			base.settings = $.extend({}, defaults, options);
			base.elements = {
				closeModal: ".mfp-close2",
				container: ".lll-container",
				//container: '.restaurant-by-receipt',
				dateOfVisit: ".date-of-visit",
				findByNumber: "a.find-by-number",
				findAnotherWay: ".find-another-way",
				foundCorrectRestaurant: ".found-correct-restaurant",
				modal: "#confirmLocation",
				orderNumber: ".order-number",
				number: ".restaurant-number",
				numberParentDiv: "div.input.input--full",
				numberInModal: "#invalid-number",
				searchByPhone: ".search-by-number",
				searchResults: "#lll-results",
				select: "select",
				selectList: ".selectlist",
				template: "#restaurantLocatorTemplate",
				timeOfVisit: ".time-of-visit",
				timeOfVisitParent: "span.selectlist",
				userSelectedTime: "#user-selected-time"
			};
			base.classNames = {
				active: "active",
				error: "error",
				findByNumber: "find-by-number"
			};
			base.ajax = {
				findByNumber: "/cfacom/restaurantlocator/findbynumber",
				GetStoreHourIntervals: "/cfacom/dateofvisit/getstorehourintervals",
				IsInThePresentOrPast: "/cfacom/dateofvisit/isinthepresentorpast",
				RecordRestaurantByReceipt:
					"/cfacom/listenlovelearnajax/recordrestaurantbyreceipt"
			};

			if ($("#receipt-form").length > 0) {
				var receipt = new CFACom.ReceiptHighlighter($("#receipt-form"));
				receipt;
			}
		};

		base.init();

		function replaceAt(string, index, replace) {
			return string.substring(0, index) + replace + string.substring(index + 1);
		}

		function cleanseNumber(number) {
			return number.trim();
		}

		function validateNumber(number) {
				var cleanIt = cleanseNumber(number);
			var retVal = cleanIt.replace(base.settings.number.regex, "");
			return retVal.length === base.settings.number.characters;
		}

		function findByNumber(datasourceId, number, errorElement) {
			var listTemplate = Handlebars.compile($(base.elements.template).html());

			$(base.elements.searchResults).html("");
			errorElement.removeClass(base.classNames.error);

			$.ajax({
				url: base.ajax.findByNumber,
				type: "Get",
				data: { datasourceId: datasourceId, number: number }
			}).done(function (data) {
				$(base.elements.searchResults).html("");
				if (data !== "") {
					handleBarsConditional();
					$(base.elements.searchResults).append(
						listTemplate(JSON.parse(data).Collection)
					);
				} else {
					$(base.elements.searchResults).append(listTemplate(""));
					$(base.elements.modal).addClass("med-width");
					$(base.elements.modal)
						.find(base.elements.numberInModal)
						.text($(base.elements.number).val());

					var time = $(base.elements.timeOfVisit).val();
					var newTime = time.substring(0, time.length - 3);
					var url =
						$(base.elements.findAnotherWay).data("url") +
						"?date=" +
						$(base.elements.dateOfVisit).val() +
						" " +
						newTime +
						"&orderNumber=" +
						$(base.elements.orderNumber).val() +
						"&page=" +
						$(base.elements.findAnotherWay).data("page");
					$(base.elements.findAnotherWay).data("url", encodeURI(url));
				}

				openModal($(base.elements.modal));
			});
		}

		function validateDate(testdate) {
			var date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
			return date_regex.test(testdate);
		}

		function getValidTimeIntervals(val) {
			var timeOfVisitParent = $(base.elements.timeOfVisit).closest(
				base.elements.timeOfVisitParent
			);
			var dateTime = new Date();
			var localeDateTime =
				dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString();

			$.ajax({
				url: base.ajax.GetStoreHourIntervals,
				type: "GET",
				data: { date: val, visitorsLocalTime: localeDateTime }
			}).done(function (results) {
				$(base.elements.timeOfVisit).empty();

				if (results === "") {
					timeOfVisitParent.addClass(base.classNames.error);
					return;
				} else {
					timeOfVisitParent.removeClass(base.classNames.error);
				}

				var timeIntervals = results.split(",");

				$(base.elements.timeOfVisit).append(
					$("<option />").val("-").text($("input.default-time").val())
				);

				for (var i = 0; i < timeIntervals.length; i++) {
					$(base.elements.timeOfVisit).append(
						$("<option />").val(timeIntervals[i]).text(timeIntervals[i])
					);
				}

				$(
					base.elements.timeOfVisit +
						' option[value="' +
						$(base.elements.userSelectedTime).val() +
						'"]'
				).attr("selected", true);
			});
		}

		function openModal(modal) {
			$.magnificPopup.open({
				items: {
					src: modal,
					type: "inline"
				},
				closeOnContentClick: false,
				closeOnBgClick: false,
				showCloseBtn: false,
				enableEscapeKey: true
			});
		}

		base.$el.on("focus", base.elements.dateOfVisit, function () {
			$(this).datepicker({
				changeMonth: true,
				changeYear: true,
				minDate: -365,
				maxDate: 0,
				beforeShowDay: function (date) {
					var dateTime = new Date();
					//If the date = today and the Hour < 5am (Store Opening), disable today
					if (
						dateTime.getHours() < 5 &&
						dateTime.getDate() === date.getDate()
					) {
						return [date.getDay() !== dateTime.getDay(), ""];
					}

					var day = date.getDay();
					return [day !== 0, ""];
				},
				onSelect: function (val, obj) {
					getValidTimeIntervals(val);
				}
			});
		});

		base.$el.on("change", base.elements.timeOfVisit, function () {
			var $this = $(this);
			var $timeOfVisit = $(base.elements.timeOfVisit);
			var $timeOfVisitParent = $this.closest(base.elements.timeOfVisitParent);

			if ($timeOfVisit.val() === "" || $timeOfVisit.val() === "-") {
				$timeOfVisitParent.addClass(base.classNames.error);
			} else {
				$timeOfVisitParent.removeClass(base.classNames.error);
			}
		});

		base.$el.on("keyup", base.elements.dateOfVisit, function () {
			var $this = $(this);
			var $dateOfVisitParent = $this.closest(base.elements.dateOfVisitParent);
			var val = $this.val();

			if (!validateDate(val)) {
				$dateOfVisitParent.addClass(base.classNames.error);
				return;
			}

			var dateTime = new Date();
			var localeDateTime =
				dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString();

			$.ajax({
				url: base.ajax.IsInThePresentOrPast,
				type: "Get",
				data: { date: val, visitorsLocalTime: localeDateTime }
			}).done(function (results) {
				if (results === "True") {
					getValidTimeIntervals(val, $(base.elements.timeOfVisit));
					$dateOfVisitParent.removeClass(base.classNames.error);
				} else {
					$dateOfVisitParent.addClass(base.classNames.error);
				}
			});
		});

		base.$el.on("click", base.elements.findByNumber, function (e) {
			e.preventDefault();
			var $this = $(this);
			var datasourceId = $this.data("source");
			var numberParent = $(base.elements.number).closest(
				base.elements.numberParentDiv
			);
			var dateOfVisit = $(base.elements.dateOfVisit);
			var dateOfVisitParent = dateOfVisit.closest("div");
			var timeOfVisit = $(base.elements.timeOfVisit);
			var timeOfVisitParent = timeOfVisit.closest("span");
			var number = cleanseNumber($(base.elements.number).val());
			var validNumber = validateNumber(number);

			if (timeOfVisit.val() === "-") {
				timeOfVisitParent.addClass(base.classNames.error);
			} else {
				timeOfVisitParent.removeClass(base.classNames.error);
			}

			if (dateOfVisit.val() === "") {
				dateOfVisitParent.addClass(base.classNames.error);
			} else {
				dateOfVisitParent.removeClass(base.classNames.error);
			}

			if (!validNumber) {
				numberParent.addClass(base.classNames.error);
			} else {
				numberParent.removeClass(base.classNames.error);
			}

			if (validNumber && timeOfVisit.val() !== "-" && dateOfVisit.val() !== "") {
				findByNumber(datasourceId, number, numberParent);
			}
		});

		$("body").on("click", base.elements.foundCorrectRestaurant, function (e) {
			e.preventDefault();
			var $this = $(this);
			var dateOfVisit = $(base.elements.dateOfVisit).val();
			var timeOfVisit = $(base.elements.timeOfVisit).val();
			var orderNumber = $(base.elements.orderNumber).val();
			var pageId = $this.data("page");
			var restaurantId = $this.data("id");
			var url = $this.data("url");

			$.magnificPopup.close();
			LLL.startFadingAnim();

			$.ajax({
				url: base.ajax.RecordRestaurantByReceipt,
				type: "POST",
				data: {
					pageId: pageId,
					restaurantId: restaurantId,
					orderNumber: orderNumber,
					date: dateOfVisit,
					time: timeOfVisit
				}
			}).done(function (results) {
				if (results === "True") {
					$(base.elements.container).load(url, function () {
						LLL.endFadingAnim();
						pushDataLayer();
					});
				} else {
					LLL.endFadingAnim();
				}
			});
		});

		$("body").on("click", base.elements.findAnotherWay, function (e) {
			e.preventDefault();
			$.magnificPopup.close();
			LLL.startFadingAnim();
			$(base.elements.container).load($(this).data("url"), function () {
				LLL.endFadingAnim();
				pushDataLayer();
			});
		});

		$("body").on("click", base.elements.closeModal, function (e) {
			e.preventDefault();
			$.magnificPopup.close();
		});

		function handleBarsConditional() {
			Handlebars.registerHelper("eq", function () {
				var args = Array.prototype.slice.call(arguments, 0, -1);
				return args.every(function (expression) {
					return args[0] === expression;
				});
			});
		}

		Handlebars.registerHelper("cfaStatus", function (
			arg1,
			arg2,
			arg3,
			options
		) {
			if (arg1 === null || arg1 === undefined) {
				arg1 = "";
			}

			var status = arg1.toLowerCase();

			if (status !== arg2) {
				return "";
			}

			if (status === arg2 && arg3 !== "") {
				return '<p class="orange-text">' + arg3 + "</p>";
			}

			if (status === arg2 && arg3 === "") {
				return '<p class="orange-text">' + options.fn(this) + "</p>";
			}

			return options.inverse(this);
		});
	};

	$.fn.menuItems = function (options) {
		var base = this;
		base.$el = $(this);

		base.init = function () {
			var defaults = {};
			base.options = $.extend({}, defaults, options);
			base.elements = {
				container: ".lll-container",
				feedbackOption: ".feedback-option",
				modalClose: ".mfp-close",
				modalOverlay: ".overlay-compliment",
				modalConfirmSelections: ".confirm-selections",
				multiActive: ".multi.active",
				overlay: ".overlay"
			};
			base.classNames = {
				active: "active",
				disabled: "disabled",
				gFormGroupError: "g-form__group--error"
			};
			base.menuItems = {
				active: "li.menu-item.active",
				container: ".lll-cards__menu",
				li: "li.menu-item",
				overlay: ".overlay",
				setBtn: ".set-menu-items",
				otherTb: ".otherMenuItem"
			};
			base.ajax = {
				RecordSelectedItems:
					"/cfacom/listenlovelearnajax/recordselectedmenuitems",
				setNextPage: "/cfacom/listenlovelearnajax/setnextpage"
			};
		};

		base.init();

		function debug(message) {
			if (window.console && window.console.log) {
				window.console.log(message);
			}
		}

		// Popup Modal - Menu Item List Step ---------------------------------------
		base.$el.on("click", base.menuItems.li, function () {
			var $this = $(this);
			var $modal = $this.find(base.elements.modalOverlay);
			var $activeLIs = $(base.menuItems.container).find(base.menuItems.active);

			if (!$this.hasClass("active") && $activeLIs.length < 5) {
				openModal($modal);
			} else {
				clearSelectedOptions($this, $modal);
			}

			toggleError($activeLIs);
			toggleContinue();
		});

		// Modal - Toggle Selections -----------------------------------------------
		$("body").on("click", ".overlay-compliment li.feedback-option", function (
			e
		) {
			var $this = $(this);

			$this.toggleClass("selected");
			setActiveIcon($this);
			e.preventDefault();
		});

		// Modal - Clear (unconfirmed) Selections on click of X (close) ------------
		$("body").on("click", base.elements.modalClose, function (e) {
			var $this = $(this);
			var $selected = $this
				.closest(base.elements.modalOverlay)
				.find("li.selected");
			var $otherMenuItem = $this
				.closest(base.elements.modalOverlay)
				.find("input.otherMenuItem");

			if ($otherMenuItem.val() !== undefined) {
				$otherMenuItem.val("");
			}

			$selected.each(function () {
				// Do not clear confirmed selections
				if (!$(this).hasClass("confirmed")) {
					$(this).removeClass("selected");
				}
			});

			e.preventDefault();
		});

		// Modal - Confirm Selections ----------------------------------------------
		$("body").on("click", base.elements.modalConfirmSelections, function (e) {
			var $this = $(this);
			var $overlay = $this.closest(base.elements.modalOverlay);
			var $selectedOptions = $overlay.find("li.selected");

			if ($this.hasClass(base.classNames.disabled)) {
				return;
			}

			if ($selectedOptions.length) {
				var selectedOptionGuids = [];
				$selectedOptions.each(function (i, obj) {
					selectedOptionGuids.push($(obj).data("id"));
				});

				// Adding 'Confirmed' to selected options to differentiate confirmed
				// selections and unconfirmed selections when exiting the modal
				$selectedOptions.addClass("confirmed");

				$.magnificPopup.close();
				var menuItemLi = $this.closest(base.menuItems.li);
				menuItemLi.find(base.menuItems.overlay).show();
				menuItemLi.addClass("active");
				toggleContinue();
			}

			e.preventDefault();
		});

		// Modal - Remove Disabled Class from Confirm Selections
		$("body").on("click", base.elements.feedbackOption, function (e) {
			e.preventDefault();
			var $this = $(this);
			var $overlay = $this.closest(base.elements.modalOverlay);
			var $selectedOptions = $overlay.find("li.selected");
			var $otherTb = $overlay.find('input[type="text"]');

			if ($selectedOptions.length && $otherTb.val() !== undefined) {
				var val = $otherTb.val().length;
				var max = $otherTb.attr("maxLength");
				var min = $otherTb.attr("minLength");

				if (val < min || val > max) {
					$(base.elements.modalConfirmSelections).addClass(
						base.classNames.disabled
					);
					return;
				} else {
					$(base.elements.modalConfirmSelections).removeClass(
						base.classNames.disabled
					);
				}
			}
			if ($selectedOptions.length) {
				$(base.elements.modalConfirmSelections).removeClass(
					base.classNames.disabled
				);
			} else {
				$(base.elements.modalConfirmSelections).addClass(
					base.classNames.disabled
				);
			}
		});

		$("body").on("blur keyup", base.menuItems.otherTb, function (e) {
			var $this = $(this);
			var txt = $this.val();
			txt = trimStart(" ", txt);
			$this.val(txt);

			validateOtherMenuItem($this);
		});

		$("body").on("blur", base.menuItems.otherTb, function (e) {
			var $this = $(this);
			var txt = $this.val();
			$this.val(txt.trim());

			validateOtherMenuItem($this);
		});

		function validateOtherMenuItem(ele) {
			var $container = ele.closest("div");
			var $error = $container.find("p.error");
			var $overlay = ele.closest(base.elements.modalOverlay);
			var $selectedOptions = $overlay.find("li.selected");
			var val = ele.val().length;
			var max = ele.attr("maxLength");
			var min = ele.attr("minLength");

			if (val < min || val > max) {
				$container.addClass(base.classNames.gFormGroupError);
				$error.show();

				$(base.elements.modalConfirmSelections).addClass(
					base.classNames.disabled
				);
			} else {
				$container.removeClass(base.classNames.gFormGroupError);
				$error.hide();

				if ($selectedOptions.length) {
					$(base.elements.modalConfirmSelections).removeClass(
						base.classNames.disabled
					);
				}
			}
		}

		// Send Selection -------------------------------------------------------------------------------------------------------------------
		base.$el.on("click", base.menuItems.setBtn, function (e) {
			e.preventDefault();
			var $this = $(this);
			var menuItemArray = [];
			var $menuItems = $(".lll-cards__menu").find("li.menu-item.active");
			var otherMenuItem = $menuItems
				.find("div#mclr-other-modal input.otherMenuItem")
				.val();

			if ($this.hasClass(base.classNames.disabled)) {
				return;
			}

			$menuItems.each(function (i) {
				var $options = $(this).find("li.selected.confirmed");
				var selectedOptions = [];

				if ($options.length) {
					$options.each(function (o, obj) {
						selectedOptions.push($(obj).data("name"));
					});
				}

				menuItemArray.push({
					itemId: $(this).data("id"),
					externalId: $(this).data("tag"),
					selectedOptions: selectedOptions
				});
			});

			LLL.startFadingAnim();

			$.ajax({
				url: base.ajax.RecordSelectedItems,
				type: "POST",
				data: {
					pageId: $this.data("page"),
					categoryKey: $this.data("categorykey"),
					menuItemsObj:
						'{ "menuItems" : ' + JSON.stringify(menuItemArray) + " }",
					otherMenuItem: otherMenuItem
				}
			}).done(function (data) {
				if (data === "True") {
					getAndLoadNextPage($this.attr("href"));
				}
			});
		});

		// Toggle the Continue button -------------------------------------------------------------------------------------------------------
		function toggleContinue() {
			var singleSelect = base.$el.find(base.menuItems.setBtn).last();

			if ($(base.menuItems.li).hasClass(base.classNames.active)) {
				singleSelect.removeClass(base.classNames.disabled);
			} else {
				singleSelect.addClass(base.classNames.disabled);
			}
		}

		// Open the Modal ----------------------------------------------------------
		function openModal(modal) {
			$.magnificPopup.open({
				items: {
					src: modal,
					type: "inline"
				},
				closeOnContentClick: false,
				closeOnBgClick: false,
				showCloseBtn: false,
				enableEscapeKey: true
			});
		}

		// Clear the Selected Options -------------------------------------------------------------------------------------------------------
		function clearSelectedOptions(menuItemLi, modal) {
			menuItemLi.removeClass(base.classNames.active);
			menuItemLi.find(base.menuItems.overlay).hide();

			modal.find("input.otherMenuItem").val("");

			// Remove Selected Confirmed from the options
			modal.find("li.selected.confirmed").each(function () {
				$(this).removeClass("selected");
				$(this).removeClass("confirmed");
				setActiveIcon($(this));
			});
		}

		// -------------------------------------------------------------------------
		function setActiveIcon(li) {
			var $this = li;
			var $icon = $this.find("img");
			var origIcon = $icon.data("original");
			var activeIcon = $icon.data("active");

			if ($this.hasClass("selected")) {
				$icon.attr("src", activeIcon);
			} else {
				$icon.attr("src", origIcon);
			}
		}

		// -------------------------------------------------------------------------
		function toggleError(activeLIs) {
			if (activeLIs.length === 5) {
				$(".maximum-selections-error-message").show();
			} else {
				$(".maximum-selections-error-message").hide();
			}
		}

		// ----------------------------------------------------------------------------------------------------------------------------------
		function trimStart(character, string) {
			var startIndex = 0;

			while (string[startIndex] === character) {
				startIndex++;
			}

			return string.substr(startIndex);
		}

		// ----------------------------------------------------------------------------------------------------------------------------------
		function getAndLoadNextPage(url) {
			if (url !== undefined && url !== "" && url !== "#") {
				$(base.elements.container).load(url, function () {
					pushDataLayer();
					LLL.endFadingAnim();
				});
			}
		}
	};

	$.fn.visitorInfoShort = function (options) {
		var base = this;
		base.$el = $(this);

		// Set datepicker max date to today
		$("#TimeOfVisitDate").attr("max", new Date().toISOString().split("T")[0]);

		base.$el.on("change", ":input", function (e) {
			var date = $("#TimeOfVisitDate").val();
			var email = $("#Email").val();
			var emailConfirm = $("#ConfirmEmail").val();
			var hasErrors = $(":input").hasClass("error");

			if (
				!hasErrors &&
				date &&
				email &&
				emailConfirm &&
				email == emailConfirm
			) {
				$(".set-visitor-information-short").removeClass("disabled");
			} else {
				$(".set-visitor-information-short").addClass("disabled");
			}
		});

		base.$el.on("click", ".set-visitor-information-short", function (e) {
			var $this = $(this);
			var buttonId = $this.data("id");
			var pageId = $this.data("page");
			var firstName = $("#FirstName").val();
			var lastName = $("#LastName").val();
			var emailAddress = $("#Email").val();
			var emailAddressConfirmation = $("#ConfirmEmail").val();
			var phoneNumber = $("#Phone").val();
			var dateOfSuspiciousActivity = $("#TimeOfVisitDate").val();

			$.ajax({
				url: "/cfacom/listenlovelearnajax/recordvisitorinformationshort",
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: {
					pageId: pageId,
					buttonId: buttonId,
					firstName: firstName,
					lastName: lastName,
					emailAddress: emailAddress,
					emailAddressConfirmation: emailAddressConfirmation,
					phoneNumber: phoneNumber,
					dateOfSuspiciousActivity: dateOfSuspiciousActivity
				}
			}).done(function (results) {});
		});
	};

	$.fn.emailFraud = function (options) {
		var base = this;
		base.$el = $(this);

		// On Radio "Yes/No" click hide/show KnowAddress radio buttons
		base.$el.on("click", "input[id$='RadioOptionWasEmailFraudYes']", function (
			e
		) {
			$("#DivKnowAddressLabel").show();
			$("#DivKnowAddress").show();
		});

		base.$el.on("click", "input[id$='RadioOptionWasEmailFraudNo']", function (
			e
		) {
			$("#RadioOptionKnowAddressNo").trigger("click");
			$("#DivKnowAddressLabel").hide();
			$("#DivKnowAddress").hide();
			$("#DivFraudEmailAddress").hide();
			$(".set-email-fraud").removeClass("disabled");
		});

		// On Radio "Yes/No" click hide/show FraudEmail textbox
		base.$el.on("click", "input[id$='RadioOptionKnowAddressYes']", function (
			e
		) {
			$("#DivFraudEmailAddress").show();

			var isValidEmail = testFraudEmailAddress($("#FraudEmailAddress").val());
			if (!isValidEmail) {
				$(".set-email-fraud").addClass("disabled");
			}
		});
		base.$el.on("click", "input[id$='RadioOptionKnowAddressNo']", function (e) {
			$("#DivFraudEmailAddress").hide();
			$(".set-email-fraud").removeClass("disabled");
		});

		// Check if divs should already be shown
		if ($('input[name="RadioOptionWasEmailFraud"]:checked').val() == "yes") {
			$("#DivKnowAddressLabel").show();
			$("#DivKnowAddress").show();
		}

		if ($('input[name="RadioOptionKnowAddress"]:checked').val() == "yes") {
			$("#DivFraudEmailAddress").show();
		}

		base.$el.on("keyup", "#FraudEmailAddress", function () {
			var isValidEmail = testFraudEmailAddress($("#FraudEmailAddress").val());
			if (isValidEmail) {
				$(".set-email-fraud").removeClass("disabled");
			} else {
				$(".set-email-fraud").addClass("disabled");
			}
		});

		base.$el.on("click", ".set-email-fraud", function (e) {
			var $this = $(this);
			var buttonId = $this.data("id");
			var pageId = $this.data("page");
			var fraudEmailAddress = $("#FraudEmailAddress").val();
			var wasEmailFraud = $(
				'input[name="RadioOptionWasEmailFraud"]:checked'
			).val();
			var knowAddress = $('input[name="RadioOptionKnowAddress"]:checked').val();

			$.ajax({
				url: "/cfacom/listenlovelearnajax/recordemailfraud",
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: {
					pageId: pageId,
					buttonId: buttonId,
					wasEmailFraud: wasEmailFraud,
					knowAddress: knowAddress,
					fraudEmailAddress: fraudEmailAddress
				}
			}).done(function (results) {});
		});
	};

	function testFraudEmailAddress(fraudEmailAddress) {
		var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return regex.test(fraudEmailAddress);
	}

	$.fn.pointsFraud = function (options) {
		var base = this;
		base.$el = $(this);
		var continueBtn = $(".set-points-fraud");
		continueBtn.removeClass("disabled");

		//  On Radio "Yes/No" click hide/show PointsFraudMessage
		base.$el.on("click", "input[id$='RadioOptionWasPointsFraudYes']", function (
			e
		) {
			$("#DivPointsFraudMessage").show();
			continueBtn.addClass("disabled");
		});
		base.$el.on("click", "input[id$='RadioOptionWasPointsFraudNo']", function (
			e
		) {
			$("#DivPointsFraudMessage").hide();
			continueBtn.removeClass("disabled");
		});

		// Check if divs should already be shown
		if ($('input[name="RadioOptionWasPointsFraud"]:checked').val() == "yes") {
			$("#DivPointsFraudMessage").show();
		}

		base.$el.on("keyup", "#PointsFraudMessage", function () {
			updateFraudMessageMinLength();
			validatateFeedback($(this));
		});

		base.$el.on("click", ".set-points-fraud", function (e) {
			var $this = $(this);
			var buttonId = $this.data("id");
			var pageId = $this.data("page");
			var pointsFraudMessage = $("#PointsFraudMessage").val();
			var wasPointsFraud = $(
				'input[name="RadioOptionWasPointsFraud"]:checked'
			).val();

			$.ajax({
				url: "/cfacom/listenlovelearnajax/recordpointsfraud",
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: {
					pageId: pageId,
					buttonId: buttonId,
					wasPointsFraud: wasPointsFraud,
					pointsFraudMessage: pointsFraudMessage
				}
			}).done(function (results) {
				if (results === "False") {
					e.preventDefault();
				}
			});
		});

		function trimStart(character, string) {
			var startIndex = 0;

			while (string[startIndex] === character) {
				startIndex++;
			}

			return string.substr(startIndex);
		}

		function cleanTheText(textArea) {
			var txt = removeEmoticons(textArea);
			txt = trimStart(" ", txt);
			txt = txt.trim();
			return txt;
		}

		function removeEmoticons(val) {
			val = val.replace("\u201c", '"').replace("\u201d", '"'); //left and right double quote
			val = val.replace("\u2018", "'").replace("\u2019", "'"); //left and right single quote
			return val.replace(/[^\x00-\x7F]/g, "");
		}

		function validatateFeedback(textArea) {
			var singleSelect = base.$el.find(".set-points-fraud");
			var min = textArea.data("minchar");
			var max = textArea.data("maxchar");
			var txt = cleanTheText(textArea.val());
			textArea.val(txt);

			if (txt.length < min || txt.length > max) {
				singleSelect.addClass("disabled");
				textArea.css("border", "3px solid #dd0031");
				textArea.addClass("error");
			} else if (txt.length >= min && txt.length <= max) {
				singleSelect.removeClass("disabled");
				textArea.removeClass("error");
				textArea.css("border", "");
			}
		}
	};

	function updateFraudMessageMinLength() {
		var text = $("#PointsFraudMessage").val();
		$(".field-count").html(text.length);
	}

	$.fn.ordersFraud = function (options) {
		var base = this;
		base.$el = $(this);

		// Check if divs should already be shown
		if ($('input[name="RadioOptionWasOrdersFraud"]:checked').val() == "yes") {
			$("#DivOrdersFraudResturantLocatorLabel").show();
			$("#DivOrdersFraudResturantLocator").show();
		}

		if ($("#mclrRestaurantMap").length) {
			if (window.addEventListener) {
				window.addEventListener("message", getRestaurantId, false);
			} else {
				window.attachEvent("onmessage", getRestaurantId);
			}
		}

		base.$el.on("click", "input[id$='RadioOptionWasOrdersFraudYes']", function (
			e
		) {
			$("#DivOrdersFraudResturantLocatorLabel").show();
			$("#DivOrdersFraudResturantLocator").width("100%");
			$("#DivOrdersFraudResturantLocator").height(800);
		});
		base.$el.on("click", "input[id$='RadioOptionWasOrdersFraudNo']", function (
			e
		) {
			$("#DivOrdersFraudResturantLocatorLabel").hide();
			$("#DivOrdersFraudResturantLocator").width(1);
			$("#DivOrdersFraudResturantLocator").height(1);
		});

		function getRestaurantId(evt) {
			if (typeof evt.data.restaurantId !== "undefined") {
				var $this = $(this);
				var buttonId = $(".set-orders-fraud").data("id");
				var pageId = $(".set-orders-fraud").data("page");

				var wasOrdersFraud = $(
					'input[name="RadioOptionWasOrdersFraud"]:checked'
				).val();
				var restaurantId = evt.data.restaurantId;

				if (restaurantId !== "") {
					LLL.startFadingAnim();

					$.ajax({
						url: "/cfacom/listenlovelearnajax/recordordersfraud",
						type: "Post",
						async: false,
						cache: false,
						dataType: "text",
						data: {
							pageId: pageId,
							buttonId: buttonId,
							wasOrdersFraud: wasOrdersFraud,
							restaurantId: restaurantId
						}
					}).done(function (result) {
						console.log("result: ", result);

						// Click the continue button
						$(".set-orders-fraud").trigger("click");
					});
				}
			}
		}
	};

	$.fn.fundsFraud = function (options) {
		var base = this;
		base.$el = $(this);

		//  On Radio "Yes/No" click hide/show RestaurantMap
		base.$el.on("click", "input[id$='RadioOptionWasFundsFraudYes']", function (
			e
		) {
			$("#DivFundsFraudPayment").show();
		});
		base.$el.on("click", "input[id$='RadioOptionWasFundsFraudNo']", function (
			e
		) {
			$("#DivFundsFraudPayment").hide();
		});

		// Check if divs should already be shown
		if ($('input[name="RadioOptionWasFundsFraud"]:checked').val() == "yes") {
			$("#DivFundsFraudPayment").show();
		}

		base.$el.on("click", ".set-funds-fraud", function (e) {
			var $this = $(this);
			var buttonId = $this.data("id");
			var pageId = $this.data("page");
			var fundsFraudAmount = $("#FundsFraudDollarAmount").val();
			var fundsFraudPaymentMethod = $("#PaymentMethodDropdown")
				.find(":selected")
				.text();
			var wasFundsFraud = $(
				'input[name="RadioOptionWasFundsFraud"]:checked'
			).val();

			$.ajax({
				url: "/cfacom/listenlovelearnajax/recordfundsfraud",
				type: "Post",
				async: false,
				cache: false,
				dataType: "text",
				data: {
					pageId: pageId,
					buttonId: buttonId,
					wasFundsFraud: wasFundsFraud,
					fundsFraudAmount: fundsFraudAmount,
					fundsFraudPaymentMethod: fundsFraudPaymentMethod
				}
			}).done(function (results) {});
		});
	};
});
