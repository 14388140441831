import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class ObjectFitPolyfill extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		objectFitImages(this._element);
	}
}

ComponentFactory.registerComponent("objectFitPolyfill", ObjectFitPolyfill);
