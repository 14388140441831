export interface IOfferData {
	readonly offerId: string;
	readonly receptionUrl: string;
}

interface ISendGiftResponse {
	readonly data: IOfferData;
	readonly errors: ReadonlyArray<string>;
	readonly statusCode: number;
}

export class RewardService {
	private readonly _endpointUri: string;

	constructor(endpoint: string) {
		this._endpointUri = endpoint;
	}

	public async sendGift(offerId: string, message: string): Promise<IOfferData> {
		const sendGiftModel = { message, offerId };

		const promise = $.post(this._endpointUri, sendGiftModel);

		const result = (await Promise.resolve(promise)) as ISendGiftResponse;

		return result.data;
	}
}
