import { ICfa } from "../models/ICfa";
import { ComponentFactory } from "./ComponentFactory";
import { IServiceFactory } from "./IServiceFactory";
import { PrintService } from "./PrintService";
import { LayoutService } from "./LayoutService";

// TODO: We should implement real dependency injection at some point,
// considering https://github.com/microsoft/tsyringe but open to options.
export class ServiceFactory implements IServiceFactory {
	public readonly componentFactory: ComponentFactory;
	public readonly printService: PrintService;
	public readonly layoutService: LayoutService;

	constructor(app: ICfa) {
		this.componentFactory = new ComponentFactory(app);
		this.printService = new PrintService();
		this.layoutService = new LayoutService();
	}
}
