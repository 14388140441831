import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { MessageGearsService } from "./MessageGearsService";
import { CampaignDBService } from "./CampaignDBService";
import { DataLayerService } from "../../services/DataLayerService";

export class JoinEmailModule extends Component {
	form: HTMLFormElement;
	titleElem: HTMLElement;
	focused: boolean;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.form = this._element.querySelector("form") as HTMLFormElement;
		this.titleElem = this._element.querySelector(".title") as HTMLElement;

		if (!this.form) {
			throw new Error("New form element found in component");
		}

		this.applyValidations();
		// Justication: adding an evtListener - not waiting for complete
		// tslint:disable-next-line: no-floating-promises
		this.handleSubmit();

		// CFAC-34725 Fire whenever a user starts filling out a form.
		// This event is generally fired after user input in the first form field
		this._element
			.querySelector("#email")
			?.addEventListener("focus", e => this.handleUserInput(e));
		this.focused = false;

		// CFAC-34725 Fire whenever a user is presented with a form on a page.
		DataLayerService.pushDataLayerEvent_Forms("form_view", {
			identifier: this.getlayerIdentifier(),
			name: this.getlayerName(),
			type: "JoinEmailModule"
		});
	}

	// CFAC-34725 Fire whenever a user starts filling out a form.
	// This event is generally fired after user input in the first form field
	async handleUserInput(e: Event): Promise<void> {
		if (!this.focused) {
			DataLayerService.pushDataLayerEvent_Forms("form_start", {
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "JoinEmailModule"
			});

			this.focused = true;
		}
	}

	applyValidations(): void {
		const errorClass = "hasError";
		const { errorMessageEmail } = this._element.dataset;
		$(this.form).validate({
			errorClass,
			errorPlacement: (error, element) => {
				error.appendTo(element.parent().siblings(".error-container"));
			},
			highlight: element => {
				$(element).parent().addClass(errorClass);
			},
			messages: {
				email: {
					email: errorMessageEmail ?? "Please enter valid email address",
					required: errorMessageEmail ?? "Please enter valid email address"
				}
			},
			rules: {
				email: {
					email: true,
					required: true
				}
			},
			unhighlight: element => {
				$(element).parent().removeClass(errorClass);
			}
		});
	}

	async handleSubmit(): Promise<void> {
		this.form.addEventListener("submit", async e => {
			e.preventDefault();
			const isFormValid = $(this.form).valid();

			if (isFormValid) {
				const payload = {
					email: this.getEmailFormVal(),
					source: this.getSource()
				};
				console.log({ payload });
				const [response, error] = await this.saveEmailToDb(payload);

				if (error) {
					this.toggleAPIErrorMsg(error.message);

					// CFAC-34725 Fire whenever a user unsuccessfully completes a form
					DataLayerService.pushDataLayerEvent_Forms("form_error", {
						error_category: "JoinEmailModuleFormSubmission",
						error_message: "Error in submitting form.",
						identifier: this.getlayerIdentifier(),
						name: this.getlayerName(),
						type: "JoinEmailModule"
					});

					return;
				}

				this.toggleSubmitDisplay();

				// CFAC-34725 Fire whenever a user successfully completes a form
				DataLayerService.pushDataLayerEvent_Forms("form_complete", {
					identifier: this.getlayerIdentifier(),
					name: this.getlayerName(),
					type: "JoinEmailModule"
				});

				// CFAC-34729 Subscriptions
				DataLayerService.pushDataLayerEvent_Form_Subscrible("subscribe", {
					identifier: this.getlayerIdentifier(),
					name: this.getlayerName(),
					type: "JoinEmailModule",
					method: "email"
				});
			} else {
				// CFAC-34725 Fire whenever a user unsuccessfully completes a form
				DataLayerService.pushDataLayerEvent_Forms("form_error", {
					error_category: "JoinEmailModuleFormSubmission",
					error_message: "Form Has Errors",
					identifier: this.getlayerIdentifier(),
					name: this.getlayerName(),
					type: "JoinEmailModule"
				});
			}
		});
	}

	async saveEmailToDb(payload: {
		email: string;
		source: string;
	}): Promise<{} | undefined> {
		const { submitEndpoint: endpoint } = this._element.dataset;

		if (!endpoint) {
			throw new Error("Missing email field and/or database endpoint value.");
		}

		const response: {} = CampaignDBService.sendEmailToDb(endpoint, payload);

		return response;
	}

	async fetchJWT(): Promise<string | undefined> {
		const { endpoint, payload } = this.getJWTReqItems();
		const jwtToken: { token?: string } | undefined =
			await MessageGearsService.getJWTToken(endpoint, payload);

		if (!jwtToken || !jwtToken?.token) {
			this.toggleAPIErrorMsg();

			return;
		}

		return jwtToken.token;
	}

	toggleAPIErrorMsg(msg?: string): void {
		const { errorMessageApi } = this._element.dataset;
		if (!errorMessageApi) {
			console.warn("No API error message provided. Please check field.");

			return;
		}
		const validator = $(this.form).validate();
		validator.showErrors({
			email: msg ? msg : errorMessageApi
		});
	}

	async submitForm(jwtToken: string): Promise<{} | undefined> {
		const email = this.getEmailFormVal();
		const endpoint = this.getSubmitEndpoint();
		const source = this.getSource();

		const response: {} | undefined = await MessageGearsService.submitEmail(
			jwtToken,
			endpoint,
			email,
			source
		);

		return response;
	}

	toggleSubmitDisplay(): void {
		this.addTextAnimation();
		this.addSuccessClass();
		this.changeToSuccessMsg();
	}

	addTextAnimation(): void {
		const delay = 300;
		const animationClass = "preAnimation";
		this.titleElem.classList.add(animationClass);
		setTimeout(() => {
			this.titleElem.classList.remove(animationClass);
		}, delay);
	}

	addSuccessClass(): void {
		const successClass = "hasSubmittedSuccess";
		this._element.classList.add(successClass);
	}

	changeToSuccessMsg(): void {
		const { successTitle = "", successSubtitle = "" } = this._element.dataset;
		const successTitleElem = this.titleElem.querySelector(
			"[data-element='title']"
		);
		const successSubtitleElem = this.titleElem.querySelector(
			"[data-element='subtitle']"
		);

		if (!successTitleElem || !successSubtitleElem) {
			throw new Error(
				"Element/s not found for selectors: .title [data-element='title'], .title [data-element='subtitle']"
			);
		}

		successTitleElem.innerHTML = successTitle;
		successSubtitleElem.innerHTML = successSubtitle;
	}

	getJWTReqItems(): {
		endpoint: string;
		payload: { username: number; password: string };
	} {
		const {
			jwtEndpoint: endpoint,
			accountId: accountIdString,
			apiKey: password
		} = this._element.dataset;

		if (!endpoint || !accountIdString || !password) {
			throw new Error(
				"Missing dependency for fetching JWT Token. Check jwtEndpoint, accountId, and/or apiKey fielids."
			);
		}
		const username = parseInt(accountIdString, 10);

		return { endpoint, payload: { username, password } };
	}

	getEmailFormVal(): string {
		const inputElem = this._element.querySelector(
			"input[name='email']"
		) as HTMLInputElement;

		if (!inputElem.value) {
			throw new Error(`No input value found for email field: ${inputElem}`);
		}

		return inputElem.value;
	}

	getSubmitEndpoint(): string {
		const { submitEndpoint: endpoint } = this._element.dataset;

		if (!endpoint) {
			throw new Error(
				"submit endpoint value not found. Check submit endpoint field. "
			);
		}

		return endpoint;
	}

	getSource(): string {
		const { source } = this._element.dataset;

		if (!source) {
			throw new Error("source field not populated. Check source field.");
		}

		return source;
	}

	getlayerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	getlayerName(): string {
		return this._element.dataset.layerName || "";
	}
}

ComponentFactory.registerComponent("JoinEmailModule", JoinEmailModule);
