import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

function extractTemplate(context: HTMLElement, selector: string): string {
	const template = context.querySelector<HTMLTemplateElement>(selector);
	const html = template?.innerHTML ?? "";
	template?.parentElement?.removeChild(template);

	return html;
}

export class FadeCarousel extends Component {
	public readonly carouselEl: JQuery;
	public readonly carouselName: string = "";
	public carousel: JQuery | null = null;
	private readonly isInitialized: boolean = false;
	private readonly carouselSlides: NodeListOf<HTMLElement> | null;
	private prevSlide: number | undefined;
	private pagination: boolean = false;
	private asFor: JQuery | null = null;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.carouselSlides =
			_element.querySelectorAll<HTMLElement>(".carousel-item");

		this.carouselName = String(_element.getAttribute("data-carousel-name"));
		if (this.carouselName !== "null") {
			this.carouselEl = $(this.carouselName);
			let asForString = _element.getAttribute("data-as-for") || "";
			this.asFor = $(asForString);
		} else {
			this.carouselEl = $(".fade-carousel");
		}

		this.pagination =
			_element.getAttribute("data-pagination") === "true" ? true : false;

		this.initCarousel();
		const slick = this.carouselEl.slick("getSlick");
		this.prevSlide = slick.defaults.initialSlide;
	}

	initCarousel(): void {
		const carouselOptions: JQuerySlickOptions = {
			accessibility: false,
			cssEase: "linear",
			dots: this.pagination,
			fade: true,
			infinite: true,
			mobileFirst: true,
			nextArrow: extractTemplate(this._element, "template[data-next-arrow]"),
			prevArrow: extractTemplate(this._element, "template[data-prev-arrow]"),
			slidesToShow: 1,
			speed: 500,
			useTransform: false,
			asNavFor: this.asFor ?? null
		};

		$(".g-tabs__toggle--desktop").on("click", () => {
			this.carousel = this.carouselEl.slick(carouselOptions);
		});

		this.carousel = this.carouselEl.slick(carouselOptions);

		$(this.carouselEl)
			.find("div[data-next-arrow]")
			.on("click", () => {
				const slick = this.carouselEl.slick("getSlick");
				this.carouselEl.slick("slickNext");
				DataLayerService.pushDataLayerEvent_Carousel(
					this.carouselEl,
					"next_carousel_slide",
					slick.currentSlide,
					this.prevSlide,
					"FadeCarousel"
				);
				this.prevSlide = slick.currentSlide;
			});

		$(this.carouselEl)
			.find("div[data-prev-arrow]")
			.on("click", () => {
				const slick = this.carouselEl.slick("getSlick");
				this.carouselEl.slick("slickPrev");
				DataLayerService.pushDataLayerEvent_Carousel(
					this.carouselEl,
					"previous_carousel_slide",
					slick.currentSlide,
					this.prevSlide,
					"FadeCarousel"
				);
				this.prevSlide = slick.currentSlide;
			});
	}
}

ComponentFactory.registerComponent("FadeCarousel", FadeCarousel);
