// IE 11 does not support CustomEvent, so we need to provide a workaround.
// See source from MDN:
//
// tslint:disable-next-line: max-line-length
// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill

export function CustomEvent<T>(
	typeArg: string,
	eventInitDict?: CustomEventInit<T>
): CustomEvent<T> {
	// Native method:
	// Lint justification: this is not true in IE.
	// tslint:disable-next-line: strict-type-predicates
	if (typeof window.CustomEvent === "function") {
		return new window.CustomEvent<T>(typeArg, eventInitDict);
	}

	// IE method:
	const evt = document.createEvent("CustomEvent");

	evt.initCustomEvent(
		typeArg,
		eventInitDict?.bubbles || false,
		eventInitDict?.cancelable || false,
		eventInitDict?.detail || null
	);

	return evt;
}
