import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class ViewMore extends Component {
	private readonly viewMoreBTN: HTMLElement | null;
	private readonly buttonLabel: string | undefined;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.buttonLabel = _element.dataset.value;

		this.createWrapElement(_element, document.createElement("div"));
		this.viewMoreBTN = this.createButton(_element);
		this.addButtonClickEvent(this.viewMoreBTN);
	}

	addButtonClickEvent(btn: HTMLElement): void {
		btn?.addEventListener("click", () => {
			const contentWrapper =
				this._element.querySelector<HTMLElement>(".view-more-wrapper");

			if (contentWrapper) {
				contentWrapper.style.height = "auto";
				btn.style.display = "none";
			}
		});
	}

	private createWrapElement(
		target: HTMLElement,
		wrapper: HTMLElement
	): HTMLElement {
		wrapper.classList.add("view-more-wrapper");
		[...target.childNodes].forEach(child => wrapper.appendChild(child));
		target.appendChild(wrapper);

		return wrapper;
	}

	private createButton(parent: HTMLElement | null): HTMLElement {
		const btnDiv = document.createElement("button");
		btnDiv.classList.add("view-more-btn");
		btnDiv.innerHTML = this.buttonLabel as string;
		parent?.appendChild(btnDiv);

		return btnDiv;
	}
}

ComponentFactory.registerComponent("ViewMore", ViewMore);
