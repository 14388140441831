import { CfaContext } from "../../app/CfaContext";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { GiftingLightbox } from "./GiftingLightbox";
import { GiftingService } from "./GiftingService";

export class ReceiveGiftRewardAddedModal extends GiftingLightbox {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
	}

	public static async show(): Promise<void> {
		const factory = CfaContext.services.componentFactory;
		const modal = factory.getComponent<ReceiveGiftRewardAddedModal>(
			ReceiveGiftRewardAddedModal
		);

		if (modal) {
			const success = await modal.claimOffer();
			modal.showMessaging(success ? "success" : "error");
			await modal.show();
		} else {
			console.warn("Reward Added Modal not on page.");
		}
	}

	private showMessaging(msg: "success" | "error"): void {
		const states = ["success", "error"];
		const currentState = msg;
		const removeStates = states.filter(s => s !== currentState);

		this._element.classList.add("state-" + currentState);
		removeStates.forEach(s => this._element.classList.remove("state-" + s));
	}

	private async claimOffer(): Promise<boolean> {
		const offerId = this._element.dataset.offerId || "";
		const service = new GiftingService(this._element.dataset.claimEndpoint);

		return service.claimGift(offerId);
	}
}

ComponentFactory.registerComponent(
	"ReceiveGiftRewardAddedModal",
	ReceiveGiftRewardAddedModal
);
