import { CfaContext } from "../../app/CfaContext";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { GiftingBehavior } from "./GiftingBehavior";
import { GiftingLightbox } from "./GiftingLightbox";

async function withFirstInstance(
	fn: (instance: ReceiveGiftQrModal) => Promise<void> | void
): Promise<void> {
	const factory = CfaContext.services.componentFactory;
	const modal = factory.getComponent<ReceiveGiftQrModal>(ReceiveGiftQrModal);

	if (modal) {
		const result = fn(modal);
		await Promise.all([result]);
	} else {
		console.warn("QR Modal not on page.");
	}
}

export class ReceiveGiftQrModal extends GiftingLightbox {
	public readonly behavior: GiftingBehavior;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.behavior = new GiftingBehavior(_element);

		_element.querySelectorAll("[data-action='print QR code']").forEach(el =>
			el.addEventListener("click", e => {
				e.preventDefault();
				this.print();
			})
		);
	}

	public static async print(): Promise<void> {
		await withFirstInstance(modal => modal.print());
	}

	public static async show(): Promise<void> {
		await withFirstInstance(modal => modal.show());
	}

	public print(): void {
		const printContainer = this._element.querySelector("[data-print]");

		if (printContainer) {
			this._app.services.printService.printHtml(printContainer.innerHTML);
		} else {
			console.warn("Could not locate print container.");
		}
	}
}

ComponentFactory.registerComponent("ReceiveGiftQrModal", ReceiveGiftQrModal);
