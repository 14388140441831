const progressBar = document.querySelector(".progress-bar-anim");

function fillInProgess(elem: Element): void {
	const rawTotal =
		elem.querySelector(".total")?.innerHTML.replace(/\,/g, "") || "";

	const totalAmount = parseInt(rawTotal, 10);
	const rawAccumulated = elem.querySelector(".accumulated")?.innerHTML || "";
	const accumulated = parseInt(rawAccumulated, 10);
	const widthOfElem = elem.querySelector<HTMLElement>(".bar")?.offsetWidth || 0;
	const percent = accumulated / totalAmount;
	const widthOfProgress = Math.round(percent * widthOfElem);
	const progressFillIn = elem.querySelector<HTMLElement>(".progress");

	if (progressFillIn) {
		const minimumThreshold = 0.18;
		if (percent < minimumThreshold) {
			progressFillIn.style.width = "35px";
		} else if (percent >= 1) {
			progressFillIn.style.width = widthOfElem.toString() + "px";
		} else {
			progressFillIn.style.width = widthOfProgress.toString() + "px";
		}
	}
}

if (progressBar) {
	fillInProgess(progressBar);
}
