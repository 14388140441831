// tslint:disable-next-line: no-unnecessary-class
export class GiftCardService {
	public static async sendForm(
		endpoint: string,
		payload: FormData
	): Promise<T> {
		try {
			const promise = $.ajax({
				data: payload,
				type: "POST",
				url: endpoint,
				cache: false,
				contentType: false,
				processData: false
			});
			const result = await Promise.resolve(promise);

			return [result, null];
		} catch (err) {
			console.warn(err);

			return [null, err];
		}
	}
}
