// tslint:disable
// import { getUserProfileTokenMock } from "./mocks/getUserProfileTokenMock";
// For retrieving and returning CFA bearerToken
export async function getToken(endpoint: string): Promise<string> {
	try {
		const promise = $.get(endpoint);
		const result = await Promise.resolve(promise);
		// tslint:disable-next-line: no-unsafe-any

		return result.Bearer;
	} catch (err) {
		throw new Error(
			`Problem retrieving DXE Bearer Token: ${JSON.stringify(err)}`
		);
	}
}

export async function getUserProfileToken(
	endpoint: string
): Promise<{ CFAID: string; Authenticated: boolean; Bearer: string }> {
	try {
		const promise = $.get(endpoint);
		const result = await Promise.resolve(promise);

		if (!result.Authenticated) {
			console.warn(
				`Authentication field is not true. Cannot save custom address to User Profile. Result: ${result}`
			);
		}

		return result;
	} catch (err) {
		throw new Error(
			`Problem retrieving DXE Bearer Token: ${JSON.stringify(err)}`
		);
	}
}
