export const Validation = {
	cfaHighlight(element: Element, errorClass: string): void {
		const $el = $(element);
		if ($el.attr("type") === "checkbox") {
			// we may be styled or part of a list
			$el.closest(".checkbox").addClass(errorClass);
		} else {
			$el.closest("p").addClass(errorClass);
		}
	},

	cfaUnhighlight(element: Element, errorClass: string): void {
		const $el = $(element);
		if ($el.attr("type") === "checkbox") {
			$el.closest(".checkbox").removeClass(errorClass);
		} else {
			$el.closest("p").removeClass(errorClass);
		}
	}
};
