import { Component } from "../../../components/base/Component";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { ICfa } from "../../../models/ICfa";
import { DataLayerService } from "../../services/DataLayerService";

export class FormEmailInsiders extends Component {
	form: HTMLFormElement;
	focused: boolean;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.form = this._element as HTMLFormElement;
		// tslint:disable-next-line
		this.initialize();

		// CFAC-34725 Fire whenever a user starts filling out a form.
		this._element
			.querySelector("#FirstName")
			?.addEventListener("focus", e => this.handleUserInput(e));
		this.focused = false;

		// CFAC-34725 Fire whenever a user is presented with a form on a page.
		DataLayerService.pushDataLayerEvent_Forms("form_view", {
			identifier: this.getlayerIdentifier(),
			name: this.getlayerName(),
			type: "FormEmailInsiders"
		});
	}

	// CFAC-34725 Fire whenever a user starts filling out a form.
	async handleUserInput(e: Event): Promise<void> {
		if (!this.focused) {
			DataLayerService.pushDataLayerEvent_Forms("form_start", {
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "FormEmailInsiders"
			});

			this.focused = true;
		}
	}

	private async initialize(): Promise<void> {
		this.form.addEventListener("submit", e => this.handleSubmission(e));
	}

	private async handleSubmission(e: Event): Promise<void> {
		// CFAC-34725 Fire whenever a user successfully completes a form
		DataLayerService.pushDataLayerEvent_Forms("form_complete", {
			identifier: this.getlayerIdentifier(),
			name: this.getlayerName(),
			type: "FormEmailInsiders"
		});

		// CFAC-34729 Subscriptions
		DataLayerService.pushDataLayerEvent_Form_Subscrible("subscribe", {
			identifier: this.getlayerIdentifier(),
			name: this.getlayerName(),
			type: "FormEmailInsiders",
			method: "email"
		});
	}

	getlayerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	getlayerName(): string {
		return this._element.dataset.layerName || "";
	}
}

ComponentFactory.registerComponent("FormEmailInsiders", FormEmailInsiders);
