import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class LocationModule extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		_element
			.querySelectorAll<HTMLElement>("[data-action='download']")
			.forEach(el => el.addEventListener("click", e => this.trackDownload(e)));
	}

	private trackDownload(e: MouseEvent): void {
		const target = e.target as HTMLElement;
		const data = target?.dataset || {};

		(dataLayer || []).push({
			category: "DeliveryDownloadApp",
			event: "engagement",
			restaurant: data.restaurant || "",
			zipcode: data.zipcode || ""
		});
	}
}

ComponentFactory.registerComponent("LocationModule", LocationModule);
