$(".accord-toggle-btn").on(
	"click keypress",
	function (e: JQuery.TriggeredEvent): void {
		e.preventDefault();

		const $target = $(e.target);

		const $accordContent = $target.next(".accord-toggle-content");

		if (e.key === "Enter" || e.type === "click") {
			if ($target.hasClass("expanded")) {
				$accordContent.stop().slideUp("fast", () => {
					$target.removeClass("expanded");
					$target.attr("aria-expanded", "false");
				});
			} else {
				$accordContent.stop().slideDown("fast", () => {
					$target.addClass("expanded");
					$target.attr("aria-expanded", "true");
				});
			}
		}
	}
);

// Lang toggle
if ($(".breadcrumbs-nav").length > 0) {
	const subnavToggle = $(".lang-accord-toggle");

	subnavToggle.on("click keypress", function (e: JQuery.TriggeredEvent): void {
		e.preventDefault();

		const $target = $(e.target);

		const $accordContent = $target.next(".accord-toggle-content");

		if (e.key === "Enter" || e.type === "click") {
			if ($target.hasClass("expanded")) {
				$accordContent.stop().slideUp("fast", () => {
					$target.removeClass("expanded");
					$target.attr("aria-expanded", "false");
				});
			} else {
				$accordContent.stop().slideDown("fast", () => {
					$target.addClass("expanded");
					$target.attr("aria-expanded", "true");
				});
			}
		}
	});

	$(document).on("click", () => {
		if (subnavToggle.hasClass("expanded")) {
			$(".accord-toggle-content").slideUp();
			subnavToggle.removeClass("expanded");
			subnavToggle.attr("aria-expanded", "false");
		}
	});
}
