import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { ISlide } from "../../legacy/BrightcoveVideo/models/ISlide";
import { DataLayerService } from "../../services/DataLayerService";

class BCVideoModule extends Component {
	public bcScript?: HTMLElement;
	private readonly cssShowModuleClassName: string;
	private isPlayingClass: string = "isPlaying";
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.cssShowModuleClassName = "showModule";
		const accountID = this.getAccountID();
		const playerID = this.getPlayerID();
		const playerHTMLID = this.getPlayerHTMLID();

		if (!playerHTMLID) {
			throw new Error("Missing element id attribute on video HTML element.");
		}

		this.initBcScript({ accountID, playerID });
		this.initPlayer(playerHTMLID);
	}

	initBcScript({
		accountID = "1918791242001",
		playerID = "qY8LyOaAw_default"
	}: {
		accountID: string | undefined;
		playerID: string | undefined;
	}): void {
		const bcUrl =
			`//players.brightcove.net/${accountID}` +
			`/${playerID}_default/index.min.js`;
		this.loadBcScript(bcUrl);

		const existingBcScript = $(`script[src="${bcUrl}"]`);
		// check if a script already exists
		// if (existingBcScript.length > 0) {
		// 	this.bcScript = existingBcScript[0];
		// } else {
		// 	this.loadBcScript(bcUrl);
		// }
	}

	loadBcScript(bcUrl: string): void {
		// append brightcove script if it doesnt exist
		this.bcScript = document.createElement("script");
		// Hardcoded to account ID.
		this.bcScript.setAttribute("src", `${bcUrl}`);
		// append to document
		document.head.appendChild(this.bcScript);
	}

	initPlayer(playerHTMLID: string): void {
		const disableAutoplay = this._element.dataset.autoplay === "false";
		const hasPlayCta = this._element.dataset.videoPlayCta || undefined;
		const hasPause = this._element.dataset.videoPauseCta || undefined;

		const script = this.bcScript;
		if (!script) {
			throw new Error(`No Brightcove script found in <head>. Value: ${script}`);
		}

		// Failsafe: Apply in case browser prevents autoplay fn
		script.onload = () => {
			const myPlayer = videojs.getPlayer(playerHTMLID);
			myPlayer?.ready(() => {
				if (!disableAutoplay) {
					myPlayer.muted(true);
					const promise = myPlayer.play();
					if (promise !== undefined) {
						promise.catch(error => {
							console.warn(`Error in video: ${error}`);
						});
					}
				}

				window.addEventListener("slideChange", e => {
					if (myPlayer.id_ === e.detail.playerId) {
						console.log({ myPlayer });
						myPlayer.pause();
					}
				});

				myPlayer.on("pause", () => {
					this._element.classList.remove(this.isPlayingClass);
					this._element.classList.add("isPaused");
				});

				myPlayer.on("play", () => {
					this._element.classList.add(this.isPlayingClass);
					this._element.classList.remove("isPaused");
				});

				if (hasPlayCta) {
					this.applyOnClickEvt(myPlayer, hasPlayCta, "play");
					this._element.classList.add("isPaused");
				}

				if (hasPause) {
					this.applyOnClickEvt(myPlayer, hasPause, "pause");
				}
			});
		};
	}

	applyOnClickEvt(player: ISlide, selector: string, type: string): void {
		const selectors = selector.split(",");
		selectors.forEach(_s => {
			const selectorClean = _s.trim();
			const button = this._element.parentNode?.querySelector(
				`[data-element=${selectorClean}]`
			);

			if (!button) {
				console.warn(
					`No play button found on parentElement with selector: ${selector}`
				);

				return;
			}

			switch (type) {
				case "play":
					button.addEventListener("click", () => {
						this.playPlayer(player);
					});
					break;
				case "pause":
					button.addEventListener("click", () => {
						this.pausePlayer(player);
					});
					break;
				default:
					console.warn("No type provided for button.");
			}
		});
	}

	playPlayer(player: ISlide): void {
		if (player) {
			player?.ready(() => {
				player.play();

				DataLayerService.pushDataLayerEvent_Video_Start({
					video_provider: "brightcove",
					video_title: this.getVideoId()
				});
			});
		}
	}

	pausePlayer(player: ISlide): void {
		if (player) {
			player?.ready(() => {
				player.pause();
			});
		}
	}

	getAccountID(): string | undefined {
		return (
			// tslint:disable-next-line: no-unsafe-any
			this._element.querySelector("video-js")?.dataset.account || undefined
		);
	}

	getPlayerID(): string | undefined {
		// tslint:disable-next-line: no-unsafe-any
		return this._element.querySelector("video-js")?.dataset.player || undefined;
	}

	getPlayerHTMLID(): string | undefined {
		return this._element.querySelector("video-js")?.id || undefined;
	}

	getVideoId(): string | undefined {
		// tslint:disable-next-line: no-unsafe-any
		return (
			this._element.querySelector("video-js")?.dataset.videoId || undefined
		);
	}
}

ComponentFactory.registerComponent("BCVideoModule", BCVideoModule);
