import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { animationDebounce } from "../../../util/AnimationDebounce";
import { DataLayerService } from "../../services/DataLayerService";

enum StickyNavState {
	unknown = 0,

	// Bits:
	mobile = 1,
	desktop = 2,
	open = 4,
	closed = 8,
	slickSlider = 16,
	sticky = 32,
	notSticky = 64,
	hidden = 128,

	// Valid states:
	mobileOpen = 5,
	mobileClosed = 9,
	mobileSticky = 17,
	mobileNotSticky = 65,
	desktopSticky = 34,
	desktopNotSticky = 66,
	desktopHidden = 130
}
export class RestaurantInfoNav extends Component {
	private readonly _elemTopPos: number = 0;
	private _state: StickyNavState = StickyNavState.unknown;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this._elemTopPos = this._element.offsetTop;
		this.applyShowHideEvt();
		this.addStickyScrollEvent();
		// Handle navCalloutCard click event
		this.applyListenEvt();
		if (window.innerWidth >= _app.layout.desktopWidth) {
			this.state = this.scrolledPastElem
				? StickyNavState.desktopSticky
				: StickyNavState.desktopNotSticky;

			return;
		}

		this.mobileInit();
	}

	public get state(): StickyNavState {
		return this._state;
	}

	public set state(value: StickyNavState) {
		if (value === this.state) {
			return;
		}

		this._element.classList.remove(
			"mobileOpen",
			"mobileClosed",
			"mobileSticky",
			"mobileNotSticky",
			"desktopSticky",
			"desktopNotSticky",
			"desktopHidden"
		);

		switch (value) {
			case StickyNavState.mobileOpen:
				this._element.classList.add("mobileOpen");
				this._element.setAttribute("aria-expanded", "true");
				this.dropdownList?.setAttribute("aria-hidden", "false");
				break;

			case StickyNavState.mobileClosed:
				this._element.classList.add("mobileClosed");
				this._element.setAttribute("aria-expanded", "false");
				this.dropdownList?.setAttribute("aria-hidden", "true");
				break;

			case StickyNavState.mobileSticky:
				// this._element.classList.add("mobileClosed");
				this._element.setAttribute("aria-expanded", "false");
				this.dropdownList?.setAttribute("aria-hidden", "true");
				this._element.classList.add("mobileSticky");
				break;

			case StickyNavState.mobileNotSticky:
				this._element.classList.add("mobileNotSticky");
				this._element.removeAttribute("aria-expanded");
				this._element.removeAttribute("data-slick-slider");
				this._app.services.layoutService.deRegisterStickyNode(this._element);
				break;

			case StickyNavState.desktopSticky:
				this._element.classList.add("desktopSticky");
				break;

			case StickyNavState.desktopNotSticky:
				this._element.classList.add("desktopNotSticky");
				break;

			case StickyNavState.desktopHidden:
				this._app.services.layoutService.deRegisterStickyNode(this._element);
				this._element.classList.add("desktopHidden");
				break;

			default:
				throw new Error(`Invalid state: ${value}; current: ${this.state}`);
		}

		this._state = value;
	}

	private mobileInit(): void {
		this._element
			.querySelector(".current-nav-item")
			?.addEventListener("click", e => {
				e.stopPropagation();
				this.transitionOnCurrentNavClick();
				const closeMobileNav = () => this.closeMobileNav();
				$(document).one("click", e => {
					closeMobileNav();
				});
			});
		// hide current nav item li
		if (window.innerWidth < this._app.layout.desktopWidth) {
			const target = this._element.querySelector(".current-nav-item button");
			this.popTargetFromCurrent(target);
		}
	}

	private closeMobileNav(): void {
		this.state = StickyNavState.mobileClosed;
	}

	private addStickyScrollEvent(): void {
		window.addEventListener(
			"scroll",
			animationDebounce(
				() => window.scrollY,
				scrollY => this.transitionOnScroll(scrollY)
			)
		);
	}

	private transitionOnScroll(scrollY: number): void {
		if (window.innerWidth >= this._app.layout.desktopWidth) {
			this.state =
				scrollY > this._elemTopPos
					? StickyNavState.desktopSticky
					: StickyNavState.desktopNotSticky;
		} else {
			this.state =
				scrollY > this._elemTopPos
					? StickyNavState.mobileSticky
					: StickyNavState.mobileNotSticky;
		}
	}

	private transitionOnCurrentNavClick(): void {
		// Bypassing the state machine for now b/c its no longer a binary toggle
		if (this.mobileIsOpen) {
			this._element.classList.remove("mobileOpen");
			this._element.classList.add("mobileClosed");
		} else {
			this._element.classList.remove("mobileClosed");
			this._element.classList.add("mobileOpen");
		}
	}

	private toggleTab(): void {
		const hash = window.location.hash;

		if (!hash) {
			// Add active class to navItem
			const firstNavItem = this._element.querySelector(".dropdown-section li");
			firstNavItem?.classList.add("active");
			// Toggle tab
			const firstTabBtn = this._element.querySelector("button");
			firstTabBtn?.click();
		}

		this._element
			.querySelector(`button[data-type="${hash.replace("#", "")}"]`)
			?.click();
	}

	private applyShowHideEvt(scrollToDiv: boolean = true): void {
		const showHideBtns = this.showHideBtns;
		if (showHideBtns.length > 0) {
			showHideBtns.forEach(btn =>
				btn.addEventListener("click", () => {
					const target = btn.getAttribute("data-type");
					if (!target) {
						console.warn(`No type attribute found on button: ${btn}`);

						return;
					}
					this.toggleTabs(false);
					this.toggleBtns(false);
					$(btn).closest("li").addClass("active");
					const targetElem = document.querySelector(`div.${target}`);
					if (!targetElem) {
						console.warn(`No element found with class: ${target}`);

						return;
					}
					targetElem.classList.add("show");

					this.scrollTo(targetElem);

					if (window.innerWidth < this._app.layout.desktopWidth) {
						// const currentNavItem = this._element.querySelector();
						this.reorderMobileList(btn);
						this.popTargetFromCurrent(btn);
					}

					DataLayerService.pushDataLayerEvent_NavTab_Click({
						nav_title: target,
						location: window.location.href
					});
				})
			);
		}
	}

	private scrollTo(target: HTMLElement): void {
		const stickyHeight = this._element.clientHeight;
		const targetTop = target.offsetTop;
		const additionalOffset = 50;
		const scrollPos = targetTop - stickyHeight - additionalOffset;

		window.scrollTo({
			behavior: "smooth",
			top: scrollPos
		});
	}

	private reorderMobileList(target: HTMLElement): void {
		const currentNavItem = this._element.querySelector(
			".current-nav-item button"
		);

		if (!currentNavItem) {
			console.warn("No current-nav-item found");

			return;
		}
		currentNavItem.innerText = target.innerText;
	}

	private popTargetFromCurrent(target: HTMLElement): void {
		const dropdownList = this._element.querySelector("#dropdown-section");
		if (!dropdownList) {
			console.warn("No dropdown list found");

			return;
		}

		const items = dropdownList?.getElementsByTagName("button");
		const liToHide = target.dataset.type;

		for (const item of items) {
			const itemLi = $(item).closest("li");
			if (itemLi.hasClass("hidden")) {
				itemLi.removeClass("hidden");
			}

			if (item.dataset.type === liToHide) {
				itemLi.addClass("hidden");
			}
		}
	}

	private toggleTabs(show: boolean): void {
		const tabs = document.querySelectorAll("div.restaurant-info-tab");
		tabs.forEach(tab => {
			if (show) {
				tab.classList.add("show");
			} else {
				tab.classList.remove("show");
			}
		});
	}

	private toggleBtns(show: boolean): void {
		const btns = this.showHideBtns;
		btns.forEach(btn => {
			if (show) {
				$(btn).closest("li").addClass("active");
			} else {
				$(btn).closest("li").removeClass("active");
			}
		});
	}

	private applyListenEvt(): void {
		document.addEventListener("navItemClicked", e => {
			const { detail } = e;
			this._element.querySelector(`button[data-type="${detail}"]`)?.click();
		});
	}

	get showHideBtns(): NodeListOf<HTMLButtonElement> {
		return this._element.querySelectorAll("button");
	}

	get mobileIsOpen(): boolean {
		return this._element.classList.contains("mobileOpen");
	}
}

ComponentFactory.registerComponent("RestaurantInfoNav", RestaurantInfoNav);
