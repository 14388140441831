import { API } from "../../util/API";
import {
	FormSubmitResponse,
	IFormSubmitResponse
} from "./Models/FormSubmitResponse";
import {
	ISubmissionCountResponse,
	SubmissionCount
} from "./Models/SubmissionCount";
// tslint:disable-next-line: no-unnecessary-class
export class SignatureDropService {
	public static async sendForm(
		endpoint: string,
		payload: {
			AddressId: string;
			AddressUnit?: string | null;
			productType: string;
			TShirtSize?: string | null;
		}
	): Promise<IFormSubmitResponse | undefined> {
		try {
			const promise = $.ajax({
				contentType: "application/json",
				data: JSON.stringify(payload),
				type: "POST",
				url: endpoint
			});
			const result = await Promise.resolve(promise);

			return new FormSubmitResponse(result);
		} catch (err) {
			console.warn(err);
		}
	}

	public static async getCount(
		endpoint: string
	): Promise<ISubmissionCountResponse | null> {
		const [response, error] = await API.getReq(endpoint, {});

		if (error) {
			console.warn(
				`Error in fetching submission count value. See error: ${JSON.stringify(
					error
				)}`
			);

			return null;
		}
		console.log({ response });
		return new SubmissionCount(response);
	}
}
