import { Accordion } from "./Accordion";
import { IAccordionStrategy } from "./IAccordionStrategy";

export class InstantStrategy implements IAccordionStrategy {
	constructor(private readonly _accordion: Accordion) {
		$(Array.from(_accordion.sections)).hide();
	}

	async hide(toggle: Element): Promise<void> {
		toggle.classList.remove("active");
		toggle.setAttribute("aria-expanded", "false");

		toggle
			.querySelectorAll("[role='switch']")
			.forEach(s => s.setAttribute("aria-checked", "false"));

		const section = toggle.nextElementSibling;
		if (section) {
			$(section).hide();
			section.setAttribute("aria-hidden", "true");
		}
	}

	async show(toggle: Element): Promise<void> {
		this._accordion.toggles.forEach(x => this.hide(x));

		// show clicked accordion
		toggle.classList.add("active");
		toggle.setAttribute("aria-expanded", "true");

		toggle
			.querySelectorAll("[role='switch']")
			.forEach(s => s.setAttribute("aria-checked", "true"));

		const section = toggle.nextElementSibling;
		if (section) {
			$(section).show();
			section.setAttribute("aria-hidden", "false");
		}
	}
}
