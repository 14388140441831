import { Endpoint } from "../../models/Endpoint";
import { ReceiveGiftGifterIsGifteeModal } from "./ReceiveGiftGifterIsGifteeModal";
import { ReceiveGiftRewardAddedModal } from "./ReceiveGiftRewardAddedModal";

export class GiftingBehavior {
	constructor(private readonly _element: HTMLElement) {
		_element.querySelectorAll("[data-action='add to rewards']").forEach(el =>
			el.addEventListener("click", async e => {
				e.preventDefault();
				await this.addToRewards();
			})
		);
	}

	public get authenticated(): boolean {
		return (
			this._element.dataset.userState === "authenticated" || this.gifterIsGiftee
		);
	}

	private get gifterIsGiftee(): boolean {
		return this._element.dataset.userState === "gifterIsGiftee";
	}

	public async addToRewards(): Promise<void> {
		if (!this.authenticated) {
			this.authenticate();

			return;
		}

		if (this.gifterIsGiftee) {
			await ReceiveGiftGifterIsGifteeModal.show();
		} else {
			await ReceiveGiftRewardAddedModal.show();
		}
	}

	public authenticate(url?: string): void {
		const endpoint = new Endpoint(
			url ?? this._element.dataset.authenticateEndpoint
		);

		if (!endpoint.method || !endpoint.url) {
			console.error("Could not sign in, no URL");

			return;
		}

		if (endpoint.method === "POST") {
			const form = document.createElement("form");
			form.action = endpoint.url;
			form.method = "POST";
			form.style.display = "none";
			this._element.appendChild(form);
			form.submit();
		} else {
			window.location.href = endpoint.url;
		}
	}
}
