import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { RewardService } from "./RewardService";

export class Reward extends Component {
	private _offerId: string | null = null;
	private _itemName: string | null = "";
	private _imageUrl: string | null = null;
	private _points: string | null = null;
	private _message: string | undefined = "";
	private _claimUrl: string | null = "";

	// services
	private readonly _rewardService = new RewardService(
		"/OnlineOrdering/OloAccount/SendGift"
	);

	// modal roots
	private readonly _sendGiftModal: HTMLElement;
	private readonly _confirmRedeemModal: HTMLElement;
	private readonly _shareTheJoyModal: HTMLElement;

	// send a gift
	private readonly _titleField: HTMLElement | null;
	private readonly _sendImageField: HTMLImageElement | null;
	private readonly _reviewButton: HTMLButtonElement | null;
	private readonly _backToMyRewardsButton: HTMLButtonElement | null;
	private readonly _messageField: HTMLTextAreaElement | null;

	// redeem
	private readonly _redeemImageField: HTMLImageElement | null;
	private readonly _redeemProductTitleField: HTMLElement | null;
	private readonly _redeemTitle: HTMLElement | null;
	private readonly _redeemButton: HTMLElement | null;

	// share the joy
	private readonly _confirmationLink: HTMLInputElement | null;
	private readonly _confirmationImageField: HTMLImageElement | null;
	private readonly _confirmationProductTitleField: HTMLElement | null;
	private readonly _confirmationMessage: HTMLParagraphElement | null;
	private readonly _confirmationBackButton: HTMLButtonElement | null;
	private readonly _confirmationSubtitle: HTMLParagraphElement | null;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		// send a gift initial modal
		this._sendGiftModal = document.getElementById(
			"send-gift-modal"
		) as HTMLElement;
		this._titleField = this._sendGiftModal.querySelector(
			"[data-send-gift-title]"
		);
		this._sendImageField = this._sendGiftModal.querySelector(
			"[data-send-gift-image]"
		);
		this._reviewButton = this._sendGiftModal.querySelector(
			"[data-send-gift-review-button]"
		);
		this._messageField = this._sendGiftModal.querySelector(
			"[data-send-gift-message]"
		);

		// confirm redeem points modal
		this._confirmRedeemModal = document.getElementById(
			"confirm-redeem-points"
		) as HTMLElement;
		this._backToMyRewardsButton = this._confirmRedeemModal.querySelector(
			"[data-confirm-back-to-rewards]"
		);
		this._redeemImageField = this._confirmRedeemModal.querySelector(
			"[data-confirm-gift-img]"
		);
		this._redeemProductTitleField = this._confirmRedeemModal.querySelector(
			"[data-confirm-product-title]"
		);
		this._redeemTitle = this._confirmRedeemModal.querySelector(
			"[data-confirm-redeem-title]"
		);
		this._redeemButton = this._confirmRedeemModal.querySelector(
			"[data-confirm-redeem-button]"
		);

		// share the joy modal
		this._shareTheJoyModal = document.getElementById(
			"share-joy-modal"
		) as HTMLElement;
		this._confirmationLink = this._shareTheJoyModal.querySelector(
			"[data-share-confirmation-link]"
		);
		this._confirmationImageField = this._shareTheJoyModal.querySelector(
			"[data-share-gift-image]"
		);
		this._confirmationProductTitleField = this._shareTheJoyModal.querySelector(
			"[data-share-product-title]"
		);
		this._confirmationMessage = this._shareTheJoyModal.querySelector(
			"[data-share-confirmation-message]"
		);
		this._confirmationBackButton = this._shareTheJoyModal.querySelector(
			"[data-share-back-button]"
		);
		this._confirmationSubtitle = this._shareTheJoyModal.querySelector(
			"[data-confirmation-subtitle]"
		);

		_element
			.querySelectorAll<HTMLElement>("a[data-action='sendGift']")
			.forEach(el =>
				el.addEventListener("click", e => {
					this._offerId = el.getAttribute("data-reward-offerId");
					this._itemName = el.getAttribute("data-reward-title");
					this._imageUrl = el.getAttribute("data-reward-imgurl");
					this._points = el.getAttribute("data-reward-points");
					this._claimUrl = el.getAttribute("data-reward-claim-url");

					this.setSendGiftFields();
					this.setRedeemGiftFields();
					this.setConfirmationFields();
				})
			);

		this._reviewButton?.addEventListener("click", this.setMessage.bind(this));

		this._backToMyRewardsButton?.addEventListener("click", e => {
			e.preventDefault();
			$.magnificPopup.close();
		});

		this._redeemButton?.addEventListener("click", async e => {
			e.preventDefault();

			const offerData = await this._rewardService.sendGift(
				this._offerId ?? "",
				this._message ?? ""
			);

			if (this._confirmationLink) {
				this._confirmationLink.value = offerData?.receptionUrl ?? "";
			}

			this._message = "";

			if (this._messageField) {
				this._messageField.textContent = "";
			}
		});

		this._confirmationBackButton?.addEventListener("click", e => {
			e.preventDefault();
			$.magnificPopup.close();
		});
	}

	private setMessage(): void {
		this._message = this._messageField?.value;

		if (this._confirmationMessage) {
			this._confirmationMessage.textContent = this._message ?? "";
		}
	}

	private setSendGiftFields(): void {
		if (this._titleField) {
			this._titleField.innerHTML = this._itemName ?? "";
		}

		if (this._sendImageField) {
			this._sendImageField.src = this._imageUrl ?? "";
			this._sendImageField.alt = this._itemName ?? "";
		}

		if (this._messageField) {
			this._messageField.value = "";
		}
	}

	private setConfirmationFields(): void {
		if (this._confirmationProductTitleField) {
			this._confirmationProductTitleField.innerHTML = this._itemName ?? "";
		}

		if (this._confirmationImageField) {
			this._confirmationImageField.src = this._imageUrl ?? "";
			this._confirmationImageField.alt = this._itemName ?? "";
		}

		if (this._confirmationLink) {
			this._confirmationLink.value = this._claimUrl ?? "";
		}

		if (this._confirmationSubtitle) {
			this._confirmationSubtitle.innerHTML =
				this._confirmationSubtitle.innerHTML?.replace(
					/\{0\}/gi,
					this._itemName ?? ""
				) ?? "";
		}
	}

	private setRedeemGiftFields(): void {
		if (this._redeemProductTitleField) {
			this._redeemProductTitleField.innerHTML = this._itemName ?? "";
		}

		if (this._redeemImageField) {
			this._redeemImageField.src = this._imageUrl ?? "";
			this._redeemImageField.alt = this._itemName ?? "";
		}

		if (this._redeemTitle) {
			this._redeemTitle.textContent =
				this._redeemTitle.textContent?.replace(/\{0\}/gi, this._points ?? "") ??
				"";
		}
	}
}

ComponentFactory.registerComponent("reward", Reward);
