import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class RadioSelection extends Component {
	constructor(_element: HTMLElement, app: ICfa) {
		super(_element, app);

		this.toggleOn(this.childElems[0]);

		this.childElems.forEach(el => {
			el.addEventListener("click", () => this.handleToggle(el));

			el.addEventListener("keypress", (e: KeyboardEvent) => {
				if (e.key === "Enter") {
					this.handleToggle(el);
				}
			});
		});
	}

	private get childElems(): NodeListOf<HTMLElement> {
		return this._element.querySelectorAll(".p-block__card");
	}

	private handleToggle(el: HTMLElement): void {
		if (el) {
			return this.toggle(el);
		}

		console.warn("Could not locate Element");
	}

	private toggle(el: HTMLElement): void {
		this.childElems.forEach(card => {
			if (card !== el) {
				return this.toggleOff(card);
			}

			return this.toggleOn(card);
		});
	}

	private toggleOff(el: Element): void {
		el.setAttribute("aria-selected", "false");
	}

	private toggleOn(el: Element): void {
		el.setAttribute("aria-selected", "true");
	}
}

ComponentFactory.registerComponent("RadioSelection", RadioSelection);
