import { Endpoint } from "../../models/Endpoint";

interface IClaimGiftResponse {
	readonly success: boolean;
}

const requests = new Map<string, Promise<boolean>>();

export class GiftingService {
	private readonly _endpoint: Endpoint;

	constructor(endpoint?: string) {
		this._endpoint = new Endpoint(endpoint);
	}

	private static generateKey(endpoint: Endpoint, offerId: string): string {
		return (endpoint.toString() + "/" + offerId).toLowerCase();
	}

	public async claimGift(offerId: string): Promise<boolean> {
		const key = GiftingService.generateKey(this._endpoint, offerId);
		const cached = requests.get(key);
		if (cached) {
			return cached;
		}

		const promise = this.claimGiftFromService(offerId);
		requests.set(key, promise);

		return promise;
	}

	private async claimGiftFromService(offerId: string): Promise<boolean> {
		const method = this._endpoint.method;
		const url = this._endpoint.url;

		if (!method || !url) {
			console.warn("Cannot claim gift: no endpoint");

			return false;
		}

		const claimGiftModel = { offerId };
		const promise = method === "GET" ? $.get(url) : $.post(url, claimGiftModel);

		try {
			const result = (await Promise.resolve(promise)) as IClaimGiftResponse;

			return result.success;
		} catch (ex) {
			const typed = ex as JQuery.jqXHR;
			console.error(`Error in ${url} : ${typed.responseText}`);

			return false;
		}
	}
}
