import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class ListicleModule extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.addChildCount();
	}

	addChildCount(): void {
		const count = this._element.childElementCount.toString();
		this._element.dataset.childCount = count;
	}
}

ComponentFactory.registerComponent("ListicleModule", ListicleModule);
