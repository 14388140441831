/*global cpGlobal:writable */

// CONSOLIDATED PROFILE
var cpGlobal = {};

$(function() {
	var $body = $("body");

	// Utilities
	var Utility = function() {
		return {
			checkBrowser: function() {
				if (
					/MSIE 10/i.test(navigator.userAgent) ||
					/MSIE 9/i.test(navigator.userAgent) ||
					/rv:11.0/i.test(navigator.userAgent)
				) {
					// this is Internet explorer 9 10 and 11
					$("html").addClass("ie");
				}

				if (/constructor/i.test(window.HTMLElement)) {
					// this is Safari
					$("html").addClass("safari");
				}

				if (window.navigator.userAgent.indexOf("Edge") > -1) {
					// this is Edge
					$("html").addClass("edge");
				}
			}
		};
	};

	var util = new Utility();

	// Navigation Sidebar Toggle
	var $navElem = $("#cp-nav");
	var $navToggle = $("#cp-content__show-nav");
	var $cpContent = $("#cp-content");

	var NavigationToggle = function() {
		return {
			showNav: function() {
				$navToggle.attr("aria-expanded", "true");
				// show nav
				$navElem.show();
				$navElem.attr("aria-hidden", "false");
				// hide content
				$cpContent.hide();
				$cpContent.attr("aria-hidden", "true");

				// Add ADA focus trapping
				modalFocusTrap($navElem);
			},

			hideNav: function() {
				$navToggle.attr("aria-expanded", "false");
				// hide nav
				$navElem.hide();
				$navElem.attr("aria-hidden", "true");
				// show content
				$cpContent.show();
				$cpContent.attr("aria-hidden", "false");

				// Remove ADA Focus Trapping
				undoModalFocusTrap($navElem);
				// refocus toggle
				$navToggle.focus();
			}
		};
	};

	var navToggle = new NavigationToggle();

	// Show nav when clicking on "Back to dashboard" button
	$navToggle.on("click keypress", function(e) {
		// check for "Enter" keypress
		if (e.which === 13 || e.type === "click") {
			navToggle.showNav();
		}
	});

	// Show content when clicking on an .active link in the nav
	$navElem.on("click keypress", ".active", function(e) {
		// check for "Enter" keypress
		if (!$(this).hasClass("cp-nav__accord")) {
			if (e.which === 13 || e.type === "click") {
				navToggle.hideNav();
			}
		}
	});

	// toggle subnav (Rewards)
	var subnavToggle = $(".cp-nav__accord");

	subnavToggle.on("click keypress", ".cp-nav__accord-toggle", function(e) {
		e.preventDefault();

		var $this = $(this);
		var $accordParent = $this.parent(".cp-nav__accord");
		var $accordContent = $this.next(".cp-nav__accord-content");

		// check for "Enter" keypress
		if (e.which === 13 || e.type === "click") {
			if ($accordParent.hasClass("expanded")) {
				$accordContent.stop().slideUp("fast", function() {
					$accordParent.removeClass("expanded");
					$this.attr("aria-expanded", "false");
				});
			} else {
				$accordContent.stop().slideDown("fast", function() {
					$accordParent.addClass("expanded");
					$this.attr("aria-expanded", "true");
				});
			}
		}
	});

	// modal focus trapping
	function modalFocusTrap(modal) {
		var focusable = modal.find("a, button, :input, .cp-modal__close");
		var firstFocusable = focusable.first()[0];
		var lastFocusable = focusable.last()[0];
		var KEYCODE_TAB = 9;

		// focus on the first element when the modal opens
		firstFocusable.focus();

		// check for tab/shift tab
		$(modal).on("keydown", function(e) {
			var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

			if (!isTabPressed) {
				return;
			}

			if (e.shiftKey) {
				if (document.activeElement === firstFocusable) {
					lastFocusable.focus();
					e.preventDefault();
				}
			} else if (document.activeElement === lastFocusable) {
				firstFocusable.focus();
				e.preventDefault();
			}
		});
	}

	// remove focus trapping events
	function undoModalFocusTrap(modal) {
		modal.off("keydown");
	}

	// Modal Show/Hide
	var $modalToggleBtn = $(".modal-toggle");
	var $lastBtn = null;

	var ModalToggle = function() {
		return {
			showModal: function(id) {
				var $modal = $(".cp-modal__wrap" + id);

				// show modal
				$modal.addClass("cp-modal__wrap--visible");
				$modal.attr("aria-hidden", "false");

				// prevent body scroll
				$body.addClass("no-scroll");

				// Add ADA focus trapping
				modalFocusTrap($modal);
			},

			hideModal: function(el) {
				var $modal = $(el).parents(".cp-modal__wrap");

				// hide modal
				$modal.removeClass("cp-modal__wrap--visible");
				$modal.attr("aria-hidden", "true");

				// allow body scroll
				$body.removeClass("no-scroll");

				// Remove ADA Focus Trapping
				undoModalFocusTrap($modal);

				// focus on the button we clicked to open the modal
				$lastBtn.focus();
			}
		};
	};

	var modalToggle = new ModalToggle();

	// Show modal when clicking on any ".modal-toggle"
	$modalToggleBtn.on("click", function(e) {
		e.preventDefault();

		var modalId = $(this).attr("data-modal");

		// check for data-modal, open modal if it exists
		if (modalId !== undefined) {
			modalToggle.showModal(modalId);

			// save the modal trigger for :focus when closing
			$lastBtn = $(this);
		} else {
			modalToggle.hideModal(this);
		}
	});

	// Toggle filters
	var $filterToggle = $("#cp-filter__toggle");
	var $filterWrap = $("#cp-filter");

	$filterToggle.on("click keypress", function(e) {
		if (e.which === 13 || e.type === "click") {
			$filterWrap.toggleClass("cp-filter--active");
		}
	});

	// Forgot 2 Scan
	var $f2s = $("#cp-f2s");

	if ($f2s.length > 0) {
		var $fsInputs = $f2s.find("input");
		var $fsTargets = $("span.highlight");

		// highlight fields
		$fsInputs.on("focus", function() {
			var $targetEl = $("#" + $(this).attr("data-target"));

			$fsTargets.removeClass("highlight--active");
			$targetEl.addClass("highlight--active");
		});

		// un-highlight fields, when all inputs are unfocused
		$fsInputs.on("blur", function() {
			if (!$fsInputs.is(":focus")) {
				$fsTargets.removeClass("highlight--active");
			}
		});
	}

	// Accordion
	var $accordBtn = $(".cp-accordion__toggle");
	var accordActiveClass = "cp-accordion--active";

	function accordClick(e) {
		var $currAccordBlock = $(e.target).parent(".cp-accordion");

		if ($currAccordBlock.hasClass(accordActiveClass)) {
			// Slide up clicked accordion block if its active
			$currAccordBlock.removeClass(accordActiveClass);
		} else {
			// Slide up active accordion block
			$currAccordBlock.addClass(accordActiveClass);
		}
	}

	// points text - assign a class based on the length of the string
	var $pointsText = $("#nge-points");

	function checkPointsLength() {
		if ($pointsText.length > 0) {
			var length = $pointsText.text().length;

			$pointsText.addClass("nge-points__giant--" + length);
		}
	}

	// "click"
	$accordBtn.on("click", function(e) {
		accordClick(e);
	});

	// show past rewards
	var $pastRewardsBtn = $("#show-past-rewards");
	var $pastRewards = $("#past-rewards");

	$pastRewardsBtn.on("click", function(e) {
		e.preventDefault();
		$(e.target).hide();

		$(".nge-reward__wrap.nge-reward__wrap--past").removeClass(
			"nge-reward__wrap--past"
		);

		$(".reward-frame").slick({
			arrows: true,
			dots: true
		});

		$pastRewards.attr("aria-hidden", "false");
	});

	// Onboarding steps
	var Onboarding = function() {
		return {
			init: function() {
				var $cpPage = $(".cp-page");
				var $obModal = $(".ob-modal");
				var $firstModal = $($obModal[0]);

				// show side menu if mobile
				if ($navToggle.css("display") !== "none") {
					navToggle.showNav();
				}

				// add skrim
				$cpPage.addClass("cp-page--skrim");

				// show first modal
				$firstModal.show("fast", function() {
					$firstModal.addClass("ob-modal--visible");

					// focus trap
					modalFocusTrap($firstModal);

					// scroll to first Modal
					var modalOffset = $firstModal.offset().top - 70;
					$("html, body").animate({ scrollTop: modalOffset }, 500);
				});

				// loop over the modals, and add click event listener to buttons
				$obModal.each(function(i) {
					var $this = $(this);
					var $btn = $this.find(".btn--vis");

					// button click
					$btn.on("click keypress", function(e) {
						e.preventDefault();

						// check for enter key
						if (e.which === 13 || e.type === "click") {
							if ($(this).hasClass("btn--skip")) {
								$cpPage.removeClass("cp-page--skrim");
								$obModal.removeClass("ob-modal--visible").hide();
							} else {
								var $nextModal = $($obModal[i + 1]);

								// toggle modals
								$this.removeClass("ob-modal--visible");

								// show next modal
								$nextModal.show("fast", function() {
									$nextModal.addClass("ob-modal--visible");

									// focus trap
									modalFocusTrap($nextModal);

									// scroll to next modal
									var modalOffset = $nextModal.offset().top - 70;
									$("html, body").animate({ scrollTop: modalOffset }, 500);
								});
							}
						}
					});
				});
			},

			end: function() {
				$cpPage.removeClass("cp-page--skrim");
				$obModal.removeClass("ob-modal--visible").hide();
			}
		};
	};

	// attach to global object
	cpGlobal.onboarding = new Onboarding();

	// Ready
	$(function() {
		// check points length
		checkPointsLength();

		// Check Browser
		util.checkBrowser();
	});
});

if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
	module.exports = cpGlobal;
}

if (typeof window !== "undefined") {
	window.cpGlobal = cpGlobal;
}
