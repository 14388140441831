const pattern = /^\s*(get|post):(.*)\s*$/i;
const patternMethodGroup = 1;
const patternUrlGroup = 2;

type MethodType = "GET" | "POST" | null;

function parseMethodType(raw: string): MethodType {
	if (!raw) {
		return null;
	}

	switch (raw.toUpperCase()) {
		case "GET":
			return "GET";
		case "POST":
			return "POST";
		default:
			console.warn("Unrecognized method", raw);

			return null;
	}
}

export class Endpoint {
	private readonly _method: MethodType = null;
	private readonly _url: string | null = null;

	constructor(raw?: string) {
		if (raw) {
			const match = pattern.exec(raw);
			if (match) {
				this._method = parseMethodType(match[patternMethodGroup]);
				this._url = match[patternUrlGroup];
			}
		}
	}

	public get method(): MethodType {
		return this._method;
	}

	public get url(): string | null {
		return this._url;
	}

	public toString(): string {
		return `${this._method ?? ""}:${this._url ?? ""}`;
	}
}
