import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

export class ImageCtaCarousel extends Component {
	public readonly sliderEl: JQuery;
	public slider: JQuery | null = null;
	public sliderContainer: JQuery;
	private prevSlide: number | undefined;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.sliderEl = $(_element);
		this.sliderContainer = this.sliderEl.find("[data-element='slider']");
		this.initSlider();
		const slick = this.sliderContainer.slick("getSlick");
		this.prevSlide = slick.defaults.initialSlide;
	}

	initSlider(): void {
		const sliderOptions: JQuerySlickOptions = {
			accessibility: false,
			arrows: true,
			centerMode: false,
			cssEase: "linear",
			dots: false,
			draggable: false,
			infinite: false,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToScroll: 1,
						slidesToShow: 3
					}
				}
			],
			slidesToScroll: 1,
			slidesToShow: 1,
			speed: 500,
			useTransform: false,
			variableWidth: true,
			prevArrow: $(this.sliderEl).find("[data-prev-arrow]"),
			nextArrow: $(this.sliderEl).find("[data-next-arrow]")
		};

		this.sliderContainer = this.sliderContainer.slick(sliderOptions);

		$(this.sliderEl)
			.find("button[data-next-arrow]")
			.on("click", () => {
				const slick = this.sliderContainer.slick("getSlick");
				this.sliderContainer.slick("slickNext");
				DataLayerService.pushDataLayerEvent_Carousel(
					this.sliderEl,
					"next_carousel_slide",
					slick.currentSlide,
					this.prevSlide,
					"ImageCtaCarousel"
				);
				this.prevSlide = slick.currentSlide;
			});

		$(this.sliderEl)
			.find("button[data-prev-arrow]")
			.on("click", () => {
				const slick = this.sliderContainer.slick("getSlick");
				this.sliderContainer.slick("slickPrev");
				DataLayerService.pushDataLayerEvent_Carousel(
					this.sliderEl,
					"previous_carousel_slide",
					slick.currentSlide,
					this.prevSlide,
					"ImageCtaCarousel"
				);
				this.prevSlide = slick.currentSlide;
			});
	}

	get isMobileView(): boolean {
		const tabletMinWidth = 767;

		return window.innerWidth < tabletMinWidth;
	}
}

ComponentFactory.registerComponent("imageCtaCarousel", ImageCtaCarousel);
