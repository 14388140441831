import { ILayoutService } from "./ILayoutService";
import { animationDebounce } from "../util/AnimationDebounce";

export class LayoutService implements ILayoutService {
	private sortedStickyElements: HTMLElement[] = [];
	constructor() {
		if (this.stickyNodes) {
			try {
				this.sortStickyElements();
			} catch (err) {
				console.log(err);
			}
			this.applyStickyTopPosition();
			this.addResizeEvent();
		}
	}

	public adjustFrameTopBy(heightToAdjust: number): void {
		const frame: HTMLElement | null = document.querySelector(".frame");

		if (frame) {
			frame.style.top = `${heightToAdjust}px`;
		}
	}

	public deRegisterStickyNode(node: HTMLElement): void {
		node.removeAttribute("data-sticky-position");
		const indexToRemove = this.sortedStickyElements.indexOf(node);
		this.sortedStickyElements.splice(indexToRemove, 1);

		this.applyStickyTopPosition();
	}

	private addResizeEvent(): void {
		window.addEventListener(
			"resize",
			animationDebounce(
				() => window.innerWidth,
				width => this.applyStickyTopPosition()
			)
		);
	}

	private applyStickyTopPosition(): void {
		let currentTopStickingPosition = 0;

		this.sortedStickyElements.forEach((node, i) => {
			node.style.top = `${currentTopStickingPosition}px`;
			currentTopStickingPosition += node.offsetHeight;
		});
	}

	private sortStickyElements(): void {
		this.sortedStickyElements = Array.from(this.stickyNodes).sort((a, b) => {
			const aPriority = $(a).data("sticky-priority") as number;
			const bPriority = $(b).data("sticky-priority") as number;

			return aPriority > bPriority ? 1 : -1;
		});
	}

	private get stickyNodes(): NodeListOf<HTMLElement> {
		return document.querySelectorAll("[data-sticky-position='true']");
	}
}
