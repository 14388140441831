import { CfaContext } from "../../CFACom";
import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { OloDessertService } from "./services/OloDessertService";

const classNames = {
	active: "p-card--active"
};

const selectors = {
	active: `.${classNames.active}`,
	card: ".p-card",
	priceAttr: "[data-price-in-cents]"
};

function parsePrice(raw?: string): number {
	if (!raw) {
		return 0;
	}

	const parsed = parseInt(raw, 10);
	if (isNaN(parsed)) {
		return 0;
	}

	return parsed;
}

// This exists to support legacy code, which sometimes queries the price
// before the component factory has finished initializing.
function scanDomForPrice(): number {
	const selector = "[data-component='OloDesserts']";

	return Array.from(document.querySelectorAll<HTMLElement>(selector))
		.map(scanComponentNodeForPrice)
		.reduce((prev, next) => prev + next, 0);
}

function scanComponentNodeForPrice(componentNode: HTMLElement): number {
	const active = selectors.active;

	return Array.from(componentNode.querySelectorAll<HTMLElement>(active))
		.map(activeCard => activeCard.closest<HTMLElement>(selectors.priceAttr))
		.filter(priceNode => componentNode.contains(priceNode))
		.map(priceNode => parsePrice(priceNode?.dataset.priceInCents))
		.reduce((prev, next) => prev + next, 0);
}

export class OloDesserts extends Component {
	private readonly _service: OloDessertService;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this._service = new OloDessertService(
			_element.dataset.endpoint || "",
			_element.dataset.datasource || ""
		);

		this._element.addEventListener("click", evt => this.handleClick(evt));
	}

	public get priceInCents(): number {
		return scanComponentNodeForPrice(this._element);
	}

	public get selectedOptions(): ReadonlyArray<string> {
		const sel = selectors.active;
		const selected = this._element.querySelectorAll<HTMLElement>(sel);

		return Array.from(selected)
			.map(el => el.dataset.externalId || "")
			.filter(Boolean);
	}

	public static get totalPriceInCents(): number {
		return scanDomForPrice();
	}

	public static async updateAll(
		selectedVariation: string,
		isDelivery: boolean,
		slug: string
	): Promise<void> {
		const instances = OloDesserts.instances;
		if (!instances) {
			return;
		}

		await Promise.all(
			instances.map(c => c.update(selectedVariation, isDelivery, slug))
		);
	}

	public static get selectedOptions(): ReadonlyArray<string> | null {
		const instances = OloDesserts.instances;
		if (!instances) {
			return null;
		}

		return instances
			.map(i => i.selectedOptions)
			.reduce((prev, next) => prev.concat(next), []);
	}

	private static get instances(): ReadonlyArray<OloDesserts> | null {
		let factory: ComponentFactory;

		try {
			factory = CfaContext.services.componentFactory;
		} catch {
			// This exists to support legacy code, which sometimes queries the price
			// before the component factory has finished initializing.
			return null;
		}

		return Array.from(factory.getAllComponents<OloDesserts>(OloDesserts));
	}

	private get cards(): NodeListOf<HTMLElement> {
		return this._element.querySelectorAll(selectors.card);
	}

	private handleClick(evt: Event): void {
		const target = evt.target as HTMLElement;
		const newActiveCard = target.closest(selectors.card);
		let selectionChanged = false;

		this.cards.forEach(card => {
			if (card === newActiveCard) {
				if (!card.classList.contains(classNames.active)) {
					card.classList.add(classNames.active);
					selectionChanged = true;
				}
			} else if (card.classList.contains(classNames.active)) {
				card.classList.remove(classNames.active);
				selectionChanged = true;
			}
		});

		if (selectionChanged) {
			// Legacy code listens for this event in olo-pdp to update the total.
			const changeEvent = document.createEvent("Event");
			changeEvent.initEvent("change", true, false);
			this._element.dispatchEvent(changeEvent);
		}
	}

	private async update(
		selectedVariation: string,
		isDelivery: boolean,
		slug: string
	): Promise<void> {
		const html = await this._service.loadHtml(
			selectedVariation,
			isDelivery,
			slug
		);

		$(this._element).empty().append(html);
	}
}

ComponentFactory.registerComponent("OloDesserts", OloDesserts);
