(() => {
	if (!window.matchMedia("(max-width: 960px)").matches) {
		return;
	}

	document

		.querySelectorAll("*[data-components='MobileCarousel']")

		.forEach(el => {
			const nextTemplate = el.querySelector("script[data-for='nextArrow']");

			const prevTemplate = el.querySelector("script[data-for='prevArrow']");

			const slickContainer = el.querySelector("*[data-initialize-as='slick']");

			const nextHtml = nextTemplate ? nextTemplate.innerHTML : "";

			const prevHtml = prevTemplate ? prevTemplate.innerHTML : "";

			if (slickContainer) {
				const dotsVal = slickContainer.getAttribute("data-dots");

				const centerModeVal = slickContainer.getAttribute("data-center-mode");

				const variableWidthVal = slickContainer.getAttribute(
					"data-variable-width"
				);

				$(slickContainer).slick({
					arrows: Boolean(nextHtml) && Boolean(prevHtml),
					centerMode: Boolean(centerModeVal),
					dots: Boolean(dotsVal),
					infinite: false,
					nextArrow: nextHtml,
					prevArrow: prevHtml,
					variableWidth: Boolean(variableWidthVal)
				});
			}
		});
})();
