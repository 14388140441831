/*
 * This function takes the dataset object and applies the styles to the element
 * @param {HTMLElement} element - The element to apply the styles to
 * @param {object} dataset - The dataset object
 * @param {boolean} isMobileView - The boolean to determine if the view is mobile
 */
export default function ApplyInlineStyles({
	element,
	dataset,
	isMobileView
}: {
	element: HTMLElement;
	dataset: any;
	isMobileView: boolean;
}) {
	let attributesToApply = getStyleAttributes({ dataset });
	attributesToApply &&
		attributesToApply
			.filter(item => (isMobileView ? item.isMobile : !item.isMobile))
			.forEach(({ attribute, value }) => {
				if (attribute && value) {
					element.style[attribute] = value;
				}
			});
}

function getStyleAttributes({
	dataset
}: {
	dataset: any;
}): null | Array<{ attribute: string; value: string; isMobile: boolean }> {
	const styleDataAttributes = [];

	// Iterate through the dataset object
	for (const key in dataset) {
		if (key.startsWith("style")) {
			// Take the attribute part of the key
			const parts = key.match(/(style)(.+)/);

			if (!parts) {
				console.error("parts is null");
				return null;
			}
			// replace "heightmobile" with height
			// rmv mobile flag (used for sorting later)
			const style = parts[2].toLowerCase().replace("mobile", "");
			styleDataAttributes.push({
				attribute: style,
				value: dataset[key],
				isMobile: key.includes("Mobile")
			});
		}
	}

	return styleDataAttributes;
}
