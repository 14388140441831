import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class ImgTextCard extends Component {
	public carouselEl: JQuery | null = null;
	public carousel: JQuery | null = null;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.carouselEl = $(_element).children("ul");

		if (window.innerWidth > _app.layout.desktopWidth) {
			return;
		}

		if (!this.carouselEl) {
			console.warn("Carousel element not found");
			return;
		}

		this.initCarousel();
	}

	initCarousel(): void {
		const carouselOptions: JQuerySlickOptions = {
			accessibility: false,
			centerMode: true,
			cssEase: "linear",
			dots: false,
			fade: false,
			infinite: false,
			mobileFirst: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 500,
			useTransform: false
		};

		this.carousel = this.carouselEl.slick(carouselOptions);
	}
}

ComponentFactory.registerComponent("imgTextCard", ImgTextCard);
