import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { animationDebounce } from "../../../util/AnimationDebounce";

export class MenuBannerAlert extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		if (window.innerWidth >= this._app.layout.desktopWidth) {
			this.handleOnScrollEvt();
		}
	}

	private handleOnScrollEvt(): void {
		window.addEventListener(
			"scroll",
			animationDebounce(
				() => window.scrollY,
				scrollY => this.transitionOnScroll(scrollY)
			)
		);
	}

	private transitionOnScroll(scrollY: number): void {
		const locationMenuTop = $(".location-menu").offset()?.top || 0;
		// Add locationMenuTop as offset b/c offsetTop calculates from parentTop
		const menuGroupTop =
			(this._element.parentElement?.offsetTop || 0) + locationMenuTop;
		const menuGroupHeight = this._element.parentElement?.offsetHeight || 0;
		const menuGroupPosBottom = menuGroupTop + menuGroupHeight;
		const menuGroupIsInView =
			menuGroupPosBottom > scrollY && scrollY > menuGroupTop;

		if (menuGroupIsInView) {
			this._element.classList.add("fixed");
		} else {
			this._element.classList.remove("fixed");
		}
	}
}

ComponentFactory.registerComponent("menuBannerAlert", MenuBannerAlert);
