import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class LocationMenuHero extends Component {
	orderCtaList: HTMLElement | null;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.orderCtaList = this._element.querySelector(
			"[data-element='order-cta-list']"
		);

		if (this.orderCtaList) {
			this.handleCtaUiUpdate();
		}
	}

	handleCtaUiUpdate(): void {
		switch (this.orderCtaList?.childElementCount) {
			case 1:
				this.orderCtaList.classList.add("wideView");
				this._element.setAttribute("data-cta-count", "1");
				break;
			default:
				return;
		}
	}
}

ComponentFactory.registerComponent("locationMenuHero", LocationMenuHero);
