import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

export class ContentLink extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		_element.addEventListener("click", e => this.onContentLinkClick(e));
	}

	// CFAC-34747 Fire whenever a user clicks on a content link
	async onContentLinkClick(e: Event): Promise<void> {
		DataLayerService.pushDataLayerEvent_Lists_SelectContent(
			e.target as Element
		);
	}
}

ComponentFactory.registerComponent("contentLink", ContentLink);
