import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class OrderDeliveryLink extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		if (this.redirectUrl) {
			this._element.setAttribute("href", this.redirectUrl);
		}
	}

	get redirectUrl(): string | undefined {
		return this._element.dataset.redirectUrl;
	}
}

ComponentFactory.registerComponent("orderDeliveryLink", OrderDeliveryLink);
