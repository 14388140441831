export class YextRedirectService {
	static async getRedirectData(
		endpoint: string,
		authorization?: string
	): Promise<[{} | null, any | null]> {
		let options = {
			contentType: "application/json",
			headers: {},
			type: "GET",
			url: endpoint
		};

		if (authorization) {
			options = { ...options, headers: { Authorization: authorization } };
		}
		try {
			const promise = $.ajax(options);
			const result = await Promise.resolve(promise);
			return [result, null];
		} catch (error) {
			console.warn(
				`Error in getting LocationRedirecCheck response. Error: ${error}`
			);
			return [null, error];
		}
	}
}
