import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { GridLayout } from "../common/GridLayout/GridLayout";
import { GiftingBehavior } from "./GiftingBehavior";
import { ReceiveGiftQrModal } from "./ReceiveGiftQrModal";

function parseBoolean(raw?: string): boolean {
	return (raw || "").toLowerCase() === "true";
}

export class ReceiveGiftCallout extends GridLayout {
	public readonly behavior: GiftingBehavior;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.behavior = new GiftingBehavior(_element);

		_element.querySelectorAll("[data-action='show qr modal']").forEach(el => {
			el.addEventListener("click", async e => {
				e.preventDefault();
				await ReceiveGiftQrModal.show();
			});
		});

		_element.querySelectorAll("[data-action='print QR code']").forEach(el =>
			el.addEventListener("click", async e => {
				e.preventDefault();
				await ReceiveGiftQrModal.print();
			})
		);

		_element
			.querySelectorAll<HTMLElement>("[data-authenticate-with]")
			.forEach(el =>
				el.addEventListener("click", e => {
					e.preventDefault();
					this.behavior.authenticate(el.dataset.authenticateWith);
				})
			);
	}

	public async init(): Promise<void> {
		if (this.autoClaim && this.behavior.authenticated) {
			return this.behavior.addToRewards();
		}
	}

	private get autoClaim(): boolean {
		return parseBoolean(this._element.dataset.autoClaim);
	}
}

ComponentFactory.registerComponent("ReceiveGiftCallout", ReceiveGiftCallout);
