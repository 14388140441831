export class DeliveryServiceAvailability {
	_data = [];
	constructor(data: []) {
		this._data = data;
	}

	get hasEligibileAddress(): boolean {
		return this._data.some(
			i =>
				i.mobileOrderingSettings?.fulfillmentMethods?.delivery
					?.deliverySubscriptionEnabled === true
		);
	}
}
