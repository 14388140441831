$(".single-item").map((_, el) => {
	function extractTemplate(context: HTMLElement, selector: string): string {
		const template = context.querySelector<HTMLTemplateElement>(selector);
		const html = template?.innerHTML ?? "";
		template?.parentElement?.removeChild(template);

		return html;
	}

	$(el).slick({
		dots: true,
		focusOnChange: true,
		focusOnSelect: true,
		infinite: true,
		mobileFirst: true,
		nextArrow: extractTemplate(el, "template[data-next-arrow]"),
		prevArrow: extractTemplate(el, "template[data-prev-arrow]"),
		responsive: [
			{
				breakpoint: 100,
				settings: {
					arrows: false
				}
			},
			{
				breakpoint: 960,
				settings: {
					arrows: true
				}
			}
		],
		slidesToScroll: 1,
		slidesToShow: 1,
		useTransform: false
	});
});
