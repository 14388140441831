import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

interface Grid {
	gridLength: number;
	gridElementHeight: number;
}

export class FlipCard extends Component {
	private _gridElement!: HTMLElement | null;
	private itemArray: Array = [];

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.toggles.forEach(el => {
			el.addEventListener("click", e => this.handleToggle(e), false);
		});

		if (
			String(this._element.getAttribute("data-card-count")) === "grid" ||
			String(this._element.getAttribute("data-card-count")) === "7"
		) {
			this.setContainerHeight();
		}

		this.state = this.isMobileView;

		this.cardCounter();
	}

	private setContainerHeight(bool: Boolean = false): void {
		this.itemArray.length = 0;
		this._gridElement = this._element;
		const { gridLength, gridElementHeight } = this.calculateHeight(
			this._gridElement
		);

		const isEven = gridLength % 2 === 0 ? true : false;
		const divisibleByThree = gridLength % 3 === 0;
		const evenGridHeight: number = Math.ceil(gridElementHeight / 2);
		const oddGridHeight: number = this.isMobileView
			? Math.ceil(
					(gridElementHeight + Math.ceil(gridElementHeight / gridLength)) / 2
			  )
			: !divisibleByThree
			? Math.ceil(
					Math.ceil(gridElementHeight / gridLength) +
						Math.ceil(gridElementHeight / 3)
			  )
			: Math.ceil(
					(gridElementHeight + Math.ceil(gridElementHeight / gridLength)) / 3
			  );

		const gridHeight: Number = isEven ? evenGridHeight : oddGridHeight;

		console.log("divisibleByThree", divisibleByThree);

		console.log("gridElementHeight", gridElementHeight);
		console.log(
			"Math.ceil(gridElementHeight / 3)",
			Math.ceil(gridElementHeight / 3)
		);
		console.log(
			"Height",
			Math.ceil(
				Math.ceil(gridElementHeight / gridLength) +
					Math.ceil(gridElementHeight / 3)
			)
		);

		this._gridElement.style.height = this.isMobileView
			? "auto"
			: gridHeight + "px";
		this._gridElement.style.opacity = "1";
	}

	private async handleToggle(e: MouseEvent): Promise<void> {
		e.preventDefault();
		e.stopPropagation();
		const target = e.currentTarget as Element;

		const ribbon = target.querySelector<HTMLTemplateElement>("div.ribbon span");
		const evtModel = {
			element: "Flip Card",
			module_name: "Flippable Cards Callout",
			link_text: ribbon?.innerHTML || ""
		};
		DataLayerService.pushDataLayerEvent("element_click", evtModel);

		this.toggles.forEach(el => {
			if (el.isSameNode(target)) {
				target?.classList.toggle("is-flipped");

				if (target?.classList.contains("is-flipped")) {
					el?.parentNode?.style.width =
						this.isMobileView || this.isTabletView ? "100%" : "auto";
				} else {
					el?.parentNode?.style.width =
						this.isMobileView || this.isTabletView ? "50%" : "auto";
				}

				setTimeout(() => {
					el?.parentNode.scrollIntoView({
						behavior: "smooth",
						block: "center"
					});
				}, 100);
			} else {
				if (el.classList.contains("is-flipped")) {
					el?.parentNode?.style.width =
						this.isMobileView || this.isTabletView ? "50%" : "auto";
					el.classList.remove("is-flipped");
				}
			}

			el.querySelectorAll("a").forEach(a => {
				a.addEventListener("click", e => {
					e.stopPropagation();
				});
			});
		});
	}

	private calculateHeight(element: HTMLElement): Grid {
		const gridLength: Number = this.getNodeLength(element);
		const gridElementHeight: Number = this.getNodeHeight(element);

		return {
			gridLength,
			gridElementHeight
		};
	}

	private getNodeLength(element: HTMLElement): Number {
		return this._element.querySelectorAll(".scene").length;
	}

	private getNodes(element: HTMLElement): NodeListOf<HTMLElement> {
		return element.querySelectorAll<HTMLElement>(".scene");
	}

	private getNodeHeight(element: HTMLElement): Number {
		const scenes = element.querySelectorAll<HTMLElement>(".scene");

		scenes.forEach(item => {
			console.log("item", item?.clientHeight);
			this.itemArray.push(item?.clientHeight);
		});

		const sum = this.itemArray.reduce(
			(total: number, amount: number) => total + amount
		);

		return sum;
	}

	public get toggles(): NodeListOf<HTMLElement> {
		return this._element.querySelectorAll<HTMLElement>(".flippable-card");
	}

	public getBackOfCard(element: HTMLElement): HTMLElement {
		const cardBack = element.querySelector<HTMLElement>(
			".flippable-card--back"
		);

		return cardBack;
	}

	public isSelectorEven(element: Element): void {
		const parent: Element = element?.parentNode;
		const elementIndex = parent?.getAttribute("data-index");
		console.log("elementIndex", Number(elementIndex) % 2 === 0);
	}

	public cardCounter() {
		const flippableCards = document.querySelectorAll(
			".content-hub-flippable-card"
		);
		flippableCards.forEach(flippableCard => {
			const sceneElements = flippableCard.querySelectorAll(".scene");
			const cardCount = sceneElements.length;
			switch (cardCount) {
				case 4:
				case 5:
				case 7:
					flippableCard.setAttribute("data-card-count", cardCount.toString());
					break;
				default:
					flippableCard.setAttribute("data-card-count", "3");
					break;
			}
		});
	}

	get isMobileView(): boolean {
		const tabletMinWidth = 767;

		return window.innerWidth < tabletMinWidth;
	}

	get isTabletView(): boolean {
		const desktop1View = 960;

		return window.innerWidth < desktop1View;
	}

	get isDesktop1View(): boolean {
		const desktop2View = 1200;

		return window.innerWidth < desktop2View;
	}

	get isDesktop2View(): boolean {
		const desktop2View = 1200;

		return window.innerWidth > desktop2View;
	}
}

ComponentFactory.registerComponent("FlipCard", FlipCard);
