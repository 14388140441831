export function animationDebounce<T>(
	capturePhase: () => T,
	actionPhase: (context: T) => void
): () => void {
	let ticking = false;

	return () => {
		const context = capturePhase();

		if (!ticking) {
			requestAnimationFrame(() => {
				actionPhase(context);
				ticking = false;
			});

			ticking = true;
		}
	};
}
