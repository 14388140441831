import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class InformationBar extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		const track = _element.dataset.track;

		if (track) {
			_element.addEventListener("click", () => {
				(dataLayer || []).push({
					category: track,
					event: "engagement"
				});
			});
		}
	}
}

ComponentFactory.registerComponent("InformationBar", InformationBar);
