import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { ArticleResultsService } from "./ArticleResultsService";
import { pageArrowsConfig } from "./pageArrowsConfig";
export class PaginatedArrows extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.updateCountElem();
		this.updateArrowState();
		// tslint:disable-next-line
		this.handleArrowClick();

		const { listView } = this._element.dataset;
		window.addEventListener(`filterChange${listView}`, e =>
			this.renderPaging(e as CustomEvent)
		);
	}

	async renderPaging(data: {
		detail: { pageNumber: string; totalResults: string; totalPage: string };
	}): Promise<void> {
		this._element.dataset.pageNumber = data.detail.pageNumber;
		this._element.dataset.totalResults = data.detail.totalResults;
		this._element.dataset.totalPages = data.detail.totalPages;

		if (data.detail.dateSort) {
			this._element.dataset.dateSort = data.detail.dateSort;
		}

		if (data.detail.filtersToApply) {
			this._element.dataset.filtersToApply = data.detail.filtersToApply;
		}

		if (data.detail.language) {
			this._element.dataset.language = data.detail.language;
		}

		this.updateCountElem();
		this.updateArrowState();
	}

	updateCountElem(): void {
		const countElem = this._element.querySelector("[data-carousel-count]");
		if (!countElem) {
			console.warn("No count element found");

			return;
		}

		const { totalPages, pageNumber } = this._element.dataset;

		if (totalPages === "0" || totalPages === "1") {
			this._element.classList.add("hidden");
			return;
		}

		if (this._element.classList.contains("hidden")) {
			this._element.classList.remove("hidden");
		}

		countElem.innerHTML = `${pageNumber} of ${totalPages}`;
	}

	async handleArrowClick(): Promise<void | null> {
		const handlers = ["[data-prev-arrow]", "[data-next-arrow]"];
		const configVal = this._element.dataset.config;
		if (!configVal) {
			console.warn(`No config found for ${this._element}`);
			return;
		}

		handlers.forEach(handler => {
			const arrow = this._element.querySelector(handler);
			if (!arrow) {
				console.warn(`No arrow found for ${handler}`);
			}
			arrow?.addEventListener("click", evt => {
				if (arrow.classList.contains("disabled")) {
					return;
				}
				this.setCount(handler);
				const { listTarget = null } = this._element.dataset;
				const targetEl = `#${listTarget}`;
				const params = pageArrowsConfig[configVal].getParams(this._element);
				// tslint:disable-next-line
				ArticleResultsService.getListViewResults(
					this._element.dataset.endpoint || "",
					params,
					document.querySelector(targetEl) as HTMLElement
				).then(async result => {
					this.updateCountElem();
					this.updateArrowState();
					const [data, err] = result;
					const detail = {
						pageNumber: data.PageNumber,
						totalResults: data.TotalResults,
						totalPages: data.MaxPage
					};
					// tslint:disable-next-line
					this.renderPaging({ data, detail });
					this.scrollTop();
					const { listView } = this._element.dataset;
					if (listView) {
						const scope = document.querySelector(listView);
            // tslint:disable-next-line no-floating-promises
						await this._app.services.componentFactory.loadComponents(
							scope as HTMLElement
						);
					}
				});
			});
		});
	}

	setCount(handler: string): void {
		const { pageNumber, totalPages } = this._element.dataset;
		const _pageNumber = parseInt(pageNumber, 10);
		const _totalPages = parseInt(totalPages, 10);

		if (!_pageNumber || !_totalPages) {
			console.warn(`No data found for ${handler}`);

			return;
		}
		const isPrev = handler === "[data-prev-arrow]";
		const chngValBy = isPrev ? -1 : 1;

		if (_pageNumber === _totalPages || _pageNumber < _totalPages) {
			this._element.dataset.pageNumber = (_pageNumber + chngValBy).toString();
		} else if (_pageNumber > _totalPages) {
			this._element.dataset.pageNumber = totalPages;
		}
	}

	updateArrowState(): void {
		const { pageNumber, totalResults, totalPages } = this._element.dataset;

		if (!pageNumber || !totalResults || !totalPages) {
			console.warn(
				"Missing attribute value. Check for: pageNumber, totalResults, totalPage"
			);

			return;
		}

		const _pageNumber = parseInt(pageNumber, 10);
		const _totalResults = parseInt(totalResults, 10);
		const _totalPages = parseInt(totalPages, 10);

		if (_totalResults === 0 || _totalPages === 1) {
			this._element.classList.add("hidden");
			return;
		}

		const prevArrow = this._element.querySelector("[data-prev-arrow]");
		const nextArrow = this._element.querySelector("[data-next-arrow]");

		if (_pageNumber === 1) {
			prevArrow?.setAttribute("disabled", "true");
			nextArrow?.removeAttribute("disabled");
		} else if (_pageNumber < _totalPages) {
			prevArrow?.removeAttribute("disabled");
			nextArrow?.removeAttribute("disabled");
		} else {
			prevArrow?.removeAttribute("disabled");
			nextArrow?.setAttribute("disabled", "true");
		}
	}

	scrollTop(): void {
		const { listTarget } = this._element.dataset;
		const targetEl = `#${listTarget}`;
		const target = document.querySelector(targetEl) as HTMLElement;
		if (target) {
			$("html, body").animate(
				{
					scrollTop: $(target).offset().top - 100
				},
				"slow"
			);
		}
	}
}

ComponentFactory.registerComponent("PaginatedArrows", PaginatedArrows);
