import { IValidateResponse } from "../../services/UserProfile";
export class ValidateFieldResponse {
	_data;
	constructor(data: IValidateResponse) {
		this._data = data;
	}

	get isValidAddress(): boolean {
		return Object.keys(this._data).length > 0;
	}
}
