import { Breakpoints } from "../../../breakpoints";
import { ServiceFactory } from "../services/ServiceFactory";
import { ICfa } from "./ICfa";
import { IPageLayout } from "./IPageLayout";
import Bugsnag from "@bugsnag/js";
import { BugsnagService } from "../services/BugsnagService";

export class Cfa implements ICfa {
	private _initPromise?: Promise<void>;
	private _services?: ServiceFactory;
	private readonly _bugsnagService = new BugsnagService();

	public async init(): Promise<void> {
		if (!this._initPromise) {
			this._initPromise = this.initialize();
		}

		return this._initPromise;
	}

	private async initialize(): Promise<void> {
		const services = (this._services = new ServiceFactory(this));

		// Any code that executes on page load should go in this area.
		// TODO: Readd later - enabledBreadcrumbTypes:
		// ['error', 'log', 'navigation', 'request', 'user'],

		if (this._bugsnagService.hasApiKey()) {
			Bugsnag.start({
				apiKey: this._bugsnagService.getApiKey(),
				appVersion: this._bugsnagService.getReleaseVersion(),
				enabledReleaseStages: ["staging", "production"],
				releaseStage: this._bugsnagService.getReleaseStage()
			});
			this._bugsnagService.clearApiKey();
		}
		await services.componentFactory.loadComponents(document.body);
	}

	public get layout(): IPageLayout {
		return {
			// Anything bigger than mobile must be desktop.
			desktopWidth: Breakpoints.mobile + 1,
			// TODO: Probably this should be calculated?
			navBaseHeight: 64,
			tablet: Breakpoints.tablet
		};
	}

	public get services(): ServiceFactory {
		const services = this._services;

		if (!services) {
			throw new Error("Cfa not initialized");
		}

		return services;
	}
}
