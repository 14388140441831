import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import SeeMore, { getSeeMoreParams } from "../../../util/SeeMore";

export class ProductTiles extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.setRowVariable();
		this.setChildCount();
		this.handleModifiers();
	}

	setRowVariable(): void {
		return this._element.style.setProperty(
			"--row-number",
			JSON.stringify(this.gridChildren - 1)
		);
	}

	setChildCount(): void {
		this._element.setAttribute(
			"data-child-count",
			JSON.stringify(this.gridChildren)
		);
	}

	handleModifiers(): void {
		const classes = this._element.classList;
		// Apply gridView to li2 and li3 if mobile view
		if (classes.contains("mobile-buttons") && this.isMobileView) {
			this.applyClickEvts();
		}

		// Apply See More to module if mobile view
		if (classes.contains("withSeeMore") && this.isMobileView) {
			console.log("withSeeMore");
			const params = getSeeMoreParams(this._element);

			SeeMore({ parent: this._element, ...params });
		}
	}

	applyClickEvts(): void {
		// TODO: don't hardcode these but get from data-attributes for more flexibility
		const querySelectors = ["li:nth-child(2)", "li:nth-child(3)"];

		querySelectors.forEach((selector, index) => {
			const element = this._element.querySelector(selector);

			if (!element) {
				console.warn(`No element found for selector: ${selector}`);
				return;
			}
			const btnText = this._element.dataset[`cta${index + 1}MobileText`];
			const textContainer = element.querySelector(".text-container p");
			if (!textContainer) return;
			textContainer.textContent = btnText;

			const href = element.querySelector("a")?.getAttribute("href");
			element.addEventListener("click", () => {
				if (href) {
					element.classList.add("redirect-button");
					window.location.href = href;
				}
			});
		});
	}

	public get gridChildren(): number {
		return this._element.childElementCount;
	}

	get isMobileView(): boolean {
		return window.innerWidth < this._app.layout.desktopWidth;
	}
}

ComponentFactory.registerComponent("productTiles", ProductTiles);
