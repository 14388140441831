import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

const lastViewKey = "DeliveryHero.lastView";

export class DeliveryHero extends Component {
	private anim: ILottieAnimationItem | null = null;
	private readonly _animationContainer: HTMLElement | null;
	private readonly _delay: number = 500;
	private readonly _wrapper: HTMLElement | null;

	constructor(element: HTMLElement, app: ICfa) {
		super(element, app);

		this._animationContainer = this._element.querySelector<HTMLElement>(
			"[data-purpose='animation content']"
		);

		this._wrapper = this._element.querySelector<HTMLElement>(
			"[data-purpose='static content']"
		);
	}

	public static get lastView(): Date | null {
		let raw: string | null;

		try {
			raw = localStorage.getItem(lastViewKey);
		} catch (ex) {
			console.warn("Could not read last animation view time");

			return null;
		}

		if (raw) {
			const asNumber = Number(raw);
			const asDate = new Date(asNumber);
			if (isNaN(asDate.valueOf())) {
				return null;
			}

			return asDate;
		}

		return null;
	}

	public static set lastView(value: Date | null) {
		try {
			if (value) {
				localStorage.setItem(lastViewKey, value.valueOf().toString());
			} else {
				localStorage.removeItem(lastViewKey);
			}
		} catch (ex) {
			console.warn("Could not set last animation view time");
		}
	}

	private static userHasSeenTheAnimationToday(): boolean {
		const last = DeliveryHero.lastView;

		if (last) {
			const next = new Date(last.valueOf());
			next.setDate(next.getDate() + 1);
			next.setHours(0, 0, 0, 0);
			const now = new Date();
			if (now.valueOf() <= next.valueOf()) {
				return true;
			}
		}

		return false;
	}

	private static isIE(): boolean {
		return /MSIE|Trident/.test(window.navigator.userAgent);
	}

	private static isEdge(): boolean {
		return /Edge/.test(window.navigator.userAgent);
	}

	private static reducedMotion(): boolean {
		const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

		return mediaQuery.matches;
	}

	public async init(): Promise<void> {
		if (
			DeliveryHero.userHasSeenTheAnimationToday() ||
			DeliveryHero.reducedMotion() ||
			DeliveryHero.isIE() ||
			DeliveryHero.isEdge()
		) {
			this.hideAnimationContent();
			this.displayStaticContent();

			return;
		}

		if (this.endpoint) {
			const result = await Promise.resolve($.ajax(this.endpoint));

			// Sitecore is returning the animation as application/octet-stream
			// in all cases and I'm not sure why. The mime type is already set
			// to application/json and I don't know what else to check. As a
			// result, this is being returned as a string instead of being
			// parsed as JSON, so we need to handle that.
			const animation =
				typeof result === "string"
					? (JSON.parse(result) as ILottieAnimationItem)
					: (result as ILottieAnimationItem);

			this.setUpAnimation(animation);
		}
	}

	private setUpAnimation(animation: ILottieAnimationItem): void {
		const container = this._animationContainer;

		if (!container) {
			return;
		}

		this.anim = lottie.loadAnimation({
			animationData: animation,
			autoplay: false,
			container,
			loop: false,
			renderer: "svg"
		});

		// Just a slight delay before the animations plays.
		// It gets choppy while the page is still rendering.
		window.setTimeout(() => this.anim?.play(), this._delay);

		this.anim?.addEventListener("enterFrame", () => this.onTick());
	}

	private onTick(): void {
		const anim = this.anim;
		if (!anim) {
			return;
		}

		if (Math.ceil(anim.currentRawFrame) >= anim.totalFrames - 1) {
			lottie.freeze();
			lottie.stop(anim);
			anim.removeEventListener("enterFrame", () => this.onTick());
			lottie.destroy(anim);
			anim.stop();

			DeliveryHero.lastView = new Date();

			this.hideAnimationContent();

			// Just a slight delay between animations.
			window.setTimeout(() => this.displayStaticContent(), this._delay);
		}
	}

	private hideAnimationContent(): void {
		const container = this._animationContainer;

		if (container) {
			container.style.display = "none";
		}
	}

	private displayStaticContent(): void {
		this._wrapper?.classList.add("active");
	}

	private get endpoint(): string {
		return this._element.dataset.endpoint || "";
	}
}

ComponentFactory.registerComponent("deliveryHero", DeliveryHero);
