/**
 * Returns class wrapper for querying success and error code of response.
 */

export interface IFormSubmitReponse {
	Success: boolean;
	Code?: string;
}

export interface IFormSubmitResponse {
	isSuccessful(): boolean;
	isDuplicateEntry(): boolean;
	hasSubmitError(): boolean;
}

export class FormSubmitResponse {
	response: IFormSubmitReponse;
	constructor(response: string) {
		this.response = JSON.parse(response) as IFormSubmitReponse;
		console.log("reponse after submission");
		console.log({ response });
	}

	isSuccessful(): boolean {
		return this.response?.Success;
	}

	isDuplicateEntry(): boolean {
		return this.response?.Code === "DUPLICATE";
	}

	hasSubmitError(): boolean {
		return this.response?.Code === "ERROR";
	}
}
