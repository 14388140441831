import { IPrintService } from "./IPrintService";

export class PrintService implements IPrintService {
	printHtml(html: string): void {
		const features =
			"width=750,height=650,top=50,left=50," +
			"toolbars=no,scrollbars=yes,status=no,resizable=yes";

		const win = window.open("", "PrintWindow", features);
		if (win) {
			const doc =
				"<html>\n<head>\n</head><body>" +
				`<div>\n ${html}\n</div>\n</body>\n</html>`;

			win.document.writeln(doc);
			win.document.close();
			win.focus();
			win.print();
			win.close();
		}
	}
}
