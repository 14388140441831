import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class NavContentCarousel extends Component {
	tabNav: HTMLElement | null;
	tabLinks: NodeListOf<HTMLElement>;
	tabContentContainer: HTMLElement | null;
	slickSlider: JQuerySlick | null = null;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.tabNav = this._element.querySelector(".tab-navigation");
		this.tabLinks = this._element.querySelectorAll(".tab-navigation li a");
		this.tabContentContainer = this._element.querySelector(".tab-content");

		if (!this.tabContentContainer || !this.tabLinks) {
			throw new Error("Tab Content Container and/or Tab Links not found.");
		}

		this.initialize();
	}

	initialize(): void {
		this.tabLinks.forEach(handler =>
			handler.addEventListener("click", e => {
				e.preventDefault();
				this.toggleContent(handler);
				const liSiblings = handler.closest(".tab-navigation")?.children;
				if (liSiblings) {
					if (handler.parentElement) {
						const index = Array.from(liSiblings).indexOf(handler.parentElement);
						this.setNavBarState(index);
					}
				}
			})
		);
		this.toggleFirstContent();
		const { tablet } = this._app.layout;

		if (window.innerWidth < tablet) {
			this.handleMobileSlider();
		}
	}

	handleMobileSlider(): void {
		this.initSlider();
		this.handleNavBarClick();
		this.handleArrowLinks();
	}

	initSlider(): void {
		const sliderOptions: JQuerySlickOptions = {
			accessibility: true,
			arrows: false,
			cssEase: "linear",
			dots: false,
			draggable: true,
			infinite: false,
			mobileFirst: true,
			slidesToScroll: 1,
			slidesToShow: 1,
			speed: 500,
			useTransform: false
		};
		this.slickSlider = $(".tab-content").slick(sliderOptions);
		$(".tab-content").on("swipe", (event, slick, direction) => {
			const currentIndex = this.slickSlider?.slick("slickCurrentSlide");
			this.setNavBarState(currentIndex);
		});
	}

	handleNavBarClick(): void {
		this.tabLinks.forEach((link, i) => {
			link.addEventListener("click", () => {
				this.slickSlider?.slick("slickGoTo", i);
				this.setNavBarCenter(i);
			});
		});
	}

	setNavBarState(index: number): void {
		const activeTabClass = "active";
		this.tabLinks.forEach((link, i) => {
			const liElem = link.parentNode as HTMLElement;
			if (liElem?.classList.contains(activeTabClass)) {
				liElem?.classList.remove(activeTabClass);
			}
			if (i === index) {
				liElem?.classList.add(activeTabClass);
				this.setNavBarCenter(i);
			}
		});
	}

	setNavBarCenter(i: number): void {
		const tabletMinWidth = 768;
		const removeTabClass = "remove";
		if (this.tabNav && window.innerWidth < tabletMinWidth) {
			if (i > 1 && i < this.tabLinks.length - 1) {
				// tslint:disable-next-line: no-magic-numbers
				this.tabNav.children[i - 2].classList.add(removeTabClass);
			}

			// tslint:disable-next-line: no-magic-numbers
			if (i !== 0 && i < this.tabLinks.length - 2) {
				this.tabNav.children[i - 1].classList.remove(removeTabClass);
			}
		}
	}

	handleArrowLinks(): void {
		const navArrows: NodeListOf<HTMLElement> = this._element.querySelectorAll(
			".navigation-arrow-handlers a"
		);

		const totalSlidesIndex = this.tabLinks?.length - 1;

		if (!navArrows) {
			throw new Error("Missing navigation arrow/s");
		}

		const prevArrow = this._element.querySelector(
			".navigation-arrow-handlers a[data-slick-direction='slickPrev']"
		);
		const nextArrow = this._element.querySelector(
			".navigation-arrow-handlers a[data-slick-direction='slickNext']"
		);

		prevArrow?.classList.add("disabled");

		navArrows.forEach(arrow => {
			arrow.addEventListener("click", () => {
				const { slickDirection } = arrow.dataset;
				/* tslint:disable-next-line */
				this.slickSlider?.slick(slickDirection);
				/* tslint:disable-next-line */
				const currentIndex = this.slickSlider?.slick("slickCurrentSlide");
				this.setNavBarState(currentIndex);

				if (currentIndex === 0) {
					prevArrow?.classList.add("disabled");
				} else if (currentIndex === totalSlidesIndex) {
					nextArrow?.classList.add("disabled");
				} else {
					prevArrow?.classList.remove("disabled");
					nextArrow?.classList.remove("disabled");
				}
			});
		});
	}

	toggleFirstContent(): void {
		if (this.tabLinks.length > 0) {
			const firstLink = this.tabLinks[0];

			firstLink.click();
		}
	}

	toggleContent(handler: HTMLElement): void {
		const activeTabClass = "active";
		const tabTarget = handler.dataset.tabTarget;
		const lastTab = this.tabContentContainer?.querySelector(
			`.${activeTabClass}`
		);
		const tabToDisplay = this.tabContentContainer?.querySelector(
			`[data-tab-target='${tabTarget}']`
		);

		if (!tabToDisplay) {
			throw new Error(`No Tab found for value: ${tabToDisplay}`);
		}

		if (!tabToDisplay.classList.contains(activeTabClass)) {
			tabToDisplay.setAttribute("aria-expanded", "true");
			tabToDisplay.classList.add(activeTabClass);

			if (lastTab !== null) {
				lastTab?.setAttribute("aria-expanded", "false");
				lastTab?.classList.remove(activeTabClass);
			}
		}
	}
}

ComponentFactory.registerComponent("navContentCarousel", NavContentCarousel);
