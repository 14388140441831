import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

class OLOReviewOrderForm extends Component {
	private readonly form: HTMLFormElement;
	private readonly consentInputs: NodeListOf<HTMLInputElement>;
	private readonly submitBtns: NodeListOf<HTMLElement>;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.form = this._element as HTMLFormElement;
		this.consentInputs = this.form.querySelectorAll(
			"input[name='olo-consent']"
		);
		this.submitBtns = this.form.querySelectorAll("button[type='submit']");

		this.consentInputs.forEach(oloConsentInput => {
			oloConsentInput.addEventListener("change", () => {
				const isChecked = oloConsentInput.checked;
				this.toggleCheckBoxes(isChecked);
				this.toggleSubmitBtns(isChecked);
			});
		});
	}

	toggleCheckBoxes(isChecked: boolean): void {
		this.consentInputs.forEach(input => {
			input.checked = isChecked;
		});
	}

	toggleSubmitBtns(isChecked: boolean): void {
		const disabledClassName = "btn-round--disabled";
		this.submitBtns.forEach(btn => {
			isChecked
				? btn.classList.remove(disabledClassName)
				: btn.classList.add(disabledClassName);
		});
	}
}

ComponentFactory.registerComponent("oloReviewOrderForm", OLOReviewOrderForm);
