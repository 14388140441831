import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { Accordion } from "../common/Accordion/Accordion";

export class QuestionsFilterAccordion extends Component {
	public readonly Accordion: Accordion;
	public readonly filterForItems: string[] = [];

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.Accordion = new Accordion(_element, _app);
		this.checkboxes.forEach(checkbox =>
			checkbox.addEventListener("change", () => this.handleFiltering(checkbox))
		);
	}

	private handleFiltering(checkbox: HTMLInputElement): void {
		const checkboxToggled: string = checkbox.value.toLowerCase();
		if (checkbox.checked) {
			this.filterForItems.push(checkboxToggled);
		} else {
			const indexItemToRemove = this.filterForItems.indexOf(checkboxToggled);
			this.filterForItems.splice(indexItemToRemove, 1);
		}
		this.filterSections();
	}

	private filterSections(): void {
		if (this.filterForItems.length > 0) {
			this.accordionSections.forEach(section => {
				const sectionTag: string = section.dataset.tag?.toLowerCase() ?? "";

				this.filterForItems.includes(sectionTag)
					? this.showSection(section)
					: this.hideSection(section);
			});
		} else {
			this.accordionSections.forEach(section => {
				this.showSection(section);
			});
		}
	}

	private hideSection(section: HTMLElement): void {
		section.classList.add("hide");
		section.style.display = "none";
	}

	private showSection(section: HTMLElement): void {
		section.classList.remove("hide");
		section.style.display = "block";
	}

	get accordionSections(): NodeListOf<HTMLElement> {
		return this._element.querySelectorAll(".toggle");
	}

	get checkboxes(): NodeListOf<HTMLInputElement> {
		return this._element.querySelectorAll(".filters ul input");
	}
}

ComponentFactory.registerComponent(
	"QuestionsFilterAccordion",
	QuestionsFilterAccordion
);
