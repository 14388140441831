/**
 * Takes parent component, hides child elements after index paramter, and shows items on button click
 */
const SeeMore = ({
	parent,
	index,
	buttonText,
	btnType
}: {
	parent: HTMLElement;
	index: number;
	buttonText: string;
	btnType: string;
}) => {
	const children = parent.children;
	for (let i = index; i < children.length; i++) {
		$(children[i]).hide();
	}

	// create button
	const button = document.createElement("button");
	const clasesToAdd = ["btn", "see-more-btn", `btn-round--${btnType}`];
	button.classList.add(...clasesToAdd);
	button.innerHTML = buttonText;
	button.addEventListener("click", () => {
		for (let i = index; i < children.length; i++) {
			$(children[i]).show();
		}
		button.classList.add("hidden");
		parent.classList.add("open");
	});

	parent.insertBefore(button, parent.children[index]);
};

export const getSeeMoreParams = (
	element: HTMLElement
): {
	index: number;
	buttonText: string;
	btnType: string;
} => {
	const {
		hideAfterIndex = "1",
		seeMoreBtnText = "See More",
		seeMoreBtnType = "red"
	} = element.dataset;

	return {
		index: parseInt(hideAfterIndex),
		buttonText: seeMoreBtnText,
		btnType: seeMoreBtnType
	};
};

export default SeeMore;
