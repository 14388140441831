import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

class ScrollContent extends Component {
	constructor(element: HTMLElement, app: ICfa) {
		super(element, app);
		this.scrollToCenter();
	}

	scrollToCenter(): void {
		const half: number = 2;
		const width = $(this._element).width();
		const widthChild: number = $(this._element).children().width() || 0;
		if (!width) {
			return;
		}

		$(this._element).animate({
			scrollLeft: width / half - widthChild / half
		});
	}
}

ComponentFactory.registerComponent("ScrollContent", ScrollContent);
