import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class Facts extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.setModalClickEvt();
	}

	public async fetchHTML(link: HTMLElement): Promise<string | null> {
		if (link.dataset.endpoint) {
			const endpoint: string = link.dataset.endpoint;
			const id: string = link.dataset.id ?? "";

			let res: string;

			try {
				const promise = $.ajax({
					data: { id },
					url: endpoint
				});
				res = (await Promise.resolve(promise)) as string;

				return res;
			} catch (err) {
				const typed = err as JQuery.jqXHR;
				console.error(`Could not load ${endpoint} : ${typed.responseText}`);

				return null;
			}
		}

		console.error("No endpoint found");

		return null;
	}

	public openModal(html: string): void {
		$.magnificPopup.open({
			items: {
				src: html
			},
			mainClass: "covid-serving-safely-modal",
			type: "inline"
		});
	}

	private setModalClickEvt(): void {
		if (!this.linkNodes) {
			const msg = "No Fact Links Found";
			throw new Error(msg);
		}

		this.linkNodes.forEach(link => {
			link.addEventListener("click", async e => {
				e.preventDefault();
				const html = await this.fetchHTML(link);

				if (html) {
					this.openModal(html);
				}
			});
		});
	}

	get linkNodes(): NodeListOf<HTMLElement> {
		return this._element.querySelectorAll("[data-link='fact-modal'");
	}
}

ComponentFactory.registerComponent("facts", Facts);
