/**
 * Takes share window values from SocialShare.ts - this should be used as the default.
 * @param target from {HTMLElement}
 * @returns string
 */
export default function shareWindowProps(target: HTMLElement) {
	/**
	 * https://developers.facebook.com/docs/workplace/sharing/share-dialog/
	 * Width and height were taken from facebook's example though i have
	 * seen examples of width: 550px and height: 450px used before.
	 */
	const shareWidth = 626;
	const shareHeight = 436;
	const windowHeight = window.innerHeight;
	const windowWidth = window.innerWidth;
	const half = 2;
	const shareValue = target?.getAttribute("href") || "";

	const shareWindowProperties = `height=${shareHeight}, width=${shareWidth},
    top=${windowHeight / half} - ${shareHeight / half},
    left=${windowWidth / half} - ${shareWidth / half},
    toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`;

	return shareWindowProperties;
}
