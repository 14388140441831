import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { AddressAutocompleteService } from "./AddressAutocompleteService";
import { inputDebounce } from "../../util/InputDebounce";
import { IAutoCompleteResponseTransformed } from "./IAutoCompleteResponseTransformed";
// tslint:disable: no-unsafe-any
export class AddressAutocomplete extends Component {
	private readonly autoCompleteService;
	private readonly autoCompleteInput: HTMLInputElement;
	private hasUserSelectedAddress = false;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		const dxeEndpoint = this.getDXEAPIEndpoint();
		const bearerTokenEndpoint = this.getBearerTokenEndpoint();
		this.autoCompleteService = new AddressAutocompleteService(
			bearerTokenEndpoint,
			dxeEndpoint
		);

		if (this._element.tagName !== "INPUT") {
			throw new Error(
				`Element must be typeof input. Received: ${this._element}`
			);
		}

		this.autoCompleteInput = this._element as HTMLInputElement;

		this.handleAutocomplete();
	}

	handleAutocomplete(): void {
		const delay = 200;
		this._element.addEventListener(
			"input",
			inputDebounce(async e => {
				const target = e.target as HTMLInputElement;
				if (target.value) {
					this.handleUserTamperingFlag();
					await this.loadAutocompleteResults(target.value);
				}
			}, delay)
		);
	}

	async loadAutocompleteResults(value: string): Promise<void> {
		const suggestions = await this.autoCompleteService.getAddressSuggestions(
			value
		);
		$(this.autoCompleteInput)
			.autocomplete({
				classes: {
					"ui-autocomplete": "signature-drop-autocomplete-menu"
				},
				select: (event, ui) => {
					// tslint:disable-next-line: no-unsafe-any
					this.autoCompleteInput.setAttribute(
						"value",
						`${ui.item.placeId}|${ui.item.mainText}|${ui.item.secondaryText}`
					);
					this.hasUserSelectedAddress = true;
					this.autoCompleteInput.setAttribute(
						"data-has-user-selected-address",
						`${this.hasUserSelectedAddress}`
					);
					this.autoCompleteInput.blur();
				},
				source: (
					// tslint:disable-next-line
					req: any,
					response: (
						arg0: IAutoCompleteResponseTransformed[] | undefined
						// tslint:disable-next-line
					) => any
				) => response(suggestions)
			})
			.autocomplete("instance")._renderItem = (
			ul: HTMLElement,
			item: IAutoCompleteResponseTransformed
		) => {
			return $("<li>")
				.append(`<div>${item.mainText}<br>${item.secondaryText}</div>`)
				.appendTo(ul);
		};
		$(this.autoCompleteInput).autocomplete(
			"option",
			"classes.ui-autocomplete",
			"signature-drop-autocomplete-menu"
		);
	}

	handleUserTamperingFlag(): boolean {
		if (this.hasUserSelectedAddress) {
			this.hasUserSelectedAddress = !this.hasUserSelectedAddress;
		}
		this.autoCompleteInput.setAttribute(
			"data-has-user-selected-address",
			`${this.hasUserSelectedAddress}`
		);

		return this.hasUserSelectedAddress;
	}

	getBearerTokenEndpoint(): string {
		if (!this._element.dataset.bearerEndpoint) {
			throw new Error(
				"'data-bearer-endpoint' value not provided on #CustomAddress input field."
			);
		}

		return this._element.dataset.bearerEndpoint;
	}

	getDXEAPIEndpoint(): string {
		if (!this._element.dataset.dxeEndpoint) {
			throw new Error(
				"'data-dxe-endpoint' value not provided on #CustomAddress input field."
			);
		}

		return this._element.dataset.dxeEndpoint;
	}
}

ComponentFactory.registerComponent("addressAutocomplete", AddressAutocomplete);
