import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class MemberBenefits extends Component {
	private readonly copyBtn: HTMLAnchorElement | null = null;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.copyBtn = this._element.querySelector("a[data-copy-code]");

		if (!this.copyBtn) {
			console.warn("Could not find copy button");
			return;
		}
		this.addCopyTextEvent();
	}

	private addCopyTextEvent(): void {
		this.copyBtn?.addEventListener("click", e => {
			e.preventDefault();
			this.copyText();
		});
	}

	private copyText(): undefined {
		const code = this._element.dataset.promoCode;
		if (!code) {
			console.warn("Could not find code to copy");
			return;
		}

		navigator.clipboard
			.writeText(code)
			.then(() => this.toggleText())
			.catch(err => console.error("Could not copy text to clipboard", err));
	}

	private toggleText(): String | undefined {
		const textElem = this.copyBtn?.querySelector<HTMLElement>("span");
		const defaultTxt = textElem?.innerHTML ?? "";
		const copiedText = this._element.dataset.copyText || "Copied!";
		if (!textElem) {
			console.warn("Could not find text element");
			return;
		}

		setTimeout(() => {
			textElem.innerHTML = defaultTxt;
		}, 5000);

		textElem.innerHTML = copiedText;
	}
}

ComponentFactory.registerComponent("MemberBenefits", MemberBenefits);
