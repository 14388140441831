interface ITruncationData {
	clamp: number;
	element: HTMLElement;
	lastCalculationAtHeight: number;
	lastCalculationAtWidth: number;
	lastKnownScrollHeight: number;
	lastKnownScrollWidth: number;
	lineHeight: number;
	originalText: string;
	segments: readonly string[];
}

class LineClamp {
	private readonly _truncationElements: readonly ITruncationData[];
	private _ticking = false;

	constructor(private readonly _element: HTMLElement) {
		window.addEventListener("resize", () => this.handleResize());
		this._truncationElements = this.readTruncationElements();
		this.truncate();
	}

	static applyTo(element: HTMLElement | null): LineClamp | null {
		return element ? new LineClamp(element) : null;
	}

	readTruncationElements(): readonly ITruncationData[] {
		return Array.from(
			this._element.querySelectorAll<HTMLElement>("[data-line-clamp]")
		)
			.map(element => {
				const clamp = parseInt(element.dataset.lineClamp || "", 10);
				if (isNaN(clamp) || clamp < 1) {
					console.warn("Invalid line clamp count.", element);

					return null;
				}

				const lineHeight = parseInt(
					window.getComputedStyle(element).lineHeight || "",
					10
				);

				if (isNaN(lineHeight) || lineHeight < 1) {
					console.warn("Could not measure line height.", element);

					return null;
				}

				// Break the original text into bits that we can add back in.
				// We are currently breaking on characters and not words.
				const segments = (element.textContent || "")
					.split(" ")
					.map(word => word.trim())
					.filter(Boolean)
					.map(word => word.split(""))
					.reduce((prev, next) => {
						const characters = prev.concat(next);
						characters[characters.length - 1] += " ";

						return characters;
					}, []);

				const originalText = segments.join("");

				const data: ITruncationData = {
					clamp,
					element,
					lastCalculationAtHeight: -1,
					lastCalculationAtWidth: -1,
					lastKnownScrollHeight: element.scrollHeight,
					lastKnownScrollWidth: element.scrollWidth,
					lineHeight,
					originalText,
					segments
				};

				return data;
			})
			.filter(Boolean) as ITruncationData[];
	}

	handleResize(): void {
		this._truncationElements.forEach(t => {
			t.lastKnownScrollHeight = t.element.scrollHeight;
			t.lastKnownScrollWidth = t.element.scrollWidth;
		});

		this.requestTick();
	}

	requestTick(): void {
		if (!this._ticking) {
			this._ticking = true;
			requestAnimationFrame(() => {
				this._ticking = false;
				this.truncate();
			});
		}
	}

	truncate(): void {
		this._truncationElements.forEach(t => {
			const dimensionsHaveChanged =
				t.lastCalculationAtHeight !== t.lastKnownScrollHeight ||
				t.lastCalculationAtWidth !== t.lastKnownScrollWidth;

			if (!dimensionsHaveChanged) {
				return;
			}

			t.lastCalculationAtHeight = t.lastKnownScrollHeight;
			t.lastCalculationAtWidth = t.lastKnownScrollWidth;

			// Modified binary search until we find the exact point at which the line
			// count exceeds the clamp.  Usual binary search algorithm modified so we
			// can make two measurements instead of one, since we are looking for a
			// boundary and not a specific segment.
			const last = t.segments.length - 1;
			let high = last;
			let low = 0;
			while (high > low) {
				// Justification: Finding the average between two items.
				// tslint:disable-next-line:no-magic-numbers
				const mid = Math.floor((high - low) / 2) + low;
				const next = mid + 1;

				// Check to see if the next segment will break the clamp.
				t.element.textContent =
					next === last
						? t.originalText
						: t.segments.slice(0, next).join("") + "…";

				const nextLineCount = Math.floor(t.element.scrollHeight / t.lineHeight);
				if (nextLineCount <= t.clamp) {
					low = next;
					continue; // Clamp intact, the boundary must be above us.
				}

				// We know the clamp is broken on the next segment. Is it broken
				// on this segment?  If not, we've found the boundary.
				t.element.textContent = t.segments.slice(0, mid).join("") + "…";

				const currentLineCount = Math.floor(
					t.element.scrollHeight / t.lineHeight
				);

				if (currentLineCount > t.clamp) {
					high = mid;
					continue; // Clamp broken, the boundary must be below us.
				}

				break; // We found the boundary.
			}

			if (t.element.textContent === t.originalText) {
				t.element.removeAttribute("title");
			} else {
				t.element.setAttribute("title", t.originalText);
			}
		});
	}
}

if (document.querySelector(".story-landing")) {
	LineClamp.applyTo(document.querySelector<HTMLElement>(".story-landing"));
}
