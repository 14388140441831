import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

function extractTemplate(context: HTMLElement, selector: string): string {
	const template = context.querySelector<HTMLTemplateElement>(selector);
	const html = template?.innerHTML ?? "";
	template?.parentElement?.removeChild(template);

	return html;
}

export class SimpleCarousel extends Component {
	public readonly carouselEl: JQuery;
	public carousel: JQuery | null = null;
	private prevSlide: number | undefined;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.carouselEl = $(_element);

		this.initCarousel();

		const slick = this.carouselEl.slick("getSlick");
		this.prevSlide = slick.defaults.initialSlide;
	}

	initCarousel(): void {
		const carouselOptions: JQuerySlickOptions = {
			accessibility: false,
			cssEase: "linear",
			dots: false,
			fade: false,
			infinite: true,
			mobileFirst: true,
			nextArrow: extractTemplate(this._element, "template[data-next-arrow]"),
			prevArrow: extractTemplate(this._element, "template[data-prev-arrow]"),
			slidesToShow: 1,
			speed: 500,
			useTransform: false
		};

		if ($(".panel-btn").length > 0) {
			$(".panel-btn").on("click", () => {
				$(window).trigger("resize");
				this.carouselEl.slick("slickGoTo", 0);
				this.carouselEl.slick("setPosition");
				DataLayerService.pushDataLayerEvent_Carousel(
					this.carouselEl,
					"next_carousel_slide",
					0,
					this.prevSlide,
					"SimpleCarousel"
				);
				this.prevSlide = 0;
			});
		}

		this.carousel = this.carouselEl.slick(carouselOptions);

		$(this.carouselEl)
			.find("[data-next-arrow]")
			.on("click", () => {
				const slick = this.carouselEl.slick("getSlick");
				this.carouselEl.slick("slickNext");
				DataLayerService.pushDataLayerEvent_Carousel(
					this.carouselEl,
					"next_carousel_slide",
					slick.currentSlide,
					this.prevSlide,
					"SimpleCarousel"
				);
				this.prevSlide = slick.currentSlide;
			});

		$(this.carouselEl)
			.find("[data-prev-arrow]")
			.on("click", () => {
				const slick = this.carouselEl.slick("getSlick");
				this.carouselEl.slick("slickPrev");
				DataLayerService.pushDataLayerEvent_Carousel(
					this.carouselEl,
					"previous_carousel_slide",
					slick.currentSlide,
					this.prevSlide,
					"SimpleCarousel"
				);
				this.prevSlide = slick.currentSlide;
			});
	}
}

ComponentFactory.registerComponent("SimpleCarousel", SimpleCarousel);
