import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { API } from "../../util/API";
import { DataLayerService } from "../services/DataLayerService";

export class CommunityCareForm extends Component {
	dxeBearerToken: string | undefined;
	form: HTMLFormElement;
	focused: boolean;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.focused = false;

		if (this._element.tagName !== "FORM") {
			throw new Error(`Non-form element passed in, element: ${this._element}`);
		}

		this.form = this._element as HTMLFormElement;
		// tslint:disable-next-line
		this.initialize();
	}

	private async initialize(): Promise<void> {
		this.applyValidationRules(this.form);
		this.form.addEventListener("submit", e => this.handleSubmission(e));

		// CFAC-34725 Fire whenever a user starts filling out a form.
		// This event is generally fired after user input in the first form field
		this._element
			.querySelector("#fullname")
			?.addEventListener("focus", e => this.handleUserInput(e));

		// CFAC-34725 Fire whenever a user is presented with a form on a page.
		DataLayerService.pushDataLayerEvent_Forms("form_view", {
			identifier: this.layerIdentifier,
			name: this.layerName,
			type: "CommunityCareForm"
		});
	}

	// CFAC-34725 Fire whenever a user starts filling out a form.
	// This event is generally fired after user input in the first form field
	private async handleUserInput(e: Event): Promise<void> {
		if (!this.focused) {
			DataLayerService.pushDataLayerEvent_Forms("form_start", {
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "CommunityCareForm"
			});

			this.focused = true;
		}
	}

	private get layerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	private get layerName(): string {
		return this._element.dataset.layerName || "";
	}
	private applyValidationRules(form: HTMLFormElement): void {
		const messages = this._element.dataset;
		const errorClass = "hasError";

		$(form).validate({
			errorClass: "hasError",
			errorPlacement: (error, element) => {
				error.appendTo(element.next(".error-container"));
			},
			highlight: element => {
				$(element).parent().addClass(errorClass);
			},
			messages: {
				address: {
					required: messages.addressMsg ?? ""
				},
				email: messages.customEmailMsg ?? "",
				formmessage: {
					required: messages.customFormMsg ?? ""
				},
				fullname: {
					required: messages.customNameMsg ?? ""
				},
				organizationname: {
					required: messages.customOrgnameMsg ?? ""
				}
			},
			rules: {
				address: {
					required: true
				},
				email: {
					email: true,
					required: true
				},
				formmessage: {
					required: true
				},
				fullname: {
					required: true
				},
				organizationname: {
					required: true
				}
			},
			unhighlight: element => {
				$(element).parent().removeClass(errorClass);
			}
		});
	}

	private async handleSubmission(e: Event): Promise<void> {
		e.preventDefault();
		this.toggleSubmitBtn(false);
		const formHasErrors = !this.validate();
		if (formHasErrors) {
			this.toggleSubmitBtn(true);

			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "CommunityCareFormSubmission",
				error_message: "Form Has Errors",
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "CommunityCareForm"
			});

			return;
		}

		await this.submitForm();
	}

	private async submitForm(addressId?: string): Promise<void> {
		const {
			submissionEndpoint,
			redirectSuccess,
			redirectError,
			formDatasourceId,
			emailToSubmit = ""
		} = this._element.dataset;

		if (!submissionEndpoint) {
			throw new Error(
				`Submission endpoint value not found for ${submissionEndpoint}`
			);
		}

		const recaptchaVal = this.form["g-recaptcha-response"]?.value;
		if (!recaptchaVal) {
			console.warn(`No recaptcha value found in payload`);

			this.toggleFieldErr("recaptchaField", true);
			this.toggleSubmitBtn(true);

			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "CommunityCareFormSubmission",
				error_message: "No recaptcha value found in payload.",
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "CommunityCareForm"
			});

			return;
		}

		const payload = $(this.form)
			.serializeArray()
			.reduce(
				(ac, cv) => {
					if (cv.name === "g-recaptcha-response") {
						return { ...ac, ["grecaptcharesponse"]: cv.value };
					}

					return { ...ac, [cv.name]: cv.value };
				},
				{ emailToSubmit, formDatasourceId }
			);
		console.log({ payload });

		const [result, err] = await API.postReq(submissionEndpoint, payload);

		console.log({ result });
		console.log({ err });

		if (err) {
			console.warn("Error in submitting form.");

			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "CommunityCareFormSubmission",
				error_message: "Error in submitting form.",
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "CommunityCareForm"
			});

			window.location.href = redirectError;
			return;
		}

		if (result && result.success) {
			// CFAC-34725 Fire whenever a user successfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_complete", {
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "CommunityCareForm"
			});

			window.location.href = redirectSuccess;
		} else if (result && result.error) {
			// Account for Form Validation Failed response from BE:
			Object.keys(result.errors).forEach(key => {
				console.warn(`${key}, ${result.errors[key]}`);
				this.toggleFieldErr(key, true);
			});
		} else {
			// Account for ERROR response from BE:
			console.warn("Error while submitting the form");

			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "CommunityCareFormSubmission",
				error_message: "Error in submitting form - BE.",
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "CommunityCareForm"
			});

			window.location.href = redirectError;
		}
	}

	private toggleFieldErr(fieldName: string, toggleOn: boolean): void {
		const field = this._element.querySelector(`[data-element='${fieldName}']`);

		if (!field) {
			console.warn(`No field ${fieldName} found. Check DOM.`);

			return;
		}

		toggleOn
			? field.classList.add("display")
			: field.classList.remove("display");
	}

	private validate(): boolean {
		if (this._element) {
			return $(this._element).valid();
		}

		return false;
	}

	private toggleSubmitBtn(toggleOn: boolean): void {
		const submitBtn = this._element.querySelector("button[type='submit']");
		const disabledClass = "disabled";

		if (!submitBtn) {
			throw new Error(`Submit button not found for ${submitBtn}`);
		}

		toggleOn
			? submitBtn.classList.remove(disabledClass)
			: submitBtn.classList.add(disabledClass);
	}
}

ComponentFactory.registerComponent("communityCareForm", CommunityCareForm);
