import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

// CFAC-34764 login
export class LogoutBtn extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		_element.addEventListener("click", e => this.logoutBtnClick(e));
	}

	async logoutBtnClick(e: Event): Promise<void> {
		DataLayerService.pushDataLayerEvent_Logout(this._element as HTMLElement);
	}
}

ComponentFactory.registerComponent("logoutBtn", LogoutBtn);
