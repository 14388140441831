import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class LocationServiceHours extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.hideAllDrawers();
		this.applyClickEvts();
	}

	applyClickEvts(): void {
		const toggleBtns = this._element.querySelectorAll(".toggle");
		const activeClass = "active";
		if (toggleBtns) {
			toggleBtns.forEach(btn => {
				btn.addEventListener("click", e => {
					e.preventDefault();
					const isActive = btn.classList.contains(activeClass);
					const hoursDiv = $(btn).siblings(".hours")[0];

					if (!isActive) {
						toggleBtns.forEach(b => b.classList.remove(activeClass));
						btn.classList.add(activeClass);

						if (!hoursDiv) {
							console.warn(
								`Corresponding hours div not found for button element: ${btn}`
							);
						}
						this.hideAllDrawers();
						$(hoursDiv).slideDown();
						$(hoursDiv).attr("aria-expanded", "true");
					} else {
						$(hoursDiv).slideUp();
						btn.classList.remove(activeClass);
						$(hoursDiv).attr("aria-expanded", "false");
					}
				});
			});
		}
	}

	hideAllDrawers(): void {
		$(this._element).find(".hours").hide();
	}
}

ComponentFactory.registerComponent(
	"LocationServiceHours",
	LocationServiceHours
);
