import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class YextSearch extends Component {
	private readonly yextScriptURLToLoad: string;
	private script: HTMLScriptElement;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		if (!this.getAPIKey() || !this.getBusinessID()) {
			throw new Error(
				"API Key and/or BusinessID not provided to Yext Search Component."
			);
		}
		this.yextScriptURLToLoad =
			"https://assets.sitescdn.net/answers-search-bar/v1.0/answers.min.js";
		this.script = this.loadScript();

		this.script.onload = () => {
			this.initYextSearch();
		};
	}

	loadScript(): HTMLScriptElement {
		this.script = document.createElement("script");
		this.script.setAttribute("src", this.yextScriptURLToLoad);
		// append to document
		document.head.appendChild(this.script);

		return this.script;
	}

	initYextSearch(): void {
		ANSWERS.init({
			apiKey: this.getAPIKey(),
			businessId: this.getBusinessID(),
			experienceKey: this.getExperienceKey(),
			experienceVersion: this.getExperienceVersion(),
			locale: this.getLocale(), // e.g. en
			onReady: () => {
				ANSWERS.addComponent("SearchBar", {
					// Container, name must be unique for every search bar on the same page
					container: `#${this.getYextUniqueNameID()}`,
					name: this.getYextUniqueNameID(),
					placeholderText: this.getPlaceholderText(),
					redirectUrl: this.getRedirectURL(),
					useForm: false // REQUIRED FOR ASP.NET SITES
				});
			},
			templateBundle: TemplateBundle.default
		});
	}

	getAPIKey(): string | undefined {
		return this._element.dataset.apiKey;
	}

	getBusinessID(): string | undefined {
		return this._element.dataset.businessId;
	}

	getExperienceKey(): string {
		return this._element.dataset.experienceKey ?? "cfa-answers";
	}

	getExperienceVersion(): string {
		return this._element.dataset.experienceVersion ?? "PRODUCTION";
	}

	getLocale(): string {
		return this._element.dataset.locale ?? "en";
	}

	getPlaceholderText(): string {
		return this._element.dataset.placeholderText ?? "";
	}

	getRedirectURL(): string {
		return (
			this._element.dataset.redirectUrl ?? "http://search.chick-fil-a.com/"
		);
	}

	getYextUniqueNameID(): string {
		return this._element.id ?? "";
	}
}

ComponentFactory.registerComponent("yextSearch", YextSearch);
