import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { animationDebounce } from "../../../util/AnimationDebounce";
import { AnimateStrategy } from "./AnimateStrategy";
import { ExpandStrategy } from "./ExpandStrategy";
import { IAccordionStrategy } from "./IAccordionStrategy";
import { InstantStrategy } from "./InstantStrategy";
import { DataLayerService } from "../../services/DataLayerService";

export class Accordion extends Component {
	private _strategy: IAccordionStrategy;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.toggles.forEach(el => {
			el.addEventListener("click", e => this.handleToggle(e));
		});

		if (
			_element.dataset.desktopExpanded === "true" &&
			window.innerWidth > _app.layout.desktopWidth
		) {
			this._strategy = new ExpandStrategy(this);
		} else {
			this._strategy =
				_element.dataset.animate === "true"
					? new AnimateStrategy(this)
					: new InstantStrategy(this);
		}

		this.addResizeEvent();
		window.addEventListener("hashchange", e => this.checkHash());
	}

	public async init(): Promise<void> {
		return this.checkHash();
	}

	public get strategy(): IAccordionStrategy {
		return this._strategy;
	}

	private addResizeEvent(): void {
		window.addEventListener(
			"resize",
			animationDebounce(
				() => window.innerWidth,
				width => this.transitionOnResize(width)
			)
		);
	}

	private transitionOnResize(width: number): void {
		switch (this._element.dataset.desktopExpanded === "true") {
			case true:
				if (
					width < this._app.layout.desktopWidth &&
					this._strategy instanceof ExpandStrategy
				) {
					this._strategy =
						this._element.dataset.animate === "true"
							? new AnimateStrategy(this)
							: new InstantStrategy(this);
				} else if (
					width >= this._app.layout.desktopWidth &&
					!(this._strategy instanceof ExpandStrategy)
				) {
					this._strategy = new ExpandStrategy(this);
				} else {
					return;
				}
				break;

			case false:
				break;
		}
	}

	public get toggles(): NodeListOf<HTMLElement> {
		return this._element.querySelectorAll<HTMLElement>("[data-action='toggle'");
	}

	public get sections(): NodeListOf<HTMLElement> {
		const query = "[data-content='section']";

		return this._element.querySelectorAll<HTMLElement>(query);
	}

	private async checkHash(): Promise<void> {
		const locationHash = window.location.hash;
		if (!locationHash) {
			return;
		}

		const query = "#" + locationHash.substring(1);
		const targetSection = this._element.querySelector(query);

		if (!targetSection) {
			return;
		}

		const targetToggle = targetSection.previousElementSibling;
		if (!targetToggle) {
			return;
		}

		return this.toggle(targetToggle);
	}

	private async handleToggle(e: MouseEvent): Promise<void> {
		const target = e.target as Element;
		if (!target) {
			return;
		}

		const toggle = target.closest("[data-action='toggle']");
		if (toggle) {
			return this.toggle(toggle);
		}

		console.warn("Could not locate toggle.");
	}

	private async hide(toggle: Element): Promise<void> {
		toggle.setAttribute("data-layer-event", "collapse_accordion");
		DataLayerService.pushDataLayerEvent_Accordion(toggle);

		if (this._strategy.hide) {
			return this._strategy.hide(toggle);
		}
	}

	private async show(toggle: Element): Promise<void> {
		toggle.setAttribute("data-layer-event", "expand_accordion");
		DataLayerService.pushDataLayerEvent_Accordion(toggle);
		if (this._strategy.show) {
			await this._strategy.show(toggle);

			return this.scrollToToggle(toggle);
		}
	}

	private async scrollToToggle(toggle: Element): Promise<void> {
		const offset = $(toggle).offset();
		if (offset) {
			const topOfToggle = offset.top - this._app.layout.navBaseHeight;

			return new Promise(resolve => {
				window.setTimeout(() => {
					return $("html, body")
						.animate(
							{
								scrollTop: topOfToggle
							},
							0
						)
						.promise()
						.then(unused => resolve());
				});
			});
		}
	}

	private async toggle(toggle: Element): Promise<void> {
		if (toggle.classList.contains("active")) {
			return this.hide(toggle);
		}

		return this.show(toggle);
	}
}

ComponentFactory.registerComponent("Accordion", Accordion);
