// From CFA\src\Assets\src\sass\cfacom\_settings.scss
// TODO: Provide a single point of truth that is shared between SCSS and TS.

// This file is all the way out here because we want to be able to write
// unit tests for specific breakpoints, which means we need to pass information
// about breakpoints into our Karma configuration.  We also import this file
// into the TypeScript layer that runs client-side.

export const Breakpoints = {
	"lrg-desktop": 1600,
	desktop: 1439,
	"sml-desktop": 1279,
	tablet: 1023,
	"lrg-tablet": 960,
	mobile: 959,
	"lrg-mobile": 750,
	"sml-mobile": 650
};
