import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

export class LocationMenuCard extends Component {
	private readonly modalBtn: HTMLElement | null;
	private readonly cardLink: HTMLElement | null;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.modalBtn = _element.querySelector("[data-element='modalCta']");

		if (this.modalBtn) {
			this.modalBtn.addEventListener("click", e => {
				e.preventDefault();
				this.handleOrderNowEvt();
			});
		}

		this.cardLink = _element.querySelector("[data-element='cardLink']");

		if (this.cardLink) {
			this.cardLink.addEventListener("click", e =>
				this.onCardLinkClick(e, this.cardLink)
			);
		}

		this.applyDataLayerContentEvent();
	}

	// CFAC-34748 Fire whenever a user clicks on a product link
	async onCardLinkClick(e: Event, link: HTMLElement | null): Promise<void> {
		if (!link) {
			return;
		}
		DataLayerService.pushDataLayerEvent_Lists_SelectItem(link);
		DataLayerService.pushDataLayerEvent("Order_CTA_Click", {
			module_name: this.modalBtn?.dataset.itemTag,
			link_category: "Ordering",
			link_text: this.modalBtn?.innerHTML || "Order Now"
		});
	}

	private handleOrderNowEvt(): void {
		const modalId = "#location-order-now-modal";
		$.magnificPopup.close();
		$.magnificPopup.open({
			callbacks: {
				open: () => {
					setOrderLinks(this.modalBtn);
				}
			},
			items: {
				src: modalId
			},
			type: "inline"
		});
	}

	applyDataLayerContentEvent(): void {
		const selector = ".content-click";
		const elements = this._element.querySelectorAll(selector);

		if (!elements) {
			console.warn("No elements found for selector", selector);
			return;
		}

		elements.forEach(element => {
			element.addEventListener("click", e =>
				DataLayerService.pushDataLayerEvent_Menu_ContentClick(element)
			);
		});
	}
}

ComponentFactory.registerComponent("locationMenuCard", LocationMenuCard);

function setOrderLinks(modalBtn: HTMLElement | null): void {
	const locationNumber = modalBtn?.dataset.locationNumber;
	const itemTag = modalBtn?.dataset.itemTag;
	const modal = document.getElementById("location-order-now-modal");

	if (!modal) {
		throw new Error("No Modal Element found.");
	}

	const pickupBtn = modal.querySelector("[data-element='pickupBtn']");
	const deliveryBtn = modal.querySelector("[data-element='deliveryBtn']");
	const redirectPickupUrl = pickupBtn?.getAttribute("data-redirect-url") || "";
	const redirectDeliveryUrl =
		deliveryBtn?.getAttribute("data-redirect-url") || "";
	let redirectUserToPickup = `${redirectPickupUrl}?locationNumber=${locationNumber}&itemTag=${itemTag}`;

	if (locationNumber === "" || locationNumber === undefined) {
		redirectUserToPickup = `${redirectPickupUrl}?itemTag=${itemTag}`;
	}

	pickupBtn?.setAttribute("href", redirectUserToPickup);
	deliveryBtn?.setAttribute("href", redirectDeliveryUrl);

	if (pickupBtn) {
		pickupBtn.addEventListener("click", e => {
			DataLayerService.pushDataLayerEvent_Conversion(
				pickupBtn?.getAttribute("data-layer-event") ||
					"conversion_pickupBtn_click"
			);

			DataLayerService.pushDataLayerEvent("Order_option_cta_click", {
				method: "Menu Modal",
				link_category: "Ordering",
				link_text: pickupBtn?.innerHTML || "Order Pickup",
				link_url: redirectUserToPickup
			});
		});
	}

	if (deliveryBtn) {
		deliveryBtn.addEventListener("click", e => {
			DataLayerService.pushDataLayerEvent_Conversion(
				deliveryBtn?.getAttribute("data-layer-event") ||
					"conversion_deliveryBtn_click"
			);

			DataLayerService.pushDataLayerEvent("Order_option_cta_click", {
				method: "Menu Modal",
				link_category: "Ordering",
				link_text: deliveryBtn?.innerHTML || "Order Delivery",
				link_url: redirectDeliveryUrl
			});
		});
	}
}
