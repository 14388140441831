export const Cookie = {
	// default cookie expiration val set to 1 year
	setBooleanCookie(
		cookieId: string,
		cookieName: string,
		expireStamp?: number
	): void {
		if (expireStamp) {
			const now: Date = new Date();
			const time: number = now.getTime();
			const expireTime = time + expireStamp;
			now.setTime(expireTime);
			document.cookie =
				encodeURIComponent(cookieId + cookieName) +
				"=1; expires=" +
				now.toUTCString() +
				";path=/;";
		}

		document.cookie = encodeURIComponent(cookieId + cookieName) + ";path=/;";
	},

	setCookie(name: string, value: string): void {
		if (!name) {
			throw new Error("Invalid cookie name.");
		}

		const encodedName = encodeURIComponent(name);
		const encodedValue = encodeURIComponent(value);

		document.cookie = `${encodedName}=${encodedValue};path=/;`;
	},

	getCookie(key: string): string | null {
		const matcher = "(^|;) ?" + encodeURIComponent(key) + "=([^;]*)(;|$)";
		const keyValue = document.cookie.match(matcher);
		const val = 2;

		return keyValue ? decodeURIComponent(keyValue[val]) : null;
	}
};
