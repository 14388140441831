import { CfaContext } from "../../app/CfaContext";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { GiftingLightbox } from "./GiftingLightbox";
import { ReceiveGiftRewardAddedModal } from "./ReceiveGiftRewardAddedModal";

export class ReceiveGiftGifterIsGifteeModal extends GiftingLightbox {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this._element
			.querySelectorAll<HTMLElement>("[data-action='claimGift']")
			.forEach(el =>
				el.addEventListener("click", async e => {
					e.preventDefault();
					await ReceiveGiftRewardAddedModal.show();
				})
			);
	}

	public static async show(): Promise<void> {
		const factory = CfaContext.services.componentFactory;
		const modal = factory?.getComponent<ReceiveGiftGifterIsGifteeModal>(
			ReceiveGiftGifterIsGifteeModal
		);

		if (modal) {
			await modal.show();
		} else {
			console.warn("Receive Gift (Gifter Is Giftee) Modal not on page.");
		}
	}
}

ComponentFactory.registerComponent(
	"ReceiveGiftGifterIsGifteeModal",
	ReceiveGiftGifterIsGifteeModal
);
