import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

// CFAC-34732 click-data layer attribute
export class ClickData extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		_element.addEventListener("click", e => this.onContentClick(e));
	}

	async onContentClick(e: Event): Promise<void> {
		DataLayerService.pushDataLayerEvent_ClickData(this._element);
	}
}

ComponentFactory.registerComponent("clickData", ClickData);
