import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class ListViewCards extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.handleModifiers();
	}

	handleModifiers(): void {
		const classes = this._element.classList;

		// Apply See More to module if mobile view
		if (classes.contains("withSeeMore") && this.isMobileView) {
			this.applySeeMoreEvt();
		}
	}

	applySeeMoreEvt(): void {
		const btn = this._element.querySelector(".see-more-btn") as HTMLElement;
		if (!btn) {
			console.warn("No see more button found");
			return;
		}

		btn.addEventListener("click", () => {
			this._element.classList.toggle("open");
		});
	}

	get isMobileView(): boolean {
		return window.innerWidth < this._app.layout.desktopWidth;
	}
}

ComponentFactory.registerComponent("ListViewCards", ListViewCards);
