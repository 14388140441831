// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
if (!Element.prototype.matches) {
	// Justification: Need to access browser-specific properties.
	// tslint:disable-next-line no-any
	const source = Element.prototype as any;

	// Justification: Need to access browser-specific properties.
	// tslint:disable-next-line no-unsafe-any
	const fn = source.msMatchesSelector || source.webkitMatchesSelector;

	Element.prototype.matches = fn as (selector: string) => boolean;
}
