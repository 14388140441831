import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { hasLandingTab } from "../Menu/QueryLandingTab";

interface IMenuCategory {
	href: string;
	imgSrc: string;
	innerText: string;
}

export class MenuNavMobile extends Component {
	remainingCategoryHeaders: (Element | null)[];
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.remainingCategoryHeaders = this.menuCategoryHeaders;

		if (hasLandingTab()) {
			return;
		}
		this.collapseMenuSections();
		this.handleMobileMenuNav();
	}

	private collapseMenuSections(): void {
		if (window.innerWidth < this._app.layout.desktopWidth) {
			$(".menu-group").children().hide();
			$(".title-container").show();
		}
	}

	private handleMobileMenuNav(): void {
		const evt = "subNavMenuCategoriesToDisplay";
		document.addEventListener(evt, e => {
			if (window.innerWidth < this._app.layout.desktopWidth) {
				this.toggleMenuSectionDisplay(e);
			}
		});
	}

	private toggleMenuSectionDisplay(e: CustomEvent): void {
		// tslint:disable-next-line: no-unsafe-any
		const { menuCategoriesToDisplay } = e.detail;
		if (menuCategoriesToDisplay) {
			// tslint:disable-next-line: no-unsafe-any
			menuCategoriesToDisplay.forEach((category: IMenuCategory) => {
				const { href } = category;
				if (href.startsWith("/")) {
					this.addLinkToNav(category);
				} else if (href.startsWith("#")) {
					// this.displayCorrespondingMenuSection(href);
				} else {
					console.error("'/' or '#' missing from link");
				}
			});
			this.displayMenuGroup();
		}
	}

	displayMenuGroup(): void {
		const menuGroupDivs = this._element.querySelectorAll(".menu-group");

		this.menuCategoryHeaders.forEach(menuGroupDiv => {
			$(menuGroupDiv).fadeIn(2000);
			menuGroupDiv.classList.remove("hidden");
		});
	}

	private addLinkToNav(category: IMenuCategory): void {
		const divToAdd = this.buildMenuLinkDiv(category);
		this._element.appendChild(divToAdd);
	}

	private buildMenuLinkDiv(category: IMenuCategory): HTMLElement {
		const div = document.createElement("div");
		const { href, imgSrc, innerText: categoryText } = category;
		div.classList.add("menu-group", "no-accordion");

		if (imgSrc) {
			div.innerHTML = `
      <div class="title-container">
			  <img class="menu-icon" src="${imgSrc}" alt="Menu Item" />
				<h2 id=${categoryText}><a href="${href}">
					${categoryText}</a>
				</h2>
      </div>
        `;
		} else {
			div.innerHTML = `
        <div class="title-container">
          <h2 id=${categoryText}><a href="${href}">
            ${categoryText}</a>
          </h2>
        </div>`;
		}

		return div;
	}

	private displayCorrespondingMenuSection(href: string): void {
		const hrefParsed = this.parseHref(href);
		const hiddenClass = "hidden";
		this.remainingCategoryHeaders.forEach((header, i) => {
			const h2Elem = header?.querySelector("h2");
			const headerId = h2Elem?.getAttribute("id");

			if (headerId && header) {
				if (headerId === hrefParsed) {
					$(header).fadeIn(2000);
					this.remainingCategoryHeaders.splice(i, 1);
				}
			}
		});
	}

	private parseHref(href: string): string {
		return href.substring(1).replace(/-/g, "");
	}

	get menuCategoryHeaders(): (Element | null)[] {
		return Array.from(this._element.querySelectorAll(".menu-group"));
	}
}

ComponentFactory.registerComponent("menuNavMobile", MenuNavMobile);
