import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class AllergensFilter extends Component {
	public selectedFilters: string[];
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.selectedFilters = [];
		this.handleFilterSelection();
		this.handleClearSelection();
	}

	handleFilterSelection(): void {
		this.filterLabels.forEach(filterLabel =>
			filterLabel.addEventListener("click", () => {
				$(filterLabel).toggleClass("mf-tag--active");
				this.updateFilterState(filterLabel);
				this.toggleClearFilterLabel();
				this.filterMenuItems();
			})
		);
	}

	updateFilterState(filterLabel: HTMLElement): void {
		const filterValSelected: string = filterLabel.dataset.allergenName || "";

		if ($(filterLabel).hasClass("mf-tag--active")) {
			if (!this.selectedFilters.includes(filterValSelected)) {
				this.selectedFilters.push(filterValSelected);
			}
		} else {
			const i = this.selectedFilters.indexOf(filterValSelected);
			this.selectedFilters.splice(i, 1);
		}
	}

	toggleClearFilterLabel(): void {
		if (this.selectedFilters.length > 0) {
			$(".mf-clear").removeClass("mf-clear--hidden");
		} else {
			$(".mf-clear").addClass("mf-clear--hidden");
		}
	}

	handleClearSelection(): void {
		const clearFilterBtn = document.querySelector(".mf-clear");
		if (clearFilterBtn) {
			clearFilterBtn.addEventListener("click", e => {
				e.preventDefault();
				this.filterLabels.forEach(label => {
					label.classList.remove("mf-tag--active");
				});
				$(".mf-clear").addClass("mf-clear--hidden");
				this.selectedFilters = [];
				this.filterMenuItems();
			});
		}
	}

	filterMenuItems(): void {
		const menuCards: NodeListOf<HTMLElement> =
			document.querySelectorAll(".menu-card");
		if (this.selectedFilters.length > 0) {
			menuCards.forEach(menuItem => {
				menuItem.style.display = "block";
				const menuItemAllergensString = menuItem.dataset.allergens || "";
				const menuItemAllergens: string[] = this.parseMenuItemAllergens(
					menuItemAllergensString
				);

				if (menuItemAllergens.length > 0) {
					menuItemAllergens.forEach(menuItemAllergen => {
						if (this.selectedFilters.includes(menuItemAllergen)) {
							menuItem.style.display = "none";
						}
					});
				}
			});
		} else {
			menuCards.forEach(menuItem => {
				menuItem.style.display = "block";
			});
		}
	}

	parseMenuItemAllergens(allergenString: string): string[] {
		return allergenString.split("|");
	}

	get filterLabels(): NodeListOf<HTMLElement> {
		return this._element.querySelectorAll(".mf-tag");
	}
}

ComponentFactory.registerComponent("allergensFilter", AllergensFilter);
