import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

export class PdpPwoCta extends Component {
	pdpCtaList: HTMLElement | null;
	private readonly orderPickupLink: HTMLElement | null;
	private readonly orderDeliveryMealLink: HTMLElement | null;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.pdpCtaList = this._element.querySelector(
			"[data-element='btn-container']"
		);

		if (this.pdpCtaList) {
			this.handleCtaUiUpdate();
		}

		this.orderPickupLink = _element.querySelector("a[data-id='orderpickup']");

		if (this.orderPickupLink) {
			this.orderPickupLink.addEventListener("click", e =>
				DataLayerService.pushDataLayerEvent_Conversion(
					this.orderPickupLink?.getAttribute("data-layer-event") ||
						"conversion_orderPickupLink_click"
				)
			);
		}

		this.orderDeliveryMealLink = _element.querySelector(
			"a[data-id='orderDeliveryMeal']"
		);

		if (this.orderDeliveryMealLink) {
			this.orderDeliveryMealLink.addEventListener("click", e =>
				DataLayerService.pushDataLayerEvent_Conversion(
					this.orderDeliveryMealLink?.getAttribute("data-layer-event") ||
						"conversion_orderDeliveryMealLink_click"
				)
			);
		}
	}

	handleCtaUiUpdate(): void {
		switch (this.pdpCtaList?.childElementCount) {
			case 1:
				this._element.classList.add("wideView");
				this.pdpCtaList.classList.add("wideView");
				this.pdpCtaList.setAttribute("data-child-count", "1");
				break;
			default:
				return;
		}
	}
}

ComponentFactory.registerComponent("pdpPwoCta", PdpPwoCta);
