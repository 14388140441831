import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class NavCalloutCard extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		const navClickClass = "restaurant-tab-nav";
		const hasNavClickBtn = this.btn?.classList.contains(navClickClass);
		if (hasNavClickBtn) {
			this.handleClickEvt();
		}
	}

	private handleClickEvt(): void {
		this.btn?.addEventListener("click", e => {
			e.preventDefault();
			const tabToToggle = this._element.dataset.type;
			const event = new CustomEvent("navItemClicked", { detail: tabToToggle });
			document.dispatchEvent(event);
		});
	}

	get btn(): HTMLButtonElement | null {
		return this._element.querySelector(".restaurant-tab-nav");
	}
}

ComponentFactory.registerComponent("NavCalloutCard", NavCalloutCard);
