import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
export class FooterModuleCallout extends Component {
	isDesktopView: boolean;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.applyBackgroundImg();
		this.isDesktopView = window.innerWidth > this._app.layout.desktopWidth;
	}

	applyBackgroundImg(): void {
		const imgSrcToApply = this.isDesktopView
			? this.mobileImgSrc
			: this.desktopImgSrc;

		this.setBackgroundImg(imgSrcToApply);
		// TODO: implement resizeEvt
		// this.addResizeEvent();
	}

	get mobileImgSrc(): string | null {
		const mobileImg = this._element.querySelector(
			"[data-element='mobile-img']"
		);

		return mobileImg ? mobileImg.getAttribute("src") : "";
	}

	get desktopImgSrc(): string | null {
		const desktopImg = this._element.querySelector(
			"[data-element='desktop-img']"
		);

		return desktopImg ? desktopImg.getAttribute("src") : "";
	}

	private setBackgroundImg(imgSrc: string | null): void {
		if (!imgSrc) {
			console.warn(`No Img Source found for component: ${this}`);

			return;
		}

		this._element.style.backgroundImage = imgSrc;
	}
}

ComponentFactory.registerComponent("footerModuleCallout", FooterModuleCallout);
