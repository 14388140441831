import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

export class CanadaLocationsCardGrid extends Component {
	childCount: number;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.childCount = this._element.childElementCount;
		const cartCountToApplyGridView = 5;

		if (this.childCount >= cartCountToApplyGridView) {
			this.applyGridView();
		}
	}

	applyGridView(): void {
		this._element.classList.add("gridView");
	}
}

ComponentFactory.registerComponent(
	"canadaLocationsCardGrid",
	CanadaLocationsCardGrid
);
