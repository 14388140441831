import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { animationDebounce } from "../../util/AnimationDebounce";

export enum SlidedownAnimationState {
	unknown = 0,

	// Bits:
	mobile = 1,
	desktop = 2,
	unexpanded = 4,
	expanded = 8,

	// Valid States:
	mobileUnexpanded = 5,
	mobileExpanded = 9,
	desktopUnexpanded = 6,
	desktopExpanded = 10
}

export class SlidedownAnimation extends Component {
	private _state: SlidedownAnimationState = SlidedownAnimationState.unknown;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		if (window.innerWidth >= _app.layout.desktopWidth) {
			this.state =
				this.desktopExpanded || this.childCount <= 1
					? SlidedownAnimationState.desktopExpanded
					: SlidedownAnimationState.desktopUnexpanded;
		} else {
			this.state =
				this.childCount > 1
					? SlidedownAnimationState.mobileUnexpanded
					: SlidedownAnimationState.mobileExpanded;
		}

		this.addButtonClickEvent();
		this.addResizeEvent();
	}

	public get state(): SlidedownAnimationState {
		return this._state;
	}

	public set state(value: SlidedownAnimationState) {
		if (value === this.state) {
			return;
		}

		this._element.classList.remove(
			"mobileUnexpanded",
			"mobileExpanded",
			"desktopUnexpanded",
			"desktopExpanded"
		);

		switch (value) {
			case SlidedownAnimationState.mobileUnexpanded:
				this._element.classList.add("mobileUnexpanded");
				break;

			case SlidedownAnimationState.mobileExpanded:
				this._element.classList.add("mobileExpanded");
				break;

			case SlidedownAnimationState.desktopUnexpanded:
				this._element.classList.add("desktopUnexpanded");
				break;

			case SlidedownAnimationState.desktopExpanded:
				this._element.classList.add("desktopExpanded");
				break;

			default:
				throw new Error(`Invalid State: ${value}; current: ${this.state}`);
		}

		this._state = value;
	}

	addButtonClickEvent(): void {
		this.showAllButton?.addEventListener("click", () =>
			this.transitionOnButtonClick()
		);
	}

	private addResizeEvent(): void {
		window.addEventListener(
			"resize",
			animationDebounce(
				() => window.innerWidth,
				width => this.transitionOnResize(width)
			)
		);
	}

	private transitionOnButtonClick(): void {
		if ((this.state & SlidedownAnimationState.expanded) === 0) {
			this.state =
				window.innerWidth >= this._app.layout.desktopWidth
					? SlidedownAnimationState.desktopExpanded
					: SlidedownAnimationState.mobileExpanded;
		}
	}

	private transitionOnResize(width: number): void {
		// Maintain state unless user crosses btw mobile & desktop
		switch (this.state) {
			case SlidedownAnimationState.mobileUnexpanded:
			case SlidedownAnimationState.mobileExpanded:
				if (width <= this._app.layout.desktopWidth) {
					return;
				}

				this.state = this.desktopExpanded
					? SlidedownAnimationState.desktopExpanded
					: SlidedownAnimationState.desktopUnexpanded;
				break;

			case SlidedownAnimationState.desktopUnexpanded:
			case SlidedownAnimationState.desktopExpanded:
				if (width > this._app.layout.desktopWidth) {
					return;
				}

				this.state = SlidedownAnimationState.mobileUnexpanded;
				break;
		}
	}

	get childCount(): number {
		const selector = "[data-count='show-more']";

		return $(selector, this._element).children().length;
	}

	get showAllButton(): HTMLElement | null {
		return this._element.querySelector("[data-element='showAll']") || null;
	}

	get desktopExpanded(): boolean {
		const deskExpandedVal = this._element.getAttribute("data-desktop-expanded");

		return deskExpandedVal ? true : false;
	}
}

ComponentFactory.registerComponent("slidedownAnimation", SlidedownAnimation);
