// tslint:disable-next-line: linebreak-style

import { Accordion } from "./Accordion";
import { IAccordionStrategy } from "./IAccordionStrategy";

const defaultSectionHeight = "100%";
const defaultAnimationDelay = 50;

export class AnimateStrategy implements IAccordionStrategy {
	constructor(private readonly _accordion: Accordion) {
		$(Array.from(_accordion.sections)).css("height", "0px");
	}

	async show(toggle: Element): Promise<void> {
		this._accordion.toggles.forEach(x => {
			x.classList.remove("active");
			x.setAttribute("aria-expanded", "false");
		});

		this._accordion.sections.forEach(x => x.classList.remove("scroll"));

		const thisSection = toggle.nextElementSibling;

		const otherSections = Array.from(this._accordion.sections).filter(
			s => s !== thisSection
		);

		const otherSectionsPromise = $(otherSections)
			.animate({ height: 0 }, defaultAnimationDelay)
			.promise();

		toggle.classList.add("active");
		toggle.setAttribute("aria-expanded", "true");

		toggle
			.querySelectorAll("[role='switch']")
			.forEach(s => s.setAttribute("aria-checked", "true"));

		if (thisSection) {
			thisSection.classList.add("scroll");
			thisSection.setAttribute("aria-hidden", "false");

			const thisSectionPromise = $(thisSection)
				.show()
				.animate({ height: defaultSectionHeight }, defaultAnimationDelay)
				.promise();

			await Promise.all([otherSectionsPromise, thisSectionPromise]);
		} else {
			await otherSectionsPromise;
		}
	}

	async hide(toggle: Element): Promise<void> {
		toggle.classList.remove("active");

		toggle.setAttribute("aria-expanded", "false");

		toggle
			.querySelectorAll("[role='switch']")
			.forEach(s => s.setAttribute("aria-checked", "false"));

		const section = toggle.nextElementSibling;
		if (section) {
			section.classList.remove("scroll");
			section.setAttribute("aria-hidden", "true");

			return $(section)
				.animate({ height: 0 }, defaultAnimationDelay)
				.promise()
				.then(() => {});
		}
	}
}
