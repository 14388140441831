// prettier-ignore
import { quizMock } from "./Mocks/quizMock";

// tslint:disable-next-line: no-unnecessary-class
export class QuizService {
	static async fetchQuestions(endpoint: string): Promise<any> {
		let response;
		if (endpoint.length) {
			response = await fetch(endpoint);
			return response.json();
		} else {
			response = JSON.parse(quizMock);
			return response;
		}
	}

	static async postResults(endpoint: string, payload: {}): Promise<T> {
		try {
			const promise = $.ajax({
				contentType: "application/json",
				data: JSON.stringify(payload),
				type: "POST",
				url: endpoint
			});
			const result = await Promise.resolve(promise);

			return [result, null];
		} catch (err) {
			console.warn(err);

			return [null, err];
		}
	}
}
