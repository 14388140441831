import { Component } from "../../../components/base/Component";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { ICfa } from "../../../models/ICfa";
import { DataLayerService } from "../../services/DataLayerService";

export class TabContainer extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.tabs.forEach(el => {
			el.addEventListener("click", e => this.onTabClick(e));
		});
	}

	private async onTabClick(e: Event): Promise<void> {
		const tab = (e.target as HTMLElement)?.parentElement;

		// CFAC-34730 Tabs
		DataLayerService.pushDataLayerEvent_Tabs_SelectTab({
			identifier: this.getlayerIdentifier(),
			name: this.getlayerName(),
			heading: tab?.dataset.layerHeading || "",
			index: tab?.dataset.layerIndex || ""
		});
	}

	getlayerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	getlayerName(): string {
		return this._element.dataset.layerName || "";
	}

	public get tabs(): NodeListOf<HTMLElement> {
		return this._element.querySelectorAll<HTMLElement>(
			"div[data-element='tab']"
		);
	}
}

ComponentFactory.registerComponent("TabContainer", TabContainer);
