import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { CustomEvent } from "../../util/EventUtil";

interface IUpdateEvent {
	readonly calories: number;
	readonly carbs: number;
	readonly fat: number;
	readonly protein: number;
}

export class NutritionAccordion extends Component {
	private static readonly UpdateEvent = "NutritionAccordionUpdateEvent";
	private static readonly DefaultDelay = 300;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		document.body.addEventListener(NutritionAccordion.UpdateEvent, evt =>
			this.handleUpdate(evt as CustomEvent<IUpdateEvent>)
		);

		this.toggles.forEach(el =>
			el.addEventListener("click", evt => this.handleToggle(evt))
		);

		this._element
			.querySelectorAll("[data-track]")
			.forEach(el => el.addEventListener("click", () => this.handleTrack()));
	}

	private get toggles(): NodeListOf<HTMLElement> {
		return this._element.querySelectorAll(
			"[data-action='toggle nutrition accordion']"
		);
	}

	private get delay(): number {
		const raw = this._element.dataset.slideDelay;
		if (raw) {
			const parsed = parseInt(raw, 10);
			if (!isNaN(parsed)) {
				return Math.max(parsed, 0);
			}
		}

		return NutritionAccordion.DefaultDelay;
	}

	public static updateAll(
		calories: number,
		carbs: number,
		fat: number,
		protein: number
	): void {
		const eventInitDict: CustomEventInit<IUpdateEvent> = {
			bubbles: true,
			detail: { calories, carbs, fat, protein }
		};

		const evt = CustomEvent(NutritionAccordion.UpdateEvent, eventInitDict);
		document.body.dispatchEvent(evt);
	}

	private populate(selector: string, value: number): void {
		this._element
			.querySelectorAll<HTMLElement>(`[data-populate-with='${selector}']`)
			.forEach(el => {
				const unit = el.dataset.unit || "";
				const human = value.toLocaleString();
				el.innerText = human + unit;
			});
	}

	private handleUpdate(evt: CustomEvent<IUpdateEvent>): void {
		this.populate("calories", evt.detail.calories);
		this.populate("carbs", evt.detail.carbs);
		this.populate("fat", evt.detail.fat);
		this.populate("protein", evt.detail.protein);
	}

	private handleTrack(): void {
		(dataLayer || []).push({
			category: "showinformationPDP",
			event: "engagement"
		});
	}

	private handleToggle(evt: Event): void {
		evt.preventDefault();
		this.toggle();
	}

	private toggle(): void {
		const openClassName = "pdp-accord--open";

		this.toggles.forEach(toggle => {
			toggle.classList.toggle(openClassName);
			const expanded = toggle.classList
				.contains(openClassName)
				.toString()
				.toLowerCase();

			toggle.setAttribute("aria-expanded", expanded);
		});

		this._element
			.querySelectorAll("[data-expand-when='toggled']")
			.forEach(el => $(el).stop().slideToggle(this.delay));
	}
}

ComponentFactory.registerComponent("NutritionAccordion", NutritionAccordion);
