﻿$(function() {
	function triggerPageEvent(eventId) {
		var url =
		"/Sitecore/api/ssc/" +
		"CFA-Foundation-SitecoreExtensions-Analytics-Controllers/Analytics/1/" +
		"TriggerPageEvent" +
		"?eventId=" + eventId +
		"&setUrl=" + encodeURIComponent(window.location.pathname);

		$.post(url);
	}

	// App Download
	$("body").on(
		"click",
		".cfa-one-app-goal, .start a.apple, .start a.google, .start a.btn.app",
		function () {
			triggerPageEvent("{15DD584A-9EC2-4358-9F30-C4CBD587DB34}");
		});

	// Home Page Drawer Read More
	$("body").on("click", ".stories .centered a.btn", function () {
		triggerPageEvent("{6C10085D-2B7E-4C77-8278-0C23F9E48F66}");
	});

	// Poll Vote
	$("body").on("click", ".vote a.btn", function () {
		triggerPageEvent("{ECC27399-D83B-493F-9BA5-EC67AACD203A}");
	});

	// Quiz Results
	if ($(".results .blurb h3").length) {
		triggerPageEvent("{ECC27399-D83B-493F-9BA5-EC67AACD203A}");
	}

	// Provide Feedback
	$("body").on(
		"submit",
		".formRestExp,.formTechAssistance,.formInquiries,#formMealKit",
		function () {
			triggerPageEvent("{01B2299C-72D3-4075-81FF-BF93D9156F11}");
		});

	// View Menu
	$("body").on("click", ".fullsize.lrgimg .scroller", function () {
		triggerPageEvent("{F50C77AF-2B72-495E-BF06-0BC69293BA17}");
	});

	// Nuggets and News
	$("body").on("click", "#stories .closed", function () {
		triggerPageEvent("{E892D155-91E0-464A-85CB-4FDC55E75D21}");
	});

	// Catering Add To Cart
	$("body").on("click", ".menuaddtocart", function () {
		triggerPageEvent("{1ED052BA-A89D-4A35-A155-21DB9DFFD61D}");
	});
});
